(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.dj.bL === region.dN.bL)
	{
		return 'on line ' + region.dj.bL;
	}
	return 'on lines ' + region.dj.bL + ' through ' + region.dN.bL;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gc,
		impl.hG,
		impl.he,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		aO: func(record.aO),
		dk: record.dk,
		de: record.de
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.aO;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.dk;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.de) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gc,
		impl.hG,
		impl.he,
		function(sendToApp, initialModel) {
			var view = impl.cL;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gc,
		impl.hG,
		impl.he,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.di && impl.di(sendToApp)
			var view = impl.cL;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.fh);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.hA) && (_VirtualDom_doc.title = title = doc.hA);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.gG;
	var onUrlRequest = impl.gH;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		di: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.eu === next.eu
							&& curr.dX === next.dX
							&& curr.er.a === next.er.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		gc: function(flags)
		{
			return A3(impl.gc, flags, _Browser_getUrl(), key);
		},
		cL: impl.cL,
		hG: impl.hG,
		he: impl.he
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { f3: 'hidden', fs: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { f3: 'mozHidden', fs: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { f3: 'msHidden', fs: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { f3: 'webkitHidden', fs: 'webkitvisibilitychange' }
		: { f3: 'hidden', fs: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		eD: _Browser_getScene(),
		bC: {
			eX: _Browser_window.pageXOffset,
			eY: _Browser_window.pageYOffset,
			ab: _Browser_doc.documentElement.clientWidth,
			av: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		ab: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		av: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			eD: {
				ab: node.scrollWidth,
				av: node.scrollHeight
			},
			bC: {
				eX: node.scrollLeft,
				eY: node.scrollTop,
				ab: node.clientWidth,
				av: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			eD: _Browser_getScene(),
			bC: {
				eX: x,
				eY: y,
				ab: _Browser_doc.documentElement.clientWidth,
				av: _Browser_doc.documentElement.clientHeight
			},
			fP: {
				eX: x + rect.left,
				eY: y + rect.top,
				ab: rect.width,
				av: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}



// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });
var $author$project$Main$NoOp = {$: 40};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.w) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.A),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.A);
		} else {
			var treeLen = builder.w * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.B) : builder.B;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.w);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.A) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.A);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{B: nodeList, w: (len / $elm$core$Array$branchFactor) | 0, A: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {dR: fragment, dX: host, ep: path, er: port_, eu: protocol, ev: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Main$ImportPageFromUrl = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$InfoPanel = {$: 1};
var $author$project$Midi$Initialising = {$: 0};
var $author$project$Main$Normal = {$: 0};
var $author$project$Main$PageResized = F2(
	function (a, b) {
		return {$: 37, a: a, b: b};
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Controller$Module = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Controller$Space = {$: 14};
var $author$project$Main$blankPage = {
	aH: {dK: false, m: 2},
	ar: A2($author$project$Controller$Module, 'Click the pencil in\n the menu to edit me!', $author$project$Controller$Space),
	d: 'Blank'
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $miniBill$elm_codec$Codec$decoder = function (_v0) {
	var m = _v0;
	return m.fI;
};
var $miniBill$elm_codec$Codec$decodeValue = function (codec) {
	return $elm$json$Json$Decode$decodeValue(
		$miniBill$elm_codec$Codec$decoder(codec));
};
var $author$project$Style$Blue = 5;
var $author$project$Controller$CCValue = function (a) {
	return {$: 7, a: a};
};
var $author$project$Midi$Ch1 = 0;
var $author$project$Midi$Ch2 = 1;
var $author$project$Midi$Ch3 = 2;
var $author$project$Controller$Command = function (a) {
	return {$: 8, a: a};
};
var $author$project$Midi$ControllerChange = function (a) {
	return {$: 3, a: a};
};
var $author$project$Style$DarkGrey = 2;
var $author$project$Style$Green = 4;
var $author$project$Style$LightGrey = 1;
var $author$project$Controller$Note = function (a) {
	return {$: 5, a: a};
};
var $author$project$Controller$Off = 1;
var $author$project$Style$Red = 7;
var $author$project$Controller$Row = function (a) {
	return {$: 1, a: a};
};
var $author$project$Controller$Sequence = function (a) {
	return {$: 9, a: a};
};
var $author$project$Controller$SizedColumn = function (a) {
	return {$: 4, a: a};
};
var $author$project$Controller$SizedRow = function (a) {
	return {$: 3, a: a};
};
var $author$project$Style$Small = 0;
var $author$project$Midi$StartSong = {$: 14};
var $author$project$Midi$StopSong = {$: 16};
var $author$project$Style$White = 0;
var $author$project$Style$Yellow = 6;
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{B: nodeList, w: nodeListSize, A: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $author$project$Main$drumsController = $author$project$Controller$SizedColumn(
	_List_fromArray(
		[
			_Utils_Tuple2(
			1,
			$author$project$Controller$SizedColumn(
				_List_fromArray(
					[
						_Utils_Tuple2(
						1,
						$author$project$Controller$Row(
							_List_fromArray(
								[
									$author$project$Controller$CCValue(
									{
										f: 0,
										i: 1,
										ar: 53,
										d: 'Unmute\nKick',
										n: $elm$core$Maybe$Just(0),
										c: 1,
										aE: 0
									}),
									$author$project$Controller$CCValue(
									{
										f: 1,
										i: 1,
										ar: 53,
										d: 'Unmute\nSnare',
										n: $elm$core$Maybe$Just(0),
										c: 1,
										aE: 0
									}),
									$author$project$Controller$CCValue(
									{
										f: 2,
										i: 1,
										ar: 53,
										d: 'Unmute\nHat',
										n: $elm$core$Maybe$Just(0),
										c: 1,
										aE: 0
									})
								]))),
						_Utils_Tuple2(
						1,
						$author$project$Controller$SizedRow(
							_List_fromArray(
								[
									_Utils_Tuple2(
									1,
									$author$project$Controller$CCValue(
										{
											f: 0,
											i: 2,
											ar: 53,
											d: 'Mute\nKick',
											n: $elm$core$Maybe$Just(0),
											c: 1,
											aE: 1
										})),
									_Utils_Tuple2(
									1,
									$author$project$Controller$CCValue(
										{
											f: 1,
											i: 2,
											ar: 53,
											d: 'Mute\nSnare',
											n: $elm$core$Maybe$Just(0),
											c: 1,
											aE: 1
										})),
									_Utils_Tuple2(
									1,
									$author$project$Controller$CCValue(
										{
											f: 2,
											i: 2,
											ar: 53,
											d: 'Mute\nHat',
											n: $elm$core$Maybe$Just(0),
											c: 1,
											aE: 1
										}))
								]))),
						_Utils_Tuple2(
						1,
						$author$project$Controller$SizedRow(
							_List_fromArray(
								[
									_Utils_Tuple2(
									1,
									$author$project$Controller$Command(
										{
											i: 6,
											d: 'Hold for\nReverse Snare',
											n: $elm$core$Maybe$Just(0),
											a8: _List_fromArray(
												[
													$author$project$Midi$ControllerChange(
													{f: 1, ar: 2, aE: 127})
												]),
											a9: _List_fromArray(
												[
													$author$project$Midi$ControllerChange(
													{f: 1, ar: 2, aE: 0})
												]),
											c: 1
										})),
									_Utils_Tuple2(
									1,
									$author$project$Controller$Sequence(
										{
											i: 0,
											a5: 0,
											d: 'Play/Stop',
											n: $elm$core$Maybe$Just(0),
											aP: $elm$core$Array$fromList(
												_List_fromArray(
													[$author$project$Midi$StartSong, $author$project$Midi$StopSong])),
											c: 1
										}))
								])))
					]))),
			_Utils_Tuple2(
			1,
			$author$project$Controller$SizedRow(
				_List_fromArray(
					[
						_Utils_Tuple2(
						1,
						$author$project$Controller$Note(
							{
								f: 1,
								i: 4,
								d: 'Snare',
								n: $elm$core$Maybe$Just(0),
								y: 53,
								c: 1,
								E: 100
							})),
						_Utils_Tuple2(
						1,
						$author$project$Controller$Note(
							{
								f: 2,
								i: 5,
								d: 'Hat 50',
								n: $elm$core$Maybe$Just(0),
								y: 53,
								c: 1,
								E: 50
							}))
					]))),
			_Utils_Tuple2(
			1,
			$author$project$Controller$SizedRow(
				_List_fromArray(
					[
						_Utils_Tuple2(
						1,
						$author$project$Controller$Note(
							{
								f: 0,
								i: 7,
								d: 'Kick',
								n: $elm$core$Maybe$Just(0),
								y: 53,
								c: 1,
								E: 100
							})),
						_Utils_Tuple2(
						1,
						$author$project$Controller$Note(
							{
								f: 2,
								i: 5,
								d: 'Hat 100',
								n: $elm$core$Maybe$Just(0),
								y: 53,
								c: 1,
								E: 100
							}))
					])))
		]));
var $author$project$Main$drumPage = {
	aH: {dK: false, m: 2},
	ar: $author$project$Main$drumsController,
	d: '🥁'
};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $miniBill$elm_codec$Codec$Codec = $elm$core$Basics$identity;
var $miniBill$elm_codec$Codec$encoder = function (_v0) {
	var m = _v0;
	return m.p;
};
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $miniBill$elm_codec$Codec$maybe = function (codec) {
	return {
		fI: $elm$json$Json$Decode$maybe(
			$miniBill$elm_codec$Codec$decoder(codec)),
		p: function (v) {
			if (v.$ === 1) {
				return $elm$json$Json$Encode$null;
			} else {
				var x = v.a;
				return A2($miniBill$elm_codec$Codec$encoder, codec, x);
			}
		}
	};
};
var $author$project$Main$Model = function (midiStatus) {
	return function (mode) {
		return function (pages) {
			return function (activePage) {
				return function (savedPages) {
					return function (savedModules) {
						return function (menuOpen) {
							return function (popup) {
								return function (midiLog) {
									return function (screen) {
										return function (navKey) {
											return {k: activePage, bt: menuOpen, bu: midiLog, bN: midiStatus, o: mode, bP: navKey, b: pages, h: popup, az: savedModules, aA: savedPages, bd: screen};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$array = _Json_decodeArray;
var $elm$core$Elm$JsArray$foldl = _JsArray_foldl;
var $elm$core$Array$foldl = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldl, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldl,
			func,
			A3($elm$core$Elm$JsArray$foldl, helper, baseCase, tree),
			tail);
	});
var $elm$json$Json$Encode$array = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$Array$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $miniBill$elm_codec$Codec$composite = F3(
	function (enc, dec, _v0) {
		var codec = _v0;
		return {
			fI: dec(codec.fI),
			p: enc(codec.p)
		};
	});
var $miniBill$elm_codec$Codec$array = A2($miniBill$elm_codec$Codec$composite, $elm$json$Json$Encode$array, $elm$json$Json$Decode$array);
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $miniBill$elm_codec$Codec$buildObject = function (_v0) {
	var om = _v0;
	return {
		fI: om.fI,
		p: function (v) {
			return $elm$json$Json$Encode$object(
				om.p(v));
		}
	};
};
var $miniBill$elm_codec$Codec$constant = function (default_) {
	return {
		fI: $elm$json$Json$Decode$succeed(default_),
		p: function (_v0) {
			return $elm$json$Json$Encode$null;
		}
	};
};
var $author$project$Controller$Chord = function (a) {
	return {$: 6, a: a};
};
var $author$project$Controller$Column = function (a) {
	return {$: 2, a: a};
};
var $author$project$Controller$Fader = function (a) {
	return {$: 10, a: a};
};
var $author$project$Controller$MidiLog = {$: 13};
var $author$project$Controller$PitchBend = function (a) {
	return {$: 12, a: a};
};
var $author$project$Controller$XYFader = function (a) {
	return {$: 11, a: a};
};
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $miniBill$elm_codec$Codec$buildCustom = function (_v0) {
	var am = _v0;
	return {
		fI: A2(
			$elm$json$Json$Decode$andThen,
			function (tag) {
				var _v1 = A2($elm$core$Dict$get, tag, am.fI);
				if (_v1.$ === 1) {
					return $elm$json$Json$Decode$fail('tag ' + (tag + 'did not match'));
				} else {
					var dec = _v1.a;
					return A2($elm$json$Json$Decode$field, 'args', dec);
				}
			},
			A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string)),
		p: function (v) {
			return am.cl(v);
		}
	};
};
var $author$project$Controller$CCValueState = F7(
	function (status, label, labelSize, colour, channel, controller, value) {
		return {f: channel, i: colour, ar: controller, d: label, n: labelSize, c: status, aE: value};
	});
var $author$project$Style$Black = 3;
var $miniBill$elm_codec$Codec$CustomCodec = $elm$core$Basics$identity;
var $miniBill$elm_codec$Codec$custom = function (match) {
	return {fI: $elm$core$Dict$empty, cl: match};
};
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $miniBill$elm_codec$Codec$variant = F4(
	function (name, matchPiece, decoderPiece, _v0) {
		var am = _v0;
		var enc = function (v) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string(name)),
						_Utils_Tuple2(
						'args',
						A2($elm$json$Json$Encode$list, $elm$core$Basics$identity, v))
					]));
		};
		return {
			fI: A3($elm$core$Dict$insert, name, decoderPiece, am.fI),
			cl: am.cl(
				matchPiece(enc))
		};
	});
var $miniBill$elm_codec$Codec$variant0 = F2(
	function (name, ctor) {
		return A3(
			$miniBill$elm_codec$Codec$variant,
			name,
			function (c) {
				return c(_List_Nil);
			},
			$elm$json$Json$Decode$succeed(ctor));
	});
var $author$project$Style$appColourCodec = $miniBill$elm_codec$Codec$buildCustom(
	A3(
		$miniBill$elm_codec$Codec$variant0,
		'Red',
		7,
		A3(
			$miniBill$elm_codec$Codec$variant0,
			'Yellow',
			6,
			A3(
				$miniBill$elm_codec$Codec$variant0,
				'Blue',
				5,
				A3(
					$miniBill$elm_codec$Codec$variant0,
					'Green',
					4,
					A3(
						$miniBill$elm_codec$Codec$variant0,
						'Black',
						3,
						A3(
							$miniBill$elm_codec$Codec$variant0,
							'DarkGrey',
							2,
							A3(
								$miniBill$elm_codec$Codec$variant0,
								'LightGrey',
								1,
								A3(
									$miniBill$elm_codec$Codec$variant0,
									'White',
									0,
									$miniBill$elm_codec$Codec$custom(
										F9(
											function (wh, lg, dg, bk, gr, bl, ye, re, value) {
												switch (value) {
													case 0:
														return wh;
													case 1:
														return lg;
													case 2:
														return dg;
													case 3:
														return bk;
													case 4:
														return gr;
													case 5:
														return bl;
													case 6:
														return ye;
													default:
														return re;
												}
											})))))))))));
var $author$project$Midi$Ch10 = 9;
var $author$project$Midi$Ch11 = 10;
var $author$project$Midi$Ch12 = 11;
var $author$project$Midi$Ch13 = 12;
var $author$project$Midi$Ch14 = 13;
var $author$project$Midi$Ch15 = 14;
var $author$project$Midi$Ch16 = 15;
var $author$project$Midi$Ch4 = 3;
var $author$project$Midi$Ch5 = 4;
var $author$project$Midi$Ch6 = 5;
var $author$project$Midi$Ch7 = 6;
var $author$project$Midi$Ch8 = 7;
var $author$project$Midi$Ch9 = 8;
var $author$project$Midi$channelCodec = $miniBill$elm_codec$Codec$buildCustom(
	A3(
		$miniBill$elm_codec$Codec$variant0,
		'Ch16',
		15,
		A3(
			$miniBill$elm_codec$Codec$variant0,
			'Ch15',
			14,
			A3(
				$miniBill$elm_codec$Codec$variant0,
				'Ch14',
				13,
				A3(
					$miniBill$elm_codec$Codec$variant0,
					'Ch13',
					12,
					A3(
						$miniBill$elm_codec$Codec$variant0,
						'Ch12',
						11,
						A3(
							$miniBill$elm_codec$Codec$variant0,
							'Ch11',
							10,
							A3(
								$miniBill$elm_codec$Codec$variant0,
								'Ch10',
								9,
								A3(
									$miniBill$elm_codec$Codec$variant0,
									'Ch9',
									8,
									A3(
										$miniBill$elm_codec$Codec$variant0,
										'Ch8',
										7,
										A3(
											$miniBill$elm_codec$Codec$variant0,
											'Ch7',
											6,
											A3(
												$miniBill$elm_codec$Codec$variant0,
												'Ch6',
												5,
												A3(
													$miniBill$elm_codec$Codec$variant0,
													'Ch5',
													4,
													A3(
														$miniBill$elm_codec$Codec$variant0,
														'Ch4',
														3,
														A3(
															$miniBill$elm_codec$Codec$variant0,
															'Ch3',
															2,
															A3(
																$miniBill$elm_codec$Codec$variant0,
																'Ch2',
																1,
																A3(
																	$miniBill$elm_codec$Codec$variant0,
																	'Ch1',
																	0,
																	$miniBill$elm_codec$Codec$custom(
																		function (c1) {
																			return function (c2) {
																				return function (c3) {
																					return function (c4) {
																						return function (c5) {
																							return function (c6) {
																								return function (c7) {
																									return function (c8) {
																										return function (c9) {
																											return function (c10) {
																												return function (c11) {
																													return function (c12) {
																														return function (c13) {
																															return function (c14) {
																																return function (c15) {
																																	return function (c16) {
																																		return function (value) {
																																			switch (value) {
																																				case 0:
																																					return c1;
																																				case 1:
																																					return c2;
																																				case 2:
																																					return c3;
																																				case 3:
																																					return c4;
																																				case 4:
																																					return c5;
																																				case 5:
																																					return c6;
																																				case 6:
																																					return c7;
																																				case 7:
																																					return c8;
																																				case 8:
																																					return c9;
																																				case 9:
																																					return c10;
																																				case 10:
																																					return c11;
																																				case 11:
																																					return c12;
																																				case 12:
																																					return c13;
																																				case 13:
																																					return c14;
																																				case 14:
																																					return c15;
																																				default:
																																					return c16;
																																			}
																																		};
																																	};
																																};
																															};
																														};
																													};
																												};
																											};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		}))))))))))))))))));
var $miniBill$elm_codec$Codec$ObjectCodec = $elm$core$Basics$identity;
var $miniBill$elm_codec$Codec$field = F4(
	function (name, getter, codec, _v0) {
		var ocodec = _v0;
		return {
			fI: A3(
				$elm$json$Json$Decode$map2,
				F2(
					function (f, x) {
						return f(x);
					}),
				ocodec.fI,
				A2(
					$elm$json$Json$Decode$field,
					name,
					$miniBill$elm_codec$Codec$decoder(codec))),
			p: function (v) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(
						name,
						A2(
							$miniBill$elm_codec$Codec$encoder,
							codec,
							getter(v))),
					ocodec.p(v));
			}
		};
	});
var $miniBill$elm_codec$Codec$build = F2(
	function (encoder_, decoder_) {
		return {fI: decoder_, p: encoder_};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Encode$int = _Json_wrap;
var $miniBill$elm_codec$Codec$int = A2($miniBill$elm_codec$Codec$build, $elm$json$Json$Encode$int, $elm$json$Json$Decode$int);
var $author$project$Style$ExtraLarge = 3;
var $author$project$Style$Large = 2;
var $author$project$Style$Medium = 1;
var $author$project$Style$labelSizeCodec = $miniBill$elm_codec$Codec$buildCustom(
	A3(
		$miniBill$elm_codec$Codec$variant0,
		'ExtraLarge',
		3,
		A3(
			$miniBill$elm_codec$Codec$variant0,
			'Large',
			2,
			A3(
				$miniBill$elm_codec$Codec$variant0,
				'Medium',
				1,
				A3(
					$miniBill$elm_codec$Codec$variant0,
					'Small',
					0,
					$miniBill$elm_codec$Codec$custom(
						F5(
							function (sm, me, la, xl, value) {
								switch (value) {
									case 0:
										return sm;
									case 1:
										return me;
									case 2:
										return la;
									default:
										return xl;
								}
							})))))));
var $miniBill$elm_codec$Codec$maybeField = F4(
	function (name, getter, codec, _v0) {
		var ocodec = _v0;
		return {
			fI: A3(
				$elm$json$Json$Decode$map2,
				F2(
					function (f, x) {
						return f(x);
					}),
				ocodec.fI,
				$elm$json$Json$Decode$maybe(
					A2(
						$elm$json$Json$Decode$field,
						name,
						$miniBill$elm_codec$Codec$decoder(codec)))),
			p: function (v) {
				var _v1 = getter(v);
				if (!_v1.$) {
					var present = _v1.a;
					return A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							name,
							A2($miniBill$elm_codec$Codec$encoder, codec, present)),
						ocodec.p(v));
				} else {
					return ocodec.p(v);
				}
			}
		};
	});
var $miniBill$elm_codec$Codec$object = function (ctor) {
	return {
		fI: $elm$json$Json$Decode$succeed(ctor),
		p: function (_v0) {
			return _List_Nil;
		}
	};
};
var $miniBill$elm_codec$Codec$string = A2($miniBill$elm_codec$Codec$build, $elm$json$Json$Encode$string, $elm$json$Json$Decode$string);
var $author$project$Controller$ccValueStateCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'value',
		function ($) {
			return $.aE;
		},
		$miniBill$elm_codec$Codec$int,
		A4(
			$miniBill$elm_codec$Codec$field,
			'controller',
			function ($) {
				return $.ar;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'channel',
				function ($) {
					return $.f;
				},
				$author$project$Midi$channelCodec,
				A4(
					$miniBill$elm_codec$Codec$field,
					'colour',
					function ($) {
						return $.i;
					},
					$author$project$Style$appColourCodec,
					A4(
						$miniBill$elm_codec$Codec$maybeField,
						'labelSize',
						function ($) {
							return $.n;
						},
						$author$project$Style$labelSizeCodec,
						A4(
							$miniBill$elm_codec$Codec$field,
							'label',
							function ($) {
								return $.d;
							},
							$miniBill$elm_codec$Codec$string,
							A4(
								$miniBill$elm_codec$Codec$field,
								'status',
								function ($) {
									return $.c;
								},
								$miniBill$elm_codec$Codec$constant(1),
								$miniBill$elm_codec$Codec$object($author$project$Controller$CCValueState)))))))));
var $author$project$Controller$ChordState = F6(
	function (status, label, labelSize, colour, velocity, notes) {
		return {i: colour, d: label, n: labelSize, aR: notes, c: status, E: velocity};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $miniBill$elm_codec$Codec$list = A2($miniBill$elm_codec$Codec$composite, $elm$json$Json$Encode$list, $elm$json$Json$Decode$list);
var $author$project$Controller$chordStateCodec = function () {
	var noteCodec = $miniBill$elm_codec$Codec$buildObject(
		A4(
			$miniBill$elm_codec$Codec$field,
			'pitch',
			function ($) {
				return $.y;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'channel',
				function ($) {
					return $.f;
				},
				$author$project$Midi$channelCodec,
				$miniBill$elm_codec$Codec$object(
					F2(
						function (c, p) {
							return {f: c, y: p};
						})))));
	return $miniBill$elm_codec$Codec$buildObject(
		A4(
			$miniBill$elm_codec$Codec$field,
			'notes',
			function ($) {
				return $.aR;
			},
			$miniBill$elm_codec$Codec$list(noteCodec),
			A4(
				$miniBill$elm_codec$Codec$field,
				'velocity',
				function ($) {
					return $.E;
				},
				$miniBill$elm_codec$Codec$int,
				A4(
					$miniBill$elm_codec$Codec$field,
					'colour',
					function ($) {
						return $.i;
					},
					$author$project$Style$appColourCodec,
					A4(
						$miniBill$elm_codec$Codec$maybeField,
						'labelSize',
						function ($) {
							return $.n;
						},
						$author$project$Style$labelSizeCodec,
						A4(
							$miniBill$elm_codec$Codec$field,
							'label',
							function ($) {
								return $.d;
							},
							$miniBill$elm_codec$Codec$string,
							A4(
								$miniBill$elm_codec$Codec$field,
								'status',
								function ($) {
									return $.c;
								},
								$miniBill$elm_codec$Codec$constant(1),
								$miniBill$elm_codec$Codec$object($author$project$Controller$ChordState))))))));
}();
var $author$project$Controller$CommandState = F6(
	function (status, label, labelSize, colour, onPressMsgs, onReleaseMsgs) {
		return {i: colour, d: label, n: labelSize, a8: onPressMsgs, a9: onReleaseMsgs, c: status};
	});
var $author$project$Midi$ActiveSensing = {$: 17};
var $author$project$Midi$ChannelPressure = function (a) {
	return {$: 5, a: a};
};
var $author$project$Midi$ContinueSong = {$: 15};
var $author$project$Midi$EndOfSysEx = {$: 12};
var $author$project$Midi$KeyPressure = function (a) {
	return {$: 2, a: a};
};
var $author$project$Midi$NoteOff = function (a) {
	return {$: 1, a: a};
};
var $author$project$Midi$NoteOn = function (a) {
	return {$: 0, a: a};
};
var $author$project$Midi$PitchBend = function (a) {
	return {$: 6, a: a};
};
var $author$project$Midi$ProgramChange = function (a) {
	return {$: 4, a: a};
};
var $author$project$Midi$SongPosition = function (a) {
	return {$: 8, a: a};
};
var $author$project$Midi$SongSelect = function (a) {
	return {$: 9, a: a};
};
var $author$project$Midi$SystemExclusive = function (a) {
	return {$: 7, a: a};
};
var $author$project$Midi$SystemReset = {$: 18};
var $author$project$Midi$TimingTick = {$: 13};
var $author$project$Midi$TuneRequest = {$: 11};
var $author$project$Midi$Unknown = function (a) {
	return {$: 19, a: a};
};
var $author$project$Midi$UnofficialBusSelect = function (a) {
	return {$: 10, a: a};
};
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $miniBill$elm_codec$Codec$variant1 = F3(
	function (name, ctor, m1) {
		return A3(
			$miniBill$elm_codec$Codec$variant,
			name,
			F2(
				function (c, v) {
					return c(
						_List_fromArray(
							[
								A2($miniBill$elm_codec$Codec$encoder, m1, v)
							]));
				}),
			A2(
				$elm$json$Json$Decode$map,
				ctor,
				A2(
					$elm$json$Json$Decode$index,
					0,
					$miniBill$elm_codec$Codec$decoder(m1))));
	});
var $author$project$Midi$midiMsgCodec = function () {
	var sysExParamsCodec = $miniBill$elm_codec$Codec$array($miniBill$elm_codec$Codec$int);
	var songPosParamsCodec = $miniBill$elm_codec$Codec$buildObject(
		A4(
			$miniBill$elm_codec$Codec$field,
			'positionMSB',
			function ($) {
				return $.bT;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'positionLSB',
				function ($) {
					return $.bS;
				},
				$miniBill$elm_codec$Codec$int,
				$miniBill$elm_codec$Codec$object(
					F2(
						function (l, m) {
							return {bS: l, bT: m};
						})))));
	var pitchBendParamsCodec = $miniBill$elm_codec$Codec$buildObject(
		A4(
			$miniBill$elm_codec$Codec$field,
			'bendMSB',
			function ($) {
				return $.dz;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'bendLSB',
				function ($) {
					return $.dy;
				},
				$miniBill$elm_codec$Codec$int,
				A4(
					$miniBill$elm_codec$Codec$field,
					'channel',
					function ($) {
						return $.f;
					},
					$miniBill$elm_codec$Codec$int,
					$miniBill$elm_codec$Codec$object(
						F3(
							function (ch, l, m) {
								return {dy: l, dz: m, f: ch};
							}))))));
	var pcParamsCodec = $miniBill$elm_codec$Codec$buildObject(
		A4(
			$miniBill$elm_codec$Codec$field,
			'preset',
			function ($) {
				return $.W;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'channel',
				function ($) {
					return $.f;
				},
				$miniBill$elm_codec$Codec$int,
				$miniBill$elm_codec$Codec$object(
					F2(
						function (ch, p) {
							return {f: ch, W: p};
						})))));
	var noteParamsCodec = $miniBill$elm_codec$Codec$buildObject(
		A4(
			$miniBill$elm_codec$Codec$field,
			'velocity',
			function ($) {
				return $.E;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'pitch',
				function ($) {
					return $.y;
				},
				$miniBill$elm_codec$Codec$int,
				A4(
					$miniBill$elm_codec$Codec$field,
					'channel',
					function ($) {
						return $.f;
					},
					$miniBill$elm_codec$Codec$int,
					$miniBill$elm_codec$Codec$object(
						F3(
							function (ch, p, v) {
								return {f: ch, y: p, E: v};
							}))))));
	var keyPressureParamsCodec = $miniBill$elm_codec$Codec$buildObject(
		A4(
			$miniBill$elm_codec$Codec$field,
			'pressure',
			function ($) {
				return $.al;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'key',
				function ($) {
					return $.aN;
				},
				$miniBill$elm_codec$Codec$int,
				A4(
					$miniBill$elm_codec$Codec$field,
					'channel',
					function ($) {
						return $.f;
					},
					$miniBill$elm_codec$Codec$int,
					$miniBill$elm_codec$Codec$object(
						F3(
							function (ch, k, p) {
								return {f: ch, aN: k, al: p};
							}))))));
	var chPresParamsCodec = $miniBill$elm_codec$Codec$buildObject(
		A4(
			$miniBill$elm_codec$Codec$field,
			'pressure',
			function ($) {
				return $.al;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'channel',
				function ($) {
					return $.f;
				},
				$miniBill$elm_codec$Codec$int,
				$miniBill$elm_codec$Codec$object(
					F2(
						function (ch, p) {
							return {f: ch, al: p};
						})))));
	var ccParamsCodec = $miniBill$elm_codec$Codec$buildObject(
		A4(
			$miniBill$elm_codec$Codec$field,
			'value',
			function ($) {
				return $.aE;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'controller',
				function ($) {
					return $.ar;
				},
				$miniBill$elm_codec$Codec$int,
				A4(
					$miniBill$elm_codec$Codec$field,
					'channel',
					function ($) {
						return $.f;
					},
					$miniBill$elm_codec$Codec$int,
					$miniBill$elm_codec$Codec$object(
						F3(
							function (ch, c, v) {
								return {f: ch, ar: c, aE: v};
							}))))));
	return $miniBill$elm_codec$Codec$buildCustom(
		A4(
			$miniBill$elm_codec$Codec$variant1,
			'Unknown',
			$author$project$Midi$Unknown,
			$miniBill$elm_codec$Codec$array($miniBill$elm_codec$Codec$int),
			A3(
				$miniBill$elm_codec$Codec$variant0,
				'SystemReset',
				$author$project$Midi$SystemReset,
				A3(
					$miniBill$elm_codec$Codec$variant0,
					'ActiveSensing',
					$author$project$Midi$ActiveSensing,
					A3(
						$miniBill$elm_codec$Codec$variant0,
						'StopSong',
						$author$project$Midi$StopSong,
						A3(
							$miniBill$elm_codec$Codec$variant0,
							'ContinueSong',
							$author$project$Midi$ContinueSong,
							A3(
								$miniBill$elm_codec$Codec$variant0,
								'StartSong',
								$author$project$Midi$StartSong,
								A3(
									$miniBill$elm_codec$Codec$variant0,
									'TimingTick',
									$author$project$Midi$TimingTick,
									A3(
										$miniBill$elm_codec$Codec$variant0,
										'EndOfSysEx',
										$author$project$Midi$EndOfSysEx,
										A3(
											$miniBill$elm_codec$Codec$variant0,
											'TuneRequest',
											$author$project$Midi$TuneRequest,
											A4(
												$miniBill$elm_codec$Codec$variant1,
												'UnofficialBusSelect',
												$author$project$Midi$UnofficialBusSelect,
												$miniBill$elm_codec$Codec$int,
												A4(
													$miniBill$elm_codec$Codec$variant1,
													'SongSelect',
													$author$project$Midi$SongSelect,
													$miniBill$elm_codec$Codec$int,
													A4(
														$miniBill$elm_codec$Codec$variant1,
														'SongPosition',
														$author$project$Midi$SongPosition,
														songPosParamsCodec,
														A4(
															$miniBill$elm_codec$Codec$variant1,
															'SystemExclusive',
															$author$project$Midi$SystemExclusive,
															sysExParamsCodec,
															A4(
																$miniBill$elm_codec$Codec$variant1,
																'PitchBend',
																$author$project$Midi$PitchBend,
																pitchBendParamsCodec,
																A4(
																	$miniBill$elm_codec$Codec$variant1,
																	'ChannelPressure',
																	$author$project$Midi$ChannelPressure,
																	chPresParamsCodec,
																	A4(
																		$miniBill$elm_codec$Codec$variant1,
																		'ProgramChange',
																		$author$project$Midi$ProgramChange,
																		pcParamsCodec,
																		A4(
																			$miniBill$elm_codec$Codec$variant1,
																			'ControllerChange',
																			$author$project$Midi$ControllerChange,
																			ccParamsCodec,
																			A4(
																				$miniBill$elm_codec$Codec$variant1,
																				'KeyPressure',
																				$author$project$Midi$KeyPressure,
																				keyPressureParamsCodec,
																				A4(
																					$miniBill$elm_codec$Codec$variant1,
																					'NoteOff',
																					$author$project$Midi$NoteOff,
																					noteParamsCodec,
																					A4(
																						$miniBill$elm_codec$Codec$variant1,
																						'NoteOn',
																						$author$project$Midi$NoteOn,
																						noteParamsCodec,
																						$miniBill$elm_codec$Codec$custom(
																							function (non) {
																								return function (nof) {
																									return function (kp) {
																										return function (cc) {
																											return function (pc) {
																												return function (cp) {
																													return function (pb) {
																														return function (se) {
																															return function (sp) {
																																return function (ss) {
																																	return function (ub) {
																																		return function (tr) {
																																			return function (es) {
																																				return function (tt) {
																																					return function (sta) {
																																						return function (con) {
																																							return function (sto) {
																																								return function (act) {
																																									return function (sre) {
																																										return function (unk) {
																																											return function (value) {
																																												switch (value.$) {
																																													case 0:
																																														var params = value.a;
																																														return non(params);
																																													case 1:
																																														var params = value.a;
																																														return nof(params);
																																													case 2:
																																														var params = value.a;
																																														return kp(params);
																																													case 3:
																																														var params = value.a;
																																														return cc(params);
																																													case 4:
																																														var params = value.a;
																																														return pc(params);
																																													case 5:
																																														var params = value.a;
																																														return cp(params);
																																													case 6:
																																														var params = value.a;
																																														return pb(params);
																																													case 7:
																																														var params = value.a;
																																														return se(params);
																																													case 8:
																																														var params = value.a;
																																														return sp(params);
																																													case 9:
																																														var songNumber = value.a;
																																														return ss(songNumber);
																																													case 10:
																																														var busNumber = value.a;
																																														return ub(busNumber);
																																													case 11:
																																														return tr;
																																													case 12:
																																														return es;
																																													case 13:
																																														return tt;
																																													case 14:
																																														return sta;
																																													case 15:
																																														return con;
																																													case 16:
																																														return sto;
																																													case 17:
																																														return act;
																																													case 18:
																																														return sre;
																																													default:
																																														var data = value.a;
																																														return unk(data);
																																												}
																																											};
																																										};
																																									};
																																								};
																																							};
																																						};
																																					};
																																				};
																																			};
																																		};
																																	};
																																};
																															};
																														};
																													};
																												};
																											};
																										};
																									};
																								};
																							}))))))))))))))))))))));
}();
var $author$project$Controller$commandStateCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'onReleaseMsgs',
		function ($) {
			return $.a9;
		},
		$miniBill$elm_codec$Codec$list($author$project$Midi$midiMsgCodec),
		A4(
			$miniBill$elm_codec$Codec$field,
			'onPressMsgs',
			function ($) {
				return $.a8;
			},
			$miniBill$elm_codec$Codec$list($author$project$Midi$midiMsgCodec),
			A4(
				$miniBill$elm_codec$Codec$field,
				'colour',
				function ($) {
					return $.i;
				},
				$author$project$Style$appColourCodec,
				A4(
					$miniBill$elm_codec$Codec$maybeField,
					'labelSize',
					function ($) {
						return $.n;
					},
					$author$project$Style$labelSizeCodec,
					A4(
						$miniBill$elm_codec$Codec$field,
						'label',
						function ($) {
							return $.d;
						},
						$miniBill$elm_codec$Codec$string,
						A4(
							$miniBill$elm_codec$Codec$field,
							'status',
							function ($) {
								return $.c;
							},
							$miniBill$elm_codec$Codec$constant(1),
							$miniBill$elm_codec$Codec$object($author$project$Controller$CommandState))))))));
var $author$project$Controller$FaderState = F9(
	function (status, label, labelSize, colour, channel, ccNumber, valuePercent, valueMin, valueMax) {
		return {b4: ccNumber, f: channel, i: colour, d: label, n: labelSize, c: status, cF: valueMax, cI: valueMin, bh: valuePercent};
	});
var $author$project$Controller$Set = {$: 0};
var $author$project$Controller$faderStateCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'valueMax',
		function ($) {
			return $.cF;
		},
		$miniBill$elm_codec$Codec$int,
		A4(
			$miniBill$elm_codec$Codec$field,
			'valueMin',
			function ($) {
				return $.cI;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'valuePercent',
				function ($) {
					return $.bh;
				},
				$miniBill$elm_codec$Codec$int,
				A4(
					$miniBill$elm_codec$Codec$field,
					'ccNumber',
					function ($) {
						return $.b4;
					},
					$miniBill$elm_codec$Codec$int,
					A4(
						$miniBill$elm_codec$Codec$field,
						'channel',
						function ($) {
							return $.f;
						},
						$author$project$Midi$channelCodec,
						A4(
							$miniBill$elm_codec$Codec$field,
							'colour',
							function ($) {
								return $.i;
							},
							$author$project$Style$appColourCodec,
							A4(
								$miniBill$elm_codec$Codec$maybeField,
								'labelSize',
								function ($) {
									return $.n;
								},
								$author$project$Style$labelSizeCodec,
								A4(
									$miniBill$elm_codec$Codec$field,
									'label',
									function ($) {
										return $.d;
									},
									$miniBill$elm_codec$Codec$string,
									A4(
										$miniBill$elm_codec$Codec$field,
										'status',
										function ($) {
											return $.c;
										},
										$miniBill$elm_codec$Codec$constant($author$project$Controller$Set),
										$miniBill$elm_codec$Codec$object($author$project$Controller$FaderState)))))))))));
var $author$project$Controller$NoteState = F7(
	function (status, label, labelSize, colour, channel, pitch, velocity) {
		return {f: channel, i: colour, d: label, n: labelSize, y: pitch, c: status, E: velocity};
	});
var $author$project$Controller$noteStateCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'velocity',
		function ($) {
			return $.E;
		},
		$miniBill$elm_codec$Codec$int,
		A4(
			$miniBill$elm_codec$Codec$field,
			'pitch',
			function ($) {
				return $.y;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'channel',
				function ($) {
					return $.f;
				},
				$author$project$Midi$channelCodec,
				A4(
					$miniBill$elm_codec$Codec$field,
					'colour',
					function ($) {
						return $.i;
					},
					$author$project$Style$appColourCodec,
					A4(
						$miniBill$elm_codec$Codec$maybeField,
						'labelSize',
						function ($) {
							return $.n;
						},
						$author$project$Style$labelSizeCodec,
						A4(
							$miniBill$elm_codec$Codec$field,
							'label',
							function ($) {
								return $.d;
							},
							$miniBill$elm_codec$Codec$string,
							A4(
								$miniBill$elm_codec$Codec$field,
								'status',
								function ($) {
									return $.c;
								},
								$miniBill$elm_codec$Codec$constant(1),
								$miniBill$elm_codec$Codec$object($author$project$Controller$NoteState)))))))));
var $author$project$Controller$PitchBendState = F6(
	function (status, label, labelSize, colour, channel, bendValue) {
		return {bn: bendValue, f: channel, i: colour, d: label, n: labelSize, c: status};
	});
var $author$project$Controller$pitchBendStateCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'bendValue',
		function ($) {
			return $.bn;
		},
		$miniBill$elm_codec$Codec$constant(8192),
		A4(
			$miniBill$elm_codec$Codec$field,
			'channel',
			function ($) {
				return $.f;
			},
			$author$project$Midi$channelCodec,
			A4(
				$miniBill$elm_codec$Codec$field,
				'colour',
				function ($) {
					return $.i;
				},
				$author$project$Style$appColourCodec,
				A4(
					$miniBill$elm_codec$Codec$maybeField,
					'labelSize',
					function ($) {
						return $.n;
					},
					$author$project$Style$labelSizeCodec,
					A4(
						$miniBill$elm_codec$Codec$field,
						'label',
						function ($) {
							return $.d;
						},
						$miniBill$elm_codec$Codec$string,
						A4(
							$miniBill$elm_codec$Codec$field,
							'status',
							function ($) {
								return $.c;
							},
							$miniBill$elm_codec$Codec$constant($author$project$Controller$Set),
							$miniBill$elm_codec$Codec$object($author$project$Controller$PitchBendState))))))));
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
var $miniBill$elm_codec$Codec$lazy = function (f) {
	return {
		fI: $elm$json$Json$Decode$lazy(
			function (_v0) {
				return $miniBill$elm_codec$Codec$decoder(
					f(0));
			}),
		p: function (v) {
			return A2(
				$miniBill$elm_codec$Codec$encoder,
				f(0),
				v);
		}
	};
};
var $miniBill$elm_codec$Codec$recursive = function (f) {
	return f(
		$miniBill$elm_codec$Codec$lazy(
			function (_v0) {
				return $miniBill$elm_codec$Codec$recursive(f);
			}));
};
var $author$project$Controller$SequenceState = F6(
	function (status, label, labelSize, colour, midiMsgs, index) {
		return {i: colour, a5: index, d: label, n: labelSize, aP: midiMsgs, c: status};
	});
var $author$project$Controller$sequenceStateCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'index',
		function ($) {
			return $.a5;
		},
		$miniBill$elm_codec$Codec$int,
		A4(
			$miniBill$elm_codec$Codec$field,
			'midiMsgs',
			function ($) {
				return $.aP;
			},
			$miniBill$elm_codec$Codec$array($author$project$Midi$midiMsgCodec),
			A4(
				$miniBill$elm_codec$Codec$field,
				'colour',
				function ($) {
					return $.i;
				},
				$author$project$Style$appColourCodec,
				A4(
					$miniBill$elm_codec$Codec$maybeField,
					'labelSize',
					function ($) {
						return $.n;
					},
					$author$project$Style$labelSizeCodec,
					A4(
						$miniBill$elm_codec$Codec$field,
						'label',
						function ($) {
							return $.d;
						},
						$miniBill$elm_codec$Codec$string,
						A4(
							$miniBill$elm_codec$Codec$field,
							'status',
							function ($) {
								return $.c;
							},
							$miniBill$elm_codec$Codec$constant(1),
							$miniBill$elm_codec$Codec$object($author$project$Controller$SequenceState))))))));
var $miniBill$elm_codec$Codec$tuple = F2(
	function (m1, m2) {
		return {
			fI: A3(
				$elm$json$Json$Decode$map2,
				F2(
					function (a, b) {
						return _Utils_Tuple2(a, b);
					}),
				A2(
					$elm$json$Json$Decode$index,
					0,
					$miniBill$elm_codec$Codec$decoder(m1)),
				A2(
					$elm$json$Json$Decode$index,
					1,
					$miniBill$elm_codec$Codec$decoder(m2))),
			p: function (_v0) {
				var v1 = _v0.a;
				var v2 = _v0.b;
				return A2(
					$elm$json$Json$Encode$list,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							A2($miniBill$elm_codec$Codec$encoder, m1, v1),
							A2($miniBill$elm_codec$Codec$encoder, m2, v2)
						]));
			}
		};
	});
var $miniBill$elm_codec$Codec$variant2 = F4(
	function (name, ctor, m1, m2) {
		return A3(
			$miniBill$elm_codec$Codec$variant,
			name,
			F3(
				function (c, v1, v2) {
					return c(
						_List_fromArray(
							[
								A2($miniBill$elm_codec$Codec$encoder, m1, v1),
								A2($miniBill$elm_codec$Codec$encoder, m2, v2)
							]));
				}),
			A3(
				$elm$json$Json$Decode$map2,
				ctor,
				A2(
					$elm$json$Json$Decode$index,
					0,
					$miniBill$elm_codec$Codec$decoder(m1)),
				A2(
					$elm$json$Json$Decode$index,
					1,
					$miniBill$elm_codec$Codec$decoder(m2))));
	});
var $author$project$Controller$XYFaderState = function (status) {
	return function (label) {
		return function (labelSize) {
			return function (colour) {
				return function (channel1) {
					return function (ccNumber1) {
						return function (valuePercent1) {
							return function (valueMin1) {
								return function (valueMax1) {
									return function (channel2) {
										return function (ccNumber2) {
											return function (valuePercent2) {
												return function (valueMin2) {
													return function (valueMax2) {
														return {b5: ccNumber1, b6: ccNumber2, bH: channel1, bI: channel2, i: colour, d: label, n: labelSize, c: status, cG: valueMax1, cH: valueMax2, cJ: valueMin1, cK: valueMin2, bi: valuePercent1, bj: valuePercent2};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Controller$xyFaderStateCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'valueMax2',
		function ($) {
			return $.cH;
		},
		$miniBill$elm_codec$Codec$int,
		A4(
			$miniBill$elm_codec$Codec$field,
			'valueMin2',
			function ($) {
				return $.cK;
			},
			$miniBill$elm_codec$Codec$int,
			A4(
				$miniBill$elm_codec$Codec$field,
				'valuePercent2',
				function ($) {
					return $.bj;
				},
				$miniBill$elm_codec$Codec$int,
				A4(
					$miniBill$elm_codec$Codec$field,
					'ccNumber2',
					function ($) {
						return $.b6;
					},
					$miniBill$elm_codec$Codec$int,
					A4(
						$miniBill$elm_codec$Codec$field,
						'channel2',
						function ($) {
							return $.bI;
						},
						$author$project$Midi$channelCodec,
						A4(
							$miniBill$elm_codec$Codec$field,
							'valueMax1',
							function ($) {
								return $.cG;
							},
							$miniBill$elm_codec$Codec$int,
							A4(
								$miniBill$elm_codec$Codec$field,
								'valueMin1',
								function ($) {
									return $.cJ;
								},
								$miniBill$elm_codec$Codec$int,
								A4(
									$miniBill$elm_codec$Codec$field,
									'valuePercent1',
									function ($) {
										return $.bi;
									},
									$miniBill$elm_codec$Codec$int,
									A4(
										$miniBill$elm_codec$Codec$field,
										'ccNumber1',
										function ($) {
											return $.b5;
										},
										$miniBill$elm_codec$Codec$int,
										A4(
											$miniBill$elm_codec$Codec$field,
											'channel1',
											function ($) {
												return $.bH;
											},
											$author$project$Midi$channelCodec,
											A4(
												$miniBill$elm_codec$Codec$field,
												'colour',
												function ($) {
													return $.i;
												},
												$author$project$Style$appColourCodec,
												A4(
													$miniBill$elm_codec$Codec$maybeField,
													'labelSize',
													function ($) {
														return $.n;
													},
													$author$project$Style$labelSizeCodec,
													A4(
														$miniBill$elm_codec$Codec$field,
														'label',
														function ($) {
															return $.d;
														},
														$miniBill$elm_codec$Codec$string,
														A4(
															$miniBill$elm_codec$Codec$field,
															'status',
															function ($) {
																return $.c;
															},
															$miniBill$elm_codec$Codec$constant($author$project$Controller$Set),
															$miniBill$elm_codec$Codec$object($author$project$Controller$XYFaderState))))))))))))))));
var $author$project$Controller$controllerCodec = $miniBill$elm_codec$Codec$recursive(
	function (rmeta) {
		return $miniBill$elm_codec$Codec$buildCustom(
			A3(
				$miniBill$elm_codec$Codec$variant0,
				'Space',
				$author$project$Controller$Space,
				A3(
					$miniBill$elm_codec$Codec$variant0,
					'MidiLog',
					$author$project$Controller$MidiLog,
					A4(
						$miniBill$elm_codec$Codec$variant1,
						'PitchBend',
						$author$project$Controller$PitchBend,
						$author$project$Controller$pitchBendStateCodec,
						A4(
							$miniBill$elm_codec$Codec$variant1,
							'XYFader',
							$author$project$Controller$XYFader,
							$author$project$Controller$xyFaderStateCodec,
							A4(
								$miniBill$elm_codec$Codec$variant1,
								'Fader',
								$author$project$Controller$Fader,
								$author$project$Controller$faderStateCodec,
								A4(
									$miniBill$elm_codec$Codec$variant1,
									'Sequence',
									$author$project$Controller$Sequence,
									$author$project$Controller$sequenceStateCodec,
									A4(
										$miniBill$elm_codec$Codec$variant1,
										'Command',
										$author$project$Controller$Command,
										$author$project$Controller$commandStateCodec,
										A4(
											$miniBill$elm_codec$Codec$variant1,
											'CCValue',
											$author$project$Controller$CCValue,
											$author$project$Controller$ccValueStateCodec,
											A4(
												$miniBill$elm_codec$Codec$variant1,
												'Chord',
												$author$project$Controller$Chord,
												$author$project$Controller$chordStateCodec,
												A4(
													$miniBill$elm_codec$Codec$variant1,
													'Note',
													$author$project$Controller$Note,
													$author$project$Controller$noteStateCodec,
													A4(
														$miniBill$elm_codec$Codec$variant1,
														'SizedColumn',
														$author$project$Controller$SizedColumn,
														$miniBill$elm_codec$Codec$list(
															A2($miniBill$elm_codec$Codec$tuple, $miniBill$elm_codec$Codec$int, rmeta)),
														A4(
															$miniBill$elm_codec$Codec$variant1,
															'SizedRow',
															$author$project$Controller$SizedRow,
															$miniBill$elm_codec$Codec$list(
																A2($miniBill$elm_codec$Codec$tuple, $miniBill$elm_codec$Codec$int, rmeta)),
															A4(
																$miniBill$elm_codec$Codec$variant1,
																'Column',
																$author$project$Controller$Column,
																$miniBill$elm_codec$Codec$list(rmeta),
																A4(
																	$miniBill$elm_codec$Codec$variant1,
																	'Row',
																	$author$project$Controller$Row,
																	$miniBill$elm_codec$Codec$list(rmeta),
																	A5(
																		$miniBill$elm_codec$Codec$variant2,
																		'Module',
																		$author$project$Controller$Module,
																		$miniBill$elm_codec$Codec$string,
																		rmeta,
																		$miniBill$elm_codec$Codec$custom(
																			function (mod) {
																				return function (row) {
																					return function (col) {
																						return function (srw) {
																							return function (scl) {
																								return function (note) {
																									return function (cho) {
																										return function (ccv) {
																											return function (com) {
																												return function (seq) {
																													return function (fad) {
																														return function (xy) {
																															return function (pb) {
																																return function (mid) {
																																	return function (spa) {
																																		return function (value) {
																																			switch (value.$) {
																																				case 0:
																																					var l = value.a;
																																					var c = value.b;
																																					return A2(mod, l, c);
																																				case 1:
																																					var cs = value.a;
																																					return row(cs);
																																				case 2:
																																					var cs = value.a;
																																					return col(cs);
																																				case 3:
																																					var cs = value.a;
																																					return srw(cs);
																																				case 4:
																																					var cs = value.a;
																																					return scl(cs);
																																				case 5:
																																					var s = value.a;
																																					return note(s);
																																				case 6:
																																					var s = value.a;
																																					return cho(s);
																																				case 7:
																																					var s = value.a;
																																					return ccv(s);
																																				case 8:
																																					var s = value.a;
																																					return com(s);
																																				case 9:
																																					var s = value.a;
																																					return seq(s);
																																				case 10:
																																					var s = value.a;
																																					return fad(s);
																																				case 11:
																																					var s = value.a;
																																					return xy(s);
																																				case 12:
																																					var s = value.a;
																																					return pb(s);
																																				case 13:
																																					return mid;
																																				default:
																																					return spa;
																																			}
																																		};
																																	};
																																};
																															};
																														};
																													};
																												};
																											};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			})))))))))))))))));
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $miniBill$elm_codec$Codec$dict = A2(
	$miniBill$elm_codec$Codec$composite,
	function (e) {
		return A2(
			$elm$core$Basics$composeL,
			A2($elm$core$Basics$composeL, $elm$json$Json$Encode$object, $elm$core$Dict$toList),
			$elm$core$Dict$map(
				function (_v0) {
					return e;
				}));
	},
	$elm$json$Json$Decode$dict);
var $author$project$Main$Page = F3(
	function (label, controller, config) {
		return {aH: config, ar: controller, d: label};
	});
var $author$project$Utils$PageConfig = F2(
	function (gapSize, debug) {
		return {dK: debug, m: gapSize};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Encode$bool = _Json_wrap;
var $miniBill$elm_codec$Codec$bool = A2($miniBill$elm_codec$Codec$build, $elm$json$Json$Encode$bool, $elm$json$Json$Decode$bool);
var $author$project$Utils$pageConfigCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'debug',
		function ($) {
			return $.dK;
		},
		$miniBill$elm_codec$Codec$bool,
		A4(
			$miniBill$elm_codec$Codec$field,
			'gapSize',
			function ($) {
				return $.m;
			},
			$miniBill$elm_codec$Codec$int,
			$miniBill$elm_codec$Codec$object($author$project$Utils$PageConfig))));
var $author$project$Main$pageCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'config',
		function ($) {
			return $.aH;
		},
		$author$project$Utils$pageConfigCodec,
		A4(
			$miniBill$elm_codec$Codec$field,
			'controller',
			function ($) {
				return $.ar;
			},
			$author$project$Controller$controllerCodec,
			A4(
				$miniBill$elm_codec$Codec$field,
				'label',
				function ($) {
					return $.d;
				},
				$miniBill$elm_codec$Codec$string,
				$miniBill$elm_codec$Codec$object($author$project$Main$Page)))));
var $author$project$Main$Screen = F2(
	function (width, height) {
		return {av: height, ab: width};
	});
var $author$project$Main$screenCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'height',
		function ($) {
			return $.av;
		},
		$miniBill$elm_codec$Codec$int,
		A4(
			$miniBill$elm_codec$Codec$field,
			'width',
			function ($) {
				return $.ab;
			},
			$miniBill$elm_codec$Codec$int,
			$miniBill$elm_codec$Codec$object($author$project$Main$Screen))));
var $author$project$Main$modelCodec = $miniBill$elm_codec$Codec$buildObject(
	A4(
		$miniBill$elm_codec$Codec$field,
		'navKey',
		function ($) {
			return $.bP;
		},
		$miniBill$elm_codec$Codec$constant($elm$core$Maybe$Nothing),
		A4(
			$miniBill$elm_codec$Codec$maybeField,
			'screen',
			function ($) {
				return $.bd;
			},
			$author$project$Main$screenCodec,
			A4(
				$miniBill$elm_codec$Codec$field,
				'midiLog',
				function ($) {
					return $.bu;
				},
				$miniBill$elm_codec$Codec$constant(_List_Nil),
				A4(
					$miniBill$elm_codec$Codec$field,
					'popup',
					function ($) {
						return $.h;
					},
					$miniBill$elm_codec$Codec$constant($elm$core$Maybe$Nothing),
					A4(
						$miniBill$elm_codec$Codec$field,
						'menuOpen',
						function ($) {
							return $.bt;
						},
						$miniBill$elm_codec$Codec$constant(true),
						A4(
							$miniBill$elm_codec$Codec$field,
							'savedModules',
							function ($) {
								return $.az;
							},
							$miniBill$elm_codec$Codec$dict($author$project$Controller$controllerCodec),
							A4(
								$miniBill$elm_codec$Codec$field,
								'savedPages',
								function ($) {
									return $.aA;
								},
								$miniBill$elm_codec$Codec$dict($author$project$Main$pageCodec),
								A4(
									$miniBill$elm_codec$Codec$field,
									'activePage',
									function ($) {
										return $.k;
									},
									$miniBill$elm_codec$Codec$constant(0),
									A4(
										$miniBill$elm_codec$Codec$field,
										'pages',
										function ($) {
											return $.b;
										},
										$miniBill$elm_codec$Codec$array($author$project$Main$pageCodec),
										A4(
											$miniBill$elm_codec$Codec$field,
											'mode',
											function ($) {
												return $.o;
											},
											$miniBill$elm_codec$Codec$constant($author$project$Main$Normal),
											A4(
												$miniBill$elm_codec$Codec$field,
												'midiStatus',
												function ($) {
													return $.bN;
												},
												$miniBill$elm_codec$Codec$constant($author$project$Midi$Initialising),
												$miniBill$elm_codec$Codec$object($author$project$Main$Model)))))))))))));
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {a3: frag, bb: params, aY: unvisited, aE: value, bk: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.aY;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.aE);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.aE);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.ep),
					$elm$url$Url$Parser$prepareQuery(url.ev),
					url.dR,
					$elm$core$Basics$identity)));
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.bk;
		var unvisited = _v1.aY;
		var params = _v1.bb;
		var frag = _v1.a3;
		var value = _v1.aE;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Controller$newNote = F6(
	function (label, labelSize, colour, channel, pitch, velocity) {
		return $author$project$Controller$Note(
			{
				f: channel,
				i: colour,
				d: label,
				n: $elm$core$Maybe$Just(labelSize),
				y: pitch,
				c: 1,
				E: velocity
			});
	});
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $author$project$Style$pitchToAppColour = function (pitch) {
	return (!A2($elm$core$Basics$modBy, 12, pitch)) ? 5 : (A2(
		$elm$core$List$member,
		A2($elm$core$Basics$modBy, 12, pitch),
		_List_fromArray(
			[1, 3, 6, 8, 10])) ? 2 : 1);
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Main$makeIsomorphicRow = F6(
	function (channel, velocity, noteRange, offset, rowLength, rowNumber) {
		var start = offset * rowNumber;
		var includedRange = A2($elm$core$List$range, start, start + rowLength);
		return function (n) {
			return _Utils_Tuple2(
				1,
				$author$project$Controller$SizedRow(n));
		}(
			A2(
				$elm$core$List$map,
				function (i) {
					return _Utils_Tuple2(
						1,
						A6(
							$author$project$Controller$newNote,
							'',
							0,
							$author$project$Style$pitchToAppColour(i),
							channel,
							i,
							velocity));
				},
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$second,
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var i = _v0.a;
							return A2($elm$core$List$member, i, includedRange);
						},
						A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, noteRange)))));
	});
var $author$project$Main$isomorphicKeyboard = function (_v0) {
	var channel = _v0.f;
	var velocity = _v0.E;
	var firstNote = _v0.ch;
	var numberOfRows = _v0.cm;
	var offset = _v0.cn;
	var rowLength = _v0.cy;
	var rowNumbers = A2($elm$core$List$range, 0, numberOfRows - 1);
	var noteRange = A2($elm$core$List$range, firstNote, 127);
	return $author$project$Controller$SizedColumn(
		$elm$core$List$reverse(
			A2(
				$elm$core$List$map,
				A5($author$project$Main$makeIsomorphicRow, channel, velocity, noteRange, offset, rowLength - 1),
				rowNumbers)));
};
var $author$project$Main$synthWideController = $author$project$Controller$SizedRow(
	_List_fromArray(
		[
			_Utils_Tuple2(
			1,
			$author$project$Controller$SizedColumn(
				_List_fromArray(
					[
						_Utils_Tuple2(
						1,
						$author$project$Controller$SizedRow(
							_List_fromArray(
								[
									_Utils_Tuple2(
									1,
									$author$project$Controller$XYFader(
										{
											b5: 1,
											b6: 2,
											bH: 5,
											bI: 5,
											i: 0,
											d: 'P1',
											n: $elm$core$Maybe$Just(0),
											c: $author$project$Controller$Set,
											cG: 127,
											cH: 127,
											cJ: 0,
											cK: 0,
											bi: 50,
											bj: 50
										})),
									_Utils_Tuple2(
									1,
									$author$project$Controller$XYFader(
										{
											b5: 3,
											b6: 4,
											bH: 5,
											bI: 5,
											i: 0,
											d: 'Fil',
											n: $elm$core$Maybe$Just(0),
											c: $author$project$Controller$Set,
											cG: 127,
											cH: 127,
											cJ: 0,
											cK: 0,
											bi: 50,
											bj: 50
										})),
									_Utils_Tuple2(
									1,
									$author$project$Controller$PitchBend(
										{
											bn: 8192,
											f: 5,
											i: 2,
											d: 'Ch6 Bend',
											n: $elm$core$Maybe$Just(0),
											c: $author$project$Controller$Set
										}))
								]))),
						_Utils_Tuple2(
						1,
						$author$project$Controller$SizedRow(
							_List_fromArray(
								[
									_Utils_Tuple2(
									1,
									$author$project$Controller$Fader(
										{
											b4: 5,
											f: 5,
											i: 4,
											d: 'Att',
											n: $elm$core$Maybe$Just(0),
											c: $author$project$Controller$Set,
											cF: 127,
											cI: 0,
											bh: 50
										})),
									_Utils_Tuple2(
									1,
									$author$project$Controller$Fader(
										{
											b4: 6,
											f: 5,
											i: 5,
											d: 'Dec',
											n: $elm$core$Maybe$Just(0),
											c: $author$project$Controller$Set,
											cF: 127,
											cI: 0,
											bh: 50
										})),
									_Utils_Tuple2(
									1,
									$author$project$Controller$Fader(
										{
											b4: 7,
											f: 5,
											i: 6,
											d: 'Sus',
											n: $elm$core$Maybe$Just(0),
											c: $author$project$Controller$Set,
											cF: 127,
											cI: 0,
											bh: 50
										})),
									_Utils_Tuple2(
									1,
									$author$project$Controller$Fader(
										{
											b4: 8,
											f: 5,
											i: 7,
											d: 'Rel',
											n: $elm$core$Maybe$Just(0),
											c: $author$project$Controller$Set,
											cF: 127,
											cI: 0,
											bh: 50
										}))
								])))
					]))),
			_Utils_Tuple2(
			1,
			A2(
				$author$project$Controller$Module,
				'Isomorphic Keyboard (Ch 6)',
				$author$project$Main$isomorphicKeyboard(
					{f: 5, ch: 48, cm: 8, cn: 5, cy: 9, E: 100})))
		]));
var $author$project$Main$synthWidePage = {
	aH: {dK: false, m: 2},
	ar: $author$project$Main$synthWideController,
	d: '🎹'
};
var $author$project$Main$init = F3(
	function (_v0, url, navKey) {
		var mInitialState = _v0.c9;
		var mPageString = A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$identity,
			A2(
				$elm$url$Url$Parser$parse,
				$elm$url$Url$Parser$query(
					$elm$url$Url$Parser$Query$string('page')),
				url));
		var defaultCmds = _List_fromArray(
			[
				A2(
				$elm$core$Task$perform,
				function (viewport) {
					return A2(
						$author$project$Main$PageResized,
						$elm$core$Basics$floor(viewport.bC.ab),
						$elm$core$Basics$floor(viewport.bC.av));
				},
				$elm$browser$Browser$Dom$getViewport)
			]);
		var _v1 = A2(
			$miniBill$elm_codec$Codec$decodeValue,
			$miniBill$elm_codec$Codec$maybe($author$project$Main$modelCodec),
			mInitialState);
		if ((!_v1.$) && (!_v1.a.$)) {
			var model = _v1.a.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						bP: $elm$core$Maybe$Just(navKey),
						h: function () {
							if ((!mPageString.$) && (!mPageString.a.$)) {
								var pageString = mPageString.a.a;
								return $elm$core$Maybe$Just(
									$author$project$Main$ImportPageFromUrl(pageString));
							} else {
								return $elm$core$Maybe$Just($author$project$Main$InfoPanel);
							}
						}()
					}),
				$elm$core$Platform$Cmd$batch(defaultCmds));
		} else {
			return _Utils_Tuple2(
				{
					k: 0,
					bt: true,
					bu: _List_Nil,
					bN: $author$project$Midi$Initialising,
					o: $author$project$Main$Normal,
					bP: $elm$core$Maybe$Just(navKey),
					b: $elm$core$Array$fromList(
						_List_fromArray(
							[$author$project$Main$drumPage, $author$project$Main$synthWidePage, $author$project$Main$blankPage])),
					h: function () {
						if ((!mPageString.$) && (!mPageString.a.$)) {
							var pageString = mPageString.a.a;
							return $elm$core$Maybe$Just(
								$author$project$Main$ImportPageFromUrl(pageString));
						} else {
							return $elm$core$Maybe$Just($author$project$Main$InfoPanel);
						}
					}(),
					az: $elm$core$Dict$empty,
					aA: $elm$core$Dict$empty,
					bd: $elm$core$Maybe$Nothing
				},
				$elm$core$Platform$Cmd$batch(defaultCmds));
		}
	});
var $author$project$Main$IncomingMidi = function (a) {
	return {$: 36, a: a};
};
var $author$project$Main$MidiDevicesChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$WebMidiNotAvailable = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Ports$incomingMidi = _Platform_incomingPort(
	'incomingMidi',
	A2(
		$elm$json$Json$Decode$andThen,
		function (midiData) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (deviceName) {
					return $elm$json$Json$Decode$succeed(
						{fN: deviceName, gp: midiData});
				},
				A2($elm$json$Json$Decode$field, 'deviceName', $elm$json$Json$Decode$string));
		},
		A2(
			$elm$json$Json$Decode$field,
			'midiData',
			$elm$json$Json$Decode$array($elm$json$Json$Decode$int))));
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $author$project$Ports$midiDevices = _Platform_incomingPort(
	'midiDevices',
	$elm$json$Json$Decode$list(
		A2(
			$elm$json$Json$Decode$andThen,
			function (output) {
				return A2(
					$elm$json$Json$Decode$andThen,
					function (name) {
						return A2(
							$elm$json$Json$Decode$andThen,
							function (input) {
								return $elm$json$Json$Decode$succeed(
									{gd: input, ed: name, gJ: output});
							},
							A2(
								$elm$json$Json$Decode$field,
								'input',
								$elm$json$Json$Decode$oneOf(
									_List_fromArray(
										[
											$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
											A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$bool)
										]))));
					},
					A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
			},
			A2(
				$elm$json$Json$Decode$field,
				'output',
				$elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
							A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$bool)
						]))))));
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {eq: pids, eM: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {dO: event, aN: key};
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.eq,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.aN;
		var event = _v0.dO;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.eM);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$Ports$webMidiNotAvailable = _Platform_incomingPort('webMidiNotAvailable', $elm$json$Json$Decode$string);
var $author$project$Main$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Ports$webMidiNotAvailable($author$project$Main$WebMidiNotAvailable),
				$author$project$Ports$midiDevices($author$project$Main$MidiDevicesChanged),
				$author$project$Ports$incomingMidi($author$project$Main$IncomingMidi),
				$elm$browser$Browser$Events$onResize($author$project$Main$PageResized)
			]));
};
var $author$project$Main$Edit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$EditMenu = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$Main$EditPageMenu = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$EditableController$EditSizedColumn = function (a) {
	return {$: 6, a: a};
};
var $author$project$EditableController$EditSizedRow = function (a) {
	return {$: 7, a: a};
};
var $author$project$Midi$MidiAvailable = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$MidiMenu = {$: 2};
var $author$project$Main$NewPage = 0;
var $author$project$Main$NewPageMenu = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$NoWebMidiPanel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$PageImported = function (a) {
	return {$: 20, a: a};
};
var $author$project$Main$ReceivedPage = function (a) {
	return {$: 19, a: a};
};
var $author$project$Main$SaveMenu = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$SavePage = 0;
var $author$project$Main$ShareMenu = function (a) {
	return {$: 4, a: a};
};
var $author$project$Controller$addSpace = function (controller) {
	switch (controller.$) {
		case 1:
			var subControls = controller.a;
			return $author$project$Controller$Row(
				_Utils_ap(
					subControls,
					_List_fromArray(
						[$author$project$Controller$Space])));
		case 2:
			var subControls = controller.a;
			return $author$project$Controller$Column(
				_Utils_ap(
					subControls,
					_List_fromArray(
						[$author$project$Controller$Space])));
		default:
			return controller;
	}
};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $author$project$Midi$channelToMidiNumber = function (ch) {
	switch (ch) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		case 7:
			return 7;
		case 8:
			return 8;
		case 9:
			return 9;
		case 10:
			return 10;
		case 11:
			return 11;
		case 12:
			return 12;
		case 13:
			return 13;
		case 14:
			return 14;
		default:
			return 15;
	}
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$Controller$buttonOff = function (controller) {
	var makeNoteOff = function (_v2) {
		var channel = _v2.f;
		var pitch = _v2.y;
		var velocity = _v2.E;
		return $author$project$Midi$NoteOff(
			{
				f: $author$project$Midi$channelToMidiNumber(channel),
				y: pitch,
				E: velocity
			});
	};
	switch (controller.$) {
		case 5:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$Note(
					_Utils_update(
						state,
						{c: 1})),
				$elm$core$List$singleton(
					$author$project$Midi$NoteOff(
						{
							f: $author$project$Midi$channelToMidiNumber(state.f),
							y: state.y,
							E: 0
						})));
		case 6:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$Chord(
					_Utils_update(
						state,
						{c: 1})),
				A2(
					$elm$core$List$map,
					makeNoteOff,
					A2(
						$elm$core$List$map,
						function (_v1) {
							var channel = _v1.f;
							var pitch = _v1.y;
							return {f: channel, y: pitch, E: state.E};
						},
						state.aR)));
		case 7:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$CCValue(
					_Utils_update(
						state,
						{c: 1})),
				_List_Nil);
		case 8:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$Command(
					_Utils_update(
						state,
						{c: 1})),
				state.a9);
		case 9:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$Sequence(
					_Utils_update(
						state,
						{c: 1})),
				_List_Nil);
		default:
			return _Utils_Tuple2(controller, _List_Nil);
	}
};
var $author$project$Controller$On = 0;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $author$project$Controller$buttonOn = function (controller) {
	var makeNoteOn = function (_v2) {
		var channel = _v2.f;
		var pitch = _v2.y;
		var velocity = _v2.E;
		return $author$project$Midi$NoteOn(
			{
				f: $author$project$Midi$channelToMidiNumber(channel),
				y: pitch,
				E: velocity
			});
	};
	switch (controller.$) {
		case 5:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$Note(
					_Utils_update(
						state,
						{c: 0})),
				$elm$core$List$singleton(
					makeNoteOn(state)));
		case 6:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$Chord(
					_Utils_update(
						state,
						{c: 0})),
				A2(
					$elm$core$List$map,
					makeNoteOn,
					A2(
						$elm$core$List$map,
						function (_v1) {
							var channel = _v1.f;
							var pitch = _v1.y;
							return {f: channel, y: pitch, E: state.E};
						},
						state.aR)));
		case 7:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$CCValue(
					_Utils_update(
						state,
						{c: 0})),
				$elm$core$List$singleton(
					$author$project$Midi$ControllerChange(
						{
							f: $author$project$Midi$channelToMidiNumber(state.f),
							ar: state.ar,
							aE: state.aE
						})));
		case 8:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$Command(
					_Utils_update(
						state,
						{c: 0})),
				state.a8);
		case 9:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$Sequence(
					_Utils_update(
						state,
						{
							a5: A2(
								$elm$core$Basics$modBy,
								$elm$core$Array$length(state.aP),
								state.a5 + 1),
							c: 0
						})),
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$singleton,
						A2($elm$core$Array$get, state.a5, state.aP))));
		default:
			return _Utils_Tuple2(controller, _List_Nil);
	}
};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $author$project$EditableController$EditCCValue = function (a) {
	return {$: 10, a: a};
};
var $author$project$EditableController$EditChord = function (a) {
	return {$: 9, a: a};
};
var $author$project$EditableController$EditColumn = function (a) {
	return {$: 4, a: a};
};
var $author$project$EditableController$EditCommand = function (a) {
	return {$: 11, a: a};
};
var $author$project$EditableController$EditFader = function (a) {
	return {$: 13, a: a};
};
var $author$project$EditableController$EditMidiLog = {$: 16};
var $author$project$EditableController$EditModule = function (a) {
	return {$: 0, a: a};
};
var $author$project$EditableController$EditNote = function (a) {
	return {$: 8, a: a};
};
var $author$project$EditableController$EditPitchBend = function (a) {
	return {$: 15, a: a};
};
var $author$project$EditableController$EditRow = function (a) {
	return {$: 5, a: a};
};
var $author$project$EditableController$EditSequence = function (a) {
	return {$: 12, a: a};
};
var $author$project$EditableController$EditSpace = {$: 17};
var $author$project$EditableController$EditXYFader = function (a) {
	return {$: 14, a: a};
};
var $author$project$EditableController$New = 0;
var $author$project$EditableController$OnPressMsgs = 0;
var $author$project$EditableController$Params1 = 0;
var $author$project$Midi$channelToString = function (ch) {
	switch (ch) {
		case 0:
			return '1';
		case 1:
			return '2';
		case 2:
			return '3';
		case 3:
			return '4';
		case 4:
			return '5';
		case 5:
			return '6';
		case 6:
			return '7';
		case 7:
			return '8';
		case 8:
			return '9';
		case 9:
			return '10';
		case 10:
			return '11';
		case 11:
			return '12';
		case 12:
			return '13';
		case 13:
			return '14';
		case 14:
			return '15';
		default:
			return '16';
	}
};
var $author$project$Main$convertToEditable = function (control) {
	switch (control.$) {
		case 0:
			var label = control.a;
			var subController = control.b;
			return $author$project$EditableController$EditModule(
				{ar: subController, fC: 0, d: label, gD: $author$project$Controller$Space, g0: $elm$core$Maybe$Nothing});
		case 1:
			var subControls = control.a;
			return $author$project$EditableController$EditRow(subControls);
		case 2:
			var subControls = control.a;
			return $author$project$EditableController$EditColumn(subControls);
		case 3:
			var subControls = control.a;
			return $author$project$EditableController$EditSizedRow(subControls);
		case 4:
			var subControls = control.a;
			return $author$project$EditableController$EditSizedColumn(subControls);
		case 5:
			var label = control.a.d;
			var labelSize = control.a.n;
			var colour = control.a.i;
			var pitch = control.a.y;
			var channel = control.a.f;
			var velocity = control.a.E;
			return $author$project$EditableController$EditNote(
				{
					f: $author$project$Midi$channelToString(channel),
					i: colour,
					d: label,
					n: A2($elm$core$Maybe$withDefault, 0, labelSize),
					y: $elm$core$String$fromInt(pitch),
					E: $elm$core$String$fromInt(velocity)
				});
		case 6:
			var label = control.a.d;
			var labelSize = control.a.n;
			var colour = control.a.i;
			var velocity = control.a.E;
			var notes = control.a.aR;
			return $author$project$EditableController$EditChord(
				{
					i: colour,
					d: label,
					n: A2($elm$core$Maybe$withDefault, 0, labelSize),
					aR: $elm$core$Dict$fromList(
						A2(
							$elm$core$List$map,
							function (note) {
								var channel = note.f;
								var pitch = note.y;
								return _Utils_Tuple2(
									_Utils_Tuple2(
										$author$project$Midi$channelToMidiNumber(channel),
										pitch),
									note);
							},
							notes)),
					E: $elm$core$String$fromInt(velocity)
				});
		case 7:
			var label = control.a.d;
			var labelSize = control.a.n;
			var colour = control.a.i;
			var channel = control.a.f;
			var controller = control.a.ar;
			var value = control.a.aE;
			return $author$project$EditableController$EditCCValue(
				{
					f: $author$project$Midi$channelToString(channel),
					i: colour,
					ar: $elm$core$String$fromInt(controller),
					d: label,
					n: A2($elm$core$Maybe$withDefault, 0, labelSize),
					aE: $elm$core$String$fromInt(value)
				});
		case 8:
			var label = control.a.d;
			var labelSize = control.a.n;
			var colour = control.a.i;
			var onPressMsgs = control.a.a8;
			var onReleaseMsgs = control.a.a9;
			return $author$project$EditableController$EditCommand(
				{
					i: colour,
					dM: 0,
					d: label,
					n: A2($elm$core$Maybe$withDefault, 0, labelSize),
					bw: $elm$core$Maybe$Nothing,
					a8: onPressMsgs,
					a9: onReleaseMsgs
				});
		case 9:
			var label = control.a.d;
			var labelSize = control.a.n;
			var colour = control.a.i;
			var midiMsgs = control.a.aP;
			return $author$project$EditableController$EditSequence(
				{
					i: colour,
					d: label,
					n: A2($elm$core$Maybe$withDefault, 0, labelSize),
					aP: midiMsgs,
					bw: $elm$core$Maybe$Nothing
				});
		case 10:
			var state = control.a;
			return $author$project$EditableController$EditFader(
				{
					b4: $elm$core$String$fromInt(state.b4),
					f: $author$project$Midi$channelToString(state.f),
					i: state.i,
					d: state.d,
					n: A2($elm$core$Maybe$withDefault, 0, state.n),
					cF: $elm$core$String$fromInt(state.cF),
					cI: $elm$core$String$fromInt(state.cI)
				});
		case 11:
			var state = control.a;
			return $author$project$EditableController$EditXYFader(
				{
					cO: 0,
					b5: $elm$core$String$fromInt(state.b5),
					b6: $elm$core$String$fromInt(state.b6),
					bH: $author$project$Midi$channelToString(state.bH),
					bI: $author$project$Midi$channelToString(state.bI),
					i: state.i,
					d: state.d,
					n: A2($elm$core$Maybe$withDefault, 0, state.n),
					cG: $elm$core$String$fromInt(state.cG),
					cH: $elm$core$String$fromInt(state.cH),
					cJ: $elm$core$String$fromInt(state.cJ),
					cK: $elm$core$String$fromInt(state.cK)
				});
		case 12:
			var state = control.a;
			return $author$project$EditableController$EditPitchBend(
				{
					f: $author$project$Midi$channelToString(state.f),
					i: state.i,
					d: state.d,
					n: A2($elm$core$Maybe$withDefault, 0, state.n)
				});
		case 13:
			return $author$project$EditableController$EditMidiLog;
		default:
			return $author$project$EditableController$EditSpace;
	}
};
var $author$project$Ports$copyToClipboard = _Platform_outgoingPort('copyToClipboard', $elm$json$Json$Encode$string);
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$crossOrigin = F3(
	function (prePath, pathSegments, parameters) {
		return prePath + ('/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters)));
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $miniBill$elm_codec$Codec$decodeString = function (codec) {
	return $elm$json$Json$Decode$decodeString(
		$miniBill$elm_codec$Codec$decoder(codec));
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $miniBill$elm_codec$Codec$encodeToString = F2(
	function (indentation, codec) {
		return A2(
			$elm$core$Basics$composeR,
			$miniBill$elm_codec$Codec$encoder(codec),
			$elm$json$Json$Encode$encode(indentation));
	});
var $miniBill$elm_codec$Codec$encodeToValue = function (codec) {
	return $miniBill$elm_codec$Codec$encoder(codec);
};
var $author$project$Controller$Changing = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $author$project$Midi$intToMsbLsb = function (input) {
	var clamped = A3($elm$core$Basics$clamp, 0, 16383, input);
	return {d7: 0, ec: (clamped & 65280) >> 7};
};
var $elm$core$Basics$round = _Basics_round;
var $author$project$Controller$faderChanging = F3(
	function (identifier, _v0, controller) {
		var newX = _v0.a;
		var newY = _v0.b;
		switch (controller.$) {
			case 10:
				var state = controller.a;
				var _v2 = state.c;
				if (_v2.$ === 1) {
					var oldIdentifier = _v2.a;
					var _v3 = _v2.b;
					var oldY = _v3.b;
					if (_Utils_eq(identifier, oldIdentifier)) {
						var valueChange = $elm$core$Basics$round(oldY - newY);
						var protectedMax = (!state.cF) ? 127 : state.cF;
						var rangeValue = protectedMax - state.cI;
						var newPercent = A3($elm$core$Basics$clamp, 0, 100, state.bh + valueChange);
						var value = A3(
							$elm$core$Basics$clamp,
							0,
							127,
							state.cI + $elm$core$Basics$round((rangeValue / 100) * newPercent));
						return _Utils_Tuple2(
							$author$project$Controller$Fader(
								_Utils_update(
									state,
									{
										c: A2(
											$author$project$Controller$Changing,
											oldIdentifier,
											_Utils_Tuple2(newX, newY)),
										bh: newPercent
									})),
							_List_fromArray(
								[
									$author$project$Midi$ControllerChange(
									{
										f: $author$project$Midi$channelToMidiNumber(state.f),
										ar: state.b4,
										aE: value
									})
								]));
					} else {
						return _Utils_Tuple2(
							$author$project$Controller$Fader(state),
							_List_Nil);
					}
				} else {
					return _Utils_Tuple2(
						$author$project$Controller$Fader(
							_Utils_update(
								state,
								{
									c: A2(
										$author$project$Controller$Changing,
										identifier,
										_Utils_Tuple2(newX, newY))
								})),
						_List_Nil);
				}
			case 11:
				var state = controller.a;
				var _v4 = state.c;
				if (_v4.$ === 1) {
					var oldIdentifier = _v4.a;
					var _v5 = _v4.b;
					var oldX = _v5.a;
					var oldY = _v5.b;
					if (_Utils_eq(identifier, oldIdentifier)) {
						var valueChange2 = $elm$core$Basics$round(oldY - newY);
						var valueChange1 = $elm$core$Basics$round(newX - oldX);
						var protectedMax2 = (!state.cH) ? 127 : state.cH;
						var rangeValue2 = protectedMax2 - state.cK;
						var protectedMax1 = (!state.cG) ? 127 : state.cG;
						var rangeValue1 = protectedMax1 - state.cJ;
						var newPercent2 = A3($elm$core$Basics$clamp, 0, 100, state.bj + valueChange2);
						var value2 = A3(
							$elm$core$Basics$clamp,
							0,
							127,
							state.cK + $elm$core$Basics$round((rangeValue2 / 100) * newPercent2));
						var newPercent1 = A3($elm$core$Basics$clamp, 0, 100, state.bi + valueChange1);
						var value1 = A3(
							$elm$core$Basics$clamp,
							0,
							127,
							state.cJ + $elm$core$Basics$round((rangeValue1 / 100) * newPercent1));
						return _Utils_Tuple2(
							$author$project$Controller$XYFader(
								_Utils_update(
									state,
									{
										c: A2(
											$author$project$Controller$Changing,
											oldIdentifier,
											_Utils_Tuple2(newX, newY)),
										bi: newPercent1,
										bj: newPercent2
									})),
							_List_fromArray(
								[
									$author$project$Midi$ControllerChange(
									{
										f: $author$project$Midi$channelToMidiNumber(state.bH),
										ar: state.b5,
										aE: value1
									}),
									$author$project$Midi$ControllerChange(
									{
										f: $author$project$Midi$channelToMidiNumber(state.bI),
										ar: state.b6,
										aE: value2
									})
								]));
					} else {
						return _Utils_Tuple2(
							$author$project$Controller$XYFader(state),
							_List_Nil);
					}
				} else {
					return _Utils_Tuple2(
						$author$project$Controller$XYFader(
							_Utils_update(
								state,
								{
									c: A2(
										$author$project$Controller$Changing,
										identifier,
										_Utils_Tuple2(newX, newY))
								})),
						_List_Nil);
				}
			case 12:
				var state = controller.a;
				var _v6 = state.c;
				if (_v6.$ === 1) {
					var oldIdentifier = _v6.a;
					var _v7 = _v6.b;
					var oldY = _v7.b;
					if (_Utils_eq(identifier, oldIdentifier)) {
						var valueChange = $elm$core$Basics$round(oldY - newY);
						var value = state.bn + (valueChange * 80);
						var _v8 = $author$project$Midi$intToMsbLsb(value);
						var lsb = _v8.d7;
						var msb = _v8.ec;
						return _Utils_Tuple2(
							$author$project$Controller$PitchBend(
								_Utils_update(
									state,
									{
										bn: value,
										c: A2(
											$author$project$Controller$Changing,
											oldIdentifier,
											_Utils_Tuple2(newX, newY))
									})),
							_List_fromArray(
								[
									$author$project$Midi$PitchBend(
									{
										dy: lsb,
										dz: msb,
										f: $author$project$Midi$channelToMidiNumber(state.f)
									})
								]));
					} else {
						return _Utils_Tuple2(
							$author$project$Controller$PitchBend(state),
							_List_Nil);
					}
				} else {
					return _Utils_Tuple2(
						$author$project$Controller$PitchBend(
							_Utils_update(
								state,
								{
									c: A2(
										$author$project$Controller$Changing,
										identifier,
										_Utils_Tuple2(newX, newY))
								})),
						_List_Nil);
				}
			default:
				return _Utils_Tuple2(controller, _List_Nil);
		}
	});
var $author$project$Controller$faderSet = function (controller) {
	switch (controller.$) {
		case 10:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$Fader(
					_Utils_update(
						state,
						{c: $author$project$Controller$Set})),
				_List_Nil);
		case 11:
			var state = controller.a;
			return _Utils_Tuple2(
				$author$project$Controller$XYFader(
					_Utils_update(
						state,
						{c: $author$project$Controller$Set})),
				_List_Nil);
		case 12:
			var state = controller.a;
			var defaultBend = 8192;
			var _v1 = $author$project$Midi$intToMsbLsb(defaultBend);
			var lsb = _v1.d7;
			var msb = _v1.ec;
			return _Utils_Tuple2(
				$author$project$Controller$PitchBend(
					_Utils_update(
						state,
						{bn: defaultBend, c: $author$project$Controller$Set})),
				_List_fromArray(
					[
						$author$project$Midi$PitchBend(
						{
							dy: lsb,
							dz: msb,
							f: $author$project$Midi$channelToMidiNumber(state.f)
						})
					]));
		default:
			return _Utils_Tuple2(controller, _List_Nil);
	}
};
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $elm$core$Array$filter = F2(
	function (isGood, array) {
		return $elm$core$Array$fromList(
			A3(
				$elm$core$Array$foldr,
				F2(
					function (x, xs) {
						return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
					}),
				_List_Nil,
				array));
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Controller$getWithId = F3(
	function (currentId, id, control) {
		getWithId:
		while (true) {
			switch (control.$) {
				case 0:
					var subController = control.b;
					if (_Utils_eq(currentId, id)) {
						return $elm$core$Maybe$Just(control);
					} else {
						var $temp$currentId = currentId + '_0',
							$temp$id = id,
							$temp$control = subController;
						currentId = $temp$currentId;
						id = $temp$id;
						control = $temp$control;
						continue getWithId;
					}
				case 1:
					var controllers = control.a;
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$List$head(
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, c) {
										return A3(
											$author$project$Controller$getWithId,
											currentId + ('_' + $elm$core$String$fromInt(i)),
											id,
											c);
									}),
								controllers)));
				case 2:
					var controllers = control.a;
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$List$head(
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, c) {
										return A3(
											$author$project$Controller$getWithId,
											currentId + ('_' + $elm$core$String$fromInt(i)),
											id,
											c);
									}),
								controllers)));
				case 3:
					var controllers = control.a;
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$List$head(
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, _v1) {
										var c = _v1.b;
										return A3(
											$author$project$Controller$getWithId,
											currentId + ('_' + $elm$core$String$fromInt(i)),
											id,
											c);
									}),
								controllers)));
				case 4:
					var controllers = control.a;
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$List$head(
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (i, _v2) {
										var c = _v2.b;
										return A3(
											$author$project$Controller$getWithId,
											currentId + ('_' + $elm$core$String$fromInt(i)),
											id,
											c);
									}),
								controllers)));
				case 5:
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$Maybe$Nothing;
				case 6:
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$Maybe$Nothing;
				case 7:
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$Maybe$Nothing;
				case 8:
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$Maybe$Nothing;
				case 9:
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$Maybe$Nothing;
				case 10:
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$Maybe$Nothing;
				case 11:
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$Maybe$Nothing;
				case 12:
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$Maybe$Nothing;
				case 13:
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$Maybe$Nothing;
				default:
					return _Utils_eq(currentId, id) ? $elm$core$Maybe$Just(control) : $elm$core$Maybe$Nothing;
			}
		}
	});
var $author$project$Main$getControllerFromActivePage = F3(
	function (id, activePage, pages) {
		return A2(
			$elm$core$Maybe$andThen,
			A2($author$project$Controller$getWithId, '0', id),
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.ar;
				},
				A2($elm$core$Array$get, activePage, pages)));
	});
var $author$project$Controller$getModules = F2(
	function (currentModules, controller) {
		switch (controller.$) {
			case 0:
				var modu = controller;
				var subController = modu.b;
				return A2(
					$elm$core$List$append,
					currentModules,
					A2(
						$elm$core$List$cons,
						modu,
						A2($author$project$Controller$getModules, _List_Nil, subController)));
			case 1:
				var subControls = controller.a;
				return A2(
					$elm$core$List$append,
					currentModules,
					$elm$core$List$concat(
						A2(
							$elm$core$List$map,
							$author$project$Controller$getModules(_List_Nil),
							subControls)));
			case 2:
				var subControls = controller.a;
				return A2(
					$elm$core$List$append,
					currentModules,
					$elm$core$List$concat(
						A2(
							$elm$core$List$map,
							$author$project$Controller$getModules(_List_Nil),
							subControls)));
			case 3:
				var subControls = controller.a;
				return A2(
					$elm$core$List$append,
					currentModules,
					$elm$core$List$concat(
						A2(
							$elm$core$List$map,
							$author$project$Controller$getModules(_List_Nil),
							A2($elm$core$List$map, $elm$core$Tuple$second, subControls))));
			case 4:
				var subControls = controller.a;
				return A2(
					$elm$core$List$append,
					currentModules,
					$elm$core$List$concat(
						A2(
							$elm$core$List$map,
							$author$project$Controller$getModules(_List_Nil),
							A2($elm$core$List$map, $elm$core$Tuple$second, subControls))));
			default:
				return _List_Nil;
		}
	});
var $elm$core$Elm$JsArray$indexedMap = _JsArray_indexedMap;
var $elm$core$Array$indexedMap = F2(
	function (func, _v0) {
		var len = _v0.a;
		var tree = _v0.c;
		var tail = _v0.d;
		var initialBuilder = {
			B: _List_Nil,
			w: 0,
			A: A3(
				$elm$core$Elm$JsArray$indexedMap,
				func,
				$elm$core$Array$tailIndex(len),
				tail)
		};
		var helper = F2(
			function (node, builder) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, builder, subTree);
				} else {
					var leaf = node.a;
					var offset = builder.w * $elm$core$Array$branchFactor;
					var mappedLeaf = $elm$core$Array$Leaf(
						A3($elm$core$Elm$JsArray$indexedMap, func, offset, leaf));
					return {
						B: A2($elm$core$List$cons, mappedLeaf, builder.B),
						w: builder.w + 1,
						A: builder.A
					};
				}
			});
		return A2(
			$elm$core$Array$builderToArray,
			true,
			A3($elm$core$Elm$JsArray$foldl, helper, initialBuilder, tree));
	});
var $author$project$Midi$intArrayToMidiMsg = function (intArray) {
	var thirdByte = A2($elm$core$Array$get, 2, intArray);
	var secondByte = A2($elm$core$Array$get, 1, intArray);
	var firstByte = A2($elm$core$Array$get, 0, intArray);
	var msgNibble = A2(
		$elm$core$Maybe$map,
		function (v) {
			return (v / 16) | 0;
		},
		firstByte);
	var channelNibble = A2(
		$elm$core$Maybe$map,
		function (v) {
			return A2($elm$core$Basics$modBy, 16, v);
		},
		firstByte);
	var _v0 = _Utils_Tuple3(firstByte, secondByte, thirdByte);
	_v0$12:
	while (true) {
		_v0$13:
		while (true) {
			if (!_v0.a.$) {
				switch (_v0.a.a) {
					case 240:
						return $author$project$Midi$SystemExclusive(intArray);
					case 242:
						if (!_v0.b.$) {
							if (!_v0.c.$) {
								var positionLSB = _v0.b.a;
								var positionMSB = _v0.c.a;
								return $author$project$Midi$SongPosition(
									{bS: positionLSB, bT: positionMSB});
							} else {
								break _v0$12;
							}
						} else {
							break _v0$13;
						}
					case 243:
						if (!_v0.b.$) {
							var songNumber = _v0.b.a;
							return $author$project$Midi$SongSelect(songNumber);
						} else {
							break _v0$13;
						}
					case 245:
						if (!_v0.b.$) {
							var busNumber = _v0.b.a;
							return $author$project$Midi$UnofficialBusSelect(busNumber);
						} else {
							break _v0$13;
						}
					case 246:
						return $author$project$Midi$TuneRequest;
					case 247:
						return $author$project$Midi$EndOfSysEx;
					case 248:
						return $author$project$Midi$TimingTick;
					case 250:
						return $author$project$Midi$StartSong;
					case 251:
						return $author$project$Midi$ContinueSong;
					case 252:
						return $author$project$Midi$StopSong;
					case 254:
						return $author$project$Midi$ActiveSensing;
					case 255:
						return $author$project$Midi$SystemReset;
					default:
						if (!_v0.b.$) {
							break _v0$12;
						} else {
							break _v0$13;
						}
				}
			} else {
				break _v0$13;
			}
		}
		return $author$project$Midi$Unknown(intArray);
	}
	var byte1 = _v0.b.a;
	var _v1 = _Utils_Tuple3(msgNibble, channelNibble, thirdByte);
	_v1$7:
	while (true) {
		if ((!_v1.a.$) && (!_v1.b.$)) {
			switch (_v1.a.a) {
				case 8:
					if (!_v1.c.$) {
						var channel = _v1.b.a;
						var velocity = _v1.c.a;
						return $author$project$Midi$NoteOff(
							{f: channel, y: byte1, E: velocity});
					} else {
						break _v1$7;
					}
				case 9:
					if (!_v1.c.$) {
						var channel = _v1.b.a;
						var velocity = _v1.c.a;
						return $author$project$Midi$NoteOn(
							{f: channel, y: byte1, E: velocity});
					} else {
						break _v1$7;
					}
				case 10:
					if (!_v1.c.$) {
						var channel = _v1.b.a;
						var pressure = _v1.c.a;
						return $author$project$Midi$KeyPressure(
							{f: channel, aN: byte1, al: pressure});
					} else {
						break _v1$7;
					}
				case 11:
					if (!_v1.c.$) {
						var channel = _v1.b.a;
						var value = _v1.c.a;
						return $author$project$Midi$ControllerChange(
							{f: channel, ar: byte1, aE: value});
					} else {
						break _v1$7;
					}
				case 12:
					var channel = _v1.b.a;
					return $author$project$Midi$ProgramChange(
						{f: channel, W: byte1});
				case 13:
					var channel = _v1.b.a;
					return $author$project$Midi$ChannelPressure(
						{f: channel, al: byte1});
				case 14:
					if (!_v1.c.$) {
						var channel = _v1.b.a;
						var bendMSB = _v1.c.a;
						return $author$project$Midi$PitchBend(
							{dy: byte1, dz: bendMSB, f: channel});
					} else {
						break _v1$7;
					}
				default:
					break _v1$7;
			}
		} else {
			break _v1$7;
		}
	}
	return $author$project$Midi$Unknown(intArray);
};
var $elm$core$Elm$JsArray$map = _JsArray_map;
var $elm$core$Array$map = F2(
	function (func, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = function (node) {
			if (!node.$) {
				var subTree = node.a;
				return $elm$core$Array$SubTree(
					A2($elm$core$Elm$JsArray$map, helper, subTree));
			} else {
				var values = node.a;
				return $elm$core$Array$Leaf(
					A2($elm$core$Elm$JsArray$map, func, values));
			}
		};
		return A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A2($elm$core$Elm$JsArray$map, helper, tree),
			A2($elm$core$Elm$JsArray$map, func, tail));
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Elm$JsArray$appendN = _JsArray_appendN;
var $elm$core$Elm$JsArray$slice = _JsArray_slice;
var $elm$core$Array$appendHelpBuilder = F2(
	function (tail, builder) {
		var tailLen = $elm$core$Elm$JsArray$length(tail);
		var notAppended = ($elm$core$Array$branchFactor - $elm$core$Elm$JsArray$length(builder.A)) - tailLen;
		var appended = A3($elm$core$Elm$JsArray$appendN, $elm$core$Array$branchFactor, builder.A, tail);
		return (notAppended < 0) ? {
			B: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.B),
			w: builder.w + 1,
			A: A3($elm$core$Elm$JsArray$slice, notAppended, tailLen, tail)
		} : ((!notAppended) ? {
			B: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.B),
			w: builder.w + 1,
			A: $elm$core$Elm$JsArray$empty
		} : {B: builder.B, w: builder.w, A: appended});
	});
var $elm$core$Elm$JsArray$push = _JsArray_push;
var $elm$core$Elm$JsArray$singleton = _JsArray_singleton;
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$insertTailInTree = F4(
	function (shift, index, tail, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		if (_Utils_cmp(
			pos,
			$elm$core$Elm$JsArray$length(tree)) > -1) {
			if (shift === 5) {
				return A2(
					$elm$core$Elm$JsArray$push,
					$elm$core$Array$Leaf(tail),
					tree);
			} else {
				var newSub = $elm$core$Array$SubTree(
					A4($elm$core$Array$insertTailInTree, shift - $elm$core$Array$shiftStep, index, tail, $elm$core$Elm$JsArray$empty));
				return A2($elm$core$Elm$JsArray$push, newSub, tree);
			}
		} else {
			var value = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!value.$) {
				var subTree = value.a;
				var newSub = $elm$core$Array$SubTree(
					A4($elm$core$Array$insertTailInTree, shift - $elm$core$Array$shiftStep, index, tail, subTree));
				return A3($elm$core$Elm$JsArray$unsafeSet, pos, newSub, tree);
			} else {
				var newSub = $elm$core$Array$SubTree(
					A4(
						$elm$core$Array$insertTailInTree,
						shift - $elm$core$Array$shiftStep,
						index,
						tail,
						$elm$core$Elm$JsArray$singleton(value)));
				return A3($elm$core$Elm$JsArray$unsafeSet, pos, newSub, tree);
			}
		}
	});
var $elm$core$Array$unsafeReplaceTail = F2(
	function (newTail, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var originalTailLen = $elm$core$Elm$JsArray$length(tail);
		var newTailLen = $elm$core$Elm$JsArray$length(newTail);
		var newArrayLen = len + (newTailLen - originalTailLen);
		if (_Utils_eq(newTailLen, $elm$core$Array$branchFactor)) {
			var overflow = _Utils_cmp(newArrayLen >>> $elm$core$Array$shiftStep, 1 << startShift) > 0;
			if (overflow) {
				var newShift = startShift + $elm$core$Array$shiftStep;
				var newTree = A4(
					$elm$core$Array$insertTailInTree,
					newShift,
					len,
					newTail,
					$elm$core$Elm$JsArray$singleton(
						$elm$core$Array$SubTree(tree)));
				return A4($elm$core$Array$Array_elm_builtin, newArrayLen, newShift, newTree, $elm$core$Elm$JsArray$empty);
			} else {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					newArrayLen,
					startShift,
					A4($elm$core$Array$insertTailInTree, startShift, len, newTail, tree),
					$elm$core$Elm$JsArray$empty);
			}
		} else {
			return A4($elm$core$Array$Array_elm_builtin, newArrayLen, startShift, tree, newTail);
		}
	});
var $elm$core$Array$appendHelpTree = F2(
	function (toAppend, array) {
		var len = array.a;
		var tree = array.c;
		var tail = array.d;
		var itemsToAppend = $elm$core$Elm$JsArray$length(toAppend);
		var notAppended = ($elm$core$Array$branchFactor - $elm$core$Elm$JsArray$length(tail)) - itemsToAppend;
		var appended = A3($elm$core$Elm$JsArray$appendN, $elm$core$Array$branchFactor, tail, toAppend);
		var newArray = A2($elm$core$Array$unsafeReplaceTail, appended, array);
		if (notAppended < 0) {
			var nextTail = A3($elm$core$Elm$JsArray$slice, notAppended, itemsToAppend, toAppend);
			return A2($elm$core$Array$unsafeReplaceTail, nextTail, newArray);
		} else {
			return newArray;
		}
	});
var $elm$core$Array$builderFromArray = function (_v0) {
	var len = _v0.a;
	var tree = _v0.c;
	var tail = _v0.d;
	var helper = F2(
		function (node, acc) {
			if (!node.$) {
				var subTree = node.a;
				return A3($elm$core$Elm$JsArray$foldl, helper, acc, subTree);
			} else {
				return A2($elm$core$List$cons, node, acc);
			}
		});
	return {
		B: A3($elm$core$Elm$JsArray$foldl, helper, _List_Nil, tree),
		w: (len / $elm$core$Array$branchFactor) | 0,
		A: tail
	};
};
var $elm$core$Array$append = F2(
	function (a, _v0) {
		var aTail = a.d;
		var bLen = _v0.a;
		var bTree = _v0.c;
		var bTail = _v0.d;
		if (_Utils_cmp(bLen, $elm$core$Array$branchFactor * 4) < 1) {
			var foldHelper = F2(
				function (node, array) {
					if (!node.$) {
						var tree = node.a;
						return A3($elm$core$Elm$JsArray$foldl, foldHelper, array, tree);
					} else {
						var leaf = node.a;
						return A2($elm$core$Array$appendHelpTree, leaf, array);
					}
				});
			return A2(
				$elm$core$Array$appendHelpTree,
				bTail,
				A3($elm$core$Elm$JsArray$foldl, foldHelper, a, bTree));
		} else {
			var foldHelper = F2(
				function (node, builder) {
					if (!node.$) {
						var tree = node.a;
						return A3($elm$core$Elm$JsArray$foldl, foldHelper, builder, tree);
					} else {
						var leaf = node.a;
						return A2($elm$core$Array$appendHelpBuilder, leaf, builder);
					}
				});
			return A2(
				$elm$core$Array$builderToArray,
				true,
				A2(
					$elm$core$Array$appendHelpBuilder,
					bTail,
					A3(
						$elm$core$Elm$JsArray$foldl,
						foldHelper,
						$elm$core$Array$builderFromArray(a),
						bTree)));
		}
	});
var $author$project$Midi$midiMsgToIntArray = function (midiMsg) {
	switch (midiMsg.$) {
		case 1:
			var channel = midiMsg.a.f;
			var pitch = midiMsg.a.y;
			var velocity = midiMsg.a.E;
			return $elm$core$Array$fromList(
				_List_fromArray(
					[128 + channel, pitch, velocity]));
		case 0:
			var channel = midiMsg.a.f;
			var pitch = midiMsg.a.y;
			var velocity = midiMsg.a.E;
			return $elm$core$Array$fromList(
				_List_fromArray(
					[144 + channel, pitch, velocity]));
		case 2:
			var channel = midiMsg.a.f;
			var key = midiMsg.a.aN;
			var pressure = midiMsg.a.al;
			return $elm$core$Array$fromList(
				_List_fromArray(
					[160 + channel, key, pressure]));
		case 3:
			var channel = midiMsg.a.f;
			var controller = midiMsg.a.ar;
			var value = midiMsg.a.aE;
			return $elm$core$Array$fromList(
				_List_fromArray(
					[176 + channel, controller, value]));
		case 4:
			var channel = midiMsg.a.f;
			var preset = midiMsg.a.W;
			return $elm$core$Array$fromList(
				_List_fromArray(
					[192 + channel, preset]));
		case 5:
			var channel = midiMsg.a.f;
			var pressure = midiMsg.a.al;
			return $elm$core$Array$fromList(
				_List_fromArray(
					[208 + channel, pressure]));
		case 6:
			var channel = midiMsg.a.f;
			var bendLSB = midiMsg.a.dy;
			var bendMSB = midiMsg.a.dz;
			return $elm$core$Array$fromList(
				_List_fromArray(
					[224 + channel, bendLSB, bendMSB]));
		case 7:
			var data = midiMsg.a;
			return A2(
				$elm$core$Array$append,
				data,
				$elm$core$Array$fromList(
					_List_fromArray(
						[240])));
		case 8:
			var positionLSB = midiMsg.a.bS;
			var positionMSB = midiMsg.a.bT;
			return $elm$core$Array$fromList(
				_List_fromArray(
					[242, positionLSB, positionMSB]));
		case 9:
			var songNumber = midiMsg.a;
			return $elm$core$Array$fromList(
				_List_fromArray(
					[243, songNumber]));
		case 10:
			var busNumber = midiMsg.a;
			return $elm$core$Array$fromList(
				_List_fromArray(
					[245, busNumber]));
		case 11:
			return $elm$core$Array$fromList(
				_List_fromArray(
					[246]));
		case 12:
			return $elm$core$Array$fromList(
				_List_fromArray(
					[247]));
		case 13:
			return $elm$core$Array$fromList(
				_List_fromArray(
					[248]));
		case 14:
			return $elm$core$Array$fromList(
				_List_fromArray(
					[250]));
		case 15:
			return $elm$core$Array$fromList(
				_List_fromArray(
					[251]));
		case 16:
			return $elm$core$Array$fromList(
				_List_fromArray(
					[252]));
		case 17:
			return $elm$core$Array$fromList(
				_List_fromArray(
					[254]));
		case 18:
			return $elm$core$Array$fromList(
				_List_fromArray(
					[255]));
		default:
			var data = midiMsg.a;
			return data;
	}
};
var $author$project$Midi$midiMsgToString = function (midiMsg) {
	switch (midiMsg.$) {
		case 0:
			var channel = midiMsg.a.f;
			var pitch = midiMsg.a.y;
			var velocity = midiMsg.a.E;
			return 'Note On: Ch ' + ($elm$core$String$fromInt(channel + 1) + (', Pit ' + ($elm$core$String$fromInt(pitch) + (', Vel ' + $elm$core$String$fromInt(velocity)))));
		case 1:
			var channel = midiMsg.a.f;
			var pitch = midiMsg.a.y;
			var velocity = midiMsg.a.E;
			return 'Note Off: Ch ' + ($elm$core$String$fromInt(channel + 1) + (', Pit ' + ($elm$core$String$fromInt(pitch) + (', Vel ' + $elm$core$String$fromInt(velocity)))));
		case 2:
			var channel = midiMsg.a.f;
			var key = midiMsg.a.aN;
			var pressure = midiMsg.a.al;
			return 'Key Pressure: Ch ' + ($elm$core$String$fromInt(channel + 1) + (', Key ' + ($elm$core$String$fromInt(key) + (', Pres ' + $elm$core$String$fromInt(pressure)))));
		case 3:
			var channel = midiMsg.a.f;
			var controller = midiMsg.a.ar;
			var value = midiMsg.a.aE;
			return 'CC: Ch ' + ($elm$core$String$fromInt(channel + 1) + (', Con ' + ($elm$core$String$fromInt(controller) + (', Val ' + $elm$core$String$fromInt(value)))));
		case 4:
			var channel = midiMsg.a.f;
			var preset = midiMsg.a.W;
			return 'Program Change: Ch ' + ($elm$core$String$fromInt(channel + 1) + (', Pre ' + $elm$core$String$fromInt(preset)));
		case 5:
			var channel = midiMsg.a.f;
			var pressure = midiMsg.a.al;
			return 'Channel Pressure: Ch ' + ($elm$core$String$fromInt(channel + 1) + (', Pres ' + $elm$core$String$fromInt(pressure)));
		case 6:
			var channel = midiMsg.a.f;
			var bendLSB = midiMsg.a.dy;
			var bendMSB = midiMsg.a.dz;
			return 'Pitch Bend: Ch ' + ($elm$core$String$fromInt(channel + 1) + (', comb ' + $elm$core$String$fromInt(bendLSB + (bendMSB << 7))));
		case 7:
			var data = midiMsg.a;
			return 'System Exclusive: ' + A2(
				$elm$core$String$join,
				' ',
				$elm$core$Array$toList(
					A2($elm$core$Array$map, $elm$core$String$fromInt, data)));
		case 8:
			var positionLSB = midiMsg.a.bS;
			var positionMSB = midiMsg.a.bT;
			return 'Song Position:  LSB ' + ($elm$core$String$fromInt(positionLSB) + (', MSB ' + $elm$core$String$fromInt(positionMSB)));
		case 9:
			var songNumber = midiMsg.a;
			return 'Song Select:  Song# ' + $elm$core$String$fromInt(songNumber);
		case 10:
			var busNumber = midiMsg.a;
			return 'Unofficial Bus Select: Bus# ' + $elm$core$String$fromInt(busNumber);
		case 11:
			return 'Tune Request';
		case 12:
			return 'End Of Sys Ex';
		case 13:
			return 'Timing Tick';
		case 14:
			return 'Start Song';
		case 15:
			return 'Continue Song';
		case 16:
			return 'Stop Song';
		case 17:
			return 'Active Sensing';
		case 18:
			return 'System Reset';
		default:
			var data = midiMsg.a;
			return 'Unknown MIDI Msg: ' + A2(
				$elm$core$String$join,
				' ',
				$elm$core$Array$toList(
					A2($elm$core$Array$map, $elm$core$String$fromInt, data)));
	}
};
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$core$Basics$not = _Basics_not;
var $author$project$Ports$outgoingMidi = _Platform_outgoingPort(
	'outgoingMidi',
	$elm$json$Json$Encode$array(
		$elm$json$Json$Encode$array($elm$json$Json$Encode$int)));
var $elm$core$Array$push = F2(
	function (a, array) {
		var tail = array.d;
		return A2(
			$elm$core$Array$unsafeReplaceTail,
			A2($elm$core$Elm$JsArray$push, a, tail),
			array);
	});
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $author$project$Controller$removeItem = function (controller) {
	switch (controller.$) {
		case 1:
			var subControls = controller.a;
			return $author$project$Controller$Row(
				$elm$core$List$reverse(
					A2(
						$elm$core$List$drop,
						1,
						$elm$core$List$reverse(subControls))));
		case 2:
			var subControls = controller.a;
			return $author$project$Controller$Column(
				$elm$core$List$reverse(
					A2(
						$elm$core$List$drop,
						1,
						$elm$core$List$reverse(subControls))));
		default:
			return controller;
	}
};
var $author$project$Ports$saveState = _Platform_outgoingPort('saveState', $elm$core$Basics$identity);
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $elm$file$File$Download$string = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(_File_download, name, mime, content));
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm$file$File$toString = _File_toString;
var $author$project$Controller$updateWithId = F3(
	function (currentId, toUpdate, updateInfo) {
		var _v0 = updateInfo;
		var id = _v0.f6;
		var updateFn = _v0.hH;
		switch (toUpdate.$) {
			case 0:
				var label = toUpdate.a;
				var controller = toUpdate.b;
				return _Utils_eq(currentId, id) ? updateFn(controller) : A2(
					$author$project$Controller$Module,
					label,
					A3($author$project$Controller$updateWithId, currentId + '_0', controller, updateInfo));
			case 1:
				var controllers = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$Row(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, c) {
								return A3(
									$author$project$Controller$updateWithId,
									currentId + ('_' + $elm$core$String$fromInt(i)),
									c,
									updateInfo);
							}),
						controllers));
			case 2:
				var controllers = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$Column(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, c) {
								return A3(
									$author$project$Controller$updateWithId,
									currentId + ('_' + $elm$core$String$fromInt(i)),
									c,
									updateInfo);
							}),
						controllers));
			case 3:
				var controllers = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$SizedRow(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, _v2) {
								var s = _v2.a;
								var c = _v2.b;
								return _Utils_Tuple2(
									s,
									A3(
										$author$project$Controller$updateWithId,
										currentId + ('_' + $elm$core$String$fromInt(i)),
										c,
										updateInfo));
							}),
						controllers));
			case 4:
				var controllers = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$SizedColumn(
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (i, _v3) {
								var s = _v3.a;
								var c = _v3.b;
								return _Utils_Tuple2(
									s,
									A3(
										$author$project$Controller$updateWithId,
										currentId + ('_' + $elm$core$String$fromInt(i)),
										c,
										updateInfo));
							}),
						controllers));
			case 5:
				var state = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$Note(state);
			case 6:
				var state = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$Chord(state);
			case 7:
				var state = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$CCValue(state);
			case 8:
				var state = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$Command(state);
			case 9:
				var state = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$Sequence(state);
			case 10:
				var state = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$Fader(state);
			case 11:
				var state = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$XYFader(state);
			case 12:
				var state = toUpdate.a;
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$PitchBend(state);
			case 13:
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$MidiLog;
			default:
				return _Utils_eq(currentId, id) ? updateFn(toUpdate) : $author$project$Controller$Space;
		}
	});
var $author$project$Main$updateControllerOnActivePage = F3(
	function (activePage, updateInfo, pages) {
		return A2(
			$elm$core$Array$map,
			function (_v0) {
				var i = _v0.a;
				var p = _v0.b;
				return _Utils_eq(i, activePage) ? _Utils_update(
					p,
					{
						ar: A3($author$project$Controller$updateWithId, '0', p.ar, updateInfo)
					}) : p;
			},
			A2($elm$core$Array$indexedMap, $elm$core$Tuple$pair, pages));
	});
var $author$project$Midi$intToChannel = function (n) {
	switch (n) {
		case 0:
			return $elm$core$Maybe$Just(0);
		case 1:
			return $elm$core$Maybe$Just(1);
		case 2:
			return $elm$core$Maybe$Just(2);
		case 3:
			return $elm$core$Maybe$Just(3);
		case 4:
			return $elm$core$Maybe$Just(4);
		case 5:
			return $elm$core$Maybe$Just(5);
		case 6:
			return $elm$core$Maybe$Just(6);
		case 7:
			return $elm$core$Maybe$Just(7);
		case 8:
			return $elm$core$Maybe$Just(8);
		case 9:
			return $elm$core$Maybe$Just(9);
		case 10:
			return $elm$core$Maybe$Just(10);
		case 11:
			return $elm$core$Maybe$Just(11);
		case 12:
			return $elm$core$Maybe$Just(12);
		case 13:
			return $elm$core$Maybe$Just(13);
		case 14:
			return $elm$core$Maybe$Just(14);
		case 15:
			return $elm$core$Maybe$Just(15);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$EditableController$updateEditCCValueWithMidiMsg = F2(
	function (midiMsg, state) {
		if (midiMsg.$ === 3) {
			var data = midiMsg.a;
			return _Utils_update(
				state,
				{
					f: $elm$core$String$fromInt(data.f + 1),
					ar: $elm$core$String$fromInt(data.ar),
					aE: $elm$core$String$fromInt(data.aE)
				});
		} else {
			return state;
		}
	});
var $author$project$EditableController$updateEditChordWithMidiMsg = F2(
	function (midiMsg, state) {
		if (!midiMsg.$) {
			var noteOnParams = midiMsg.a;
			return _Utils_update(
				state,
				{
					aR: A3(
						$elm$core$Dict$insert,
						_Utils_Tuple2(
							$author$project$Midi$channelToMidiNumber(
								A2(
									$elm$core$Maybe$withDefault,
									0,
									$author$project$Midi$intToChannel(noteOnParams.f))),
							noteOnParams.y),
						{
							f: A2(
								$elm$core$Maybe$withDefault,
								0,
								$author$project$Midi$intToChannel(noteOnParams.f)),
							y: noteOnParams.y
						},
						state.aR)
				});
		} else {
			return state;
		}
	});
var $author$project$Midi$EActiveSensing = {$: 10};
var $author$project$Midi$EContinueSong = {$: 8};
var $author$project$Midi$EControllerChange = function (a) {
	return {$: 2, a: a};
};
var $author$project$Midi$ENoteOff = function (a) {
	return {$: 1, a: a};
};
var $author$project$Midi$ENoteOn = function (a) {
	return {$: 0, a: a};
};
var $author$project$Midi$EProgramChange = function (a) {
	return {$: 3, a: a};
};
var $author$project$Midi$ESongSelect = function (a) {
	return {$: 5, a: a};
};
var $author$project$Midi$EStartSong = {$: 7};
var $author$project$Midi$EStopSong = {$: 9};
var $author$project$Midi$ESystemExclusive = function (a) {
	return {$: 4, a: a};
};
var $author$project$Midi$ESystemReset = {$: 11};
var $author$project$Midi$ETuneRequest = {$: 6};
var $author$project$Midi$midiMsgToEditMidiButtonMsg = function (midiMsg) {
	switch (midiMsg.$) {
		case 0:
			var channel = midiMsg.a.f;
			var pitch = midiMsg.a.y;
			var velocity = midiMsg.a.E;
			return $elm$core$Maybe$Just(
				$author$project$Midi$ENoteOn(
					{
						f: $elm$core$String$fromInt(channel + 1),
						y: $elm$core$String$fromInt(pitch),
						E: $elm$core$String$fromInt(velocity)
					}));
		case 1:
			var channel = midiMsg.a.f;
			var pitch = midiMsg.a.y;
			var velocity = midiMsg.a.E;
			return $elm$core$Maybe$Just(
				$author$project$Midi$ENoteOff(
					{
						f: $elm$core$String$fromInt(channel + 1),
						y: $elm$core$String$fromInt(pitch),
						E: $elm$core$String$fromInt(velocity)
					}));
		case 3:
			var channel = midiMsg.a.f;
			var controller = midiMsg.a.ar;
			var value = midiMsg.a.aE;
			return $elm$core$Maybe$Just(
				$author$project$Midi$EControllerChange(
					{
						f: $elm$core$String$fromInt(channel + 1),
						ar: $elm$core$String$fromInt(controller),
						aE: $elm$core$String$fromInt(value)
					}));
		case 4:
			var channel = midiMsg.a.f;
			var preset = midiMsg.a.W;
			return $elm$core$Maybe$Just(
				$author$project$Midi$EProgramChange(
					{
						f: $elm$core$String$fromInt(channel + 1),
						W: $elm$core$String$fromInt(preset)
					}));
		case 7:
			var data = midiMsg.a;
			return $elm$core$Maybe$Just(
				$author$project$Midi$ESystemExclusive(
					A2(
						$elm$core$String$join,
						', ',
						A2(
							$elm$core$List$map,
							$elm$core$String$fromInt,
							$elm$core$Array$toList(data)))));
		case 9:
			var songNumber = midiMsg.a;
			return $elm$core$Maybe$Just(
				$author$project$Midi$ESongSelect(
					$elm$core$String$fromInt(songNumber)));
		case 11:
			return $elm$core$Maybe$Just($author$project$Midi$ETuneRequest);
		case 14:
			return $elm$core$Maybe$Just($author$project$Midi$EStartSong);
		case 15:
			return $elm$core$Maybe$Just($author$project$Midi$EContinueSong);
		case 16:
			return $elm$core$Maybe$Just($author$project$Midi$EStopSong);
		case 17:
			return $elm$core$Maybe$Just($author$project$Midi$EActiveSensing);
		case 18:
			return $elm$core$Maybe$Just($author$project$Midi$ESystemReset);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$EditableController$updateEditCommandWithMidiMsg = F2(
	function (midiMsg, state) {
		return _Utils_update(
			state,
			{
				bw: $author$project$Midi$midiMsgToEditMidiButtonMsg(midiMsg)
			});
	});
var $author$project$EditableController$updateEditFaderWithMidiMsg = F2(
	function (midiMsg, state) {
		if (midiMsg.$ === 3) {
			var channel = midiMsg.a.f;
			var controller = midiMsg.a.ar;
			return _Utils_update(
				state,
				{
					b4: $elm$core$String$fromInt(controller),
					f: $elm$core$String$fromInt(channel + 1)
				});
		} else {
			return state;
		}
	});
var $author$project$EditableController$updateEditNoteWithMidiMsg = F2(
	function (midiMsg, state) {
		if (!midiMsg.$) {
			var noteOnParams = midiMsg.a;
			return _Utils_update(
				state,
				{
					f: $elm$core$String$fromInt(noteOnParams.f + 1),
					y: $elm$core$String$fromInt(noteOnParams.y),
					E: $elm$core$String$fromInt(noteOnParams.E)
				});
		} else {
			return state;
		}
	});
var $author$project$EditableController$updateEditPitchBendWithMidiMsg = F2(
	function (midiMsg, state) {
		switch (midiMsg.$) {
			case 0:
				var channel = midiMsg.a.f;
				return _Utils_update(
					state,
					{
						f: $elm$core$String$fromInt(channel + 1)
					});
			case 1:
				var channel = midiMsg.a.f;
				return _Utils_update(
					state,
					{
						f: $elm$core$String$fromInt(channel + 1)
					});
			case 3:
				var channel = midiMsg.a.f;
				return _Utils_update(
					state,
					{
						f: $elm$core$String$fromInt(channel + 1)
					});
			case 6:
				var channel = midiMsg.a.f;
				return _Utils_update(
					state,
					{
						f: $elm$core$String$fromInt(channel + 1)
					});
			default:
				return state;
		}
	});
var $author$project$EditableController$updateEditSequenceWithMidiMsg = F2(
	function (midiMsg, state) {
		return _Utils_update(
			state,
			{
				bw: $author$project$Midi$midiMsgToEditMidiButtonMsg(midiMsg)
			});
	});
var $author$project$EditableController$updateEditXYFaderWithMidiMsg = F2(
	function (midiMsg, state) {
		if (midiMsg.$ === 3) {
			var channel = midiMsg.a.f;
			var controller = midiMsg.a.ar;
			var _v1 = state.cO;
			if (!_v1) {
				return _Utils_update(
					state,
					{
						b5: $elm$core$String$fromInt(controller),
						bH: $elm$core$String$fromInt(channel + 1)
					});
			} else {
				return _Utils_update(
					state,
					{
						b6: $elm$core$String$fromInt(controller),
						bI: $elm$core$String$fromInt(channel + 1)
					});
			}
		} else {
			return state;
		}
	});
var $author$project$EditableController$updateWithMidiMsg = F2(
	function (midiMsg, state) {
		switch (state.$) {
			case 0:
				return state;
			case 1:
				return state;
			case 2:
				return state;
			case 3:
				return state;
			case 4:
				var subControls = state.a;
				switch (midiMsg.$) {
					case 0:
						var channel = midiMsg.a.f;
						var pitch = midiMsg.a.y;
						var velocity = midiMsg.a.E;
						var ch = A2(
							$elm$core$Maybe$withDefault,
							0,
							$author$project$Midi$intToChannel(channel));
						var label = 'Ch' + ($author$project$Midi$channelToString(ch) + ('#' + $elm$core$String$fromInt(pitch)));
						return $author$project$EditableController$EditColumn(
							A2(
								$elm$core$List$append,
								subControls,
								_List_fromArray(
									[
										A6(
										$author$project$Controller$newNote,
										label,
										1,
										$author$project$Style$pitchToAppColour(pitch),
										ch,
										pitch,
										velocity)
									])));
					case 3:
						var channel = midiMsg.a.f;
						var controller = midiMsg.a.ar;
						var ch = A2(
							$elm$core$Maybe$withDefault,
							0,
							$author$project$Midi$intToChannel(channel));
						var label = 'Ch' + ($author$project$Midi$channelToString(ch) + (' CC ' + $elm$core$String$fromInt(controller)));
						return $author$project$EditableController$EditColumn(
							A2(
								$elm$core$List$append,
								subControls,
								_List_fromArray(
									[
										$author$project$Controller$Fader(
										{
											b4: controller,
											f: ch,
											i: 6,
											d: label,
											n: $elm$core$Maybe$Just(1),
											c: $author$project$Controller$Set,
											cF: 127,
											cI: 0,
											bh: 50
										})
									])));
					default:
						return state;
				}
			case 5:
				var subControls = state.a;
				switch (midiMsg.$) {
					case 0:
						var channel = midiMsg.a.f;
						var pitch = midiMsg.a.y;
						var velocity = midiMsg.a.E;
						var ch = A2(
							$elm$core$Maybe$withDefault,
							0,
							$author$project$Midi$intToChannel(channel));
						var label = 'Ch' + ($author$project$Midi$channelToString(ch) + ('#' + $elm$core$String$fromInt(pitch)));
						return $author$project$EditableController$EditRow(
							A2(
								$elm$core$List$append,
								subControls,
								_List_fromArray(
									[
										A6(
										$author$project$Controller$newNote,
										label,
										1,
										$author$project$Style$pitchToAppColour(pitch),
										ch,
										pitch,
										velocity)
									])));
					case 3:
						var channel = midiMsg.a.f;
						var controller = midiMsg.a.ar;
						var ch = A2(
							$elm$core$Maybe$withDefault,
							0,
							$author$project$Midi$intToChannel(channel));
						var label = 'Ch' + ($author$project$Midi$channelToString(ch) + (' CC ' + $elm$core$String$fromInt(controller)));
						return $author$project$EditableController$EditRow(
							A2(
								$elm$core$List$append,
								subControls,
								_List_fromArray(
									[
										$author$project$Controller$Fader(
										{
											b4: controller,
											f: ch,
											i: 6,
											d: label,
											n: $elm$core$Maybe$Just(1),
											c: $author$project$Controller$Set,
											cF: 127,
											cI: 0,
											bh: 50
										})
									])));
					default:
						return state;
				}
			case 6:
				var subControls = state.a;
				switch (midiMsg.$) {
					case 0:
						var channel = midiMsg.a.f;
						var pitch = midiMsg.a.y;
						var velocity = midiMsg.a.E;
						var ch = A2(
							$elm$core$Maybe$withDefault,
							0,
							$author$project$Midi$intToChannel(channel));
						var label = 'Ch' + ($author$project$Midi$channelToString(ch) + ('#' + $elm$core$String$fromInt(pitch)));
						return $author$project$EditableController$EditSizedColumn(
							A2(
								$elm$core$List$append,
								subControls,
								_List_fromArray(
									[
										_Utils_Tuple2(
										1,
										A6(
											$author$project$Controller$newNote,
											label,
											1,
											$author$project$Style$pitchToAppColour(pitch),
											ch,
											pitch,
											velocity))
									])));
					case 3:
						var channel = midiMsg.a.f;
						var controller = midiMsg.a.ar;
						var ch = A2(
							$elm$core$Maybe$withDefault,
							0,
							$author$project$Midi$intToChannel(channel));
						var label = 'Ch' + ($author$project$Midi$channelToString(ch) + (' CC ' + $elm$core$String$fromInt(controller)));
						return $author$project$EditableController$EditSizedColumn(
							A2(
								$elm$core$List$append,
								subControls,
								_List_fromArray(
									[
										_Utils_Tuple2(
										1,
										$author$project$Controller$Fader(
											{
												b4: controller,
												f: ch,
												i: 6,
												d: label,
												n: $elm$core$Maybe$Just(1),
												c: $author$project$Controller$Set,
												cF: 127,
												cI: 0,
												bh: 50
											}))
									])));
					default:
						return state;
				}
			case 7:
				var subControls = state.a;
				switch (midiMsg.$) {
					case 0:
						var channel = midiMsg.a.f;
						var pitch = midiMsg.a.y;
						var velocity = midiMsg.a.E;
						var ch = A2(
							$elm$core$Maybe$withDefault,
							0,
							$author$project$Midi$intToChannel(channel));
						var label = 'Ch' + ($author$project$Midi$channelToString(ch) + ('#' + $elm$core$String$fromInt(pitch)));
						return $author$project$EditableController$EditSizedRow(
							A2(
								$elm$core$List$append,
								subControls,
								_List_fromArray(
									[
										_Utils_Tuple2(
										1,
										A6(
											$author$project$Controller$newNote,
											label,
											1,
											$author$project$Style$pitchToAppColour(pitch),
											ch,
											pitch,
											velocity))
									])));
					case 3:
						var channel = midiMsg.a.f;
						var controller = midiMsg.a.ar;
						var ch = A2(
							$elm$core$Maybe$withDefault,
							0,
							$author$project$Midi$intToChannel(channel));
						var label = 'Ch' + ($author$project$Midi$channelToString(ch) + (' CC ' + $elm$core$String$fromInt(controller)));
						return $author$project$EditableController$EditSizedRow(
							A2(
								$elm$core$List$append,
								subControls,
								_List_fromArray(
									[
										_Utils_Tuple2(
										1,
										$author$project$Controller$Fader(
											{
												b4: controller,
												f: ch,
												i: 6,
												d: label,
												n: $elm$core$Maybe$Just(1),
												c: $author$project$Controller$Set,
												cF: 127,
												cI: 0,
												bh: 50
											}))
									])));
					default:
						return state;
				}
			case 8:
				var noteState = state.a;
				return $author$project$EditableController$EditNote(
					A2($author$project$EditableController$updateEditNoteWithMidiMsg, midiMsg, noteState));
			case 9:
				var chordState = state.a;
				return $author$project$EditableController$EditChord(
					A2($author$project$EditableController$updateEditChordWithMidiMsg, midiMsg, chordState));
			case 10:
				var ccState = state.a;
				return $author$project$EditableController$EditCCValue(
					A2($author$project$EditableController$updateEditCCValueWithMidiMsg, midiMsg, ccState));
			case 11:
				var comState = state.a;
				return $author$project$EditableController$EditCommand(
					A2($author$project$EditableController$updateEditCommandWithMidiMsg, midiMsg, comState));
			case 12:
				var seqState = state.a;
				return $author$project$EditableController$EditSequence(
					A2($author$project$EditableController$updateEditSequenceWithMidiMsg, midiMsg, seqState));
			case 13:
				var faderState = state.a;
				return $author$project$EditableController$EditFader(
					A2($author$project$EditableController$updateEditFaderWithMidiMsg, midiMsg, faderState));
			case 14:
				var xyFaderState = state.a;
				return $author$project$EditableController$EditXYFader(
					A2($author$project$EditableController$updateEditXYFaderWithMidiMsg, midiMsg, xyFaderState));
			case 15:
				var pbState = state.a;
				return $author$project$EditableController$EditPitchBend(
					A2($author$project$EditableController$updateEditPitchBendWithMidiMsg, midiMsg, pbState));
			case 16:
				return state;
			default:
				return state;
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var reason = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h: $elm$core$Maybe$Just(
								$author$project$Main$NoWebMidiPanel(reason))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var devices = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bN: $author$project$Midi$MidiAvailable(devices)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bt: !model.bt}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h: $elm$core$Maybe$Just($author$project$Main$InfoPanel)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h: $elm$core$Maybe$Just($author$project$Main$MidiMenu)
						}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h: $elm$core$Maybe$Just(
								$author$project$Main$SaveMenu(
									{
										cj: $elm$core$Maybe$Nothing,
										ad: $elm$core$Maybe$Nothing,
										o: 0,
										db: $elm$core$List$concat(
											A2(
												$elm$core$List$map,
												$author$project$Controller$getModules(_List_Nil),
												$elm$core$Array$toList(
													A2(
														$elm$core$Array$map,
														function ($) {
															return $.ar;
														},
														model.b)))),
										b: model.b
									}))
						}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var state = msg.a;
				var _v1 = model.h;
				if ((!_v1.$) && (_v1.a.$ === 3)) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: $elm$core$Maybe$Just(
									$author$project$Main$SaveMenu(state))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{h: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				}
			case 7:
				var page = msg.a;
				var _v2 = function () {
					if (A2($elm$core$Dict$member, page.d, model.aA)) {
						var newLabel = page.d + '_0';
						return _Utils_Tuple2(
							newLabel,
							_Utils_update(
								page,
								{d: newLabel}));
					} else {
						return _Utils_Tuple2(page.d, page);
					}
				}();
				var key = _v2.a;
				var updatedPage = _v2.b;
				var newModel = _Utils_update(
					model,
					{
						h: $elm$core$Maybe$Nothing,
						aA: A3($elm$core$Dict$insert, key, updatedPage, model.aA)
					});
				return _Utils_Tuple2(
					newModel,
					$author$project$Ports$saveState(
						A2($miniBill$elm_codec$Codec$encodeToValue, $author$project$Main$modelCodec, newModel)));
			case 8:
				var controller = msg.a;
				if (!controller.$) {
					var modu = controller;
					var label = modu.a;
					var subControl = modu.b;
					var _v4 = function () {
						if (A2($elm$core$Dict$member, label, model.az)) {
							var newLabel = label + '_0';
							return _Utils_Tuple2(
								newLabel,
								A2($author$project$Controller$Module, newLabel, subControl));
						} else {
							return _Utils_Tuple2(label, modu);
						}
					}();
					var key = _v4.a;
					var updatedModule = _v4.b;
					var newModel = _Utils_update(
						model,
						{
							h: $elm$core$Maybe$Nothing,
							az: A3($elm$core$Dict$insert, key, updatedModule, model.az)
						});
					return _Utils_Tuple2(
						newModel,
						$author$project$Ports$saveState(
							A2($miniBill$elm_codec$Codec$encodeToValue, $author$project$Main$modelCodec, newModel)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				var page = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: $elm$core$Maybe$Nothing}),
					A3(
						$elm$file$File$Download$string,
						'midisurf.json',
						'text/json',
						A3($miniBill$elm_codec$Codec$encodeToString, 0, $author$project$Main$pageCodec, page)));
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h: $elm$core$Maybe$Just(
								$author$project$Main$ShareMenu(
									A2($elm$core$Array$get, model.k, model.b)))
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							o: function () {
								var _v5 = model.o;
								if (!_v5.$) {
									return $author$project$Main$Edit(
										_Utils_Tuple2(
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.ar;
												},
												A2($elm$core$Array$get, model.k, model.b)),
											_List_Nil));
								} else {
									return $author$project$Main$Normal;
								}
							}()
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				return _Utils_Tuple2(
					function () {
						var _v6 = model.o;
						if (_v6.$ === 1) {
							if (_v6.a.b.b) {
								var _v7 = _v6.a;
								var first = _v7.a;
								var _v8 = _v7.b;
								var next = _v8.a;
								var rest = _v8.b;
								return _Utils_update(
									model,
									{
										o: $author$project$Main$Edit(
											_Utils_Tuple2(first, rest)),
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: '0',
												hH: function (_v9) {
													return next;
												}
											},
											model.b)
									});
							} else {
								if (!_v6.a.a.$) {
									var _v10 = _v6.a;
									var first = _v10.a.a;
									return _Utils_update(
										model,
										{
											o: $author$project$Main$Edit(
												_Utils_Tuple2(
													$elm$core$Maybe$Just(first),
													_List_Nil)),
											b: A3(
												$author$project$Main$updateControllerOnActivePage,
												model.k,
												{
													f6: '0',
													hH: function (_v11) {
														return first;
													}
												},
												model.b)
										});
								} else {
									var _v12 = _v6.a;
									var _v13 = _v12.a;
									return model;
								}
							}
						} else {
							return model;
						}
					}(),
					$elm$core$Platform$Cmd$none);
			case 13:
				var index = msg.a;
				var mSelectedPage = A2($elm$core$Array$get, index, model.b);
				if (!mSelectedPage.$) {
					var page = mSelectedPage.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: $elm$core$Maybe$Just(
									A2(
										$author$project$Main$EditPageMenu,
										index,
										{bp: $elm$core$Maybe$Nothing, d: page.d, bs: $elm$core$Maybe$Nothing, a7: $elm$core$Maybe$Nothing, ad: $elm$core$Maybe$Nothing, o: 0}))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 14:
				var index = msg.a;
				var newModel = _Utils_update(
					model,
					{
						o: $author$project$Main$Normal,
						b: A2(
							$elm$core$Array$map,
							$elm$core$Tuple$second,
							A2(
								$elm$core$Array$filter,
								function (_v15) {
									var i = _v15.a;
									return !_Utils_eq(i, index);
								},
								A2($elm$core$Array$indexedMap, $elm$core$Tuple$pair, model.b))),
						h: $elm$core$Maybe$Nothing
					});
				return _Utils_Tuple2(
					newModel,
					$author$project$Ports$saveState(
						A2($miniBill$elm_codec$Codec$encodeToValue, $author$project$Main$modelCodec, newModel)));
			case 15:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h: $elm$core$Maybe$Just(
								$author$project$Main$NewPageMenu(
									{bp: $elm$core$Maybe$Nothing, d: '', bs: $elm$core$Maybe$Nothing, a7: $elm$core$Maybe$Nothing, ad: $elm$core$Maybe$Nothing, o: 0}))
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var state = msg.a;
				var _v16 = model.h;
				_v16$2:
				while (true) {
					if (!_v16.$) {
						switch (_v16.a.$) {
							case 6:
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											h: $elm$core$Maybe$Just(
												$author$project$Main$NewPageMenu(state))
										}),
									$elm$core$Platform$Cmd$none);
							case 8:
								var _v17 = _v16.a;
								var index = _v17.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											h: $elm$core$Maybe$Just(
												A2($author$project$Main$EditPageMenu, index, state))
										}),
									$elm$core$Platform$Cmd$none);
							default:
								break _v16$2;
						}
					} else {
						break _v16$2;
					}
				}
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 22:
				var index = msg.a;
				var state = msg.b;
				var oldPage = A2($elm$core$Array$get, index, model.b);
				var newModel = function () {
					if (!oldPage.$) {
						var page = oldPage.a;
						return _Utils_update(
							model,
							{
								k: index,
								b: A3(
									$elm$core$Array$set,
									index,
									_Utils_update(
										page,
										{d: state.d}),
									model.b),
								h: $elm$core$Maybe$Nothing
							});
					} else {
						return _Utils_update(
							model,
							{h: $elm$core$Maybe$Nothing});
					}
				}();
				return _Utils_Tuple2(
					newModel,
					$author$project$Ports$saveState(
						A2($miniBill$elm_codec$Codec$encodeToValue, $author$project$Main$modelCodec, newModel)));
			case 17:
				var key = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aA: A2($elm$core$Dict$remove, key, model.aA)
						}),
					$elm$core$Platform$Cmd$none);
			case 18:
				return _Utils_Tuple2(
					model,
					A2(
						$elm$file$File$Select$file,
						_List_fromArray(
							['text/json']),
						$author$project$Main$ReceivedPage));
			case 19:
				var file = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Task$perform,
						$author$project$Main$PageImported,
						$elm$file$File$toString(file)));
			case 20:
				var string = msg.a;
				return _Utils_Tuple2(
					function () {
						var _v19 = model.h;
						if ((!_v19.$) && (_v19.a.$ === 6)) {
							var state = _v19.a.a;
							return _Utils_update(
								model,
								{
									h: $elm$core$Maybe$Just(
										$author$project$Main$NewPageMenu(
											function () {
												var _v20 = A2($miniBill$elm_codec$Codec$decodeString, $author$project$Main$pageCodec, string);
												if (!_v20.$) {
													var page = _v20.a;
													return _Utils_update(
														state,
														{
															bs: $elm$core$Maybe$Nothing,
															a7: $elm$core$Maybe$Just(page)
														});
												} else {
													var error = _v20.a;
													return _Utils_update(
														state,
														{
															bs: $elm$core$Maybe$Just(
																$elm$json$Json$Decode$errorToString(error)),
															a7: $elm$core$Maybe$Nothing
														});
												}
											}()))
								});
						} else {
							return model;
						}
					}(),
					$elm$core$Platform$Cmd$none);
			case 21:
				var page = msg.a;
				var newModel = _Utils_update(
					model,
					{
						k: $elm$core$Array$length(model.b),
						o: $author$project$Main$Edit(
							_Utils_Tuple2(
								$elm$core$Maybe$Just(page.ar),
								_List_Nil)),
						b: A2($elm$core$Array$push, page, model.b),
						h: $elm$core$Maybe$Nothing
					});
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Ports$saveState(
								A2($miniBill$elm_codec$Codec$encodeToValue, $author$project$Main$modelCodec, newModel)),
								function () {
								var _v21 = model.bP;
								if (!_v21.$) {
									var key = _v21.a;
									return A2(
										$elm$browser$Browser$Navigation$pushUrl,
										key,
										A3($elm$url$Url$Builder$crossOrigin, 'https://midisurf.app', _List_Nil, _List_Nil));
								} else {
									return $elm$core$Platform$Cmd$none;
								}
							}()
							])));
			case 23:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3(
								$author$project$Main$updateControllerOnActivePage,
								model.k,
								{f6: id, hH: $author$project$Controller$addSpace},
								model.b)
						}),
					$elm$core$Platform$Cmd$none);
			case 24:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3(
								$author$project$Main$updateControllerOnActivePage,
								model.k,
								{f6: id, hH: $author$project$Controller$removeItem},
								model.b)
						}),
					$elm$core$Platform$Cmd$none);
			case 25:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h: A2(
								$elm$core$Maybe$map,
								function (controller) {
									return A3(
										$author$project$Main$EditMenu,
										id,
										controller,
										$author$project$Main$convertToEditable(controller));
								},
								A3($author$project$Main$getControllerFromActivePage, id, model.k, model.b))
						}),
					$elm$core$Platform$Cmd$none);
			case 26:
				var editType = msg.a;
				var newPopup = function () {
					var _v22 = model.h;
					if ((!_v22.$) && (_v22.a.$ === 5)) {
						var _v23 = _v22.a;
						var id = _v23.a;
						var baseController = _v23.b;
						var oldEController = _v23.c;
						return $elm$core$Maybe$Just(
							A3(
								$author$project$Main$EditMenu,
								id,
								baseController,
								function () {
									var _v24 = _Utils_Tuple2(oldEController, editType);
									_v24$4:
									while (true) {
										switch (_v24.a.$) {
											case 6:
												switch (_v24.b.$) {
													case 7:
														var items = _v24.a.a;
														return $author$project$EditableController$EditSizedRow(items);
													case 6:
														var items = _v24.a.a;
														return $author$project$EditableController$EditSizedColumn(items);
													default:
														break _v24$4;
												}
											case 7:
												switch (_v24.b.$) {
													case 7:
														var items = _v24.a.a;
														return $author$project$EditableController$EditSizedRow(items);
													case 6:
														var items = _v24.a.a;
														return $author$project$EditableController$EditSizedColumn(items);
													default:
														break _v24$4;
												}
											default:
												break _v24$4;
										}
									}
									return editType;
								}()));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: newPopup}),
					$elm$core$Platform$Cmd$none);
			case 29:
				var controller = msg.a;
				var _v25 = model.h;
				if ((!_v25.$) && (_v25.a.$ === 5)) {
					var _v26 = _v25.a;
					var id = _v26.a;
					var oldController = A3($author$project$Main$getControllerFromActivePage, '0', model.k, model.b);
					var newModel = _Utils_update(
						model,
						{
							o: function () {
								var _v27 = model.o;
								if (_v27.$ === 1) {
									if (!_v27.a.a.$) {
										var _v28 = _v27.a;
										var first = _v28.a.a;
										var rest = _v28.b;
										return $author$project$Main$Edit(
											_Utils_Tuple2(
												$elm$core$Maybe$Just(first),
												function () {
													if (!oldController.$) {
														var oc = oldController.a;
														return A2($elm$core$List$cons, oc, rest);
													} else {
														return rest;
													}
												}()));
									} else {
										var _v30 = _v27.a;
										var _v31 = _v30.a;
										return $author$project$Main$Edit(
											_Utils_Tuple2(oldController, _List_Nil));
									}
								} else {
									return $author$project$Main$Normal;
								}
							}(),
							b: A3(
								$author$project$Main$updateControllerOnActivePage,
								model.k,
								{
									f6: id,
									hH: function (_v32) {
										return controller;
									}
								},
								model.b),
							h: $elm$core$Maybe$Nothing
						});
					return _Utils_Tuple2(
						newModel,
						$author$project$Ports$saveState(
							A2($miniBill$elm_codec$Codec$encodeToValue, $author$project$Main$modelCodec, newModel)));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{o: $author$project$Main$Normal, h: $elm$core$Maybe$Nothing}),
						A2(
							$elm$core$Task$perform,
							function (viewport) {
								return A2(
									$author$project$Main$PageResized,
									$elm$core$Basics$floor(viewport.bC.ab),
									$elm$core$Basics$floor(viewport.bC.av));
							},
							$elm$browser$Browser$Dom$getViewport));
				}
			case 30:
				var activePage = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{k: activePage}),
					A2(
						$elm$core$Task$perform,
						function (viewport) {
							return A2(
								$author$project$Main$PageResized,
								$elm$core$Basics$floor(viewport.bC.ab),
								$elm$core$Basics$floor(viewport.bC.av));
						},
						$elm$browser$Browser$Dom$getViewport));
			case 27:
				var state = msg.a;
				var _v33 = model.h;
				if ((!_v33.$) && (_v33.a.$ === 5)) {
					var _v34 = _v33.a;
					var id = _v34.a;
					var oldController = _v34.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: $elm$core$Maybe$Just(
									A3($author$project$Main$EditMenu, id, oldController, state))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{h: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				}
			case 28:
				var key = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							az: A2($elm$core$Dict$remove, key, model.az)
						}),
					$elm$core$Platform$Cmd$none);
			case 31:
				var id = msg.a;
				var _v35 = A3($author$project$Main$getControllerFromActivePage, id, model.k, model.b);
				if (!_v35.$) {
					switch (_v35.a.$) {
						case 0:
							var _v36 = _v35.a;
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 1:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 4:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 3:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 5:
							var note = _v35.a;
							var _v37 = $author$project$Controller$buttonOn(note);
							var updatedNote = _v37.a;
							var midiMsgs = _v37.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: id,
												hH: $elm$core$Basics$always(updatedNote)
											},
											model.b)
									}),
								$author$project$Ports$outgoingMidi(
									$elm$core$Array$fromList(
										A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
						case 6:
							var chord = _v35.a;
							var _v38 = $author$project$Controller$buttonOn(chord);
							var updatedChord = _v38.a;
							var midiMsgs = _v38.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: id,
												hH: $elm$core$Basics$always(updatedChord)
											},
											model.b)
									}),
								$author$project$Ports$outgoingMidi(
									$elm$core$Array$fromList(
										A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
						case 7:
							var ccValue = _v35.a;
							var _v39 = $author$project$Controller$buttonOn(ccValue);
							var updatedCCValue = _v39.a;
							var midiMsgs = _v39.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: id,
												hH: $elm$core$Basics$always(updatedCCValue)
											},
											model.b)
									}),
								$author$project$Ports$outgoingMidi(
									$elm$core$Array$fromList(
										A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
						case 8:
							var command = _v35.a;
							var _v40 = $author$project$Controller$buttonOn(command);
							var updatedCommand = _v40.a;
							var midiMsgs = _v40.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: id,
												hH: $elm$core$Basics$always(updatedCommand)
											},
											model.b)
									}),
								$author$project$Ports$outgoingMidi(
									$elm$core$Array$fromList(
										A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
						case 9:
							var sequence = _v35.a;
							var _v41 = $author$project$Controller$buttonOn(sequence);
							var updatedSequence = _v41.a;
							var midiMsgs = _v41.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: id,
												hH: $elm$core$Basics$always(updatedSequence)
											},
											model.b)
									}),
								$author$project$Ports$outgoingMidi(
									$elm$core$Array$fromList(
										A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
						case 10:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 11:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 12:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 13:
							var _v42 = _v35.a;
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						default:
							var _v43 = _v35.a;
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 32:
				var id = msg.a;
				var _v44 = A3($author$project$Main$getControllerFromActivePage, id, model.k, model.b);
				if (!_v44.$) {
					switch (_v44.a.$) {
						case 0:
							var _v45 = _v44.a;
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 1:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 4:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 3:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 5:
							var note = _v44.a;
							var _v46 = $author$project$Controller$buttonOff(note);
							var updatedNote = _v46.a;
							var midiMsgs = _v46.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: id,
												hH: $elm$core$Basics$always(updatedNote)
											},
											model.b)
									}),
								$author$project$Ports$outgoingMidi(
									$elm$core$Array$fromList(
										A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
						case 6:
							var chord = _v44.a;
							var _v47 = $author$project$Controller$buttonOff(chord);
							var updatedChord = _v47.a;
							var midiMsgs = _v47.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: id,
												hH: $elm$core$Basics$always(updatedChord)
											},
											model.b)
									}),
								$author$project$Ports$outgoingMidi(
									$elm$core$Array$fromList(
										A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
						case 7:
							var ccValue = _v44.a;
							var _v48 = $author$project$Controller$buttonOff(ccValue);
							var updatedCCValue = _v48.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: id,
												hH: $elm$core$Basics$always(updatedCCValue)
											},
											model.b)
									}),
								$elm$core$Platform$Cmd$none);
						case 8:
							var command = _v44.a;
							var _v49 = $author$project$Controller$buttonOff(command);
							var updatedCommand = _v49.a;
							var midiMsgs = _v49.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: id,
												hH: $elm$core$Basics$always(updatedCommand)
											},
											model.b)
									}),
								$author$project$Ports$outgoingMidi(
									$elm$core$Array$fromList(
										A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
						case 9:
							var sequence = _v44.a;
							var _v50 = $author$project$Controller$buttonOff(sequence);
							var updatedSequence = _v50.a;
							var midiMsgs = _v50.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										b: A3(
											$author$project$Main$updateControllerOnActivePage,
											model.k,
											{
												f6: id,
												hH: $elm$core$Basics$always(updatedSequence)
											},
											model.b)
									}),
								$author$project$Ports$outgoingMidi(
									$elm$core$Array$fromList(
										A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
						case 10:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 11:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 12:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 13:
							var _v51 = _v44.a;
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						default:
							var _v52 = _v44.a;
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 33:
				var id = msg.a;
				var touchEvent = msg.b;
				var touchCoordinates = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(0, 0),
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.dG;
						},
						$elm$core$List$head(touchEvent.dD)));
				var identifier = A2(
					$elm$core$Maybe$withDefault,
					-1,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.f7;
						},
						$elm$core$List$head(touchEvent.dD)));
				var fader = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Controller$Fader(
						{
							b4: 1,
							f: 0,
							i: 1,
							d: 'ERROR',
							n: $elm$core$Maybe$Just(0),
							c: $author$project$Controller$Set,
							cF: 127,
							cI: 0,
							bh: 50
						}),
					A3($author$project$Main$getControllerFromActivePage, id, model.k, model.b));
				var _v53 = A3($author$project$Controller$faderChanging, identifier, touchCoordinates, fader);
				var newFader = _v53.a;
				var midiMsgs = _v53.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3(
								$author$project$Main$updateControllerOnActivePage,
								model.k,
								{
									f6: id,
									hH: $elm$core$Basics$always(newFader)
								},
								model.b)
						}),
					$author$project$Ports$outgoingMidi(
						$elm$core$Array$fromList(
							A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
			case 34:
				var id = msg.a;
				var mouseEvent = msg.b;
				var fader = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Controller$Fader(
						{
							b4: 1,
							f: 0,
							i: 1,
							d: 'ERROR',
							n: $elm$core$Maybe$Just(0),
							c: $author$project$Controller$Set,
							cF: 127,
							cI: 0,
							bh: 50
						}),
					A3($author$project$Main$getControllerFromActivePage, id, model.k, model.b));
				var _v54 = A3($author$project$Controller$faderChanging, -1, mouseEvent.dG, fader);
				var newFader = _v54.a;
				var midiMsgs = _v54.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3(
								$author$project$Main$updateControllerOnActivePage,
								model.k,
								{
									f6: id,
									hH: $elm$core$Basics$always(newFader)
								},
								model.b)
						}),
					$author$project$Ports$outgoingMidi(
						$elm$core$Array$fromList(
							A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
			case 35:
				var id = msg.a;
				var fader = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Controller$Fader(
						{
							b4: 1,
							f: 0,
							i: 1,
							d: 'ERROR',
							n: $elm$core$Maybe$Just(0),
							c: $author$project$Controller$Set,
							cF: 127,
							cI: 0,
							bh: 50
						}),
					A3($author$project$Main$getControllerFromActivePage, id, model.k, model.b));
				var _v55 = $author$project$Controller$faderSet(fader);
				var newFader = _v55.a;
				var midiMsgs = _v55.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b: A3(
								$author$project$Main$updateControllerOnActivePage,
								model.k,
								{
									f6: id,
									hH: $elm$core$Basics$always(newFader)
								},
								model.b)
						}),
					$author$project$Ports$outgoingMidi(
						$elm$core$Array$fromList(
							A2($elm$core$List$map, $author$project$Midi$midiMsgToIntArray, midiMsgs))));
			case 37:
				var width = msg.a;
				var height = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bd: $elm$core$Maybe$Just(
								{av: height, ab: width})
						}),
					$elm$core$Platform$Cmd$none);
			case 38:
				var string = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$copyToClipboard(string));
			case 39:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 36:
				var deviceName = msg.a.fN;
				var midiData = msg.a.gp;
				var midiMsg = $author$project$Midi$intArrayToMidiMsg(midiData);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bu: A2(
								$elm$core$List$cons,
								deviceName + (' ' + $author$project$Midi$midiMsgToString(midiMsg)),
								A2($elm$core$List$take, 199, model.bu)),
							h: function () {
								var _v56 = model.h;
								if ((!_v56.$) && (_v56.a.$ === 5)) {
									var _v57 = _v56.a;
									var id = _v57.a;
									var oldController = _v57.b;
									var state = _v57.c;
									return $elm$core$Maybe$Just(
										A3(
											$author$project$Main$EditMenu,
											id,
											oldController,
											A2($author$project$EditableController$updateWithMidiMsg, midiMsg, state)));
								} else {
									return model.h;
								}
							}()
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $mdgriffith$elm_ui$Internal$Model$AlignX = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$CenterX = 1;
var $mdgriffith$elm_ui$Element$centerX = $mdgriffith$elm_ui$Internal$Model$AlignX(1);
var $mdgriffith$elm_ui$Internal$Model$AlignY = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$CenterY = 1;
var $mdgriffith$elm_ui$Element$centerY = $mdgriffith$elm_ui$Internal$Model$AlignY(1);
var $mdgriffith$elm_ui$Internal$Model$Unkeyed = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsColumn = 1;
var $mdgriffith$elm_ui$Internal$Model$asColumn = 1;
var $mdgriffith$elm_ui$Internal$Style$classes = {e_: 'a', cO: 'atv', e1: 'ab', e2: 'cx', e3: 'cy', e4: 'acb', e5: 'accx', e6: 'accy', e7: 'acr', dv: 'al', dw: 'ar', e8: 'at', cP: 'ah', cQ: 'av', fb: 's', ff: 'bh', fg: 'b', fi: 'w7', fk: 'bd', fl: 'bdt', b2: 'bn', fm: 'bs', b3: 'cpe', fu: 'cp', fv: 'cpx', fw: 'cpy', aG: 'c', cb: 'ctr', cc: 'cb', cd: 'ccx', aI: 'ccy', bJ: 'cl', ce: 'cr', fA: 'ct', fE: 'cptr', fF: 'ctxt', fY: 'fcs', dQ: 'focus-within', f_: 'fs', f1: 'g', c3: 'hbh', c5: 'hc', dV: 'he', c6: 'hf', dW: 'hfp', f4: 'hv', f9: 'ic', gb: 'fr', ci: 'lbl', ge: 'iml', gf: 'imlf', gg: 'imlp', gh: 'implw', gi: 'it', gk: 'i', d5: 'lnk', bv: 'nb', ef: 'notxt', gE: 'ol', gF: 'or', ba: 'oq', gK: 'oh', en: 'pg', eo: 'p', gM: 'ppe', gU: 'ui', am: 'r', gY: 'sb', gZ: 'sbx', g_: 'sby', g$: 'sbt', g5: 'e', g7: 'cap', g8: 'sev', hd: 'sk', dn: 't', hj: 'tc', hk: 'w8', hl: 'w2', hm: 'w9', hn: 'tj', cE: 'tja', ho: 'tl', hp: 'w3', hq: 'w5', hr: 'w4', hs: 'tr', ht: 'w6', hu: 'w1', hv: 'tun', eP: 'ts', bg: 'clr', hF: 'u', dq: 'wc', eV: 'we', dr: 'wf', eW: 'wfp', dt: 'wrp'};
var $mdgriffith$elm_ui$Internal$Model$Generic = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$div = $mdgriffith$elm_ui$Internal$Model$Generic;
var $mdgriffith$elm_ui$Internal$Model$NoNearbyChildren = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$columnClass = $mdgriffith$elm_ui$Internal$Style$classes.fb + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.aG);
var $mdgriffith$elm_ui$Internal$Model$gridClass = $mdgriffith$elm_ui$Internal$Style$classes.fb + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f1);
var $mdgriffith$elm_ui$Internal$Model$pageClass = $mdgriffith$elm_ui$Internal$Style$classes.fb + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.en);
var $mdgriffith$elm_ui$Internal$Model$paragraphClass = $mdgriffith$elm_ui$Internal$Style$classes.fb + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eo);
var $mdgriffith$elm_ui$Internal$Model$rowClass = $mdgriffith$elm_ui$Internal$Style$classes.fb + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.am);
var $mdgriffith$elm_ui$Internal$Model$singleClass = $mdgriffith$elm_ui$Internal$Style$classes.fb + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.g5);
var $mdgriffith$elm_ui$Internal$Model$contextClasses = function (context) {
	switch (context) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Model$rowClass;
		case 1:
			return $mdgriffith$elm_ui$Internal$Model$columnClass;
		case 2:
			return $mdgriffith$elm_ui$Internal$Model$singleClass;
		case 3:
			return $mdgriffith$elm_ui$Internal$Model$gridClass;
		case 4:
			return $mdgriffith$elm_ui$Internal$Model$paragraphClass;
		default:
			return $mdgriffith$elm_ui$Internal$Model$pageClass;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Keyed = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$NoStyleSheet = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$Styled = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unstyled = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addChildren = F2(
	function (existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(behind, existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(existing, inFront);
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					behind,
					_Utils_ap(existing, inFront));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$addKeyedChildren = F3(
	function (key, existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(
					existing,
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						inFront));
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					_Utils_ap(
						existing,
						A2(
							$elm$core$List$map,
							function (x) {
								return _Utils_Tuple2(key, x);
							},
							inFront)));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$AsEl = 2;
var $mdgriffith$elm_ui$Internal$Model$asEl = 2;
var $mdgriffith$elm_ui$Internal$Model$AsParagraph = 4;
var $mdgriffith$elm_ui$Internal$Model$asParagraph = 4;
var $mdgriffith$elm_ui$Internal$Flag$Flag = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Second = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$flag = function (i) {
	return (i > 31) ? $mdgriffith$elm_ui$Internal$Flag$Second(1 << (i - 32)) : $mdgriffith$elm_ui$Internal$Flag$Flag(1 << i);
};
var $mdgriffith$elm_ui$Internal$Flag$alignBottom = $mdgriffith$elm_ui$Internal$Flag$flag(41);
var $mdgriffith$elm_ui$Internal$Flag$alignRight = $mdgriffith$elm_ui$Internal$Flag$flag(40);
var $mdgriffith$elm_ui$Internal$Flag$centerX = $mdgriffith$elm_ui$Internal$Flag$flag(42);
var $mdgriffith$elm_ui$Internal$Flag$centerY = $mdgriffith$elm_ui$Internal$Flag$flag(43);
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $mdgriffith$elm_ui$Internal$Model$lengthClassName = function (x) {
	switch (x.$) {
		case 0:
			var px = x.a;
			return $elm$core$String$fromInt(px) + 'px';
		case 1:
			return 'auto';
		case 2:
			var i = x.a;
			return $elm$core$String$fromInt(i) + 'fr';
		case 3:
			var min = x.a;
			var len = x.b;
			return 'min' + ($elm$core$String$fromInt(min) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
		default:
			var max = x.a;
			var len = x.b;
			return 'max' + ($elm$core$String$fromInt(max) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
	}
};
var $mdgriffith$elm_ui$Internal$Model$floatClass = function (x) {
	return $elm$core$String$fromInt(
		$elm$core$Basics$round(x * 255));
};
var $mdgriffith$elm_ui$Internal$Model$transformClass = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'mv-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(x) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(y) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(z))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			return $elm$core$Maybe$Just(
				'tfrm-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ty) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ox) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oz) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(angle))))))))))))))))))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$getStyleName = function (style) {
	switch (style.$) {
		case 13:
			var name = style.a;
			return name;
		case 12:
			var name = style.a;
			var o = style.b;
			return name;
		case 0:
			var _class = style.a;
			return _class;
		case 1:
			var name = style.a;
			return name;
		case 2:
			var i = style.a;
			return 'font-size-' + $elm$core$String$fromInt(i);
		case 3:
			var _class = style.a;
			return _class;
		case 4:
			var _class = style.a;
			return _class;
		case 5:
			var cls = style.a;
			var x = style.b;
			var y = style.c;
			return cls;
		case 7:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 6:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 8:
			var template = style.a;
			return 'grid-rows-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.gW)) + ('-cols-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.fy)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.g9.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.g9.b)))))));
		case 9:
			var pos = style.a;
			return 'gp grid-pos-' + ($elm$core$String$fromInt(pos.am) + ('-' + ($elm$core$String$fromInt(pos.fx) + ('-' + ($elm$core$String$fromInt(pos.ab) + ('-' + $elm$core$String$fromInt(pos.av)))))));
		case 11:
			var selector = style.a;
			var subStyle = style.b;
			var name = function () {
				switch (selector) {
					case 0:
						return 'fs';
					case 1:
						return 'hv';
					default:
						return 'act';
				}
			}();
			return A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (sty) {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$getStyleName(sty);
						if (_v1 === '') {
							return '';
						} else {
							var styleName = _v1;
							return styleName + ('-' + name);
						}
					},
					subStyle));
		default:
			var x = style.a;
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				$mdgriffith$elm_ui$Internal$Model$transformClass(x));
	}
};
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $mdgriffith$elm_ui$Internal$Model$reduceStyles = F2(
	function (style, nevermind) {
		var cache = nevermind.a;
		var existing = nevermind.b;
		var styleName = $mdgriffith$elm_ui$Internal$Model$getStyleName(style);
		return A2($elm$core$Set$member, styleName, cache) ? nevermind : _Utils_Tuple2(
			A2($elm$core$Set$insert, styleName, cache),
			A2($elm$core$List$cons, style, existing));
	});
var $mdgriffith$elm_ui$Internal$Model$Property = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Style = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$dot = function (c) {
	return '.' + c;
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $mdgriffith$elm_ui$Internal$Model$formatColor = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return 'rgba(' + ($elm$core$String$fromInt(
		$elm$core$Basics$round(red * 255)) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(green * 255))) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(blue * 255))) + (',' + ($elm$core$String$fromFloat(alpha) + ')')))));
};
var $mdgriffith$elm_ui$Internal$Model$formatBoxShadow = function (shadow) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					shadow.d0 ? $elm$core$Maybe$Just('inset') : $elm$core$Maybe$Nothing,
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.cn.a) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.cn.b) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.bo) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.cB) + 'px'),
					$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Internal$Model$formatColor(shadow.bq))
				])));
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $mdgriffith$elm_ui$Internal$Model$renderFocusStyle = function (focus) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dQ) + ':focus-within',
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.fj),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.fd),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										bo: shadow.bo,
										bq: shadow.bq,
										d0: false,
										cn: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.cn)),
										cB: shadow.cB
									}));
						},
						focus.g2),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					]))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb) + ':focus .focusable, ') + (($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb) + '.focusable:focus, ') + ('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb) + ' .focusable-thumb'))),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.fj),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.fd),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										bo: shadow.bo,
										bq: shadow.bq,
										d0: false,
										cn: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.cn)),
										cB: shadow.cB
									}));
						},
						focus.g2),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					])))
		]);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlJson(value));
	});
var $mdgriffith$elm_ui$Internal$Style$AllChildren = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Batch = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Style$Child = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Class = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Descriptor = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Left = 3;
var $mdgriffith$elm_ui$Internal$Style$Prop = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Right = 2;
var $mdgriffith$elm_ui$Internal$Style$Self = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Supports = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Content = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Bottom = 1;
var $mdgriffith$elm_ui$Internal$Style$CenterX = 4;
var $mdgriffith$elm_ui$Internal$Style$CenterY = 5;
var $mdgriffith$elm_ui$Internal$Style$Top = 0;
var $mdgriffith$elm_ui$Internal$Style$alignments = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $mdgriffith$elm_ui$Internal$Style$contentName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fA);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cc);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ce);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bJ);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cd);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aI);
	}
};
var $mdgriffith$elm_ui$Internal$Style$selfName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e8);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e1);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dw);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e2);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e3);
	}
};
var $mdgriffith$elm_ui$Internal$Style$describeAlignment = function (values) {
	var createDescription = function (alignment) {
		var _v0 = values(alignment);
		var content = _v0.a;
		var indiv = _v0.b;
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$contentName(alignment),
				content),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						indiv)
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$elDescription = _List_fromArray(
	[
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c3),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ff),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g$),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dn),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c6),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dr),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'auto !important')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c5),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c6),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dr),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eW),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dq),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
			])),
		$mdgriffith$elm_ui$Internal$Style$describeAlignment(
		function (alignment) {
			switch (alignment) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
							]));
				case 3:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							]));
				case 4:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
									]))
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
							]));
			}
		})
	]);
var $mdgriffith$elm_ui$Internal$Style$gridAlignments = function (values) {
	var createDescription = function (alignment) {
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						values(alignment))
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$Above = 0;
var $mdgriffith$elm_ui$Internal$Style$Behind = 5;
var $mdgriffith$elm_ui$Internal$Style$Below = 1;
var $mdgriffith$elm_ui$Internal$Style$OnLeft = 3;
var $mdgriffith$elm_ui$Internal$Style$OnRight = 2;
var $mdgriffith$elm_ui$Internal$Style$Within = 4;
var $mdgriffith$elm_ui$Internal$Style$locations = function () {
	var loc = 0;
	var _v0 = function () {
		switch (loc) {
			case 0:
				return 0;
			case 1:
				return 0;
			case 2:
				return 0;
			case 3:
				return 0;
			case 4:
				return 0;
			default:
				return 0;
		}
	}();
	return _List_fromArray(
		[0, 1, 2, 3, 4, 5]);
}();
var $mdgriffith$elm_ui$Internal$Style$baseSheet = _List_fromArray(
	[
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		'html,body',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		_Utils_ap(
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb),
			_Utils_ap(
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g5),
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f9))),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c6),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-height', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dr),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb) + ':focus',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'outline', 'none')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gU),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c6)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c6),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gb),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bv),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bv),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g5),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				$mdgriffith$elm_ui$Internal$Style$Batch(
				function (fn) {
					return A2($elm$core$List$map, fn, $mdgriffith$elm_ui$Internal$Style$locations);
				}(
					function (loc) {
						switch (loc) {
							case 0:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e_),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c6),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dr),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
												])),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 1:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fg),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c6),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												]))
										]));
							case 2:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gF),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 3:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'right', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 4:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gb),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							default:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ff),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
						}
					}))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'resize', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'box-sizing', 'border-box'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-size', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-family', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'inherit'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dt),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-wrap', 'wrap')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ef),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-moz-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-webkit-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-ms-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'user-select', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fE),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'pointer')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fF),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gM),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b3),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bg),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ba),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.f4, $mdgriffith$elm_ui$Internal$Style$classes.bg)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.f4, $mdgriffith$elm_ui$Internal$Style$classes.ba)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.fY, $mdgriffith$elm_ui$Internal$Style$classes.bg)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.fY, $mdgriffith$elm_ui$Internal$Style$classes.ba)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.cO, $mdgriffith$elm_ui$Internal$Style$classes.bg)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.cO, $mdgriffith$elm_ui$Internal$Style$classes.ba)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eP),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Prop,
						'transition',
						A2(
							$elm$core$String$join,
							', ',
							A2(
								$elm$core$List$map,
								function (x) {
									return x + ' 160ms';
								},
								_List_fromArray(
									['transform', 'opacity', 'filter', 'background-color', 'color', 'font-size']))))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gY),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gZ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.am),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g_),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aG),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g5),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fu),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fv),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fw),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dq),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', 'auto')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b2),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fk),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dashed')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fl),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dotted')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fm),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dn),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gi),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1.05'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background', 'transparent'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'inherit')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g5),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.am),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0%'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eV),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d5),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c6),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dW),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dr),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.e7,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.e5,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e2),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-left', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.e5,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e2),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-right', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.e5,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e3),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.e5 + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.e7 + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.e5)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_Nil);
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_Nil);
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g8),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ci),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'baseline')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aG),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0px'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', 'min-content'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dV),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c6),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dr),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eW),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dq),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.e4,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.e6,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e3),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.e6,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e3),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.e6,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e3),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.e6 + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.e4 + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.e6)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g8),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f1),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', '-ms-grid'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'.gp',
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Supports,
						_Utils_Tuple2('display', 'grid'),
						_List_fromArray(
							[
								_Utils_Tuple2('display', 'grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$gridAlignments(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
										]);
								case 1:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
										]);
								case 2:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
										]);
								case 3:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
										]);
								case 4:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
										]);
								default:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
										]);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.en),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb + ':first-child'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.fb + ($mdgriffith$elm_ui$Internal$Style$selfName(3) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.fb))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.fb + ($mdgriffith$elm_ui$Internal$Style$selfName(2) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.fb))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ge),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background-color', 'transparent')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gh),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g5),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gg),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gf),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'transparent')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eo),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-wrap', 'break-word'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c3),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ff),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dn),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eo),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::after',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::before',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g5),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eV),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gb),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ff),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e_),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fg),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gF),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dn),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.am),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.aG),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f1),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left')
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.hidden',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hu),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '100')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hl),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '200')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hp),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '300')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hr),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '400')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hq),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '500')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ht),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '600')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fi),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '700')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hk),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '800')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hm),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '900')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gk),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'italic')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hd),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hF),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hF),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hd)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hv),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'normal')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hn),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cE),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify-all')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hj),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'center')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hs),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'right')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ho),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'left')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.modal',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none')
					]))
			]))
	]);
var $mdgriffith$elm_ui$Internal$Style$fontVariant = function (_var) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + _var,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\"'))
				])),
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + (_var + '-off'),
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\" 0'))
				]))
		]);
};
var $mdgriffith$elm_ui$Internal$Style$commonValues = $elm$core$List$concat(
	_List_fromArray(
		[
			A2(
			$elm$core$List$map,
			function (x) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.border-' + $elm$core$String$fromInt(x),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'border-width',
							$elm$core$String$fromInt(x) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 6)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 8, 32)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.p-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'padding',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 24)),
			_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'small-caps')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp-off',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'normal')
					]))
			]),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('zero'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('onum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('liga'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('dlig'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('ordn'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('tnum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('afrc'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('frac')
		]));
var $mdgriffith$elm_ui$Internal$Style$explainer = '\n.explain {\n    border: 6px solid rgb(174, 121, 15) !important;\n}\n.explain > .' + ($mdgriffith$elm_ui$Internal$Style$classes.fb + (' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n.ctr {\n    border: none !important;\n}\n.explain > .ctr > .' + ($mdgriffith$elm_ui$Internal$Style$classes.fb + ' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n')));
var $mdgriffith$elm_ui$Internal$Style$inputTextReset = '\ninput[type="search"],\ninput[type="search"]::-webkit-search-decoration,\ninput[type="search"]::-webkit-search-cancel-button,\ninput[type="search"]::-webkit-search-results-button,\ninput[type="search"]::-webkit-search-results-decoration {\n  -webkit-appearance:none;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$sliderReset = '\ninput[type=range] {\n  -webkit-appearance: none; \n  background: transparent;\n  position:absolute;\n  left:0;\n  top:0;\n  z-index:10;\n  width: 100%;\n  outline: dashed 1px;\n  height: 100%;\n  opacity: 0;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$thumbReset = '\ninput[type=range]::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-moz-range-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-ms-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range][orient=vertical]{\n    writing-mode: bt-lr; /* IE */\n    -webkit-appearance: slider-vertical;  /* WebKit */\n}\n';
var $mdgriffith$elm_ui$Internal$Style$trackReset = '\ninput[type=range]::-moz-range-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-ms-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-webkit-slider-runnable-track {\n    background: transparent;\n    cursor: pointer;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$overrides = '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.am) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb) + (' { flex-basis: auto !important; } ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.am) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cb) + (' { flex-basis: auto !important; }}' + ($mdgriffith$elm_ui$Internal$Style$inputTextReset + ($mdgriffith$elm_ui$Internal$Style$sliderReset + ($mdgriffith$elm_ui$Internal$Style$trackReset + ($mdgriffith$elm_ui$Internal$Style$thumbReset + $mdgriffith$elm_ui$Internal$Style$explainer)))))))))))))));
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $mdgriffith$elm_ui$Internal$Style$Intermediate = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$emptyIntermediate = F2(
	function (selector, closing) {
		return {b9: closing, D: _List_Nil, aV: _List_Nil, an: selector};
	});
var $mdgriffith$elm_ui$Internal$Style$renderRules = F2(
	function (_v0, rulesToRender) {
		var parent = _v0;
		var generateIntermediates = F2(
			function (rule, rendered) {
				switch (rule.$) {
					case 0:
						var name = rule.a;
						var val = rule.b;
						return _Utils_update(
							rendered,
							{
								aV: A2(
									$elm$core$List$cons,
									_Utils_Tuple2(name, val),
									rendered.aV)
							});
					case 3:
						var _v2 = rule.a;
						var prop = _v2.a;
						var value = _v2.b;
						var props = rule.b;
						return _Utils_update(
							rendered,
							{
								D: A2(
									$elm$core$List$cons,
									{b9: '\n}', D: _List_Nil, aV: props, an: '@supports (' + (prop + (':' + (value + (') {' + parent.an))))},
									rendered.D)
							});
					case 5:
						var selector = rule.a;
						var adjRules = rule.b;
						return _Utils_update(
							rendered,
							{
								D: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.an + (' + ' + selector), ''),
										adjRules),
									rendered.D)
							});
					case 1:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								D: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.an + (' > ' + child), ''),
										childRules),
									rendered.D)
							});
					case 2:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								D: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.an + (' ' + child), ''),
										childRules),
									rendered.D)
							});
					case 4:
						var descriptor = rule.a;
						var descriptorRules = rule.b;
						return _Utils_update(
							rendered,
							{
								D: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(
											$mdgriffith$elm_ui$Internal$Style$emptyIntermediate,
											_Utils_ap(parent.an, descriptor),
											''),
										descriptorRules),
									rendered.D)
							});
					default:
						var batched = rule.a;
						return _Utils_update(
							rendered,
							{
								D: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.an, ''),
										batched),
									rendered.D)
							});
				}
			});
		return A3($elm$core$List$foldr, generateIntermediates, parent, rulesToRender);
	});
var $mdgriffith$elm_ui$Internal$Style$renderCompact = function (styleClasses) {
	var renderValues = function (values) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				function (_v3) {
					var x = _v3.a;
					var y = _v3.b;
					return x + (':' + (y + ';'));
				},
				values));
	};
	var renderClass = function (rule) {
		var _v2 = rule.aV;
		if (!_v2.b) {
			return '';
		} else {
			return rule.an + ('{' + (renderValues(rule.aV) + (rule.b9 + '}')));
		}
	};
	var renderIntermediate = function (_v0) {
		var rule = _v0;
		return _Utils_ap(
			renderClass(rule),
			$elm$core$String$concat(
				A2($elm$core$List$map, renderIntermediate, rule.D)));
	};
	return $elm$core$String$concat(
		A2(
			$elm$core$List$map,
			renderIntermediate,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v1, existing) {
						var name = _v1.a;
						var styleRules = _v1.b;
						return A2(
							$elm$core$List$cons,
							A2(
								$mdgriffith$elm_ui$Internal$Style$renderRules,
								A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, name, ''),
								styleRules),
							existing);
					}),
				_List_Nil,
				styleClasses)));
};
var $mdgriffith$elm_ui$Internal$Style$rules = _Utils_ap(
	$mdgriffith$elm_ui$Internal$Style$overrides,
	$mdgriffith$elm_ui$Internal$Style$renderCompact(
		_Utils_ap($mdgriffith$elm_ui$Internal$Style$baseSheet, $mdgriffith$elm_ui$Internal$Style$commonValues)));
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $mdgriffith$elm_ui$Internal$Model$staticRoot = function (opts) {
	var _v0 = opts.o;
	switch (_v0) {
		case 0:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'div',
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$elm$virtual_dom$VirtualDom$node,
						'style',
						_List_Nil,
						_List_fromArray(
							[
								$elm$virtual_dom$VirtualDom$text($mdgriffith$elm_ui$Internal$Style$rules)
							]))
					]));
		case 1:
			return $elm$virtual_dom$VirtualDom$text('');
		default:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'elm-ui-static-rules',
				_List_fromArray(
					[
						A2(
						$elm$virtual_dom$VirtualDom$property,
						'rules',
						$elm$json$Json$Encode$string($mdgriffith$elm_ui$Internal$Style$rules))
					]),
				_List_Nil);
	}
};
var $mdgriffith$elm_ui$Internal$Model$fontName = function (font) {
	switch (font.$) {
		case 0:
			return 'serif';
		case 1:
			return 'sans-serif';
		case 2:
			return 'monospace';
		case 3:
			var name = font.a;
			return '\"' + (name + '\"');
		case 4:
			var name = font.a;
			var url = font.b;
			return '\"' + (name + '\"');
		default:
			var name = font.a.ed;
			return '\"' + (name + '\"');
	}
};
var $mdgriffith$elm_ui$Internal$Model$isSmallCaps = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return name === 'smcp';
		case 1:
			var name = _var.a;
			return false;
		default:
			var name = _var.a;
			var index = _var.b;
			return (name === 'smcp') && (index === 1);
	}
};
var $mdgriffith$elm_ui$Internal$Model$hasSmallCaps = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$isSmallCaps, font.eQ);
	} else {
		return false;
	}
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $mdgriffith$elm_ui$Internal$Model$renderProps = F3(
	function (force, _v0, existing) {
		var key = _v0.a;
		var val = _v0.b;
		return force ? (existing + ('\n  ' + (key + (': ' + (val + ' !important;'))))) : (existing + ('\n  ' + (key + (': ' + (val + ';')))));
	});
var $mdgriffith$elm_ui$Internal$Model$renderStyle = F4(
	function (options, maybePseudo, selector, props) {
		if (maybePseudo.$ === 1) {
			return _List_fromArray(
				[
					selector + ('{' + (A3(
					$elm$core$List$foldl,
					$mdgriffith$elm_ui$Internal$Model$renderProps(false),
					'',
					props) + '\n}'))
				]);
		} else {
			var pseudo = maybePseudo.a;
			switch (pseudo) {
				case 1:
					var _v2 = options.f4;
					switch (_v2) {
						case 0:
							return _List_Nil;
						case 2:
							return _List_fromArray(
								[
									selector + ('-hv {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(true),
									'',
									props) + '\n}'))
								]);
						default:
							return _List_fromArray(
								[
									selector + ('-hv:hover {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(false),
									'',
									props) + '\n}'))
								]);
					}
				case 0:
					var renderedProps = A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_ui$Internal$Model$renderProps(false),
						'',
						props);
					return _List_fromArray(
						[
							selector + ('-fs:focus {' + (renderedProps + '\n}')),
							('.' + ($mdgriffith$elm_ui$Internal$Style$classes.fb + (':focus ' + (selector + '-fs  {')))) + (renderedProps + '\n}'),
							(selector + '-fs:focus-within {') + (renderedProps + '\n}'),
							('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fb) + (' .focusable-thumb' + (selector + '-fs {')))) + (renderedProps + '\n}')
						]);
				default:
					return _List_fromArray(
						[
							selector + ('-act:active {' + (A3(
							$elm$core$List$foldl,
							$mdgriffith$elm_ui$Internal$Model$renderProps(false),
							'',
							props) + '\n}'))
						]);
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderVariant = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return '\"' + (name + '\"');
		case 1:
			var name = _var.a;
			return '\"' + (name + '\" 0');
		default:
			var name = _var.a;
			var index = _var.b;
			return '\"' + (name + ('\" ' + $elm$core$String$fromInt(index)));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderVariants = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$renderVariant, font.eQ)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$transformValue = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'translate3d(' + ($elm$core$String$fromFloat(x) + ('px, ' + ($elm$core$String$fromFloat(y) + ('px, ' + ($elm$core$String$fromFloat(z) + 'px)'))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			var translate = 'translate3d(' + ($elm$core$String$fromFloat(tx) + ('px, ' + ($elm$core$String$fromFloat(ty) + ('px, ' + ($elm$core$String$fromFloat(tz) + 'px)')))));
			var scale = 'scale3d(' + ($elm$core$String$fromFloat(sx) + (', ' + ($elm$core$String$fromFloat(sy) + (', ' + ($elm$core$String$fromFloat(sz) + ')')))));
			var rotate = 'rotate3d(' + ($elm$core$String$fromFloat(ox) + (', ' + ($elm$core$String$fromFloat(oy) + (', ' + ($elm$core$String$fromFloat(oz) + (', ' + ($elm$core$String$fromFloat(angle) + 'rad)')))))));
			return $elm$core$Maybe$Just(translate + (' ' + (scale + (' ' + rotate))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderStyleRule = F3(
	function (options, rule, maybePseudo) {
		switch (rule.$) {
			case 0:
				var selector = rule.a;
				var props = rule.b;
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, selector, props);
			case 13:
				var name = rule.a;
				var prop = rule.b;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, 'box-shadow', prop)
						]));
			case 12:
				var name = rule.a;
				var transparency = rule.b;
				var opacity = A2(
					$elm$core$Basics$max,
					0,
					A2($elm$core$Basics$min, 1, 1 - transparency));
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'opacity',
							$elm$core$String$fromFloat(opacity))
						]));
			case 2:
				var i = rule.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			case 1:
				var name = rule.a;
				var typefaces = rule.b;
				var features = A2(
					$elm$core$String$join,
					', ',
					A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Internal$Model$renderVariants, typefaces));
				var families = _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-family',
						A2(
							$elm$core$String$join,
							', ',
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$fontName, typefaces))),
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'font-feature-settings', features),
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-variant',
						A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$hasSmallCaps, typefaces) ? 'small-caps' : 'normal')
					]);
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, '.' + name, families);
			case 3:
				var _class = rule.a;
				var prop = rule.b;
				var val = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, prop, val)
						]));
			case 4:
				var _class = rule.a;
				var prop = rule.b;
				var color = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							prop,
							$mdgriffith$elm_ui$Internal$Model$formatColor(color))
						]));
			case 5:
				var cls = rule.a;
				var x = rule.b;
				var y = rule.c;
				var yPx = $elm$core$String$fromInt(y) + 'px';
				var xPx = $elm$core$String$fromInt(x) + 'px';
				var single = '.' + $mdgriffith$elm_ui$Internal$Style$classes.g5;
				var row = '.' + $mdgriffith$elm_ui$Internal$Style$classes.am;
				var wrappedRow = '.' + ($mdgriffith$elm_ui$Internal$Style$classes.dt + row);
				var right = '.' + $mdgriffith$elm_ui$Internal$Style$classes.dw;
				var paragraph = '.' + $mdgriffith$elm_ui$Internal$Style$classes.eo;
				var page = '.' + $mdgriffith$elm_ui$Internal$Style$classes.en;
				var left = '.' + $mdgriffith$elm_ui$Internal$Style$classes.dv;
				var halfY = $elm$core$String$fromFloat(y / 2) + 'px';
				var halfX = $elm$core$String$fromFloat(x / 2) + 'px';
				var column = '.' + $mdgriffith$elm_ui$Internal$Style$classes.aG;
				var _class = '.' + cls;
				var any = '.' + $mdgriffith$elm_ui$Internal$Style$classes.fb;
				return $elm$core$List$concat(
					_List_fromArray(
						[
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (row + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (wrappedRow + (' > ' + any)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin', halfY + (' ' + halfX))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (column + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_Utils_ap(_class, paragraph),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							'textarea' + (any + _class),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)')),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'height',
									'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::after'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-top',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::before'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-bottom',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								]))
						]));
			case 7:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'padding',
							$elm$core$String$fromFloat(top) + ('px ' + ($elm$core$String$fromFloat(right) + ('px ' + ($elm$core$String$fromFloat(bottom) + ('px ' + ($elm$core$String$fromFloat(left) + 'px')))))))
						]));
			case 6:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'border-width',
							$elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px')))))))
						]));
			case 8:
				var template = rule.a;
				var toGridLengthHelper = F3(
					function (minimum, maximum, x) {
						toGridLengthHelper:
						while (true) {
							switch (x.$) {
								case 0:
									var px = x.a;
									return $elm$core$String$fromInt(px) + 'px';
								case 1:
									var _v2 = _Utils_Tuple2(minimum, maximum);
									if (_v2.a.$ === 1) {
										if (_v2.b.$ === 1) {
											var _v3 = _v2.a;
											var _v4 = _v2.b;
											return 'max-content';
										} else {
											var _v6 = _v2.a;
											var maxSize = _v2.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v2.b.$ === 1) {
											var minSize = _v2.a.a;
											var _v5 = _v2.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + 'max-content)'));
										} else {
											var minSize = _v2.a.a;
											var maxSize = _v2.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 2:
									var i = x.a;
									var _v7 = _Utils_Tuple2(minimum, maximum);
									if (_v7.a.$ === 1) {
										if (_v7.b.$ === 1) {
											var _v8 = _v7.a;
											var _v9 = _v7.b;
											return $elm$core$String$fromInt(i) + 'fr';
										} else {
											var _v11 = _v7.a;
											var maxSize = _v7.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v7.b.$ === 1) {
											var minSize = _v7.a.a;
											var _v10 = _v7.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(i) + ('fr' + 'fr)'))));
										} else {
											var minSize = _v7.a.a;
											var maxSize = _v7.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 3:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = $elm$core$Maybe$Just(m),
										$temp$maximum = maximum,
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
								default:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = minimum,
										$temp$maximum = $elm$core$Maybe$Just(m),
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
							}
						}
					});
				var toGridLength = function (x) {
					return A3(toGridLengthHelper, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, x);
				};
				var xSpacing = toGridLength(template.g9.a);
				var ySpacing = toGridLength(template.g9.b);
				var rows = function (x) {
					return 'grid-template-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.gW)));
				var msRows = function (x) {
					return '-ms-grid-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.fy)));
				var msColumns = function (x) {
					return '-ms-grid-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.fy)));
				var gapY = 'grid-row-gap:' + (toGridLength(template.g9.b) + ';');
				var gapX = 'grid-column-gap:' + (toGridLength(template.g9.a) + ';');
				var columns = function (x) {
					return 'grid-template-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.fy)));
				var _class = '.grid-rows-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.gW)) + ('-cols-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.fy)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.g9.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.g9.b)))))));
				var modernGrid = _class + ('{' + (columns + (rows + (gapX + (gapY + '}')))));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msColumns + (msRows + '}')));
				return _List_fromArray(
					[base, supports]);
			case 9:
				var position = rule.a;
				var msPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'-ms-grid-row: ' + ($elm$core$String$fromInt(position.am) + ';'),
							'-ms-grid-row-span: ' + ($elm$core$String$fromInt(position.av) + ';'),
							'-ms-grid-column: ' + ($elm$core$String$fromInt(position.fx) + ';'),
							'-ms-grid-column-span: ' + ($elm$core$String$fromInt(position.ab) + ';')
						]));
				var modernPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'grid-row: ' + ($elm$core$String$fromInt(position.am) + (' / ' + ($elm$core$String$fromInt(position.am + position.av) + ';'))),
							'grid-column: ' + ($elm$core$String$fromInt(position.fx) + (' / ' + ($elm$core$String$fromInt(position.fx + position.ab) + ';')))
						]));
				var _class = '.grid-pos-' + ($elm$core$String$fromInt(position.am) + ('-' + ($elm$core$String$fromInt(position.fx) + ('-' + ($elm$core$String$fromInt(position.ab) + ('-' + $elm$core$String$fromInt(position.av)))))));
				var modernGrid = _class + ('{' + (modernPosition + '}'));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msPosition + '}'));
				return _List_fromArray(
					[base, supports]);
			case 11:
				var _class = rule.a;
				var styles = rule.b;
				var renderPseudoRule = function (style) {
					return A3(
						$mdgriffith$elm_ui$Internal$Model$renderStyleRule,
						options,
						style,
						$elm$core$Maybe$Just(_class));
				};
				return A2($elm$core$List$concatMap, renderPseudoRule, styles);
			default:
				var transform = rule.a;
				var val = $mdgriffith$elm_ui$Internal$Model$transformValue(transform);
				var _class = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				var _v12 = _Utils_Tuple2(_class, val);
				if ((!_v12.a.$) && (!_v12.b.$)) {
					var cls = _v12.a.a;
					var v = _v12.b.a;
					return A4(
						$mdgriffith$elm_ui$Internal$Model$renderStyle,
						options,
						maybePseudo,
						'.' + cls,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Model$Property, 'transform', v)
							]));
				} else {
					return _List_Nil;
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$encodeStyles = F2(
	function (options, stylesheet) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$map,
				function (style) {
					var styled = A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing);
					return _Utils_Tuple2(
						$mdgriffith$elm_ui$Internal$Model$getStyleName(style),
						A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, styled));
				},
				stylesheet));
	});
var $mdgriffith$elm_ui$Internal$Model$bracket = F2(
	function (selector, rules) {
		var renderPair = function (_v0) {
			var name = _v0.a;
			var val = _v0.b;
			return name + (': ' + (val + ';'));
		};
		return selector + (' {' + (A2(
			$elm$core$String$join,
			'',
			A2($elm$core$List$map, renderPair, rules)) + '}'));
	});
var $mdgriffith$elm_ui$Internal$Model$fontRule = F3(
	function (name, modifier, _v0) {
		var parentAdj = _v0.a;
		var textAdjustment = _v0.b;
		return _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + (', ' + ('.' + (name + (' .' + modifier))))))), parentAdj),
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.dn + (', .' + (name + (' .' + (modifier + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.dn)))))))))), textAdjustment)
			]);
	});
var $mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule = F3(
	function (fontToAdjust, _v0, otherFontName) {
		var full = _v0.a;
		var capital = _v0.b;
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_Utils_ap(
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.g7, capital),
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.f_, full)));
	});
var $mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule = F2(
	function (fontToAdjust, otherFontName) {
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.g7 + (', ' + ('.' + (name + (' .' + $mdgriffith$elm_ui$Internal$Style$classes.g7))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('line-height', '1')
						])),
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.g7 + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.dn + (', .' + (name + (' .' + ($mdgriffith$elm_ui$Internal$Style$classes.g7 + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.dn)))))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('vertical-align', '0'),
							_Utils_Tuple2('line-height', '1')
						]))
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$adjust = F3(
	function (size, height, vertical) {
		return {av: height / size, cB: size, eR: vertical};
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$convertAdjustment = function (adjustment) {
	var lines = _List_fromArray(
		[adjustment.fr, adjustment.fe, adjustment.fM, adjustment.gm]);
	var lineHeight = 1.5;
	var normalDescender = (lineHeight - 1) / 2;
	var oldMiddle = lineHeight / 2;
	var descender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.fM,
		$elm$core$List$minimum(lines));
	var newBaseline = A2(
		$elm$core$Maybe$withDefault,
		adjustment.fe,
		$elm$core$List$minimum(
			A2(
				$elm$core$List$filter,
				function (x) {
					return !_Utils_eq(x, descender);
				},
				lines)));
	var base = lineHeight;
	var ascender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.fr,
		$elm$core$List$maximum(lines));
	var capitalSize = 1 / (ascender - newBaseline);
	var capitalVertical = 1 - ascender;
	var fullSize = 1 / (ascender - descender);
	var fullVertical = 1 - ascender;
	var newCapitalMiddle = ((ascender - newBaseline) / 2) + newBaseline;
	var newFullMiddle = ((ascender - descender) / 2) + descender;
	return {
		fr: A3($mdgriffith$elm_ui$Internal$Model$adjust, capitalSize, ascender - newBaseline, capitalVertical),
		dS: A3($mdgriffith$elm_ui$Internal$Model$adjust, fullSize, ascender - descender, fullVertical)
	};
};
var $mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules = function (converted) {
	return _Utils_Tuple2(
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'block')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'inline-block'),
				_Utils_Tuple2(
				'line-height',
				$elm$core$String$fromFloat(converted.av)),
				_Utils_Tuple2(
				'vertical-align',
				$elm$core$String$fromFloat(converted.eR) + 'em'),
				_Utils_Tuple2(
				'font-size',
				$elm$core$String$fromFloat(converted.cB) + 'em')
			]));
};
var $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment = function (typefaces) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (face, found) {
				if (found.$ === 1) {
					if (face.$ === 5) {
						var _with = face.a;
						var _v2 = _with.e0;
						if (_v2.$ === 1) {
							return found;
						} else {
							var adjustment = _v2.a;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.dS;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment))),
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.fr;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment)))));
						}
					} else {
						return found;
					}
				} else {
					return found;
				}
			}),
		$elm$core$Maybe$Nothing,
		typefaces);
};
var $mdgriffith$elm_ui$Internal$Model$renderTopLevelValues = function (rules) {
	var withImport = function (font) {
		if (font.$ === 4) {
			var url = font.b;
			return $elm$core$Maybe$Just('@import url(\'' + (url + '\');'));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var fontImports = function (_v2) {
		var name = _v2.a;
		var typefaces = _v2.b;
		var imports = A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$filterMap, withImport, typefaces));
		return imports;
	};
	var allNames = A2($elm$core$List$map, $elm$core$Tuple$first, rules);
	var fontAdjustments = function (_v1) {
		var name = _v1.a;
		var typefaces = _v1.b;
		var _v0 = $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment(typefaces);
		if (_v0.$ === 1) {
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule(name),
					allNames));
		} else {
			var adjustment = _v0.a;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					A2($mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule, name, adjustment),
					allNames));
		}
	};
	return _Utils_ap(
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontImports, rules)),
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontAdjustments, rules)));
};
var $mdgriffith$elm_ui$Internal$Model$topLevelValue = function (rule) {
	if (rule.$ === 1) {
		var name = rule.a;
		var typefaces = rule.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(name, typefaces));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$toStyleSheetString = F2(
	function (options, stylesheet) {
		var combine = F2(
			function (style, rendered) {
				return {
					cz: _Utils_ap(
						rendered.cz,
						A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing)),
					b_: function () {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$topLevelValue(style);
						if (_v1.$ === 1) {
							return rendered.b_;
						} else {
							var topLevel = _v1.a;
							return A2($elm$core$List$cons, topLevel, rendered.b_);
						}
					}()
				};
			});
		var _v0 = A3(
			$elm$core$List$foldl,
			combine,
			{cz: _List_Nil, b_: _List_Nil},
			stylesheet);
		var topLevel = _v0.b_;
		var rules = _v0.cz;
		return _Utils_ap(
			$mdgriffith$elm_ui$Internal$Model$renderTopLevelValues(topLevel),
			$elm$core$String$concat(rules));
	});
var $mdgriffith$elm_ui$Internal$Model$toStyleSheet = F2(
	function (options, styleSheet) {
		var _v0 = options.o;
		switch (_v0) {
			case 0:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			case 1:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			default:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'elm-ui-rules',
					_List_fromArray(
						[
							A2(
							$elm$virtual_dom$VirtualDom$property,
							'rules',
							A2($mdgriffith$elm_ui$Internal$Model$encodeStyles, options, styleSheet))
						]),
					_List_Nil);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$embedKeyed = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.fY)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'static-stylesheet',
				$mdgriffith$elm_ui$Internal$Model$staticRoot(opts)),
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
				children)) : A2(
			$elm$core$List$cons,
			_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
			children);
	});
var $mdgriffith$elm_ui$Internal$Model$embedWith = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.fY)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Internal$Model$staticRoot(opts),
			A2($elm$core$List$cons, dynamicStyleSheet, children)) : A2($elm$core$List$cons, dynamicStyleSheet, children);
	});
var $mdgriffith$elm_ui$Internal$Flag$heightBetween = $mdgriffith$elm_ui$Internal$Flag$flag(45);
var $mdgriffith$elm_ui$Internal$Flag$heightFill = $mdgriffith$elm_ui$Internal$Flag$flag(37);
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $mdgriffith$elm_ui$Internal$Flag$present = F2(
	function (myFlag, _v0) {
		var fieldOne = _v0.a;
		var fieldTwo = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return _Utils_eq(first & fieldOne, first);
		} else {
			var second = myFlag.a;
			return _Utils_eq(second & fieldTwo, second);
		}
	});
var $elm$html$Html$s = _VirtualDom_node('s');
var $elm$html$Html$u = _VirtualDom_node('u');
var $mdgriffith$elm_ui$Internal$Flag$widthBetween = $mdgriffith$elm_ui$Internal$Flag$flag(44);
var $mdgriffith$elm_ui$Internal$Flag$widthFill = $mdgriffith$elm_ui$Internal$Flag$flag(39);
var $mdgriffith$elm_ui$Internal$Model$finalizeNode = F6(
	function (has, node, attributes, children, embedMode, parentContext) {
		var createNode = F2(
			function (nodeName, attrs) {
				if (children.$ === 1) {
					var keyed = children.a;
					return A3(
						$elm$virtual_dom$VirtualDom$keyedNode,
						nodeName,
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return keyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, false, opts, styles, keyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, true, opts, styles, keyed);
							}
						}());
				} else {
					var unkeyed = children.a;
					return A2(
						function () {
							switch (nodeName) {
								case 'div':
									return $elm$html$Html$div;
								case 'p':
									return $elm$html$Html$p;
								default:
									return $elm$virtual_dom$VirtualDom$node(nodeName);
							}
						}(),
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return unkeyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, false, opts, styles, unkeyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, true, opts, styles, unkeyed);
							}
						}());
				}
			});
		var html = function () {
			switch (node.$) {
				case 0:
					return A2(createNode, 'div', attributes);
				case 1:
					var nodeName = node.a;
					return A2(createNode, nodeName, attributes);
				default:
					var nodeName = node.a;
					var internal = node.b;
					return A3(
						$elm$virtual_dom$VirtualDom$node,
						nodeName,
						attributes,
						_List_fromArray(
							[
								A2(
								createNode,
								internal,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.fb + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.g5))
									]))
							]));
			}
		}();
		switch (parentContext) {
			case 0:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignRight, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.fb, $mdgriffith$elm_ui$Internal$Style$classes.g5, $mdgriffith$elm_ui$Internal$Style$classes.cb, $mdgriffith$elm_ui$Internal$Style$classes.aI, $mdgriffith$elm_ui$Internal$Style$classes.e7])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerX, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.fb, $mdgriffith$elm_ui$Internal$Style$classes.g5, $mdgriffith$elm_ui$Internal$Style$classes.cb, $mdgriffith$elm_ui$Internal$Style$classes.aI, $mdgriffith$elm_ui$Internal$Style$classes.e5])))
						]),
					_List_fromArray(
						[html])) : html));
			case 1:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerY, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.fb, $mdgriffith$elm_ui$Internal$Style$classes.g5, $mdgriffith$elm_ui$Internal$Style$classes.cb, $mdgriffith$elm_ui$Internal$Style$classes.e6])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignBottom, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.fb, $mdgriffith$elm_ui$Internal$Style$classes.g5, $mdgriffith$elm_ui$Internal$Style$classes.cb, $mdgriffith$elm_ui$Internal$Style$classes.e4])))
						]),
					_List_fromArray(
						[html])) : html));
			default:
				return html;
		}
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $mdgriffith$elm_ui$Internal$Model$textElementClasses = $mdgriffith$elm_ui$Internal$Style$classes.fb + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.dn + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.dq + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.c5)))));
var $mdgriffith$elm_ui$Internal$Model$textElement = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$textElementFillClasses = $mdgriffith$elm_ui$Internal$Style$classes.fb + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.dn + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.dr + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.c6)))));
var $mdgriffith$elm_ui$Internal$Model$textElementFill = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementFillClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$createElement = F3(
	function (context, children, rendered) {
		var gatherKeyed = F2(
			function (_v8, _v9) {
				var key = _v8.a;
				var child = _v8.b;
				var htmls = _v9.a;
				var existingStyles = _v9.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.f5, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.eL : _Utils_ap(styled.eL, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.f5, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.eL : _Utils_ap(styled.eL, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str)),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		var gather = F2(
			function (child, _v6) {
				var htmls = _v6.a;
				var existingStyles = _v6.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.f5, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.eL : _Utils_ap(styled.eL, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.f5, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.eL : _Utils_ap(styled.eL, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		if (children.$ === 1) {
			var keyedChildren = children.a;
			var _v1 = A3(
				$elm$core$List$foldr,
				gatherKeyed,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				keyedChildren);
			var keyed = _v1.a;
			var styles = _v1.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.eL : _Utils_ap(rendered.eL, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.a4,
						rendered.eg,
						rendered.a0,
						$mdgriffith$elm_ui$Internal$Model$Keyed(
							A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.ft)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						f5: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.a4,
							rendered.eg,
							rendered.a0,
							$mdgriffith$elm_ui$Internal$Model$Keyed(
								A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.ft))),
						eL: allStyles
					});
			}
		} else {
			var unkeyedChildren = children.a;
			var _v3 = A3(
				$elm$core$List$foldr,
				gather,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				unkeyedChildren);
			var unkeyed = _v3.a;
			var styles = _v3.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.eL : _Utils_ap(rendered.eL, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.a4,
						rendered.eg,
						rendered.a0,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.ft)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						f5: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.a4,
							rendered.eg,
							rendered.a0,
							$mdgriffith$elm_ui$Internal$Model$Unkeyed(
								A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.ft))),
						eL: allStyles
					});
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Single = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$Transform = function (a) {
	return {$: 10, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $mdgriffith$elm_ui$Internal$Flag$add = F2(
	function (myFlag, _v0) {
		var one = _v0.a;
		var two = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, first | one, two);
		} else {
			var second = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, one, second | two);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehind = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenInFront = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$nearbyElement = F2(
	function (location, elem) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					function () {
						switch (location) {
							case 0:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.bv, $mdgriffith$elm_ui$Internal$Style$classes.g5, $mdgriffith$elm_ui$Internal$Style$classes.e_]));
							case 1:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.bv, $mdgriffith$elm_ui$Internal$Style$classes.g5, $mdgriffith$elm_ui$Internal$Style$classes.fg]));
							case 2:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.bv, $mdgriffith$elm_ui$Internal$Style$classes.g5, $mdgriffith$elm_ui$Internal$Style$classes.gF]));
							case 3:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.bv, $mdgriffith$elm_ui$Internal$Style$classes.g5, $mdgriffith$elm_ui$Internal$Style$classes.gE]));
							case 4:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.bv, $mdgriffith$elm_ui$Internal$Style$classes.g5, $mdgriffith$elm_ui$Internal$Style$classes.gb]));
							default:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.bv, $mdgriffith$elm_ui$Internal$Style$classes.g5, $mdgriffith$elm_ui$Internal$Style$classes.ff]));
						}
					}())
				]),
			_List_fromArray(
				[
					function () {
					switch (elem.$) {
						case 3:
							return $elm$virtual_dom$VirtualDom$text('');
						case 2:
							var str = elem.a;
							return $mdgriffith$elm_ui$Internal$Model$textElement(str);
						case 0:
							var html = elem.a;
							return html($mdgriffith$elm_ui$Internal$Model$asEl);
						default:
							var styled = elem.a;
							return A2(styled.f5, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, $mdgriffith$elm_ui$Internal$Model$asEl);
					}
				}()
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$addNearbyElement = F3(
	function (location, elem, existing) {
		var nearby = A2($mdgriffith$elm_ui$Internal$Model$nearbyElement, location, elem);
		switch (existing.$) {
			case 0:
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						_List_fromArray(
							[nearby]));
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						_List_fromArray(
							[nearby]));
				}
			case 1:
				var existingBehind = existing.a;
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						A2($elm$core$List$cons, nearby, existingBehind));
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						_List_fromArray(
							[nearby]));
				}
			case 2:
				var existingInFront = existing.a;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						_List_fromArray(
							[nearby]),
						existingInFront);
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						A2($elm$core$List$cons, nearby, existingInFront));
				}
			default:
				var existingBehind = existing.a;
				var existingInFront = existing.b;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						A2($elm$core$List$cons, nearby, existingBehind),
						existingInFront);
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						A2($elm$core$List$cons, nearby, existingInFront));
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Embedded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$NodeName = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addNodeName = F2(
	function (newNode, old) {
		switch (old.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NodeName(newNode);
			case 1:
				var name = old.a;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, name, newNode);
			default:
				var x = old.a;
				var y = old.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$alignXName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.cP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dv);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.cP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dw);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.cP + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.e2);
	}
};
var $mdgriffith$elm_ui$Internal$Model$alignYName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.cQ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.e8);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.cQ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.e1);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.cQ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.e3);
	}
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $mdgriffith$elm_ui$Internal$Model$FullTransform = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Internal$Model$Moved = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$composeTransformation = F2(
	function (transform, component) {
		switch (transform.$) {
			case 0:
				switch (component.$) {
					case 0:
						var x = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, 0, 0));
					case 1:
						var y = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, y, 0));
					case 2:
						var z = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, 0, z));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var xyz = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							xyz,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			case 1:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(newX, y, z));
					case 1:
						var newY = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, newY, z));
					case 2:
						var newZ = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, y, newZ));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var scale = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							scale,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			default:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				var scaled = transform.b;
				var origin = transform.c;
				var angle = transform.d;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(newX, y, z),
							scaled,
							origin,
							angle);
					case 1:
						var newY = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, newY, z),
							scaled,
							origin,
							angle);
					case 2:
						var newZ = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, y, newZ),
							scaled,
							origin,
							angle);
					case 3:
						var newMove = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, newMove, scaled, origin, angle);
					case 4:
						var newOrigin = component.a;
						var newAngle = component.b;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, scaled, newOrigin, newAngle);
					default:
						var newScale = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, newScale, origin, angle);
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$height = $mdgriffith$elm_ui$Internal$Flag$flag(7);
var $mdgriffith$elm_ui$Internal$Flag$heightContent = $mdgriffith$elm_ui$Internal$Flag$flag(36);
var $mdgriffith$elm_ui$Internal$Flag$merge = F2(
	function (_v0, _v1) {
		var one = _v0.a;
		var two = _v0.b;
		var three = _v1.a;
		var four = _v1.b;
		return A2($mdgriffith$elm_ui$Internal$Flag$Field, one | three, two | four);
	});
var $mdgriffith$elm_ui$Internal$Flag$none = A2($mdgriffith$elm_ui$Internal$Flag$Field, 0, 0);
var $mdgriffith$elm_ui$Internal$Model$renderHeight = function (h) {
	switch (h.$) {
		case 0:
			var px = h.a;
			var val = $elm$core$String$fromInt(px);
			var name = 'height-px-' + val;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.dV + (' ' + name),
				_List_fromArray(
					[
						A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height', val + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.c5,
				_List_Nil);
		case 2:
			var portion = h.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.c6,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.dW + (' height-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.fb + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.aG + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'height-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = h.a;
			var len = h.b;
			var cls = 'min-height-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-height',
				$elm$core$String$fromInt(minSize) + 'px !important');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = h.a;
			var len = h.b;
			var cls = 'max-height-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-height',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$widthContent = $mdgriffith$elm_ui$Internal$Flag$flag(38);
var $mdgriffith$elm_ui$Internal$Model$renderWidth = function (w) {
	switch (w.$) {
		case 0:
			var px = w.a;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.eV + (' width-px-' + $elm$core$String$fromInt(px)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						'width-px-' + $elm$core$String$fromInt(px),
						'width',
						$elm$core$String$fromInt(px) + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.dq,
				_List_Nil);
		case 2:
			var portion = w.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.dr,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.eW + (' width-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.fb + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.am + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'width-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = w.a;
			var len = w.b;
			var cls = 'min-width-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-width',
				$elm$core$String$fromInt(minSize) + 'px');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = w.a;
			var len = w.b;
			var cls = 'max-width-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-width',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$borderWidth = $mdgriffith$elm_ui$Internal$Flag$flag(27);
var $mdgriffith$elm_ui$Internal$Model$skippable = F2(
	function (flag, style) {
		if (_Utils_eq(flag, $mdgriffith$elm_ui$Internal$Flag$borderWidth)) {
			if (style.$ === 3) {
				var val = style.c;
				switch (val) {
					case '0px':
						return true;
					case '1px':
						return true;
					case '2px':
						return true;
					case '3px':
						return true;
					case '4px':
						return true;
					case '5px':
						return true;
					case '6px':
						return true;
					default:
						return false;
				}
			} else {
				return false;
			}
		} else {
			switch (style.$) {
				case 2:
					var i = style.a;
					return (i >= 8) && (i <= 32);
				case 7:
					var name = style.a;
					var t = style.b;
					var r = style.c;
					var b = style.d;
					var l = style.e;
					return _Utils_eq(t, b) && (_Utils_eq(t, r) && (_Utils_eq(t, l) && ((t >= 0) && (t <= 24))));
				default:
					return false;
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$width = $mdgriffith$elm_ui$Internal$Flag$flag(6);
var $mdgriffith$elm_ui$Internal$Flag$xAlign = $mdgriffith$elm_ui$Internal$Flag$flag(30);
var $mdgriffith$elm_ui$Internal$Flag$yAlign = $mdgriffith$elm_ui$Internal$Flag$flag(29);
var $mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive = F8(
	function (classes, node, has, transform, styles, attrs, children, elementAttrs) {
		gatherAttrRecursive:
		while (true) {
			if (!elementAttrs.b) {
				var _v1 = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				if (_v1.$ === 1) {
					return {
						a0: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes),
							attrs),
						ft: children,
						a4: has,
						eg: node,
						eL: styles
					};
				} else {
					var _class = _v1.a;
					return {
						a0: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes + (' ' + _class)),
							attrs),
						ft: children,
						a4: has,
						eg: node,
						eL: A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$Transform(transform),
							styles)
					};
				}
			} else {
				var attribute = elementAttrs.a;
				var remaining = elementAttrs.b;
				switch (attribute.$) {
					case 0:
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 3:
						var flag = attribute.a;
						var exactClassName = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = exactClassName + (' ' + classes),
								$temp$node = node,
								$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					case 1:
						var actualAttribute = attribute.a;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = A2($elm$core$List$cons, actualAttribute, attrs),
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 4:
						var flag = attribute.a;
						var style = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							if (A2($mdgriffith$elm_ui$Internal$Model$skippable, flag, style)) {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							} else {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = A2($elm$core$List$cons, style, styles),
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							}
						}
					case 10:
						var flag = attribute.a;
						var component = attribute.b;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
							$temp$transform = A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, transform, component),
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 7:
						var width = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$width, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (width.$) {
								case 0:
									var px = width.a;
									var $temp$classes = ($mdgriffith$elm_ui$Internal$Style$classes.eV + (' width-px-' + $elm$core$String$fromInt(px))) + (' ' + classes),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3(
											$mdgriffith$elm_ui$Internal$Model$Single,
											'width-px-' + $elm$core$String$fromInt(px),
											'width',
											$elm$core$String$fromInt(px) + 'px'),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dq),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$widthContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = width.a;
									if (portion === 1) {
										var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dr),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.eW + (' width-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.fb + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.am + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'width-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v4 = $mdgriffith$elm_ui$Internal$Model$renderWidth(width);
									var addToFlags = _v4.a;
									var newClass = _v4.b;
									var newStyles = _v4.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 8:
						var height = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$height, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (height.$) {
								case 0:
									var px = height.a;
									var val = $elm$core$String$fromInt(px) + 'px';
									var name = 'height-px-' + val;
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.dV + (' ' + (name + (' ' + classes))),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height ', val),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.c5 + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$heightContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = height.a;
									if (portion === 1) {
										var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.c6 + (' ' + classes),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.dW + (' height-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.fb + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.aG + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'height-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v6 = $mdgriffith$elm_ui$Internal$Model$renderHeight(height);
									var addToFlags = _v6.a;
									var newClass = _v6.b;
									var newStyles = _v6.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 2:
						var description = attribute.a;
						switch (description.$) {
							case 0:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'main', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 1:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'nav', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 2:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'footer', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 3:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'aside', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 4:
								var i = description.a;
								if (i <= 1) {
									var $temp$classes = classes,
										$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h1', node),
										$temp$has = has,
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								} else {
									if (i < 7) {
										var $temp$classes = classes,
											$temp$node = A2(
											$mdgriffith$elm_ui$Internal$Model$addNodeName,
											'h' + $elm$core$String$fromInt(i),
											node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes,
											$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h6', node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								}
							case 9:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 8:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'role', 'button'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 5:
								var label = description.a;
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-label', label),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 6:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'polite'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							default:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'assertive'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
						}
					case 9:
						var location = attribute.a;
						var elem = attribute.b;
						var newStyles = function () {
							switch (elem.$) {
								case 3:
									return styles;
								case 2:
									var str = elem.a;
									return styles;
								case 0:
									var html = elem.a;
									return styles;
								default:
									var styled = elem.a;
									return _Utils_ap(styles, styled.eL);
							}
						}();
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = newStyles,
							$temp$attrs = attrs,
							$temp$children = A3($mdgriffith$elm_ui$Internal$Model$addNearbyElement, location, elem, children),
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 6:
						var x = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignXName(x) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (x) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerX, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignRight, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					default:
						var y = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignYName(y) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (y) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerY, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignBottom, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Untransformed = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$untransformed = $mdgriffith$elm_ui$Internal$Model$Untransformed;
var $mdgriffith$elm_ui$Internal$Model$element = F4(
	function (context, node, attributes, children) {
		return A3(
			$mdgriffith$elm_ui$Internal$Model$createElement,
			context,
			children,
			A8(
				$mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive,
				$mdgriffith$elm_ui$Internal$Model$contextClasses(context),
				node,
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Model$untransformed,
				_List_Nil,
				_List_Nil,
				$mdgriffith$elm_ui$Internal$Model$NoNearbyChildren,
				$elm$core$List$reverse(attributes)));
	});
var $mdgriffith$elm_ui$Internal$Model$Height = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Element$height = $mdgriffith$elm_ui$Internal$Model$Height;
var $mdgriffith$elm_ui$Internal$Model$Attr = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$htmlClass = function (cls) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		$elm$html$Html$Attributes$class(cls));
};
var $mdgriffith$elm_ui$Internal$Model$Content = {$: 1};
var $mdgriffith$elm_ui$Element$shrink = $mdgriffith$elm_ui$Internal$Model$Content;
var $mdgriffith$elm_ui$Internal$Model$Width = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_ui$Element$width = $mdgriffith$elm_ui$Internal$Model$Width;
var $mdgriffith$elm_ui$Element$column = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.fA + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.bJ)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$el = F2(
	function (attrs, child) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[child])));
	});
var $mdgriffith$elm_ui$Internal$Model$ImportFont = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$Font$external = function (_v0) {
	var url = _v0.ap;
	var name = _v0.ed;
	return A2($mdgriffith$elm_ui$Internal$Model$ImportFont, name, url);
};
var $mdgriffith$elm_ui$Internal$Model$FontFamily = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$StyleClass = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$fontFamily = $mdgriffith$elm_ui$Internal$Flag$flag(5);
var $elm$core$String$toLower = _String_toLower;
var $elm$core$String$words = _String_words;
var $mdgriffith$elm_ui$Internal$Model$renderFontClassName = F2(
	function (font, current) {
		return _Utils_ap(
			current,
			function () {
				switch (font.$) {
					case 0:
						return 'serif';
					case 1:
						return 'sans-serif';
					case 2:
						return 'monospace';
					case 3:
						var name = font.a;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					case 4:
						var name = font.a;
						var url = font.b;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					default:
						var name = font.a.ed;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
				}
			}());
	});
var $mdgriffith$elm_ui$Element$Font$family = function (families) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontFamily,
		A2(
			$mdgriffith$elm_ui$Internal$Model$FontFamily,
			A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'ff-', families),
			families));
};
var $mdgriffith$elm_ui$Internal$Model$Fill = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$fill = $mdgriffith$elm_ui$Internal$Model$Fill(1);
var $author$project$Style$fillSpace = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
	]);
var $mdgriffith$elm_ui$Internal$Model$InFront = 4;
var $mdgriffith$elm_ui$Internal$Model$Nearby = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$NoAttribute = {$: 0};
var $mdgriffith$elm_ui$Element$createNearby = F2(
	function (loc, element) {
		if (element.$ === 3) {
			return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
		} else {
			return A2($mdgriffith$elm_ui$Internal$Model$Nearby, loc, element);
		}
	});
var $mdgriffith$elm_ui$Element$inFront = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 4, element);
};
var $mdgriffith$elm_ui$Internal$Model$OnlyDynamic = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$AllowHover = 1;
var $mdgriffith$elm_ui$Internal$Model$Layout = 0;
var $mdgriffith$elm_ui$Internal$Model$Rgba = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle = {
	fd: $elm$core$Maybe$Nothing,
	fj: $elm$core$Maybe$Nothing,
	g2: $elm$core$Maybe$Just(
		{
			bo: 0,
			bq: A4($mdgriffith$elm_ui$Internal$Model$Rgba, 155 / 255, 203 / 255, 1, 1),
			cn: _Utils_Tuple2(0, 0),
			cB: 3
		})
};
var $mdgriffith$elm_ui$Internal$Model$optionsToRecord = function (options) {
	var combine = F2(
		function (opt, record) {
			switch (opt.$) {
				case 0:
					var hoverable = opt.a;
					var _v4 = record.f4;
					if (_v4.$ === 1) {
						return _Utils_update(
							record,
							{
								f4: $elm$core$Maybe$Just(hoverable)
							});
					} else {
						return record;
					}
				case 1:
					var focusStyle = opt.a;
					var _v5 = record.fY;
					if (_v5.$ === 1) {
						return _Utils_update(
							record,
							{
								fY: $elm$core$Maybe$Just(focusStyle)
							});
					} else {
						return record;
					}
				default:
					var renderMode = opt.a;
					var _v6 = record.o;
					if (_v6.$ === 1) {
						return _Utils_update(
							record,
							{
								o: $elm$core$Maybe$Just(renderMode)
							});
					} else {
						return record;
					}
			}
		});
	var andFinally = function (record) {
		return {
			fY: function () {
				var _v0 = record.fY;
				if (_v0.$ === 1) {
					return $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle;
				} else {
					var focusable = _v0.a;
					return focusable;
				}
			}(),
			f4: function () {
				var _v1 = record.f4;
				if (_v1.$ === 1) {
					return 1;
				} else {
					var hoverable = _v1.a;
					return hoverable;
				}
			}(),
			o: function () {
				var _v2 = record.o;
				if (_v2.$ === 1) {
					return 0;
				} else {
					var actualMode = _v2.a;
					return actualMode;
				}
			}()
		};
	};
	return andFinally(
		A3(
			$elm$core$List$foldr,
			combine,
			{fY: $elm$core$Maybe$Nothing, f4: $elm$core$Maybe$Nothing, o: $elm$core$Maybe$Nothing},
			options));
};
var $mdgriffith$elm_ui$Internal$Model$toHtml = F2(
	function (mode, el) {
		switch (el.$) {
			case 0:
				var html = el.a;
				return html($mdgriffith$elm_ui$Internal$Model$asEl);
			case 1:
				var styles = el.a.eL;
				var html = el.a.f5;
				return A2(
					html,
					mode(styles),
					$mdgriffith$elm_ui$Internal$Model$asEl);
			case 2:
				var text = el.a;
				return $mdgriffith$elm_ui$Internal$Model$textElement(text);
			default:
				return $mdgriffith$elm_ui$Internal$Model$textElement('');
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderRoot = F3(
	function (optionList, attributes, child) {
		var options = $mdgriffith$elm_ui$Internal$Model$optionsToRecord(optionList);
		var embedStyle = function () {
			var _v0 = options.o;
			if (_v0 === 1) {
				return $mdgriffith$elm_ui$Internal$Model$OnlyDynamic(options);
			} else {
				return $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic(options);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Internal$Model$toHtml,
			embedStyle,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				attributes,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[child]))));
	});
var $mdgriffith$elm_ui$Internal$Model$Colored = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$FontSize = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$SansSerif = {$: 1};
var $mdgriffith$elm_ui$Internal$Model$Typeface = function (a) {
	return {$: 3, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$bgColor = $mdgriffith$elm_ui$Internal$Flag$flag(8);
var $mdgriffith$elm_ui$Internal$Flag$fontColor = $mdgriffith$elm_ui$Internal$Flag$flag(14);
var $mdgriffith$elm_ui$Internal$Flag$fontSize = $mdgriffith$elm_ui$Internal$Flag$flag(4);
var $mdgriffith$elm_ui$Internal$Model$formatColorClass = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return $mdgriffith$elm_ui$Internal$Model$floatClass(red) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(green) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(blue) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(alpha))))));
};
var $mdgriffith$elm_ui$Internal$Model$rootStyle = function () {
	var families = _List_fromArray(
		[
			$mdgriffith$elm_ui$Internal$Model$Typeface('Open Sans'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Helvetica'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Verdana'),
			$mdgriffith$elm_ui$Internal$Model$SansSerif
		]);
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$bgColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0)),
				'background-color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1)),
				'color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontSize,
			$mdgriffith$elm_ui$Internal$Model$FontSize(20)),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontFamily,
			A2(
				$mdgriffith$elm_ui$Internal$Model$FontFamily,
				A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'font-', families),
				families))
		]);
}();
var $mdgriffith$elm_ui$Element$layoutWith = F3(
	function (_v0, attrs, child) {
		var options = _v0.aT;
		return A3(
			$mdgriffith$elm_ui$Internal$Model$renderRoot,
			options,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass(
					A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[$mdgriffith$elm_ui$Internal$Style$classes.gU, $mdgriffith$elm_ui$Internal$Style$classes.fb, $mdgriffith$elm_ui$Internal$Style$classes.g5]))),
				_Utils_ap($mdgriffith$elm_ui$Internal$Model$rootStyle, attrs)),
			child);
	});
var $mdgriffith$elm_ui$Element$layout = $mdgriffith$elm_ui$Element$layoutWith(
	{aT: _List_Nil});
var $mdgriffith$elm_ui$Element$Lazy$embed = function (x) {
	switch (x.$) {
		case 0:
			var html = x.a;
			return html;
		case 1:
			var styled = x.a;
			return styled.f5(
				A2(
					$mdgriffith$elm_ui$Internal$Model$OnlyDynamic,
					{
						fY: {fd: $elm$core$Maybe$Nothing, fj: $elm$core$Maybe$Nothing, g2: $elm$core$Maybe$Nothing},
						f4: 1,
						o: 0
					},
					styled.eL));
		case 2:
			var text = x.a;
			return $elm$core$Basics$always(
				$elm$virtual_dom$VirtualDom$text(text));
		default:
			return $elm$core$Basics$always(
				$elm$virtual_dom$VirtualDom$text(''));
	}
};
var $mdgriffith$elm_ui$Element$Lazy$apply2 = F3(
	function (fn, a, b) {
		return $mdgriffith$elm_ui$Element$Lazy$embed(
			A2(fn, a, b));
	});
var $elm$virtual_dom$VirtualDom$lazy4 = _VirtualDom_lazy4;
var $mdgriffith$elm_ui$Element$Lazy$lazy2 = F3(
	function (fn, a, b) {
		return $mdgriffith$elm_ui$Internal$Model$Unstyled(
			A4($elm$virtual_dom$VirtualDom$lazy4, $mdgriffith$elm_ui$Element$Lazy$apply2, fn, a, b));
	});
var $mdgriffith$elm_ui$Internal$Model$Monospace = {$: 2};
var $mdgriffith$elm_ui$Element$Font$monospace = $mdgriffith$elm_ui$Internal$Model$Monospace;
var $mdgriffith$elm_ui$Internal$Model$PaddingStyle = F5(
	function (a, b, c, d, e) {
		return {$: 7, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Flag$padding = $mdgriffith$elm_ui$Internal$Flag$flag(2);
var $mdgriffith$elm_ui$Element$padding = function (x) {
	var f = x;
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + $elm$core$String$fromInt(x),
			f,
			f,
			f,
			f));
};
var $mdgriffith$elm_ui$Internal$Model$Px = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$px = $mdgriffith$elm_ui$Internal$Model$Px;
var $author$project$Main$AddSpace = function (a) {
	return {$: 23, a: a};
};
var $author$project$Main$ButtonDown = function (a) {
	return {$: 31, a: a};
};
var $author$project$Main$ButtonUp = function (a) {
	return {$: 32, a: a};
};
var $author$project$Main$FaderChanging = F2(
	function (a, b) {
		return {$: 33, a: a, b: b};
	});
var $author$project$Main$FaderChangingMouse = F2(
	function (a, b) {
		return {$: 34, a: a, b: b};
	});
var $author$project$Main$FaderSet = function (a) {
	return {$: 35, a: a};
};
var $author$project$Main$OpenEditController = function (a) {
	return {$: 25, a: a};
};
var $author$project$Main$RemoveItem = function (a) {
	return {$: 24, a: a};
};
var $mdgriffith$elm_ui$Element$htmlAttribute = $mdgriffith$elm_ui$Internal$Model$Attr;
var $author$project$Utils$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $author$project$Utils$onContextMenu = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'contextmenu',
		A2(
			$elm$json$Json$Decode$map,
			$author$project$Utils$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $mdgriffith$elm_ui$Element$paddingXY = F2(
	function (x, y) {
		if (_Utils_eq(x, y)) {
			var f = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + $elm$core$String$fromInt(x),
					f,
					f,
					f,
					f));
		} else {
			var yFloat = y;
			var xFloat = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
					yFloat,
					xFloat,
					yFloat,
					xFloat));
		}
	});
var $author$project$Controller$EditContainer = 0;
var $mdgriffith$elm_ui$Internal$Model$Class = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$fontAlignment = $mdgriffith$elm_ui$Internal$Flag$flag(12);
var $mdgriffith$elm_ui$Element$Font$alignLeft = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.ho);
var $mdgriffith$elm_ui$Internal$Model$Right = 2;
var $mdgriffith$elm_ui$Element$alignRight = $mdgriffith$elm_ui$Internal$Model$AlignX(2);
var $mdgriffith$elm_ui$Element$rgb255 = F3(
	function (red, green, blue) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, red / 255, green / 255, blue / 255, 1);
	});
var $author$project$Style$appColourToRGB = function (appColour) {
	switch (appColour) {
		case 0:
			return A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255);
		case 1:
			return A3($mdgriffith$elm_ui$Element$rgb255, 200, 200, 200);
		case 2:
			return A3($mdgriffith$elm_ui$Element$rgb255, 86, 90, 94);
		case 3:
			return A3($mdgriffith$elm_ui$Element$rgb255, 0, 0, 0);
		case 4:
			return A3($mdgriffith$elm_ui$Element$rgb255, 0, 133, 86);
		case 5:
			return A3($mdgriffith$elm_ui$Element$rgb255, 35, 141, 193);
		case 6:
			return A3($mdgriffith$elm_ui$Element$rgb255, 255, 183, 27);
		default:
			return A3($mdgriffith$elm_ui$Element$rgb255, 202, 0, 61);
	}
};
var $mdgriffith$elm_ui$Element$Background$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$bgColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'background-color',
			clr));
};
var $author$project$Style$backgroundColour = function (appColour) {
	return $mdgriffith$elm_ui$Element$Background$color(
		$author$project$Style$appColourToRGB(appColour));
};
var $mdgriffith$elm_ui$Internal$Flag$borderColor = $mdgriffith$elm_ui$Internal$Flag$flag(28);
var $mdgriffith$elm_ui$Element$Border$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'border-color',
			clr));
};
var $author$project$Style$borderColour = function (appColour) {
	return $mdgriffith$elm_ui$Element$Border$color(
		$author$project$Style$appColourToRGB(appColour));
};
var $mdgriffith$elm_ui$Internal$Flag$overflow = $mdgriffith$elm_ui$Internal$Flag$flag(20);
var $mdgriffith$elm_ui$Element$clipX = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.fv);
var $mdgriffith$elm_ui$Internal$Flag$borderStyle = $mdgriffith$elm_ui$Internal$Flag$flag(11);
var $mdgriffith$elm_ui$Element$Border$dashed = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$borderStyle, $mdgriffith$elm_ui$Internal$Style$classes.fk);
var $mdgriffith$elm_ui$Element$Border$dotted = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$borderStyle, $mdgriffith$elm_ui$Internal$Style$classes.fl);
var $mdgriffith$elm_ui$Element$fillPortion = $mdgriffith$elm_ui$Internal$Model$Fill;
var $mdgriffith$elm_ui$Internal$Model$Empty = {$: 3};
var $mdgriffith$elm_ui$Element$none = $mdgriffith$elm_ui$Internal$Model$Empty;
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onClick = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onClick);
var $mdgriffith$elm_ui$Internal$Model$Describe = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Paragraph = {$: 9};
var $mdgriffith$elm_ui$Internal$Model$SpacingStyle = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Flag$spacing = $mdgriffith$elm_ui$Internal$Flag$flag(3);
var $mdgriffith$elm_ui$Internal$Model$spacingName = F2(
	function (x, y) {
		return 'spacing-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y)));
	});
var $mdgriffith$elm_ui$Element$spacing = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$spacing,
		A3(
			$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
			A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, x),
			x,
			x));
};
var $mdgriffith$elm_ui$Element$paragraph = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asParagraph,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Paragraph),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$spacing(5),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Internal$Model$Button = {$: 8};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $mdgriffith$elm_ui$Element$Input$enter = 'Enter';
var $mdgriffith$elm_ui$Element$Input$hasFocusStyle = function (attr) {
	if (((attr.$ === 4) && (attr.b.$ === 11)) && (!attr.b.a)) {
		var _v1 = attr.b;
		var _v2 = _v1.a;
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Element$Input$focusDefault = function (attrs) {
	return A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, attrs) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
};
var $mdgriffith$elm_ui$Element$Input$onKeyLookup = function (lookup) {
	var decode = function (code) {
		var _v0 = lookup(code);
		if (_v0.$ === 1) {
			return $elm$json$Json$Decode$fail('No key matched');
		} else {
			var msg = _v0.a;
			return $elm$json$Json$Decode$succeed(msg);
		}
	};
	var isKey = A2(
		$elm$json$Json$Decode$andThen,
		decode,
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		A2(
			$elm$html$Html$Events$preventDefaultOn,
			'keydown',
			A2(
				$elm$json$Json$Decode$map,
				function (fired) {
					return _Utils_Tuple2(fired, true);
				},
				isKey)));
};
var $mdgriffith$elm_ui$Internal$Flag$cursor = $mdgriffith$elm_ui$Internal$Flag$flag(21);
var $mdgriffith$elm_ui$Element$pointer = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.fE);
var $mdgriffith$elm_ui$Element$Input$space = ' ';
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $mdgriffith$elm_ui$Element$Input$button = F2(
	function (attrs, _v0) {
		var onPress = _v0.af;
		var label = _v0.d;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cd + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.aI + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.g$ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ef)))))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$pointer,
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Button),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											$elm$html$Html$Attributes$tabindex(0)),
										function () {
											if (onPress.$ === 1) {
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Internal$Model$Attr(
														$elm$html$Html$Attributes$disabled(true)),
													attrs);
											} else {
												var msg = onPress.a;
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Element$Events$onClick(msg),
													A2(
														$elm$core$List$cons,
														$mdgriffith$elm_ui$Element$Input$onKeyLookup(
															function (code) {
																return _Utils_eq(code, $mdgriffith$elm_ui$Element$Input$enter) ? $elm$core$Maybe$Just(msg) : (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space) ? $elm$core$Maybe$Just(msg) : $elm$core$Maybe$Nothing);
															}),
														attrs));
											}
										}()))))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $mdgriffith$elm_ui$Element$Font$size = function (i) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontSize,
		$mdgriffith$elm_ui$Internal$Model$FontSize(i));
};
var $author$project$Style$labelSizeToFontSize = function (labelSize) {
	switch (labelSize) {
		case 0:
			return $mdgriffith$elm_ui$Element$Font$size(14);
		case 1:
			return $mdgriffith$elm_ui$Element$Font$size(24);
		case 2:
			return $mdgriffith$elm_ui$Element$Font$size(32);
		default:
			return $mdgriffith$elm_ui$Element$Font$size(48);
	}
};
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Style$noSelect = A2(
	$elm$core$List$map,
	$mdgriffith$elm_ui$Element$htmlAttribute,
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, '-webkit-touch-callout', 'none'),
			A2($elm$html$Html$Attributes$style, '-webkit-user-select', 'none'),
			A2($elm$html$Html$Attributes$style, '-khtml-user-select', 'none'),
			A2($elm$html$Html$Attributes$style, '-moz-user-select', 'none'),
			A2($elm$html$Html$Attributes$style, '-ms-user-select', 'none'),
			A2($elm$html$Html$Attributes$style, 'user-select', 'none')
		]));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions = {de: true, dk: false};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$Event = F6(
	function (keys, button, clientPos, offsetPos, pagePos, screenPos) {
		return {fq: button, dG: clientPos, gl: keys, gC: offsetPos, gL: pagePos, gX: screenPos};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$BackButton = 4;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ErrorButton = 0;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ForwardButton = 5;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MainButton = 1;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MiddleButton = 2;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$SecondButton = 3;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonFromId = function (id) {
	switch (id) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		default:
			return 0;
	}
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonDecoder = A2(
	$elm$json$Json$Decode$map,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonFromId,
	A2($elm$json$Json$Decode$field, 'button', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $mpizenberg$elm_pointer_events$Internal$Decode$clientPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'clientX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'clientY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$Keys = F4(
	function (alt, ctrl, meta, shift) {
		return {e9: alt, fD: ctrl, go: meta, g3: shift};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $mpizenberg$elm_pointer_events$Internal$Decode$keys = A5(
	$elm$json$Json$Decode$map4,
	$mpizenberg$elm_pointer_events$Internal$Decode$Keys,
	A2($elm$json$Json$Decode$field, 'altKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'metaKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool));
var $elm$json$Json$Decode$map6 = _Json_map6;
var $mpizenberg$elm_pointer_events$Internal$Decode$offsetPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'offsetX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'offsetY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$pagePos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$screenPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'screenX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'screenY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder = A7($elm$json$Json$Decode$map6, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$Event, $mpizenberg$elm_pointer_events$Internal$Decode$keys, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonDecoder, $mpizenberg$elm_pointer_events$Internal$Decode$clientPos, $mpizenberg$elm_pointer_events$Internal$Decode$offsetPos, $mpizenberg$elm_pointer_events$Internal$Decode$pagePos, $mpizenberg$elm_pointer_events$Internal$Decode$screenPos);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions = F3(
	function (event, options, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (ev) {
					return {
						aO: tag(ev),
						de: options.de,
						dk: options.dk
					};
				},
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions, 'mousedown', $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$defaultOptions = {de: true, dk: false};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$Event = F4(
	function (keys, changedTouches, targetTouches, touches) {
		return {dD: changedTouches, gl: keys, hi: targetTouches, hD: touches};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$Touch = F4(
	function (identifier, clientPos, pagePos, screenPos) {
		return {dG: clientPos, f7: identifier, gL: pagePos, gX: screenPos};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchDecoder = A5(
	$elm$json$Json$Decode$map4,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$Touch,
	A2($elm$json$Json$Decode$field, 'identifier', $elm$json$Json$Decode$int),
	$mpizenberg$elm_pointer_events$Internal$Decode$clientPos,
	$mpizenberg$elm_pointer_events$Internal$Decode$pagePos,
	$mpizenberg$elm_pointer_events$Internal$Decode$screenPos);
var $mpizenberg$elm_pointer_events$Internal$Decode$all = A2(
	$elm$core$List$foldr,
	$elm$json$Json$Decode$map2($elm$core$List$cons),
	$elm$json$Json$Decode$succeed(_List_Nil));
var $mpizenberg$elm_pointer_events$Internal$Decode$dynamicListOf = function (itemDecoder) {
	var decodeOne = function (n) {
		return A2(
			$elm$json$Json$Decode$field,
			$elm$core$String$fromInt(n),
			itemDecoder);
	};
	var decodeN = function (n) {
		return $mpizenberg$elm_pointer_events$Internal$Decode$all(
			A2(
				$elm$core$List$map,
				decodeOne,
				A2($elm$core$List$range, 0, n - 1)));
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		decodeN,
		A2($elm$json$Json$Decode$field, 'length', $elm$json$Json$Decode$int));
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchListDecoder = $mpizenberg$elm_pointer_events$Internal$Decode$dynamicListOf;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$eventDecoder = A5(
	$elm$json$Json$Decode$map4,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$Event,
	$mpizenberg$elm_pointer_events$Internal$Decode$keys,
	A2(
		$elm$json$Json$Decode$field,
		'changedTouches',
		$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchListDecoder($mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'targetTouches',
		$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchListDecoder($mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'touches',
		$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchListDecoder($mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$touchDecoder)));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onWithOptions = F3(
	function (event, options, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (ev) {
					return {
						aO: tag(ev),
						de: options.de,
						dk: options.dk
					};
				},
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$eventDecoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onWithOptions, 'touchend', $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onStart = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onWithOptions, 'touchstart', $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions, 'mouseup', $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions);
var $mdgriffith$elm_ui$Internal$Model$Text = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$text = function (content) {
	return $mdgriffith$elm_ui$Internal$Model$Text(content);
};
var $mdgriffith$elm_ui$Internal$Model$BorderWidth = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Element$Border$width = function (v) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + $elm$core$String$fromInt(v),
			v,
			v,
			v,
			v));
};
var $author$project$Controller$renderCCValue = F4(
	function (msgs, config, editing, state) {
		return (!editing) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(0),
						$mdgriffith$elm_ui$Element$spacing(0),
						$mdgriffith$elm_ui$Element$Border$width(4),
						function () {
						var _v0 = state.c;
						if (_v0 === 1) {
							return $author$project$Style$backgroundColour(state.i);
						} else {
							return $mdgriffith$elm_ui$Element$Border$dashed;
						}
					}(),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onStart(
							function (_v1) {
								return msgs.s;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown(
							function (_v2) {
								return msgs.s;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd(
							function (_v3) {
								return msgs.t;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
							function (_v4) {
								return msgs.t;
							}))
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$author$project$Style$labelSizeToFontSize(
						A2($elm$core$Maybe$withDefault, 0, state.n))
					]),
				$mdgriffith$elm_ui$Element$text(
					_Utils_ap(
						function () {
							if (config.dK) {
								var _v5 = state.c;
								if (_v5 === 1) {
									return 'Off\n' + ($author$project$Midi$channelToString(state.f) + '\n');
								} else {
									return 'Off\n' + ($author$project$Midi$channelToString(state.f) + '\n');
								}
							} else {
								return '';
							}
						}(),
						state.d)))) : A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(config.m),
						$mdgriffith$elm_ui$Element$spacing(config.m),
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Font$size(14),
						$author$project$Style$backgroundColour(state.i)
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			{
				d: $mdgriffith$elm_ui$Element$text(state.d),
				af: $elm$core$Maybe$Just(msgs.j)
			});
	});
var $author$project$Controller$renderChord = F4(
	function (msgs, config, editing, state) {
		return (!editing) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(0),
						$mdgriffith$elm_ui$Element$spacing(0),
						$mdgriffith$elm_ui$Element$Border$width(4),
						function () {
						var _v0 = state.c;
						if (_v0 === 1) {
							return $author$project$Style$backgroundColour(state.i);
						} else {
							return $mdgriffith$elm_ui$Element$Border$dashed;
						}
					}(),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onStart(
							function (_v1) {
								return msgs.s;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown(
							function (_v2) {
								return msgs.s;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd(
							function (_v3) {
								return msgs.t;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
							function (_v4) {
								return msgs.t;
							}))
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$author$project$Style$labelSizeToFontSize(
						A2($elm$core$Maybe$withDefault, 0, state.n))
					]),
				$mdgriffith$elm_ui$Element$text(state.d))) : A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(config.m),
						$mdgriffith$elm_ui$Element$spacing(config.m),
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Font$size(14),
						$author$project$Style$backgroundColour(state.i)
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			{
				d: $mdgriffith$elm_ui$Element$text(state.d),
				af: $elm$core$Maybe$Just(msgs.j)
			});
	});
var $author$project$Controller$renderCommand = F4(
	function (msgs, config, editing, state) {
		return (!editing) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(0),
						$mdgriffith$elm_ui$Element$spacing(0),
						$mdgriffith$elm_ui$Element$Border$width(4),
						function () {
						var _v0 = state.c;
						if (_v0 === 1) {
							return $author$project$Style$backgroundColour(state.i);
						} else {
							return $mdgriffith$elm_ui$Element$Border$dashed;
						}
					}(),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onStart(
							function (_v1) {
								return msgs.s;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown(
							function (_v2) {
								return msgs.s;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd(
							function (_v3) {
								return msgs.t;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
							function (_v4) {
								return msgs.t;
							}))
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$author$project$Style$labelSizeToFontSize(
						A2($elm$core$Maybe$withDefault, 0, state.n))
					]),
				$mdgriffith$elm_ui$Element$text(state.d))) : A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(config.m),
						$mdgriffith$elm_ui$Element$spacing(config.m),
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Font$size(14),
						$author$project$Style$backgroundColour(state.i)
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			{
				d: $mdgriffith$elm_ui$Element$text(state.d),
				af: $elm$core$Maybe$Just(msgs.j)
			});
	});
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $feathericons$elm_feather$FeatherIcons$Icon = $elm$core$Basics$identity;
var $feathericons$elm_feather$FeatherIcons$defaultAttributes = function (name) {
	return {
		b8: $elm$core$Maybe$Just('feather feather-' + name),
		cB: 24,
		bW: '',
		cD: 2,
		cM: '0 0 24 24'
	};
};
var $feathericons$elm_feather$FeatherIcons$makeBuilder = F2(
	function (name, src) {
		return {
			J: $feathericons$elm_feather$FeatherIcons$defaultAttributes(name),
			N: src
		};
	});
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $feathericons$elm_feather$FeatherIcons$edit2 = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'edit-2',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z')
				]),
			_List_Nil)
		]));
var $mdgriffith$elm_ui$Internal$Model$unstyled = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Unstyled, $elm$core$Basics$always);
var $mdgriffith$elm_ui$Element$html = $mdgriffith$elm_ui$Internal$Model$unstyled;
var $elm$svg$Svg$line = $elm$svg$Svg$trustedNode('line');
var $elm$svg$Svg$Attributes$x1 = _VirtualDom_attribute('x1');
var $elm$svg$Svg$Attributes$x2 = _VirtualDom_attribute('x2');
var $elm$svg$Svg$Attributes$y1 = _VirtualDom_attribute('y1');
var $elm$svg$Svg$Attributes$y2 = _VirtualDom_attribute('y2');
var $feathericons$elm_feather$FeatherIcons$minus = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'minus',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('5'),
					$elm$svg$Svg$Attributes$y1('12'),
					$elm$svg$Svg$Attributes$x2('19'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$plus = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'plus',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('5'),
					$elm$svg$Svg$Attributes$x2('12'),
					$elm$svg$Svg$Attributes$y2('19')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('5'),
					$elm$svg$Svg$Attributes$y1('12'),
					$elm$svg$Svg$Attributes$x2('19'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil)
		]));
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$svg$Svg$map = $elm$virtual_dom$VirtualDom$map;
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeLinecap = _VirtualDom_attribute('stroke-linecap');
var $elm$svg$Svg$Attributes$strokeLinejoin = _VirtualDom_attribute('stroke-linejoin');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $feathericons$elm_feather$FeatherIcons$toHtml = F2(
	function (attributes, _v0) {
		var src = _v0.N;
		var attrs = _v0.J;
		var strSize = $elm$core$String$fromFloat(attrs.cB);
		var baseAttributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$fill('none'),
				$elm$svg$Svg$Attributes$height(
				_Utils_ap(strSize, attrs.bW)),
				$elm$svg$Svg$Attributes$width(
				_Utils_ap(strSize, attrs.bW)),
				$elm$svg$Svg$Attributes$stroke('currentColor'),
				$elm$svg$Svg$Attributes$strokeLinecap('round'),
				$elm$svg$Svg$Attributes$strokeLinejoin('round'),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(attrs.cD)),
				$elm$svg$Svg$Attributes$viewBox(attrs.cM)
			]);
		var combinedAttributes = _Utils_ap(
			function () {
				var _v1 = attrs.b8;
				if (!_v1.$) {
					var c = _v1.a;
					return A2(
						$elm$core$List$cons,
						$elm$svg$Svg$Attributes$class(c),
						baseAttributes);
				} else {
					return baseAttributes;
				}
			}(),
			attributes);
		return A2(
			$elm$svg$Svg$svg,
			combinedAttributes,
			A2(
				$elm$core$List$map,
				$elm$svg$Svg$map($elm$core$Basics$never),
				src));
	});
var $feathericons$elm_feather$FeatherIcons$withSize = F2(
	function (size, _v0) {
		var attrs = _v0.J;
		var src = _v0.N;
		return {
			J: _Utils_update(
				attrs,
				{cB: size}),
			N: src
		};
	});
var $author$project$Controller$renderEditButton = F3(
	function (msgs, config, editOperation) {
		switch (editOperation) {
			case 0:
				return A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$padding(config.m),
							$mdgriffith$elm_ui$Element$spacing(config.m),
							$mdgriffith$elm_ui$Element$Border$width(2)
						]),
					{
						d: $mdgriffith$elm_ui$Element$html(
							A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withSize, 20, $feathericons$elm_feather$FeatherIcons$edit2))),
						af: $elm$core$Maybe$Just(msgs.j)
					});
			case 1:
				return A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$padding(config.m),
							$mdgriffith$elm_ui$Element$spacing(config.m),
							$mdgriffith$elm_ui$Element$Border$width(2)
						]),
					{
						d: $mdgriffith$elm_ui$Element$html(
							A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withSize, 20, $feathericons$elm_feather$FeatherIcons$plus))),
						af: $elm$core$Maybe$Just(msgs.bl)
					});
			default:
				return A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$padding(config.m),
							$mdgriffith$elm_ui$Element$spacing(config.m),
							$mdgriffith$elm_ui$Element$Border$width(2)
						]),
					{
						d: $mdgriffith$elm_ui$Element$html(
							A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withSize, 20, $feathericons$elm_feather$FeatherIcons$minus))),
						af: $elm$core$Maybe$Just(msgs.bx)
					});
		}
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onMove = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions, 'mousemove', $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onMove = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onWithOptions, 'touchmove', $mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$defaultOptions);
var $mdgriffith$elm_ui$Element$Border$solid = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$borderStyle, $mdgriffith$elm_ui$Internal$Style$classes.fm);
var $mdgriffith$elm_ui$Element$Border$widthXY = F2(
	function (x, y) {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$borderWidth,
			A5(
				$mdgriffith$elm_ui$Internal$Model$BorderWidth,
				'b-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
				y,
				x,
				y,
				x));
	});
var $mdgriffith$elm_ui$Element$Border$widthEach = function (_v0) {
	var bottom = _v0.bG;
	var top = _v0.bZ;
	var left = _v0.bK;
	var right = _v0.bV;
	return (_Utils_eq(top, bottom) && _Utils_eq(left, right)) ? (_Utils_eq(top, right) ? $mdgriffith$elm_ui$Element$Border$width(top) : A2($mdgriffith$elm_ui$Element$Border$widthXY, left, top)) : A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left))))))),
			top,
			right,
			bottom,
			left));
};
var $author$project$Controller$renderFader = F4(
	function (msgs, config, editing, state) {
		return (!editing) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(0),
						$mdgriffith$elm_ui$Element$spacing(0),
						$mdgriffith$elm_ui$Element$Border$width(4),
						function () {
						var _v0 = state.c;
						if (!_v0.$) {
							return $mdgriffith$elm_ui$Element$Border$solid;
						} else {
							return $mdgriffith$elm_ui$Element$Border$dashed;
						}
					}(),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onStart(
							function (event) {
								return msgs.T(event);
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown(
							function (event) {
								return msgs.S(event);
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onMove(
							function (event) {
								return msgs.T(event);
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd(
							function (_v1) {
								return msgs.U;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
							function (_v2) {
								return msgs.U;
							}))
					]),
				_Utils_ap(
					function () {
						var _v3 = state.c;
						if (_v3.$ === 1) {
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$htmlAttribute(
									$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onMove(
										function (event) {
											return msgs.S(event);
										}))
								]);
						} else {
							return _List_Nil;
						}
					}(),
					_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace))),
			A2(
				$mdgriffith$elm_ui$Element$column,
				A2(
					$elm$core$List$cons,
					$author$project$Style$backgroundColour(0),
					$author$project$Style$fillSpace),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$padding(10),
								$mdgriffith$elm_ui$Element$Font$size(14)
							]),
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(state.bh) + '%')),
						A2(
						$mdgriffith$elm_ui$Element$column,
						$author$project$Style$fillSpace,
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$fillPortion(100 - state.bh)),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$author$project$Style$backgroundColour(0)
									]),
								$mdgriffith$elm_ui$Element$none),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$fillPortion(state.bh)),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$author$project$Style$backgroundColour(state.i),
										$mdgriffith$elm_ui$Element$Border$widthEach(
										{bG: 0, bK: 0, bV: 0, bZ: 8})
									]),
								$mdgriffith$elm_ui$Element$none)
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$padding(10),
								$author$project$Style$labelSizeToFontSize(
								A2($elm$core$Maybe$withDefault, 0, state.n))
							]),
						$mdgriffith$elm_ui$Element$text(state.d))
					]))) : A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(config.m),
						$mdgriffith$elm_ui$Element$spacing(config.m),
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Font$size(14),
						$author$project$Style$backgroundColour(state.i)
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			{
				d: $mdgriffith$elm_ui$Element$text(state.d),
				af: $elm$core$Maybe$Just(msgs.j)
			});
	});
var $author$project$Controller$renderNote = F4(
	function (msgs, config, editing, state) {
		return (!editing) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(0),
						$mdgriffith$elm_ui$Element$spacing(0),
						$mdgriffith$elm_ui$Element$Border$width(4),
						function () {
						var _v0 = state.c;
						if (_v0 === 1) {
							return $author$project$Style$backgroundColour(state.i);
						} else {
							return $mdgriffith$elm_ui$Element$Border$dashed;
						}
					}(),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onStart(
							function (_v1) {
								return msgs.s;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown(
							function (_v2) {
								return msgs.s;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd(
							function (_v3) {
								return msgs.t;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
							function (_v4) {
								return msgs.t;
							}))
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$author$project$Style$labelSizeToFontSize(
						A2($elm$core$Maybe$withDefault, 0, state.n))
					]),
				$mdgriffith$elm_ui$Element$text(
					_Utils_ap(
						config.dK ? ($author$project$Midi$channelToString(state.f) + '\n') : '',
						state.d)))) : A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(config.m),
						$mdgriffith$elm_ui$Element$spacing(config.m),
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Font$size(14),
						$author$project$Style$backgroundColour(state.i)
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			{
				d: $mdgriffith$elm_ui$Element$text(state.d),
				af: $elm$core$Maybe$Just(msgs.j)
			});
	});
var $author$project$Controller$renderPitchBend = F4(
	function (msgs, config, editing, state) {
		var fillFraction = $elm$core$Basics$round(100 * (state.bn / 16383));
		return (!editing) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(0),
						$mdgriffith$elm_ui$Element$spacing(0),
						$mdgriffith$elm_ui$Element$Border$width(4),
						function () {
						var _v0 = state.c;
						if (!_v0.$) {
							return $mdgriffith$elm_ui$Element$Border$solid;
						} else {
							return $mdgriffith$elm_ui$Element$Border$dashed;
						}
					}(),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onStart(
							function (event) {
								return msgs.T(event);
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown(
							function (event) {
								return msgs.S(event);
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onMove(
							function (event) {
								return msgs.T(event);
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd(
							function (_v1) {
								return msgs.U;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
							function (_v2) {
								return msgs.U;
							}))
					]),
				_Utils_ap(
					function () {
						var _v3 = state.c;
						if (_v3.$ === 1) {
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$htmlAttribute(
									$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onMove(
										function (event) {
											return msgs.S(event);
										}))
								]);
						} else {
							return _List_Nil;
						}
					}(),
					_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace))),
			A2(
				$mdgriffith$elm_ui$Element$column,
				A2(
					$elm$core$List$cons,
					$author$project$Style$backgroundColour(0),
					$author$project$Style$fillSpace),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						$author$project$Style$fillSpace,
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$fillPortion(100 - fillFraction)),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$author$project$Style$backgroundColour(state.i)
									]),
								$mdgriffith$elm_ui$Element$none),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$fillPortion(fillFraction)),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$author$project$Style$backgroundColour(state.i),
										$mdgriffith$elm_ui$Element$Border$widthEach(
										{bG: 0, bK: 0, bV: 0, bZ: 8})
									]),
								$mdgriffith$elm_ui$Element$none)
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$padding(10),
								$author$project$Style$labelSizeToFontSize(
								A2($elm$core$Maybe$withDefault, 0, state.n))
							]),
						$mdgriffith$elm_ui$Element$text(state.d))
					]))) : A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(config.m),
						$mdgriffith$elm_ui$Element$spacing(config.m),
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Font$size(14),
						$author$project$Style$backgroundColour(state.i)
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			{
				d: $mdgriffith$elm_ui$Element$text(state.d),
				af: $elm$core$Maybe$Just(msgs.j)
			});
	});
var $author$project$Controller$renderSequence = F4(
	function (msgs, config, editing, state) {
		return (!editing) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(0),
						$mdgriffith$elm_ui$Element$spacing(0),
						$mdgriffith$elm_ui$Element$Border$width(4),
						function () {
						var _v0 = state.c;
						if (_v0 === 1) {
							return $author$project$Style$backgroundColour(state.i);
						} else {
							return $mdgriffith$elm_ui$Element$Border$dashed;
						}
					}(),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onStart(
							function (_v1) {
								return msgs.s;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown(
							function (_v2) {
								return msgs.s;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd(
							function (_v3) {
								return msgs.t;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
							function (_v4) {
								return msgs.t;
							}))
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$author$project$Style$labelSizeToFontSize(
						A2($elm$core$Maybe$withDefault, 0, state.n))
					]),
				$mdgriffith$elm_ui$Element$text(
					state.d + ('\n' + $elm$core$String$fromInt(state.a5 + 1))))) : A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(config.m),
						$mdgriffith$elm_ui$Element$spacing(config.m),
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Font$size(14),
						$author$project$Style$backgroundColour(state.i)
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			{
				d: $mdgriffith$elm_ui$Element$text(state.d),
				af: $elm$core$Maybe$Just(msgs.j)
			});
	});
var $mdgriffith$elm_ui$Internal$Model$AsRow = 0;
var $mdgriffith$elm_ui$Internal$Model$asRow = 0;
var $mdgriffith$elm_ui$Element$row = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.bJ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.aI)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $author$project$Controller$renderXYFader = F4(
	function (msgs, config, editing, state) {
		return (!editing) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(0),
						$mdgriffith$elm_ui$Element$spacing(0),
						$mdgriffith$elm_ui$Element$Border$width(4),
						function () {
						var _v0 = state.c;
						if (!_v0.$) {
							return $mdgriffith$elm_ui$Element$Border$solid;
						} else {
							return $mdgriffith$elm_ui$Element$Border$dashed;
						}
					}(),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onStart(
							function (event) {
								return msgs.T(event);
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onDown(
							function (event) {
								return msgs.S(event);
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onMove(
							function (event) {
								return msgs.T(event);
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Touch$onEnd(
							function (_v1) {
								return msgs.U;
							})),
						$mdgriffith$elm_ui$Element$htmlAttribute(
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onUp(
							function (_v2) {
								return msgs.U;
							}))
					]),
				_Utils_ap(
					function () {
						var _v3 = state.c;
						if (_v3.$ === 1) {
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$htmlAttribute(
									$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onMove(
										function (event) {
											return msgs.S(event);
										}))
								]);
						} else {
							return _List_Nil;
						}
					}(),
					_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace))),
			A2(
				$mdgriffith$elm_ui$Element$column,
				A2(
					$elm$core$List$cons,
					$author$project$Style$backgroundColour(0),
					$author$project$Style$fillSpace),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$padding(10),
								$mdgriffith$elm_ui$Element$Font$size(14)
							]),
						$mdgriffith$elm_ui$Element$text(
							'X ' + ($elm$core$String$fromInt(state.bi) + ('%, ' + ('Y ' + ($elm$core$String$fromInt(state.bj) + '%')))))),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$inFront(
									A2(
										$mdgriffith$elm_ui$Element$row,
										$author$project$Style$fillSpace,
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$width(
														$mdgriffith$elm_ui$Element$fillPortion(state.bi))
													]),
												$mdgriffith$elm_ui$Element$none),
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$width(
														$mdgriffith$elm_ui$Element$fillPortion(100 - state.bi)),
														$mdgriffith$elm_ui$Element$Border$widthEach(
														{bG: 0, bK: 8, bV: 0, bZ: 0})
													]),
												$mdgriffith$elm_ui$Element$none)
											]))),
									$author$project$Style$backgroundColour(state.i)
								]),
							$author$project$Style$fillSpace),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$fillPortion(100 - state.bj)),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								$mdgriffith$elm_ui$Element$none),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$fillPortion(state.bj)),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$Border$widthEach(
										{bG: 0, bK: 0, bV: 0, bZ: 8})
									]),
								$mdgriffith$elm_ui$Element$none)
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$padding(10),
								$author$project$Style$labelSizeToFontSize(
								A2($elm$core$Maybe$withDefault, 0, state.n))
							]),
						$mdgriffith$elm_ui$Element$text(state.d))
					]))) : A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(config.m),
						$mdgriffith$elm_ui$Element$spacing(config.m),
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Font$size(14),
						$author$project$Style$backgroundColour(state.i)
					]),
				_Utils_ap($author$project$Style$noSelect, $author$project$Style$fillSpace)),
			{
				d: $mdgriffith$elm_ui$Element$text(state.d),
				af: $elm$core$Maybe$Just(msgs.j)
			});
	});
var $mdgriffith$elm_ui$Element$scrollbarY = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.g_);
var $mdgriffith$elm_ui$Element$spacingXY = F2(
	function (x, y) {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$spacing,
			A3(
				$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
				A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, y),
				x,
				y));
	});
var $author$project$Controller$renderController = F7(
	function (msgs, editing, midiLog, config, idParts, controller, id) {
		var updatedParts = A2(
			$elm$core$List$cons,
			$elm$core$String$fromInt(id),
			idParts);
		var parentId = A2(
			$elm$core$String$join,
			'_',
			$elm$core$List$reverse(updatedParts));
		switch (controller.$) {
			case 0:
				var label = controller.a;
				var subControls = controller.b;
				return A3(
					$mdgriffith$elm_ui$Element$Lazy$lazy2,
					$mdgriffith$elm_ui$Element$column,
					_Utils_ap(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(config.m),
								$mdgriffith$elm_ui$Element$spacing(config.m)
							]),
						_Utils_ap(
							$author$project$Style$fillSpace,
							(!editing) ? _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Border$dotted,
									$mdgriffith$elm_ui$Element$Border$width(4)
								]) : _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(2),
									$mdgriffith$elm_ui$Element$Border$width(2),
									$mdgriffith$elm_ui$Element$Border$dashed
								]))),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							A2(
								$elm$core$List$cons,
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$padding(4)
										]),
									$mdgriffith$elm_ui$Element$text(label)),
								(!editing) ? _List_Nil : _List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$alignRight,
												$mdgriffith$elm_ui$Element$padding(4),
												$author$project$Style$backgroundColour(0)
											]),
										A3(
											$author$project$Controller$renderEditButton,
											{
												bl: msgs.bE(parentId),
												j: msgs.j(parentId),
												bx: msgs.bU(parentId)
											},
											config,
											0))
									]))),
							A7($author$project$Controller$renderController, msgs, editing, midiLog, config, updatedParts, subControls, 0)
						]));
			case 1:
				var subControls = controller.a;
				return A3(
					$mdgriffith$elm_ui$Element$Lazy$lazy2,
					$mdgriffith$elm_ui$Element$row,
					_Utils_ap(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$spacingXY, config.m, 0),
								$mdgriffith$elm_ui$Element$Events$onClick(msgs.eh)
							]),
						_Utils_ap(
							$author$project$Style$fillSpace,
							(!editing) ? _List_Nil : _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(2),
									$mdgriffith$elm_ui$Element$Border$width(2),
									$mdgriffith$elm_ui$Element$Border$dashed
								]))),
					_Utils_ap(
						A3(
							$elm$core$List$map2,
							A5($author$project$Controller$renderController, msgs, editing, midiLog, config, updatedParts),
							subControls,
							A2(
								$elm$core$List$range,
								0,
								$elm$core$List$length(subControls))),
						(!editing) ? _List_Nil : _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$padding(4),
										$author$project$Style$backgroundColour(0)
									]),
								A3(
									$author$project$Controller$renderEditButton,
									{
										bl: msgs.bE(parentId),
										j: msgs.j(parentId),
										bx: msgs.bU(parentId)
									},
									config,
									0))
							])));
			case 2:
				var subControls = controller.a;
				return (!editing) ? A3(
					$mdgriffith$elm_ui$Element$Lazy$lazy2,
					$mdgriffith$elm_ui$Element$column,
					A2(
						$elm$core$List$cons,
						A2($mdgriffith$elm_ui$Element$spacingXY, 0, config.m),
						_Utils_ap(
							$author$project$Style$fillSpace,
							(!editing) ? _List_Nil : _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(2),
									$mdgriffith$elm_ui$Element$Border$width(2),
									$mdgriffith$elm_ui$Element$Border$dashed
								]))),
					A3(
						$elm$core$List$map2,
						A5($author$project$Controller$renderController, msgs, editing, midiLog, config, updatedParts),
						subControls,
						A2(
							$elm$core$List$range,
							0,
							$elm$core$List$length(subControls)))) : A3(
					$mdgriffith$elm_ui$Element$Lazy$lazy2,
					$mdgriffith$elm_ui$Element$column,
					_Utils_ap(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
								$mdgriffith$elm_ui$Element$spacing(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$dashed
							]),
						$author$project$Style$fillSpace),
					_List_fromArray(
						[
							A3(
							$author$project$Controller$renderEditButton,
							{
								bl: msgs.bE(parentId),
								j: msgs.j(parentId),
								bx: msgs.bU(parentId)
							},
							config,
							0),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_Utils_ap(
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$spacingXY, 0, config.m),
										$mdgriffith$elm_ui$Element$padding(config.m)
									]),
								$author$project$Style$fillSpace),
							A3(
								$elm$core$List$map2,
								A5($author$project$Controller$renderController, msgs, editing, midiLog, config, updatedParts),
								subControls,
								A2(
									$elm$core$List$range,
									0,
									$elm$core$List$length(subControls))))
						]));
			case 3:
				var subControls = controller.a;
				return A3(
					$mdgriffith$elm_ui$Element$Lazy$lazy2,
					$mdgriffith$elm_ui$Element$row,
					_Utils_ap(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$spacingXY, config.m, 0),
								$mdgriffith$elm_ui$Element$Events$onClick(msgs.eh)
							]),
						_Utils_ap(
							$author$project$Style$fillSpace,
							(!editing) ? _List_Nil : _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(2),
									$mdgriffith$elm_ui$Element$Border$width(2),
									$mdgriffith$elm_ui$Element$Border$dashed
								]))),
					_Utils_ap(
						A3(
							$elm$core$List$map2,
							F2(
								function (_v1, i) {
									var s = _v1.a;
									var c = _v1.b;
									return A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$fillPortion(s))
											]),
										A7($author$project$Controller$renderController, msgs, editing, midiLog, config, updatedParts, c, i));
								}),
							subControls,
							A2(
								$elm$core$List$range,
								0,
								$elm$core$List$length(subControls))),
						(!editing) ? _List_Nil : _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$padding(4),
										$author$project$Style$backgroundColour(0)
									]),
								A3(
									$author$project$Controller$renderEditButton,
									{
										bl: msgs.bE(parentId),
										j: msgs.j(parentId),
										bx: msgs.bU(parentId)
									},
									config,
									0))
							])));
			case 4:
				var subControls = controller.a;
				return (!editing) ? A3(
					$mdgriffith$elm_ui$Element$Lazy$lazy2,
					$mdgriffith$elm_ui$Element$column,
					A2(
						$elm$core$List$cons,
						A2($mdgriffith$elm_ui$Element$spacingXY, 0, config.m),
						_Utils_ap(
							$author$project$Style$fillSpace,
							(!editing) ? _List_Nil : _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(2),
									$mdgriffith$elm_ui$Element$Border$width(2),
									$mdgriffith$elm_ui$Element$Border$dashed
								]))),
					A3(
						$elm$core$List$map2,
						F2(
							function (_v2, i) {
								var s = _v2.a;
								var c = _v2.b;
								return A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$height(
											$mdgriffith$elm_ui$Element$fillPortion(s)),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									A7($author$project$Controller$renderController, msgs, editing, midiLog, config, updatedParts, c, i));
							}),
						subControls,
						A2(
							$elm$core$List$range,
							0,
							$elm$core$List$length(subControls)))) : A3(
					$mdgriffith$elm_ui$Element$Lazy$lazy2,
					$mdgriffith$elm_ui$Element$column,
					_Utils_ap(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
								$mdgriffith$elm_ui$Element$spacing(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$dashed
							]),
						$author$project$Style$fillSpace),
					_List_fromArray(
						[
							A3(
							$author$project$Controller$renderEditButton,
							{
								bl: msgs.bE(parentId),
								j: msgs.j(parentId),
								bx: msgs.bU(parentId)
							},
							config,
							0),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_Utils_ap(
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$spacingXY, 0, config.m),
										$mdgriffith$elm_ui$Element$padding(config.m)
									]),
								$author$project$Style$fillSpace),
							A3(
								$elm$core$List$map2,
								F2(
									function (_v3, i) {
										var s = _v3.a;
										var c = _v3.b;
										return A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$height(
													$mdgriffith$elm_ui$Element$fillPortion(s)),
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											A7($author$project$Controller$renderController, msgs, editing, midiLog, config, updatedParts, c, i));
									}),
								subControls,
								A2(
									$elm$core$List$range,
									0,
									$elm$core$List$length(subControls))))
						]));
			case 5:
				var state = controller.a;
				return A4(
					$author$project$Controller$renderNote,
					{
						s: msgs.s(parentId),
						t: msgs.t(parentId),
						j: msgs.j(parentId)
					},
					config,
					editing,
					state);
			case 6:
				var state = controller.a;
				return A4(
					$author$project$Controller$renderChord,
					{
						s: msgs.s(parentId),
						t: msgs.t(parentId),
						j: msgs.j(parentId)
					},
					config,
					editing,
					state);
			case 7:
				var state = controller.a;
				return A4(
					$author$project$Controller$renderCCValue,
					{
						s: msgs.s(parentId),
						t: msgs.t(parentId),
						j: msgs.j(parentId)
					},
					config,
					editing,
					state);
			case 8:
				var state = controller.a;
				return A4(
					$author$project$Controller$renderCommand,
					{
						s: msgs.s(parentId),
						t: msgs.t(parentId),
						j: msgs.j(parentId)
					},
					config,
					editing,
					state);
			case 9:
				var state = controller.a;
				return A4(
					$author$project$Controller$renderSequence,
					{
						s: msgs.s(parentId),
						t: msgs.t(parentId),
						j: msgs.j(parentId)
					},
					config,
					editing,
					state);
			case 10:
				var state = controller.a;
				return A4(
					$author$project$Controller$renderFader,
					{
						j: msgs.j(parentId),
						S: msgs.S(parentId),
						T: msgs.T(parentId),
						U: msgs.U(parentId)
					},
					config,
					editing,
					state);
			case 11:
				var state = controller.a;
				return A4(
					$author$project$Controller$renderXYFader,
					{
						j: msgs.j(parentId),
						S: msgs.S(parentId),
						T: msgs.T(parentId),
						U: msgs.U(parentId)
					},
					config,
					editing,
					state);
			case 12:
				var state = controller.a;
				return A4(
					$author$project$Controller$renderPitchBend,
					{
						j: msgs.j(parentId),
						S: msgs.S(parentId),
						T: msgs.T(parentId),
						U: msgs.U(parentId)
					},
					config,
					editing,
					state);
			case 13:
				return (!editing) ? A2(
					$mdgriffith$elm_ui$Element$column,
					$author$project$Style$fillSpace,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_Utils_ap(
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$padding(4),
										$mdgriffith$elm_ui$Element$spacing(10),
										$author$project$Style$backgroundColour(0),
										$mdgriffith$elm_ui$Element$scrollbarY,
										$mdgriffith$elm_ui$Element$clipX
									]),
								$author$project$Style$fillSpace),
							$elm$core$List$isEmpty(midiLog) ? _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
									$mdgriffith$elm_ui$Element$text('No MIDI events in log.'))
								]) : A2(
								$elm$core$List$map,
								function (s) {
									return A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$alignLeft,
												$mdgriffith$elm_ui$Element$Font$size(12),
												$mdgriffith$elm_ui$Element$padding(2),
												$mdgriffith$elm_ui$Element$Border$widthEach(
												{bG: 1, bK: 0, bV: 0, bZ: 0})
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(s)
											]));
								},
								midiLog))
						])) : A2(
					$mdgriffith$elm_ui$Element$el,
					_Utils_ap(
						_List_fromArray(
							[
								$author$project$Style$backgroundColour(1),
								$mdgriffith$elm_ui$Element$Events$onClick(
								msgs.j(parentId))
							]),
						$author$project$Style$fillSpace),
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
						$mdgriffith$elm_ui$Element$text('MIDI Log')));
			default:
				return (!editing) ? A2(
					$mdgriffith$elm_ui$Element$el,
					_Utils_ap(
						_List_fromArray(
							[
								$author$project$Style$backgroundColour(0),
								$author$project$Style$borderColour(1),
								$mdgriffith$elm_ui$Element$Border$width(4)
							]),
						$author$project$Style$fillSpace),
					$mdgriffith$elm_ui$Element$none) : A2(
					$mdgriffith$elm_ui$Element$el,
					_Utils_ap(
						_List_fromArray(
							[
								$author$project$Style$backgroundColour(1),
								$mdgriffith$elm_ui$Element$Events$onClick(
								msgs.j(parentId))
							]),
						$author$project$Style$fillSpace),
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
						$mdgriffith$elm_ui$Element$text('SPACE')));
		}
	});
var $author$project$Main$renderPage = F3(
	function (mode, midiLog, page) {
		var _v0 = page;
		var config = _v0.aH;
		var controller = _v0.ar;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$htmlAttribute(
					$author$project$Utils$onContextMenu($author$project$Main$NoOp)),
				_Utils_ap(
					$author$project$Style$fillSpace,
					function () {
						if (!mode.$) {
							return _List_Nil;
						} else {
							return _List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$paddingXY, config.m, 0)
								]);
						}
					}())),
			A7(
				$author$project$Controller$renderController,
				{bE: $author$project$Main$AddSpace, s: $author$project$Main$ButtonDown, t: $author$project$Main$ButtonUp, j: $author$project$Main$OpenEditController, S: $author$project$Main$FaderChangingMouse, T: $author$project$Main$FaderChanging, U: $author$project$Main$FaderSet, eh: $author$project$Main$NoOp, bU: $author$project$Main$RemoveItem},
				function () {
					if (!mode.$) {
						return false;
					} else {
						return true;
					}
				}(),
				midiLog,
				config,
				_List_Nil,
				controller,
				0));
	});
var $author$project$Main$ClosePopUp = {$: 39};
var $author$project$EditableController$EditChordBuilder = function (a) {
	return {$: 3, a: a};
};
var $author$project$EditableController$EditIsomorphic = function (a) {
	return {$: 1, a: a};
};
var $author$project$EditableController$EditScale = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$FinishedEdit = function (a) {
	return {$: 29, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Top = 0;
var $mdgriffith$elm_ui$Element$alignTop = $mdgriffith$elm_ui$Internal$Model$AlignY(0);
var $mdgriffith$elm_ui$Element$Font$color = function (fontColor) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(fontColor),
			'color',
			fontColor));
};
var $author$project$Style$fontColour = function (appColour) {
	return $mdgriffith$elm_ui$Element$Font$color(
		$author$project$Style$appColourToRGB(appColour));
};
var $author$project$Style$acceptOrCloseButtons = F3(
	function (acceptString, cancelMsg, acceptMsg) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignTop,
					$mdgriffith$elm_ui$Element$spacing(4),
					$author$project$Style$backgroundColour(0)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_Utils_ap(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$solid,
								$author$project$Style$borderColour(3)
							]),
						function () {
							if (!acceptMsg.$) {
								return _List_fromArray(
									[
										$author$project$Style$fontColour(3),
										$author$project$Style$borderColour(3)
									]);
							} else {
								return _List_fromArray(
									[
										$author$project$Style$fontColour(1),
										$author$project$Style$borderColour(1)
									]);
							}
						}()),
					{
						d: $mdgriffith$elm_ui$Element$text(acceptString),
						af: acceptMsg
					}),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(5),
							$mdgriffith$elm_ui$Element$Border$width(2),
							$mdgriffith$elm_ui$Element$Border$solid,
							$author$project$Style$borderColour(3)
						]),
					{
						d: $mdgriffith$elm_ui$Element$text('Cancel'),
						af: $elm$core$Maybe$Just(cancelMsg)
					})
				]));
	});
var $author$project$EditableController$defaultEditCCValueState = {f: '1', i: 4, ar: '1', d: '', n: 1, aE: '63'};
var $author$project$EditableController$C = 0;
var $author$project$EditableController$Major = 0;
var $author$project$EditableController$Triad = 0;
var $author$project$EditableController$defaultEditChordBuilderState = {f: '', b7: 0, aN: 0, aS: '', aW: '', by: 0, E: ''};
var $author$project$EditableController$defaultEditChordState = {i: 1, d: '', n: 1, aR: $elm$core$Dict$empty, E: '100'};
var $author$project$EditableController$defaultEditCommandState = {i: 4, dM: 0, d: '', n: 1, bw: $elm$core$Maybe$Nothing, a8: _List_Nil, a9: _List_Nil};
var $author$project$EditableController$defaultEditFaderState = {b4: '1', f: '1', i: 6, d: '', n: 1, cF: '127', cI: '0'};
var $author$project$EditableController$defaultEditIsomorphicState = {f: '', ch: '', cm: '', cn: '', cy: '', E: ''};
var $author$project$EditableController$defaultEditNoteState = {f: '1', i: 1, d: '', n: 1, y: '60', E: '100'};
var $author$project$EditableController$defaultEditPitchBendState = {f: '1', i: 5, d: '', n: 1};
var $author$project$EditableController$defaultEditScaleState = {f: '', aN: 0, aS: '', aW: '', by: 0, E: ''};
var $author$project$EditableController$defaultEditSequenceState = {i: 6, d: '', n: 1, aP: $elm$core$Array$empty, bw: $elm$core$Maybe$Nothing};
var $author$project$EditableController$defaultEditXYFaderState = {cO: 0, b5: '1', b6: '2', bH: '1', bI: '1', i: 6, d: '', n: 1, cG: '127', cH: '127', cJ: '0', cK: '0'};
var $author$project$EditableController$description = function (eController) {
	switch (eController.$) {
		case 0:
			return 'A container used to group other controls.\r\n            Modules can be saved for future use with the save button\r\n            in the menu.\r\n            ';
		case 1:
			return 'A tool to create isomorphic keyboards. Once created,\r\n            you can customise colours and labels of individual notes.\r\n            ';
		case 2:
			return 'A tool to create a keyboards in a particular scale. Once created,\r\n            you can customise colours and labels of individual notes.\r\n            ';
		case 3:
			return 'A tool for creating a palette of chords. Once created,\r\n            you can customise colours and labels of individual chords.\r\n            You can choose between triads and four note chords, which\r\n            contain sixth and seventh chords.\r\n            ';
		case 4:
			return 'A container that holds multiple controls in a column.\r\n            Press "Add" to add a space that you can edit later.\r\n            While creating, you can press MIDI notes and send CC on your\r\n            device to automatically add them to the column. \r\n            ';
		case 5:
			return 'A container that holds multiple controls in a row.\r\n            Press "Add" to add a space that you can edit later.\r\n            While creating, you can press MIDI notes and send CC on your\r\n            device to automatically add them to the row.\r\n            ';
		case 6:
			return 'A container that holds multiple controls in a column.\r\n            Press "Add" to add a space that you can edit later.\r\n            While creating, you can press MIDI notes and send CC on your\r\n            device to automatically add them to the column. You can set\r\n            the fill fraction using the "+" and "-" buttons. For example,\r\n            if you have 3 elements with the first set to 2 and the others\r\n            set to 1, then the first will fill half the space and the\r\n            others will fill a quarter each.\r\n            ';
		case 7:
			return 'A container that holds multiple controls in a row.\r\n            Press "Add" to add a space that you can edit later.\r\n            While creating, you can press MIDI notes and send CC on your\r\n            device to automatically add them to the row. You can set\r\n            the fill fraction using the "+" and "-" buttons. For example,\r\n            if you have 3 elements with the first set to 2 and the others\r\n            set to 1, then the first will fill half the space and the\r\n            others will fill a quarter each.\r\n            ';
		case 8:
			return 'A button that when pressed will send a note on message and\r\n            when released will send a note off message. \r\n            While creating, you can press MIDI notes on your device to set parameters.\r\n            ';
		case 9:
			return 'A button that when pressed will send multiple note on messages\r\n            and when released will send corresponding note off messages.\r\n            While creating, you can press notes on your device to set parameters.\r\n            ';
		case 10:
			return 'A button that when pressed will send a single CC value.\r\n            While creating, you can send a CC value on your device\r\n            to set parameters.\r\n            ';
		case 11:
			return 'A button that when pressed and/or released can send multiple\r\n            MIDI messages. You can send a wide range of message types,\r\n            including notes, CC, play, stop, programme change etc.\r\n            While adding messages, you can press a note or send a CC value on your device\r\n            to set parameters.\r\n            ';
		case 12:
			return 'A button that when pressed will cycle through a sequence of\r\n            MIDI messages. You can send a wide range of message types,\r\n            including notes, CC, play, stop, programme change etc.\r\n            While adding messages, you can press a note or send a CC value on your device\r\n            to set parameters.\r\n            ';
		case 13:
			return 'A fader that can be used to send CC messages.\r\n            While adding messages, you can send a CC value on your device\r\n            to set parameters.\r\n            ';
		case 14:
			return 'A xy fader that can be used to send two CC messages simultaneously.\r\n            While adding messages, you can send a CC value on your device\r\n            to set parameters.\r\n            ';
		case 15:
			return 'A fader that can be used to send pitch bend messages. The position\r\n            resets when released.\r\n            While adding messages, you can press a note or send a CC\r\n            value on your device to set the channel.\r\n            ';
		case 16:
			return 'A log that shows MIDI messages sent from your devices.\r\n            ';
		default:
			return 'A space that can be used to make a gap or as a placeholder.\r\n            ';
	}
};
var $author$project$Main$UpdateControllerState = function (a) {
	return {$: 27, a: a};
};
var $mdgriffith$elm_ui$Element$Input$Above = 2;
var $mdgriffith$elm_ui$Element$Input$Label = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Element$Input$labelAbove = $mdgriffith$elm_ui$Element$Input$Label(2);
var $mdgriffith$elm_ui$Element$Input$Option = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Left = 0;
var $mdgriffith$elm_ui$Element$alignLeft = $mdgriffith$elm_ui$Internal$Model$AlignX(0);
var $mdgriffith$elm_ui$Element$rgb = F3(
	function (r, g, b) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, r, g, b, 1);
	});
var $mdgriffith$elm_ui$Internal$Flag$borderRound = $mdgriffith$elm_ui$Internal$Flag$flag(17);
var $mdgriffith$elm_ui$Element$Border$rounded = function (radius) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + $elm$core$String$fromInt(radius),
			'border-radius',
			$elm$core$String$fromInt(radius) + 'px'));
};
var $mdgriffith$elm_ui$Element$Input$white = A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1);
var $mdgriffith$elm_ui$Element$Input$defaultRadioOption = F2(
	function (optionLabel, status) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(14)),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(14)),
							$mdgriffith$elm_ui$Element$Background$color($mdgriffith$elm_ui$Element$Input$white),
							$mdgriffith$elm_ui$Element$Border$rounded(7),
							function () {
							if (status === 2) {
								return $mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
							} else {
								return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
							}
						}(),
							$mdgriffith$elm_ui$Element$Border$width(
							function () {
								switch (status) {
									case 0:
										return 1;
									case 1:
										return 1;
									default:
										return 5;
								}
							}()),
							$mdgriffith$elm_ui$Element$Border$color(
							function () {
								switch (status) {
									case 0:
										return A3($mdgriffith$elm_ui$Element$rgb, 208 / 255, 208 / 255, 208 / 255);
									case 1:
										return A3($mdgriffith$elm_ui$Element$rgb, 208 / 255, 208 / 255, 208 / 255);
									default:
										return A3($mdgriffith$elm_ui$Element$rgb, 59 / 255, 153 / 255, 252 / 255);
								}
							}())
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Internal$Model$htmlClass('unfocusable')
						]),
					optionLabel)
				]));
	});
var $mdgriffith$elm_ui$Element$Input$option = F2(
	function (val, txt) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$Option,
			val,
			$mdgriffith$elm_ui$Element$Input$defaultRadioOption(txt));
	});
var $mdgriffith$elm_ui$Internal$Model$paddingName = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left)))))));
	});
var $mdgriffith$elm_ui$Element$paddingEach = function (_v0) {
	var top = _v0.bZ;
	var right = _v0.bV;
	var bottom = _v0.bG;
	var left = _v0.bK;
	if (_Utils_eq(top, right) && (_Utils_eq(top, bottom) && _Utils_eq(top, left))) {
		var topFloat = top;
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				'p-' + $elm$core$String$fromInt(top),
				topFloat,
				topFloat,
				topFloat,
				topFloat));
	} else {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				A4($mdgriffith$elm_ui$Internal$Model$paddingName, top, right, bottom, left),
				top,
				right,
				bottom,
				left));
	}
};
var $mdgriffith$elm_ui$Element$Input$Column = 1;
var $mdgriffith$elm_ui$Element$Input$AfterFound = 2;
var $mdgriffith$elm_ui$Element$Input$BeforeFound = 1;
var $mdgriffith$elm_ui$Element$Input$Idle = 0;
var $mdgriffith$elm_ui$Element$Input$NotFound = 0;
var $mdgriffith$elm_ui$Element$Input$Selected = 2;
var $mdgriffith$elm_ui$Internal$Model$LivePolite = {$: 6};
var $mdgriffith$elm_ui$Element$Region$announce = $mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$LivePolite);
var $mdgriffith$elm_ui$Element$Input$applyLabel = F3(
	function (attrs, label, input) {
		if (label.$ === 1) {
			var labelText = label.a;
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asColumn,
				$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
				attrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[input])));
		} else {
			var position = label.a;
			var labelAttrs = label.b;
			var labelChild = label.c;
			var labelElement = A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				labelAttrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[labelChild])));
			switch (position) {
				case 2:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.ci),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
				case 3:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.ci),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				case 0:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.ci),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				default:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.ci),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
			}
		}
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $mdgriffith$elm_ui$Element$Input$column = F2(
	function (attributes, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					attributes)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$Input$downArrow = 'ArrowDown';
var $mdgriffith$elm_ui$Internal$Model$filter = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (x, _v0) {
				var found = _v0.a;
				var has = _v0.b;
				switch (x.$) {
					case 0:
						return _Utils_Tuple2(found, has);
					case 3:
						var key = x.a;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 1:
						var attr = x.a;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 4:
						var style = x.b;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 7:
						var width = x.a;
						return A2($elm$core$Set$member, 'width', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'width', has));
					case 8:
						var height = x.a;
						return A2($elm$core$Set$member, 'height', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'height', has));
					case 2:
						var description = x.a;
						return A2($elm$core$Set$member, 'described', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'described', has));
					case 9:
						var location = x.a;
						var elem = x.b;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 6:
						return A2($elm$core$Set$member, 'align-x', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'align-x', has));
					case 5:
						return A2($elm$core$Set$member, 'align-y', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'align-y', has));
					default:
						return A2($elm$core$Set$member, 'transform', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'transform', has));
				}
			}),
		_Utils_Tuple2(_List_Nil, $elm$core$Set$empty),
		attrs).a;
};
var $mdgriffith$elm_ui$Internal$Model$get = F2(
	function (attrs, isAttr) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, found) {
					return isAttr(x) ? A2($elm$core$List$cons, x, found) : found;
				}),
			_List_Nil,
			$mdgriffith$elm_ui$Internal$Model$filter(attrs));
	});
var $mdgriffith$elm_ui$Internal$Model$Label = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute = function (label) {
	if (label.$ === 1) {
		var textLabel = label.a;
		return $mdgriffith$elm_ui$Internal$Model$Describe(
			$mdgriffith$elm_ui$Internal$Model$Label(textLabel));
	} else {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	}
};
var $mdgriffith$elm_ui$Element$Input$leftArrow = 'ArrowLeft';
var $mdgriffith$elm_ui$Element$Input$rightArrow = 'ArrowRight';
var $mdgriffith$elm_ui$Element$Input$row = F2(
	function (attributes, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				attributes),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$Input$tabindex = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$tabindex);
var $mdgriffith$elm_ui$Element$Input$upArrow = 'ArrowUp';
var $mdgriffith$elm_ui$Element$Input$radioHelper = F3(
	function (orientation, attrs, input) {
		var track = F2(
			function (opt, _v14) {
				var found = _v14.a;
				var prev = _v14.b;
				var nxt = _v14.c;
				var val = opt.a;
				switch (found) {
					case 0:
						return _Utils_eq(
							$elm$core$Maybe$Just(val),
							input.aX) ? _Utils_Tuple3(1, prev, nxt) : _Utils_Tuple3(found, val, nxt);
					case 1:
						return _Utils_Tuple3(2, prev, val);
					default:
						return _Utils_Tuple3(found, prev, nxt);
				}
			});
		var renderOption = function (_v11) {
			var val = _v11.a;
			var view = _v11.b;
			var status = _Utils_eq(
				$elm$core$Maybe$Just(val),
				input.aX) ? 2 : 0;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$pointer,
						function () {
						if (!orientation) {
							return $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink);
						} else {
							return $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill);
						}
					}(),
						$mdgriffith$elm_ui$Element$Events$onClick(
						input.ae(val)),
						function () {
						if (status === 2) {
							return $mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'aria-checked', 'true'));
						} else {
							return $mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'aria-checked', 'false'));
						}
					}(),
						$mdgriffith$elm_ui$Internal$Model$Attr(
						A2($elm$html$Html$Attributes$attribute, 'role', 'radio'))
					]),
				view(status));
		};
		var prevNext = function () {
			var _v5 = input.aT;
			if (!_v5.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var _v6 = _v5.a;
				var val = _v6.a;
				return function (_v7) {
					var found = _v7.a;
					var b = _v7.b;
					var a = _v7.c;
					switch (found) {
						case 0:
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(b, val));
						case 1:
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(b, val));
						default:
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(b, a));
					}
				}(
					A3(
						$elm$core$List$foldl,
						track,
						_Utils_Tuple3(0, val, val),
						input.aT));
			}
		}();
		var optionArea = function () {
			if (!orientation) {
				return A2(
					$mdgriffith$elm_ui$Element$Input$row,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(input.d),
						attrs),
					A2($elm$core$List$map, renderOption, input.aT));
			} else {
				return A2(
					$mdgriffith$elm_ui$Element$Input$column,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(input.d),
						attrs),
					A2($elm$core$List$map, renderOption, input.aT));
			}
		}();
		var events = A2(
			$mdgriffith$elm_ui$Internal$Model$get,
			attrs,
			function (attr) {
				_v3$3:
				while (true) {
					switch (attr.$) {
						case 7:
							if (attr.a.$ === 2) {
								return true;
							} else {
								break _v3$3;
							}
						case 8:
							if (attr.a.$ === 2) {
								return true;
							} else {
								break _v3$3;
							}
						case 1:
							return true;
						default:
							break _v3$3;
					}
				}
				return false;
			});
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$elm$core$Maybe$Just($mdgriffith$elm_ui$Element$alignLeft),
							$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Element$Input$tabindex(0)),
							$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Internal$Model$htmlClass('focus')),
							$elm$core$Maybe$Just($mdgriffith$elm_ui$Element$Region$announce),
							$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'role', 'radiogroup'))),
							function () {
							if (prevNext.$ === 1) {
								return $elm$core$Maybe$Nothing;
							} else {
								var _v1 = prevNext.a;
								var prev = _v1.a;
								var next = _v1.b;
								return $elm$core$Maybe$Just(
									$mdgriffith$elm_ui$Element$Input$onKeyLookup(
										function (code) {
											if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$leftArrow)) {
												return $elm$core$Maybe$Just(
													input.ae(prev));
											} else {
												if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$upArrow)) {
													return $elm$core$Maybe$Just(
														input.ae(prev));
												} else {
													if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$rightArrow)) {
														return $elm$core$Maybe$Just(
															input.ae(next));
													} else {
														if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$downArrow)) {
															return $elm$core$Maybe$Just(
																input.ae(next));
														} else {
															if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space)) {
																var _v2 = input.aX;
																if (_v2.$ === 1) {
																	return $elm$core$Maybe$Just(
																		input.ae(prev));
																} else {
																	return $elm$core$Maybe$Nothing;
																}
															} else {
																return $elm$core$Maybe$Nothing;
															}
														}
													}
												}
											}
										}));
							}
						}()
						])),
				events),
			input.d,
			optionArea);
	});
var $mdgriffith$elm_ui$Element$Input$radio = $mdgriffith$elm_ui$Element$Input$radioHelper(1);
var $author$project$Style$colourRadio = F2(
	function (colour, msg) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$radio,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(2),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(100)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$scrollbarY,
					$mdgriffith$elm_ui$Element$Border$width(2),
					$mdgriffith$elm_ui$Element$Border$dashed
				]),
			{
				d: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{bG: 10, bK: 0, bV: 0, bZ: 0})
						]),
					$mdgriffith$elm_ui$Element$text('Colour')),
				ae: msg,
				aT: _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						4,
						$mdgriffith$elm_ui$Element$text('Green')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						5,
						$mdgriffith$elm_ui$Element$text('Blue')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						6,
						$mdgriffith$elm_ui$Element$text('Yellow')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						7,
						$mdgriffith$elm_ui$Element$text('Red')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						0,
						$mdgriffith$elm_ui$Element$text('White')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						1,
						$mdgriffith$elm_ui$Element$text('Light Grey')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						2,
						$mdgriffith$elm_ui$Element$text('Dark Grey')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						3,
						$mdgriffith$elm_ui$Element$text('Black'))
					]),
				aX: $elm$core$Maybe$Just(colour)
			});
	});
var $mdgriffith$elm_ui$Internal$Model$Max = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$maximum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Max, i, l);
	});
var $mdgriffith$elm_ui$Internal$Model$Min = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$minimum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Min, i, l);
	});
var $author$project$Main$editPanelWidth = $mdgriffith$elm_ui$Element$width(
	A2(
		$mdgriffith$elm_ui$Element$minimum,
		320,
		A2($mdgriffith$elm_ui$Element$maximum, 540, $mdgriffith$elm_ui$Element$fill)));
var $author$project$Controller$newCCValue = F6(
	function (label, labelSize, colour, channel, controller, value) {
		return $author$project$Controller$CCValue(
			{
				f: channel,
				i: colour,
				ar: controller,
				d: label,
				n: $elm$core$Maybe$Just(labelSize),
				c: 1,
				aE: value
			});
	});
var $author$project$Midi$stringToChannel = function (string) {
	switch (string) {
		case '1':
			return $elm$core$Maybe$Just(0);
		case '2':
			return $elm$core$Maybe$Just(1);
		case '3':
			return $elm$core$Maybe$Just(2);
		case '4':
			return $elm$core$Maybe$Just(3);
		case '5':
			return $elm$core$Maybe$Just(4);
		case '6':
			return $elm$core$Maybe$Just(5);
		case '7':
			return $elm$core$Maybe$Just(6);
		case '8':
			return $elm$core$Maybe$Just(7);
		case '9':
			return $elm$core$Maybe$Just(8);
		case '10':
			return $elm$core$Maybe$Just(9);
		case '11':
			return $elm$core$Maybe$Just(10);
		case '12':
			return $elm$core$Maybe$Just(11);
		case '13':
			return $elm$core$Maybe$Just(12);
		case '14':
			return $elm$core$Maybe$Just(13);
		case '15':
			return $elm$core$Maybe$Just(14);
		case '16':
			return $elm$core$Maybe$Just(15);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$EditableController$editStateToCCValue = function (_v0) {
	var label = _v0.d;
	var labelSize = _v0.n;
	var colour = _v0.i;
	var channel = _v0.f;
	var controller = _v0.ar;
	var value = _v0.aE;
	var _v1 = _Utils_Tuple3(
		$author$project$Midi$stringToChannel(channel),
		$elm$core$String$toInt(controller),
		$elm$core$String$toInt(value));
	if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
		var ch = _v1.a.a;
		var c = _v1.b.a;
		var v = _v1.c.a;
		return $elm$core$Maybe$Just(
			A6($author$project$Controller$newCCValue, label, labelSize, colour, ch, c, v));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Element$Input$Placeholder = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$Input$placeholder = $mdgriffith$elm_ui$Element$Input$Placeholder;
var $mdgriffith$elm_ui$Element$Input$TextInputNode = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$Input$TextArea = {$: 1};
var $mdgriffith$elm_ui$Element$Input$autofill = A2(
	$elm$core$Basics$composeL,
	$mdgriffith$elm_ui$Internal$Model$Attr,
	$elm$html$Html$Attributes$attribute('autocomplete'));
var $mdgriffith$elm_ui$Internal$Model$Behind = 5;
var $mdgriffith$elm_ui$Element$behindContent = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 5, element);
};
var $mdgriffith$elm_ui$Internal$Model$MoveY = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$TransformComponent = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$moveY = $mdgriffith$elm_ui$Internal$Flag$flag(26);
var $mdgriffith$elm_ui$Element$moveUp = function (y) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveY,
		$mdgriffith$elm_ui$Internal$Model$MoveY(-y));
};
var $mdgriffith$elm_ui$Element$Input$calcMoveToCompensateForPadding = function (attrs) {
	var gatherSpacing = F2(
		function (attr, found) {
			if ((attr.$ === 4) && (attr.b.$ === 5)) {
				var _v2 = attr.b;
				var x = _v2.b;
				var y = _v2.c;
				if (found.$ === 1) {
					return $elm$core$Maybe$Just(y);
				} else {
					return found;
				}
			} else {
				return found;
			}
		});
	var _v0 = A3($elm$core$List$foldr, gatherSpacing, $elm$core$Maybe$Nothing, attrs);
	if (_v0.$ === 1) {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	} else {
		var vSpace = _v0.a;
		return $mdgriffith$elm_ui$Element$moveUp(
			$elm$core$Basics$floor(vSpace / 2));
	}
};
var $mdgriffith$elm_ui$Element$clip = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.fu);
var $mdgriffith$elm_ui$Element$Input$darkGrey = A3($mdgriffith$elm_ui$Element$rgb, 186 / 255, 189 / 255, 182 / 255);
var $mdgriffith$elm_ui$Element$Input$defaultTextPadding = A2($mdgriffith$elm_ui$Element$paddingXY, 12, 12);
var $mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Input$defaultTextPadding,
		$mdgriffith$elm_ui$Element$Border$rounded(3),
		$mdgriffith$elm_ui$Element$Border$color($mdgriffith$elm_ui$Element$Input$darkGrey),
		$mdgriffith$elm_ui$Element$Background$color($mdgriffith$elm_ui$Element$Input$white),
		$mdgriffith$elm_ui$Element$Border$width(1),
		$mdgriffith$elm_ui$Element$spacing(5),
		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
		$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink)
	]);
var $mdgriffith$elm_ui$Element$Input$getHeight = function (attr) {
	if (attr.$ === 8) {
		var h = attr.a;
		return $elm$core$Maybe$Just(h);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Element$Input$isConstrained = function (len) {
	isConstrained:
	while (true) {
		switch (len.$) {
			case 1:
				return false;
			case 0:
				return true;
			case 2:
				return true;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isConstrained;
			default:
				var l = len.b;
				return true;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$isHiddenLabel = function (label) {
	if (label.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Element$Input$isStacked = function (label) {
	if (!label.$) {
		var loc = label.a;
		switch (loc) {
			case 0:
				return false;
			case 1:
				return false;
			case 2:
				return true;
			default:
				return true;
		}
	} else {
		return true;
	}
};
var $mdgriffith$elm_ui$Element$Input$negateBox = function (box) {
	return {bG: -box.bG, bK: -box.bK, bV: -box.bV, bZ: -box.bZ};
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $mdgriffith$elm_ui$Element$Input$isFill = function (len) {
	isFill:
	while (true) {
		switch (len.$) {
			case 2:
				return true;
			case 1:
				return false;
			case 0:
				return false;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isFill;
			default:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isFill;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$isPixel = function (len) {
	isPixel:
	while (true) {
		switch (len.$) {
			case 1:
				return false;
			case 0:
				return true;
			case 2:
				return false;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isPixel;
			default:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isPixel;
		}
	}
};
var $mdgriffith$elm_ui$Internal$Model$paddingNameFloat = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(top) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(right) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(bottom) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(left)))))));
	});
var $mdgriffith$elm_ui$Element$Input$redistributeOver = F4(
	function (isMultiline, stacked, attr, els) {
		switch (attr.$) {
			case 9:
				return _Utils_update(
					els,
					{
						l: A2($elm$core$List$cons, attr, els.l)
					});
			case 7:
				var width = attr.a;
				return $mdgriffith$elm_ui$Element$Input$isFill(width) ? _Utils_update(
					els,
					{
						r: A2($elm$core$List$cons, attr, els.r),
						gd: A2($elm$core$List$cons, attr, els.gd),
						l: A2($elm$core$List$cons, attr, els.l)
					}) : (stacked ? _Utils_update(
					els,
					{
						r: A2($elm$core$List$cons, attr, els.r)
					}) : _Utils_update(
					els,
					{
						l: A2($elm$core$List$cons, attr, els.l)
					}));
			case 8:
				var height = attr.a;
				return (!stacked) ? _Utils_update(
					els,
					{
						r: A2($elm$core$List$cons, attr, els.r),
						l: A2($elm$core$List$cons, attr, els.l)
					}) : ($mdgriffith$elm_ui$Element$Input$isFill(height) ? _Utils_update(
					els,
					{
						r: A2($elm$core$List$cons, attr, els.r),
						l: A2($elm$core$List$cons, attr, els.l)
					}) : ($mdgriffith$elm_ui$Element$Input$isPixel(height) ? _Utils_update(
					els,
					{
						l: A2($elm$core$List$cons, attr, els.l)
					}) : _Utils_update(
					els,
					{
						l: A2($elm$core$List$cons, attr, els.l)
					})));
			case 6:
				return _Utils_update(
					els,
					{
						r: A2($elm$core$List$cons, attr, els.r)
					});
			case 5:
				return _Utils_update(
					els,
					{
						r: A2($elm$core$List$cons, attr, els.r)
					});
			case 4:
				switch (attr.b.$) {
					case 5:
						var _v1 = attr.b;
						return _Utils_update(
							els,
							{
								r: A2($elm$core$List$cons, attr, els.r),
								gd: A2($elm$core$List$cons, attr, els.gd),
								l: A2($elm$core$List$cons, attr, els.l),
								bD: A2($elm$core$List$cons, attr, els.bD)
							});
					case 7:
						var cls = attr.a;
						var _v2 = attr.b;
						var pad = _v2.a;
						var t = _v2.b;
						var r = _v2.c;
						var b = _v2.d;
						var l = _v2.e;
						if (isMultiline) {
							return _Utils_update(
								els,
								{
									O: A2($elm$core$List$cons, attr, els.O),
									l: A2($elm$core$List$cons, attr, els.l)
								});
						} else {
							var newTop = t - A2($elm$core$Basics$min, t, b);
							var newLineHeight = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'line-height',
									'calc(1.0em + ' + ($elm$core$String$fromFloat(
										2 * A2($elm$core$Basics$min, t, b)) + 'px)')));
							var newHeight = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'height',
									'calc(1.0em + ' + ($elm$core$String$fromFloat(
										2 * A2($elm$core$Basics$min, t, b)) + 'px)')));
							var newBottom = b - A2($elm$core$Basics$min, t, b);
							var reducedVerticalPadding = A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, r, newBottom, l),
									newTop,
									r,
									newBottom,
									l));
							return _Utils_update(
								els,
								{
									O: A2($elm$core$List$cons, attr, els.O),
									gd: A2(
										$elm$core$List$cons,
										newHeight,
										A2($elm$core$List$cons, newLineHeight, els.gd)),
									l: A2($elm$core$List$cons, reducedVerticalPadding, els.l)
								});
						}
					case 6:
						var _v3 = attr.b;
						return _Utils_update(
							els,
							{
								O: A2($elm$core$List$cons, attr, els.O),
								l: A2($elm$core$List$cons, attr, els.l)
							});
					case 10:
						return _Utils_update(
							els,
							{
								O: A2($elm$core$List$cons, attr, els.O),
								l: A2($elm$core$List$cons, attr, els.l)
							});
					case 2:
						return _Utils_update(
							els,
							{
								r: A2($elm$core$List$cons, attr, els.r)
							});
					case 1:
						var _v4 = attr.b;
						return _Utils_update(
							els,
							{
								r: A2($elm$core$List$cons, attr, els.r)
							});
					default:
						var flag = attr.a;
						var cls = attr.b;
						return _Utils_update(
							els,
							{
								l: A2($elm$core$List$cons, attr, els.l)
							});
				}
			case 0:
				return els;
			case 1:
				var a = attr.a;
				return _Utils_update(
					els,
					{
						gd: A2($elm$core$List$cons, attr, els.gd)
					});
			case 2:
				return _Utils_update(
					els,
					{
						gd: A2($elm$core$List$cons, attr, els.gd)
					});
			case 3:
				return _Utils_update(
					els,
					{
						l: A2($elm$core$List$cons, attr, els.l)
					});
			default:
				return _Utils_update(
					els,
					{
						gd: A2($elm$core$List$cons, attr, els.gd)
					});
		}
	});
var $mdgriffith$elm_ui$Element$Input$redistribute = F3(
	function (isMultiline, stacked, attrs) {
		return function (redist) {
			return {
				O: $elm$core$List$reverse(redist.O),
				r: $elm$core$List$reverse(redist.r),
				gd: $elm$core$List$reverse(redist.gd),
				l: $elm$core$List$reverse(redist.l),
				bD: $elm$core$List$reverse(redist.bD)
			};
		}(
			A3(
				$elm$core$List$foldl,
				A2($mdgriffith$elm_ui$Element$Input$redistributeOver, isMultiline, stacked),
				{O: _List_Nil, r: _List_Nil, gd: _List_Nil, l: _List_Nil, bD: _List_Nil},
				attrs));
	});
var $mdgriffith$elm_ui$Element$Input$renderBox = function (_v0) {
	var top = _v0.bZ;
	var right = _v0.bV;
	var bottom = _v0.bG;
	var left = _v0.bK;
	return $elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px'))))));
};
var $mdgriffith$elm_ui$Internal$Model$Transparency = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$transparency = $mdgriffith$elm_ui$Internal$Flag$flag(0);
var $mdgriffith$elm_ui$Element$alpha = function (o) {
	var transparency = function (x) {
		return 1 - x;
	}(
		A2(
			$elm$core$Basics$min,
			1.0,
			A2($elm$core$Basics$max, 0.0, o)));
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2(
			$mdgriffith$elm_ui$Internal$Model$Transparency,
			'transparency-' + $mdgriffith$elm_ui$Internal$Model$floatClass(transparency),
			transparency));
};
var $mdgriffith$elm_ui$Element$Input$charcoal = A3($mdgriffith$elm_ui$Element$rgb, 136 / 255, 138 / 255, 133 / 255);
var $mdgriffith$elm_ui$Element$rgba = $mdgriffith$elm_ui$Internal$Model$Rgba;
var $mdgriffith$elm_ui$Element$Input$renderPlaceholder = F3(
	function (_v0, forPlaceholder, on) {
		var placeholderAttrs = _v0.a;
		var placeholderEl = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				forPlaceholder,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($mdgriffith$elm_ui$Element$Input$charcoal),
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.ef + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gM)),
							$mdgriffith$elm_ui$Element$clip,
							$mdgriffith$elm_ui$Element$Border$color(
							A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
							$mdgriffith$elm_ui$Element$Background$color(
							A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alpha(
							on ? 1 : 0)
						]),
					placeholderAttrs)),
			placeholderEl);
	});
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$spellcheck = $elm$html$Html$Attributes$boolProperty('spellcheck');
var $mdgriffith$elm_ui$Element$Input$spellcheck = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$spellcheck);
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $mdgriffith$elm_ui$Element$Input$value = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$value);
var $mdgriffith$elm_ui$Element$Input$textHelper = F3(
	function (textInput, attrs, textOptions) {
		var withDefaults = _Utils_ap($mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle, attrs);
		var redistributed = A3(
			$mdgriffith$elm_ui$Element$Input$redistribute,
			_Utils_eq(textInput.I, $mdgriffith$elm_ui$Element$Input$TextArea),
			$mdgriffith$elm_ui$Element$Input$isStacked(textOptions.d),
			withDefaults);
		var onlySpacing = function (attr) {
			if ((attr.$ === 4) && (attr.b.$ === 5)) {
				var _v9 = attr.b;
				return true;
			} else {
				return false;
			}
		};
		var heightConstrained = function () {
			var _v7 = textInput.I;
			if (!_v7.$) {
				var inputType = _v7.a;
				return false;
			} else {
				return A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						$mdgriffith$elm_ui$Element$Input$isConstrained,
						$elm$core$List$head(
							$elm$core$List$reverse(
								A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Element$Input$getHeight, withDefaults)))));
			}
		}();
		var getPadding = function (attr) {
			if ((attr.$ === 4) && (attr.b.$ === 7)) {
				var cls = attr.a;
				var _v6 = attr.b;
				var pad = _v6.a;
				var t = _v6.b;
				var r = _v6.c;
				var b = _v6.d;
				var l = _v6.e;
				return $elm$core$Maybe$Just(
					{
						bG: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(b - 3)),
						bK: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(l - 3)),
						bV: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(r - 3)),
						bZ: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(t - 3))
					});
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var parentPadding = A2(
			$elm$core$Maybe$withDefault,
			{bG: 0, bK: 0, bV: 0, bZ: 0},
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2($elm$core$List$filterMap, getPadding, withDefaults))));
		var inputElement = A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			function () {
				var _v3 = textInput.I;
				if (!_v3.$) {
					var inputType = _v3.a;
					return $mdgriffith$elm_ui$Internal$Model$NodeName('input');
				} else {
					return $mdgriffith$elm_ui$Internal$Model$NodeName('textarea');
				}
			}(),
			_Utils_ap(
				function () {
					var _v4 = textInput.I;
					if (!_v4.$) {
						var inputType = _v4.a;
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$type_(inputType)),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gi)
							]);
					} else {
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$clip,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.ge),
								$mdgriffith$elm_ui$Element$Input$calcMoveToCompensateForPadding(withDefaults),
								$mdgriffith$elm_ui$Element$paddingEach(parentPadding),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								A2(
									$elm$html$Html$Attributes$style,
									'margin',
									$mdgriffith$elm_ui$Element$Input$renderBox(
										$mdgriffith$elm_ui$Element$Input$negateBox(parentPadding)))),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$style, 'box-sizing', 'content-box'))
							]);
					}
				}(),
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Input$value(textOptions.dn),
							$mdgriffith$elm_ui$Internal$Model$Attr(
							$elm$html$Html$Events$onInput(textOptions.ae)),
							$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(textOptions.d),
							$mdgriffith$elm_ui$Element$Input$spellcheck(textInput.ao),
							A2(
							$elm$core$Maybe$withDefault,
							$mdgriffith$elm_ui$Internal$Model$NoAttribute,
							A2($elm$core$Maybe$map, $mdgriffith$elm_ui$Element$Input$autofill, textInput.ag))
						]),
					redistributed.gd)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil));
		var wrappedInput = function () {
			var _v0 = textInput.I;
			if (_v0.$ === 1) {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_Utils_ap(
						(heightConstrained ? $elm$core$List$cons($mdgriffith$elm_ui$Element$scrollbarY) : $elm$core$Basics$identity)(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, withDefaults) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dQ),
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gh)
								])),
						redistributed.l),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asParagraph,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Element$inFront(inputElement),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gg),
												redistributed.bD)))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(
									function () {
										if (textOptions.dn === '') {
											var _v1 = textOptions.e;
											if (_v1.$ === 1) {
												return _List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('\u00A0')
													]);
											} else {
												var place = _v1.a;
												return _List_fromArray(
													[
														A3($mdgriffith$elm_ui$Element$Input$renderPlaceholder, place, _List_Nil, textOptions.dn === '')
													]);
											}
										} else {
											return _List_fromArray(
												[
													$mdgriffith$elm_ui$Internal$Model$unstyled(
													A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.gf)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(textOptions.dn + '\u00A0')
															])))
												]);
										}
									}()))
							])));
			} else {
				var inputType = _v0.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						A2(
							$elm$core$List$cons,
							A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, withDefaults) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dQ),
							$elm$core$List$concat(
								_List_fromArray(
									[
										redistributed.l,
										function () {
										var _v2 = textOptions.e;
										if (_v2.$ === 1) {
											return _List_Nil;
										} else {
											var place = _v2.a;
											return _List_fromArray(
												[
													$mdgriffith$elm_ui$Element$behindContent(
													A3($mdgriffith$elm_ui$Element$Input$renderPlaceholder, place, redistributed.O, textOptions.dn === ''))
												]);
										}
									}()
									])))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[inputElement])));
			}
		}();
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			A2(
				$elm$core$List$cons,
				A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.fF),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(textOptions.d) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Element$spacing(5),
					A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$Region$announce, redistributed.r))),
			textOptions.d,
			wrappedInput);
	});
var $mdgriffith$elm_ui$Element$Input$text = $mdgriffith$elm_ui$Element$Input$textHelper(
	{
		ag: $elm$core$Maybe$Nothing,
		ao: false,
		I: $mdgriffith$elm_ui$Element$Input$TextInputNode('text')
	});
var $author$project$Style$editTextBox = F3(
	function (_v0, type_, msg) {
		var placeholder = _v0.e;
		var label = _v0.d;
		var current = _v0.g;
		return A2(
			$mdgriffith$elm_ui$Element$Input$text,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Border$width(2),
					$mdgriffith$elm_ui$Element$Border$rounded(0),
					$author$project$Style$borderColour(3),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$elm$html$Html$Attributes$type_(type_))
				]),
			{
				d: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					$mdgriffith$elm_ui$Element$text(label)),
				ae: msg,
				e: $elm$core$Maybe$Just(
					A2(
						$mdgriffith$elm_ui$Element$Input$placeholder,
						_List_Nil,
						$mdgriffith$elm_ui$Element$text(placeholder))),
				dn: current
			});
	});
var $author$project$Style$labelSizeRadio = F2(
	function (labelSize, msg) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$radio,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(2),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(100)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$scrollbarY,
					$mdgriffith$elm_ui$Element$Border$width(2),
					$mdgriffith$elm_ui$Element$Border$dashed
				]),
			{
				d: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{bG: 10, bK: 0, bV: 0, bZ: 0})
						]),
					$mdgriffith$elm_ui$Element$text('Label size')),
				ae: msg,
				aT: _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						0,
						$mdgriffith$elm_ui$Element$text('Small')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						1,
						$mdgriffith$elm_ui$Element$text('Medium')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						2,
						$mdgriffith$elm_ui$Element$text('Large')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						3,
						$mdgriffith$elm_ui$Element$text('ExtraLarge'))
					]),
				aX: $elm$core$Maybe$Just(labelSize)
			});
	});
var $author$project$Main$editCCValuePane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.d, d: 'Label', e: 'label'},
				'text',
				function (newLabel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditCCValue(
							_Utils_update(
								state,
								{d: newLabel})));
				}),
				A2(
				$author$project$Style$labelSizeRadio,
				state.n,
				function (newLabelSize) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditCCValue(
							_Utils_update(
								state,
								{n: newLabelSize})));
				}),
				A2(
				$author$project$Style$colourRadio,
				state.i,
				function (newColour) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditCCValue(
							_Utils_update(
								state,
								{i: newColour})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.f, d: 'Channel', e: 'channel#'},
				'number',
				function (newChannel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditCCValue(
							_Utils_update(
								state,
								{f: newChannel})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.ar, d: 'Controller\nNumber', e: 'controller#'},
				'number',
				function (newController) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditCCValue(
							_Utils_update(
								state,
								{ar: newController})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.aE, d: 'Value', e: 'value'},
				'number',
				function (newValue) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditCCValue(
							_Utils_update(
								state,
								{aE: newValue})));
				}),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (c) {
						return $author$project$Main$FinishedEdit(c);
					},
					$author$project$EditableController$editStateToCCValue(state)))
			]));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Chord$Chord = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Chord$chord = F2(
	function (rootPitchClass, theChordType) {
		return A2($duncanmalashock$elm_music_theory$Music$Internal$Chord$Chord, rootPitchClass, theChordType);
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$semitones = function (letter) {
	switch (letter) {
		case 0:
			return 0;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 5;
		case 4:
			return 7;
		case 5:
			return 9;
		default:
			return 11;
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$semitones = function (_v0) {
	var l = _v0.a;
	var o = _v0.b;
	return $duncanmalashock$elm_music_theory$Music$Internal$Letter$semitones(l) + o;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Scale$Heptatonic = function (a) {
	return {$: 2, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$Scale$Hexatonic = function (a) {
	return {$: 1, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$Scale$Octatonic = function (a) {
	return {$: 3, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$Scale$Pentatonic = function (a) {
	return {$: 0, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$Offset = $elm$core$Basics$identity;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$PitchClass = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $duncanmalashock$elm_music_theory$Util$Basic$applyNTimesHelp = F4(
	function (i, n, fn, v) {
		applyNTimesHelp:
		while (true) {
			if (_Utils_cmp(i, n) < 0) {
				var $temp$i = i + 1,
					$temp$n = n,
					$temp$fn = fn,
					$temp$v = fn(v);
				i = $temp$i;
				n = $temp$n;
				fn = $temp$fn;
				v = $temp$v;
				continue applyNTimesHelp;
			} else {
				return v;
			}
		}
	});
var $duncanmalashock$elm_music_theory$Util$Basic$applyNTimes = F3(
	function (n, fn, v) {
		return A4($duncanmalashock$elm_music_theory$Util$Basic$applyNTimesHelp, 0, n, fn, v);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$direction = function (_v0) {
	var dir = _v0.a;
	var intervalQuality = _v0.b;
	var intervalNumber = _v0.c;
	return dir;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$intervalNumberIndex = function (intervalNumber) {
	switch (intervalNumber.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		default:
			var anotherIntervalNumber = intervalNumber.a;
			return 7 + $duncanmalashock$elm_music_theory$Music$Internal$Interval$intervalNumberIndex(anotherIntervalNumber);
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$letter = function (_v0) {
	var l = _v0.a;
	return l;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$A = 5;
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$B = 6;
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$C = 0;
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$D = 1;
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$E = 2;
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$F = 3;
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$G = 4;
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$nextWithSemitoneCount = function (_v0) {
	var letter = _v0.a;
	var semis = _v0.b;
	switch (letter) {
		case 0:
			return _Utils_Tuple2(1, semis + 2);
		case 1:
			return _Utils_Tuple2(2, semis + 2);
		case 2:
			return _Utils_Tuple2(3, semis + 1);
		case 3:
			return _Utils_Tuple2(4, semis + 2);
		case 4:
			return _Utils_Tuple2(5, semis + 2);
		case 5:
			return _Utils_Tuple2(6, semis + 2);
		default:
			return _Utils_Tuple2(0, semis + 1);
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$number = function (_v0) {
	var n = _v0.c;
	return n;
};
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$offset = function (_v0) {
	var o = _v0.b;
	return o;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$prevWithSemitoneCount = function (_v0) {
	var letter = _v0.a;
	var semis = _v0.b;
	switch (letter) {
		case 0:
			return _Utils_Tuple2(6, semis - 1);
		case 6:
			return _Utils_Tuple2(5, semis - 2);
		case 5:
			return _Utils_Tuple2(4, semis - 2);
		case 4:
			return _Utils_Tuple2(3, semis - 2);
		case 3:
			return _Utils_Tuple2(2, semis - 1);
		case 2:
			return _Utils_Tuple2(1, semis - 2);
		default:
			return _Utils_Tuple2(0, semis - 2);
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$directionToInteger = function (dir) {
	if (!dir) {
		return 1;
	} else {
		return -1;
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$intervalNumberSemitones = function (intervalNumber) {
	switch (intervalNumber.$) {
		case 0:
			return 0;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 5;
		case 4:
			return 7;
		case 5:
			return 9;
		case 6:
			return 11;
		default:
			var anotherIntervalNumber = intervalNumber.a;
			return 12 + $duncanmalashock$elm_music_theory$Music$Internal$Interval$intervalNumberSemitones(anotherIntervalNumber);
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$intervalQualitySemitones = function (intervalQuality) {
	if (!intervalQuality.$) {
		var off = intervalQuality.a;
		return off;
	} else {
		var off = intervalQuality.a;
		return off;
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones = function (_v0) {
	var dir = _v0.a;
	var intervalQuality = _v0.b;
	var intervalNumber = _v0.c;
	return ($duncanmalashock$elm_music_theory$Music$Internal$Interval$intervalNumberSemitones(intervalNumber) + $duncanmalashock$elm_music_theory$Music$Internal$Interval$intervalQualitySemitones(intervalQuality)) * $duncanmalashock$elm_music_theory$Music$Internal$Interval$directionToInteger(dir);
};
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose = F2(
	function (interval, pc) {
		var startingLetter = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$letter(pc);
		var numberOfIntervalSteps = $duncanmalashock$elm_music_theory$Music$Internal$Interval$intervalNumberIndex(
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$number(interval));
		var _v0 = function () {
			var _v1 = $duncanmalashock$elm_music_theory$Music$Internal$Interval$direction(interval);
			if (!_v1) {
				return A3(
					$duncanmalashock$elm_music_theory$Util$Basic$applyNTimes,
					numberOfIntervalSteps,
					$duncanmalashock$elm_music_theory$Music$Internal$Letter$nextWithSemitoneCount,
					_Utils_Tuple2(startingLetter, 0));
			} else {
				return A3(
					$duncanmalashock$elm_music_theory$Util$Basic$applyNTimes,
					numberOfIntervalSteps,
					$duncanmalashock$elm_music_theory$Music$Internal$Letter$prevWithSemitoneCount,
					_Utils_Tuple2(startingLetter, 0));
			}
		}();
		var targetLetter = _v0.a;
		var semitoneDistance = _v0.b;
		return A2(
			$duncanmalashock$elm_music_theory$Music$Internal$PitchClass$PitchClass,
			targetLetter,
			($duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(interval) - semitoneDistance) + $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$offset(pc));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Scale$toScaleDegrees = function (_v0) {
	var scaleRoot = _v0.a;
	var theScaleType = _v0.b;
	switch (theScaleType.$) {
		case 0:
			var scaleTypeIntervals = theScaleType.a;
			return $duncanmalashock$elm_music_theory$Music$Internal$Scale$Pentatonic(
				{
					dP: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cu, scaleRoot),
					V: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cv, scaleRoot),
					gU: scaleRoot,
					_: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cw, scaleRoot),
					eO: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cx, scaleRoot)
				});
		case 1:
			var scaleTypeIntervals = theScaleType.a;
			return $duncanmalashock$elm_music_theory$Music$Internal$Scale$Hexatonic(
				{
					dP: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cu, scaleRoot),
					V: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cv, scaleRoot),
					gU: scaleRoot,
					_: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cw, scaleRoot),
					aB: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.dh, scaleRoot),
					eO: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cx, scaleRoot)
				});
		case 2:
			var scaleTypeIntervals = theScaleType.a;
			return $duncanmalashock$elm_music_theory$Music$Internal$Scale$Heptatonic(
				{
					dP: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cu, scaleRoot),
					V: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cv, scaleRoot),
					gU: scaleRoot,
					_: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cw, scaleRoot),
					g1: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.ey, scaleRoot),
					aB: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.dh, scaleRoot),
					eO: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cx, scaleRoot)
				});
		default:
			var scaleTypeIntervals = theScaleType.a;
			return $duncanmalashock$elm_music_theory$Music$Internal$Scale$Octatonic(
				{
					cW: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.gV, scaleRoot),
					dP: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cu, scaleRoot),
					V: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cv, scaleRoot),
					gU: scaleRoot,
					_: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cw, scaleRoot),
					g1: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.ey, scaleRoot),
					aB: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.dh, scaleRoot),
					eO: A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, scaleTypeIntervals.cx, scaleRoot)
				});
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Scale$toList = function (theScale) {
	var _v0 = $duncanmalashock$elm_music_theory$Music$Internal$Scale$toScaleDegrees(theScale);
	switch (_v0.$) {
		case 0:
			var scaleDegrees = _v0.a;
			return _List_fromArray(
				[scaleDegrees.gU, scaleDegrees._, scaleDegrees.eO, scaleDegrees.V, scaleDegrees.dP]);
		case 1:
			var scaleDegrees = _v0.a;
			return _List_fromArray(
				[scaleDegrees.gU, scaleDegrees._, scaleDegrees.eO, scaleDegrees.V, scaleDegrees.dP, scaleDegrees.aB]);
		case 2:
			var scaleDegrees = _v0.a;
			return _List_fromArray(
				[scaleDegrees.gU, scaleDegrees._, scaleDegrees.eO, scaleDegrees.V, scaleDegrees.dP, scaleDegrees.aB, scaleDegrees.g1]);
		default:
			var scaleDegrees = _v0.a;
			return _List_fromArray(
				[scaleDegrees.gU, scaleDegrees._, scaleDegrees.eO, scaleDegrees.V, scaleDegrees.dP, scaleDegrees.aB, scaleDegrees.g1, scaleDegrees.cW]);
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Scale$containsPitchClass = F3(
	function (thePitchClass, theScale, _v0) {
		var ignoreSpelling = _v0.f8;
		return ignoreSpelling ? A2(
			$elm$core$List$member,
			$duncanmalashock$elm_music_theory$Music$Internal$PitchClass$semitones(thePitchClass),
			A2(
				$elm$core$List$map,
				$duncanmalashock$elm_music_theory$Music$Internal$PitchClass$semitones,
				$duncanmalashock$elm_music_theory$Music$Internal$Scale$toList(theScale))) : A2(
			$elm$core$List$member,
			thePitchClass,
			$duncanmalashock$elm_music_theory$Music$Internal$Scale$toList(theScale));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$toIntervals = function (theChordType) {
	var chordFactors = theChordType;
	return chordFactors;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Chord$toPitchClasses = function (_v0) {
	var rootPitchClass = _v0.a;
	var theChordType = _v0.b;
	return A2(
		$elm$core$List$map,
		function (interval) {
			return A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose, interval, rootPitchClass);
		},
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$toIntervals(theChordType));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordScale$chordIsInScale = F2(
	function (scale, chord) {
		var pitchClasses = $duncanmalashock$elm_music_theory$Music$Internal$Chord$toPitchClasses(chord);
		return A2(
			$elm$core$List$all,
			function (pc) {
				return A3(
					$duncanmalashock$elm_music_theory$Music$Internal$Scale$containsPitchClass,
					pc,
					scale,
					{f8: true});
			},
			pitchClasses);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$ChordScale$diatonicChordsAt = function (_v0) {
	var root = _v0.gU;
	var scale = _v0.by;
	var chordTypesAllowed = _v0.dF;
	return A2(
		$elm$core$List$filter,
		$duncanmalashock$elm_music_theory$Music$Internal$ChordScale$chordIsInScale(scale),
		A2(
			$elm$core$List$map,
			$duncanmalashock$elm_music_theory$Music$Internal$Chord$chord(root),
			chordTypesAllowed));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordScale$allChordsInScale = F2(
	function (chordTypesAllowed, scale) {
		return A2(
			$elm$core$List$concatMap,
			function (pitchClass) {
				return $duncanmalashock$elm_music_theory$Music$Internal$ChordScale$diatonicChordsAt(
					{dF: chordTypesAllowed, gU: pitchClass, by: scale});
			},
			$duncanmalashock$elm_music_theory$Music$Internal$Scale$toList(scale));
	});
var $duncanmalashock$elm_music_theory$Music$Scale$allChords = F2(
	function (chordTypesAllowed, scale) {
		return A2($duncanmalashock$elm_music_theory$Music$Internal$ChordScale$allChordsInScale, chordTypesAllowed, scale);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$Pitch = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Octave$Octave = $elm$core$Basics$identity;
var $duncanmalashock$elm_music_theory$Music$Internal$Octave$octave = function (n) {
	return n;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$fromPitchClassWithInt = F2(
	function (o, p) {
		return A2(
			$duncanmalashock$elm_music_theory$Music$Internal$Pitch$Pitch,
			p,
			$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(o));
	});
var $duncanmalashock$elm_music_theory$Music$Pitch$fromPitchClassInOctave = F2(
	function (o, pc) {
		return A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$fromPitchClassWithInt, o, pc);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$natural = 0;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass = F2(
	function (l, o) {
		return A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$PitchClass, l, o);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$a = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 5, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$natural);
var $duncanmalashock$elm_music_theory$Music$PitchClass$a = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$a;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$flat = -1;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$aFlat = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 5, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$flat);
var $duncanmalashock$elm_music_theory$Music$PitchClass$aFlat = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$aFlat;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$sharp = 1;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$aSharp = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 5, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$sharp);
var $duncanmalashock$elm_music_theory$Music$PitchClass$aSharp = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$aSharp;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$b = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 6, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$natural);
var $duncanmalashock$elm_music_theory$Music$PitchClass$b = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$b;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$bFlat = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 6, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$flat);
var $duncanmalashock$elm_music_theory$Music$PitchClass$bFlat = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$bFlat;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$c = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 0, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$natural);
var $duncanmalashock$elm_music_theory$Music$PitchClass$c = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$c;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$cSharp = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 0, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$sharp);
var $duncanmalashock$elm_music_theory$Music$PitchClass$cSharp = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$cSharp;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$d = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 1, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$natural);
var $duncanmalashock$elm_music_theory$Music$PitchClass$d = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$d;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$dFlat = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 1, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$flat);
var $duncanmalashock$elm_music_theory$Music$PitchClass$dFlat = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$dFlat;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$dSharp = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 1, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$sharp);
var $duncanmalashock$elm_music_theory$Music$PitchClass$dSharp = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$dSharp;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$e = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 2, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$natural);
var $duncanmalashock$elm_music_theory$Music$PitchClass$e = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$e;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$eFlat = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 2, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$flat);
var $duncanmalashock$elm_music_theory$Music$PitchClass$eFlat = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$eFlat;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$f = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 3, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$natural);
var $duncanmalashock$elm_music_theory$Music$PitchClass$f = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$f;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$fSharp = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 3, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$sharp);
var $duncanmalashock$elm_music_theory$Music$PitchClass$fSharp = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$fSharp;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$g = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 4, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$natural);
var $duncanmalashock$elm_music_theory$Music$PitchClass$g = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$g;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$gFlat = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 4, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$flat);
var $duncanmalashock$elm_music_theory$Music$PitchClass$gFlat = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$gFlat;
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$gSharp = A2($duncanmalashock$elm_music_theory$Music$Internal$PitchClass$pitchClass, 4, $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$sharp);
var $duncanmalashock$elm_music_theory$Music$PitchClass$gSharp = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$gSharp;
var $author$project$Main$keyToPitchClass = function (key) {
	switch (key) {
		case 0:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$c;
		case 1:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$cSharp;
		case 2:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$d;
		case 3:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$dSharp;
		case 4:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$dFlat;
		case 5:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$e;
		case 6:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$eFlat;
		case 7:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$f;
		case 8:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$fSharp;
		case 9:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$g;
		case 10:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$gSharp;
		case 11:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$gFlat;
		case 12:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$a;
		case 13:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$aSharp;
		case 14:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$aFlat;
		case 15:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$b;
		default:
			return $duncanmalashock$elm_music_theory$Music$PitchClass$bFlat;
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Fourth = {$: 3};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave = function (a) {
	return {$: 7, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Offset = $elm$core$Basics$identity;
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Perfect = function (a) {
	return {$: 0, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectAugmented = $duncanmalashock$elm_music_theory$Music$Internal$Interval$Perfect(1);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Up = 0;
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$up = 0;
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedEleventh = A3(
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$up,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectAugmented,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave($duncanmalashock$elm_music_theory$Music$Internal$Interval$Fourth));
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Fifth = {$: 4};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedFifth = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectAugmented, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Fifth);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Second = {$: 1};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Imperfect = function (a) {
	return {$: 1, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$imperfectAugmented = $duncanmalashock$elm_music_theory$Music$Internal$Interval$Imperfect(1);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedNinth = A3(
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$up,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$imperfectAugmented,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave($duncanmalashock$elm_music_theory$Music$Internal$Interval$Second));
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectDiminished = $duncanmalashock$elm_music_theory$Music$Internal$Interval$Perfect(-1);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedFifth = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectDiminished, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Fifth);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Seventh = {$: 6};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$imperfectDiminished = $duncanmalashock$elm_music_theory$Music$Internal$Interval$Imperfect(-2);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedSeventh = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$imperfectDiminished, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Seventh);
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes = F2(
	function (theInterval, _v0) {
		var intervals = _v0;
		return A2($elm$core$List$member, theInterval, intervals);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$major = $duncanmalashock$elm_music_theory$Music$Internal$Interval$Imperfect(0);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorNinth = A3(
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$up,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$major,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave($duncanmalashock$elm_music_theory$Music$Internal$Interval$Second));
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$major, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Second);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$major, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Seventh);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Sixth = {$: 5};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$major, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Sixth);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Third = {$: 2};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$major, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Third);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThirteenth = A3(
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$up,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$major,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave($duncanmalashock$elm_music_theory$Music$Internal$Interval$Sixth));
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$minor = $duncanmalashock$elm_music_theory$Music$Internal$Interval$Imperfect(-1);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorNinth = A3(
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$up,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$minor,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave($duncanmalashock$elm_music_theory$Music$Internal$Interval$Second));
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSeventh = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minor, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Seventh);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minor, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Third);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThirteenth = A3(
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$up,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$minor,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave($duncanmalashock$elm_music_theory$Music$Internal$Interval$Sixth));
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfect = $duncanmalashock$elm_music_theory$Music$Internal$Interval$Perfect(0);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectEleventh = A3(
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$up,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfect,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave($duncanmalashock$elm_music_theory$Music$Internal$Interval$Fourth));
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfect, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Fifth);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfect, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Fourth);
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$pickFirst = F2(
	function (choices, theChordType) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (factor) {
					return A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, factor, theChordType);
				},
				choices));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$categorizeFactors = function (theChordType) {
	var maybeThird = A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$pickFirst,
		_List_fromArray(
			[$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth]),
		theChordType);
	var maybeFifth = A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$pickFirst,
		_List_fromArray(
			[$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth, $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedFifth, $duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedFifth]),
		theChordType);
	return A3(
		$elm$core$Maybe$map2,
		F2(
			function (third, fifth) {
				return {
					fQ: A2(
						$duncanmalashock$elm_music_theory$Music$Internal$ChordType$pickFirst,
						_List_fromArray(
							[$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectEleventh, $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedEleventh]),
						theChordType),
					dP: fifth,
					gu: A2(
						$elm$core$List$filter,
						function (interval) {
							return A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, interval, theChordType);
						},
						_List_fromArray(
							[$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorNinth, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorNinth, $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedNinth])),
					g6: A2(
						$duncanmalashock$elm_music_theory$Music$Internal$ChordType$pickFirst,
						_List_fromArray(
							[$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSeventh, $duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedSeventh]),
						theChordType),
					eO: third,
					hw: A2(
						$duncanmalashock$elm_music_theory$Music$Internal$ChordType$pickFirst,
						_List_fromArray(
							[$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThirteenth, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThirteenth]),
						theChordType)
				};
			}),
		maybeThird,
		maybeFifth);
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$VoicingMethod = $elm$core$Basics$identity;
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$custom = F2(
	function (categorizeFn, buildFromCategorized) {
		return A2(
			$elm$core$Basics$composeR,
			categorizeFn,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(buildFromCategorized),
				$elm$core$Maybe$withDefault(_List_Nil)));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Unison = {$: 0};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfect, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Unison);
var $duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$execute = F2(
	function (_v0, _v1) {
		var placeFactors = _v0.gN;
		var voicingsWithIntervals = _v1;
		return A2(
			$elm$core$List$concatMap,
			placeFactors,
			A2(
				$elm$core$List$map,
				function (_v2) {
					var v = _v2.a;
					var i = _v2.b;
					return v;
				},
				voicingsWithIntervals));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$addOctave = function (_v0) {
	var dir = _v0.a;
	var intervalQuality = _v0.b;
	var intervalNumber = _v0.c;
	return A3(
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
		dir,
		intervalQuality,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave(intervalNumber));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Down = 1;
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$addOffset = F2(
	function (_int, _v0) {
		var dir = _v0.a;
		var qual = _v0.b;
		var num = _v0.c;
		if (!qual.$) {
			var offsetInt = qual.a;
			return A3(
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
				dir,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$Perfect(offsetInt + _int),
				num);
		} else {
			var offsetInt = qual.a;
			return A3(
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
				dir,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$Imperfect(offsetInt + _int),
				num);
		}
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$indexToIntervalNumber = function (_int) {
	indexToIntervalNumber:
	while (true) {
		switch (_int) {
			case 0:
				return $duncanmalashock$elm_music_theory$Music$Internal$Interval$Unison;
			case 1:
				return $duncanmalashock$elm_music_theory$Music$Internal$Interval$Second;
			case 2:
				return $duncanmalashock$elm_music_theory$Music$Internal$Interval$Third;
			case 3:
				return $duncanmalashock$elm_music_theory$Music$Internal$Interval$Fourth;
			case 4:
				return $duncanmalashock$elm_music_theory$Music$Internal$Interval$Fifth;
			case 5:
				return $duncanmalashock$elm_music_theory$Music$Internal$Interval$Sixth;
			case 6:
				return $duncanmalashock$elm_music_theory$Music$Internal$Interval$Seventh;
			default:
				var absoluteInt = $elm$core$Basics$abs(_int);
				if (absoluteInt >= 7) {
					return $duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave(
						$duncanmalashock$elm_music_theory$Music$Internal$Interval$indexToIntervalNumber(absoluteInt - 7));
				} else {
					var $temp$int = absoluteInt;
					_int = $temp$int;
					continue indexToIntervalNumber;
				}
		}
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$numberToQuality = function (num) {
	numberToQuality:
	while (true) {
		var imperfect = $duncanmalashock$elm_music_theory$Music$Internal$Interval$major;
		switch (num.$) {
			case 0:
				return $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfect;
			case 1:
				return imperfect;
			case 2:
				return imperfect;
			case 3:
				return $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfect;
			case 4:
				return $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfect;
			case 5:
				return imperfect;
			case 6:
				return imperfect;
			default:
				var remainingNum = num.a;
				var $temp$num = remainingNum;
				num = $temp$num;
				continue numberToQuality;
		}
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$add = F2(
	function (intA, intB) {
		var directionA = intA.a;
		var qualityA = intA.b;
		var numberA = intA.c;
		var directionB = intB.a;
		var qualityB = intB.b;
		var numberB = intB.c;
		var semitoneTarget = $duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(intA) + $duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(intB);
		var indexB = $duncanmalashock$elm_music_theory$Music$Internal$Interval$intervalNumberIndex(numberB) * $duncanmalashock$elm_music_theory$Music$Internal$Interval$directionToInteger(directionB);
		var indexA = $duncanmalashock$elm_music_theory$Music$Internal$Interval$intervalNumberIndex(numberA) * $duncanmalashock$elm_music_theory$Music$Internal$Interval$directionToInteger(directionA);
		var indexSum = indexA + indexB;
		var finalNumber = $duncanmalashock$elm_music_theory$Music$Internal$Interval$indexToIntervalNumber(indexSum);
		var initialQuality = $duncanmalashock$elm_music_theory$Music$Internal$Interval$numberToQuality(finalNumber);
		var dir = (indexSum >= 0) ? 0 : 1;
		var initialResult = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, dir, initialQuality, finalNumber);
		var semitonesActual = $duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(initialResult);
		var semitonesError = (semitoneTarget - semitonesActual) * $duncanmalashock$elm_music_theory$Music$Internal$Interval$directionToInteger(dir);
		return A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$addOffset, semitonesError, initialResult);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$reverse = function (_v0) {
	var dir = _v0.a;
	var intervalQuality = _v0.b;
	var intervalNumber = _v0.c;
	if (!dir) {
		return A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, 1, intervalQuality, intervalNumber);
	} else {
		return A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, 0, intervalQuality, intervalNumber);
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract = F2(
	function (subtrahend, minuend) {
		return A2(
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$add,
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$reverse(subtrahend),
			minuend);
	});
var $duncanmalashock$elm_music_theory$Util$Basic$while = F3(
	function (predicate, fn, val) {
		_while:
		while (true) {
			if (predicate(val)) {
				var $temp$predicate = predicate,
					$temp$fn = fn,
					$temp$val = fn(val);
				predicate = $temp$predicate;
				fn = $temp$fn;
				val = $temp$val;
				continue _while;
			} else {
				return val;
			}
		}
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$adjustToBeAboveMinimum = function (_v0) {
	var minimum = _v0.bO;
	var lower = _v0.c8;
	var upper = _v0.dp;
	var lowerIsAboveUpper = F2(
		function (l, u) {
			return $duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(
				A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract, l, u)) < 0;
		});
	var differenceIsBelowMinimum = F2(
		function (l, u) {
			return _Utils_cmp(
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(
					A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract, l, u)),
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(minimum)) < 0;
		});
	return A3(
		$duncanmalashock$elm_music_theory$Util$Basic$while,
		function (currentUpper) {
			return A2(lowerIsAboveUpper, lower, currentUpper) || A2(differenceIsBelowMinimum, lower, currentUpper);
		},
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$addOctave,
		upper);
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$max = function (_v0) {
	var rangeMin = _v0.a;
	var rangeMax = _v0.b;
	return rangeMax;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$min = function (_v0) {
	var rangeMin = _v0.a;
	var rangeMax = _v0.b;
	return rangeMin;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave = A3(
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$up,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfect,
	$duncanmalashock$elm_music_theory$Music$Internal$Interval$Octave($duncanmalashock$elm_music_theory$Music$Internal$Interval$Unison));
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$octaveShiftsAllowedBetween = function (_v0) {
	var maximum = _v0.bM;
	var lower = _v0.c8;
	var upper = _v0.dp;
	var difference = F2(
		function (l, u) {
			return $duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(
				A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract, l, u));
		});
	var differenceIsBelowMaximum = F2(
		function (l, u) {
			return _Utils_cmp(
				A2(difference, l, u),
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(maximum)) < 0;
		});
	var maximumIsAtLeastCurrentDifferencePlusAnOctave = F2(
		function (l, u) {
			return _Utils_cmp(
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(maximum),
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones($duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave) + A2(difference, l, u)) > -1;
		});
	return function (_v3) {
		var count = _v3.a;
		var shouldBeAbove = _v3.b;
		return count;
	}(
		A3(
			$duncanmalashock$elm_music_theory$Util$Basic$while,
			function (_v1) {
				var count = _v1.a;
				var currentUpper = _v1.b;
				return A2(differenceIsBelowMaximum, lower, currentUpper) && A2(maximumIsAtLeastCurrentDifferencePlusAnOctave, lower, currentUpper);
			},
			function (_v2) {
				var count = _v2.a;
				var shouldBeAbove = _v2.b;
				return _Utils_Tuple2(
					count + 1,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$addOctave(shouldBeAbove));
			},
			_Utils_Tuple2(0, upper)));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$shiftCountToIntervalList = function (count) {
	return A2(
		$elm$core$List$map,
		function (currentCount) {
			return A3($duncanmalashock$elm_music_theory$Util$Basic$applyNTimes, currentCount, $duncanmalashock$elm_music_theory$Music$Internal$Interval$addOctave, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison);
		},
		A2($elm$core$List$range, 0, count));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$shiftFromVoiceOne = F2(
	function (shiftCount, voicingClasses) {
		var doShifts = F2(
			function (intervalsToShift, vc) {
				return A2(
					$elm$core$List$map,
					function (shift) {
						return _Utils_update(
							vc,
							{
								eS: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, shift, vc.eS)
							});
					},
					intervalsToShift);
			});
		return A2(
			$elm$core$List$concatMap,
			doShifts(
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$shiftCountToIntervalList(shiftCount)),
			voicingClasses);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$shiftFromVoiceThree = F2(
	function (shiftCount, voicingClasses) {
		var doShifts = F2(
			function (intervalsToShift, vc) {
				return A2(
					$elm$core$List$map,
					function (shift) {
						return _Utils_update(
							vc,
							{
								eS: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, shift, vc.eS),
								eT: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, shift, vc.eT),
								eU: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, shift, vc.eU)
							});
					},
					intervalsToShift);
			});
		return A2(
			$elm$core$List$concatMap,
			doShifts(
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$shiftCountToIntervalList(shiftCount)),
			voicingClasses);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$shiftFromVoiceTwo = F2(
	function (shiftCount, voicingClasses) {
		var doShifts = F2(
			function (intervalsToShift, vc) {
				return A2(
					$elm$core$List$map,
					function (shift) {
						return _Utils_update(
							vc,
							{
								eS: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, shift, vc.eS),
								eU: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, shift, vc.eU)
							});
					},
					intervalsToShift);
			});
		return A2(
			$elm$core$List$concatMap,
			doShifts(
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$shiftCountToIntervalList(shiftCount)),
			voicingClasses);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$performAllowedOctaveShifts = F2(
	function (_v0, initialVoicingClass) {
		var shiftsForVoiceThree = _v0.eG;
		var shiftsForVoiceTwo = _v0.eH;
		var shiftsForVoiceOne = _v0.eF;
		return A2(
			$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$shiftFromVoiceOne,
			shiftsForVoiceOne,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$shiftFromVoiceTwo,
				shiftsForVoiceTwo,
				A2(
					$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$shiftFromVoiceThree,
					shiftsForVoiceThree,
					_List_fromArray(
						[initialVoicingClass]))));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$toSimple = function (_v0) {
	toSimple:
	while (true) {
		var dir = _v0.a;
		var qual = _v0.b;
		var num = _v0.c;
		if (num.$ === 7) {
			var innerNum = num.a;
			var $temp$_v0 = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, dir, qual, innerNum);
			_v0 = $temp$_v0;
			continue toSimple;
		} else {
			return A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, dir, qual, num);
		}
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$validateInitialVoicingClass = F2(
	function (intervalLimits, voicingClass) {
		var differenceMeetsMinimum = F3(
			function (l, u, minimum) {
				return _Utils_cmp(
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(
						A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract, l, u)),
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(minimum)) > -1;
			});
		var differenceMeetsMaximum = F3(
			function (l, u, maximum) {
				return _Utils_cmp(
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(
						A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract, l, u)),
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(maximum)) < 1;
			});
		return A2(
			$elm$core$List$all,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					differenceMeetsMinimum,
					voicingClass.hI,
					voicingClass.eT,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$min(intervalLimits.au)),
					A3(
					differenceMeetsMaximum,
					voicingClass.hI,
					voicingClass.eT,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$max(intervalLimits.au)),
					A3(
					differenceMeetsMinimum,
					voicingClass.eT,
					voicingClass.eU,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$min(intervalLimits.aC)),
					A3(
					differenceMeetsMaximum,
					voicingClass.eT,
					voicingClass.eU,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$max(intervalLimits.aC)),
					A3(
					differenceMeetsMinimum,
					voicingClass.eU,
					voicingClass.eS,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$min(intervalLimits.aD)),
					A3(
					differenceMeetsMaximum,
					voicingClass.eU,
					voicingClass.eS,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$max(intervalLimits.aD))
				])) ? $elm$core$Maybe$Just(voicingClass) : $elm$core$Maybe$Nothing;
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$placeFactors = F2(
	function (intervalLimits, voicingClass) {
		var voiceFourInitial = $duncanmalashock$elm_music_theory$Music$Internal$Interval$toSimple(voicingClass.hI);
		var voiceThreeInitial = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$adjustToBeAboveMinimum(
			{
				c8: voiceFourInitial,
				bO: $duncanmalashock$elm_music_theory$Music$Internal$Interval$min(intervalLimits.au),
				dp: $duncanmalashock$elm_music_theory$Music$Internal$Interval$toSimple(voicingClass.eT)
			});
		var voiceTwoInitial = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$adjustToBeAboveMinimum(
			{
				c8: voiceThreeInitial,
				bO: $duncanmalashock$elm_music_theory$Music$Internal$Interval$min(intervalLimits.aC),
				dp: $duncanmalashock$elm_music_theory$Music$Internal$Interval$toSimple(voicingClass.eU)
			});
		var voiceOneInitial = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$adjustToBeAboveMinimum(
			{
				c8: voiceTwoInitial,
				bO: $duncanmalashock$elm_music_theory$Music$Internal$Interval$min(intervalLimits.aD),
				dp: $duncanmalashock$elm_music_theory$Music$Internal$Interval$toSimple(voicingClass.eS)
			});
		var twoToOneOctaveShifts = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$octaveShiftsAllowedBetween(
			{
				c8: voiceTwoInitial,
				bM: $duncanmalashock$elm_music_theory$Music$Internal$Interval$max(intervalLimits.aD),
				dp: voiceOneInitial
			});
		var threeToTwoOctaveShifts = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$octaveShiftsAllowedBetween(
			{
				c8: voiceThreeInitial,
				bM: $duncanmalashock$elm_music_theory$Music$Internal$Interval$max(intervalLimits.aC),
				dp: voiceTwoInitial
			});
		var fourToThreeOctaveShifts = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$octaveShiftsAllowedBetween(
			{
				c8: voiceFourInitial,
				bM: $duncanmalashock$elm_music_theory$Music$Internal$Interval$max(intervalLimits.au),
				dp: voiceThreeInitial
			});
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$performAllowedOctaveShifts(
					{eF: twoToOneOctaveShifts, eG: fourToThreeOctaveShifts, eH: threeToTwoOctaveShifts}),
				A2(
					$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$validateInitialVoicingClass,
					intervalLimits,
					{hI: voiceFourInitial, eS: voiceOneInitial, eT: voiceThreeInitial, eU: voiceTwoInitial})));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$placeSelectedFactors = F2(
	function (voiceIntervalLimits, builder) {
		return A2(
			$duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$execute,
			{
				gN: $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$placeFactors(voiceIntervalLimits)
			},
			builder);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$VoicingClass = F4(
	function (voiceOne, voiceTwo, voiceThree, voiceFour) {
		return {hI: voiceFour, eS: voiceOne, eT: voiceThree, eU: voiceTwo};
	});
var $duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$VoicingClassBuilder = $elm$core$Basics$identity;
var $duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$builder = function (construct) {
	return _List_fromArray(
		[
			_Utils_Tuple2(construct, _List_Nil)
		]);
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$selectFactors = $duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$builder($duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$VoicingClass);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedUnison = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectAugmented, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Unison);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$Range = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$range = F2(
	function (a, b) {
		return (_Utils_cmp(
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(a),
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(b)) < 0) ? A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$Range, a, b) : A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$Range, b, a);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$Basic$spacingLimits = {
	au: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$range, $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedUnison, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave),
	aC: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$range, $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedUnison, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave),
	aD: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$range, $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedUnison, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave)
};
var $duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$withFactor = F3(
	function (newInterval, _v0, _v1) {
		var mustBeUnique = _v0.aQ;
		var voicingsSoFar = _v1;
		var addIntervalToVoicing = F2(
			function (intervalToApply, _v2) {
				var theVoicing = _v2.a;
				var intervalsUsed = _v2.b;
				return (mustBeUnique && A2($elm$core$List$member, intervalToApply, intervalsUsed)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					_Utils_Tuple2(
						theVoicing(intervalToApply),
						A2($elm$core$List$cons, intervalToApply, intervalsUsed)));
			});
		return A2(
			$elm$core$List$filterMap,
			function (voicingWithIntervals) {
				return A2(addIntervalToVoicing, newInterval, voicingWithIntervals);
			},
			voicingsSoFar);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$withFactor = F2(
	function (factor, builder) {
		return A3(
			$duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$withFactor,
			factor,
			{aQ: false},
			builder);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$withFactorFrom = F3(
	function (options, _v0, _v1) {
		var mustBeUnique = _v0.aQ;
		var voicingsSoFar = _v1;
		var addIntervalToVoicing = F2(
			function (intervalToApply, _v2) {
				var theVoicing = _v2.a;
				var intervalsUsed = _v2.b;
				return (mustBeUnique && A2($elm$core$List$member, intervalToApply, intervalsUsed)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					_Utils_Tuple2(
						theVoicing(intervalToApply),
						A2($elm$core$List$cons, intervalToApply, intervalsUsed)));
			});
		return A2(
			$elm$core$List$concatMap,
			function (voicingWithIntervals) {
				return A2(
					$elm$core$List$filterMap,
					function (interval) {
						return A2(addIntervalToVoicing, interval, voicingWithIntervals);
					},
					options);
			},
			voicingsSoFar);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$withFactorFrom = F2(
	function (options, builder) {
		return A3(
			$duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$withFactorFrom,
			options,
			{aQ: false},
			builder);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$Basic$basic = A2(
	$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$custom,
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$categorizeFactors,
	function (factors) {
		return A2(
			$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$placeSelectedFactors,
			$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$Basic$spacingLimits,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$withFactor,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison,
				A2(
					$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$withFactor,
					factors.eO,
					A2(
						$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$withFactor,
						factors.dP,
						A2(
							$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$withFactorFrom,
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[
										$elm$core$Maybe$Just($duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison),
										factors.g6
									])),
							$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$selectFactors)))));
	});
var $duncanmalashock$elm_music_theory$Music$Voicing$FourPart$basic = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$Basic$basic;
var $duncanmalashock$elm_music_theory$Music$Interval$majorSeventh = $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh;
var $author$project$Controller$newChord = F5(
	function (label, labelSize, colour, velocity, notes) {
		return $author$project$Controller$Chord(
			{
				i: colour,
				d: label,
				n: $elm$core$Maybe$Just(labelSize),
				aR: notes,
				c: 1,
				E: velocity
			});
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$Range = $elm$core$Basics$identity;
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$range = F2(
	function (lower, upper) {
		return {c8: lower, dp: upper};
	});
var $duncanmalashock$elm_music_theory$Music$Range$range = F2(
	function (lower, upper) {
		return A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$range, lower, upper);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Octave$semitones = function (_v0) {
	var o = _v0;
	return o * 12;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$semitones = function (_v0) {
	var pc = _v0.a;
	var o = _v0.b;
	return $duncanmalashock$elm_music_theory$Music$Internal$Octave$semitones(o) + $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$semitones(pc);
};
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$toMIDINoteNumber = function (thePitch) {
	return $duncanmalashock$elm_music_theory$Music$Internal$Pitch$semitones(thePitch) + 12;
};
var $duncanmalashock$elm_music_theory$Music$Pitch$toMIDINoteNumber = function (pitch) {
	return $duncanmalashock$elm_music_theory$Music$Internal$Pitch$toMIDINoteNumber(pitch);
};
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$fromPitchClass = F2(
	function (o, p) {
		return A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$Pitch, p, o);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Chord$root = function (_v0) {
	var rootPitchClass = _v0.a;
	return rootPitchClass;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$root = function (_v0) {
	var ch = _v0.a;
	var theOctave = _v0.b;
	var vc = _v0.c;
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$Pitch$fromPitchClass,
		theOctave,
		$duncanmalashock$elm_music_theory$Music$Internal$Chord$root(ch));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$pitchClass = function (_v0) {
	var pc = _v0.a;
	return pc;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$transpose = F2(
	function (interval, p) {
		var transposedPitchClass = A2(
			$duncanmalashock$elm_music_theory$Music$Internal$PitchClass$transpose,
			interval,
			$duncanmalashock$elm_music_theory$Music$Internal$Pitch$pitchClass(p));
		var transposedSemitones = $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$semitones(transposedPitchClass);
		var intervalSemitoneTarget = $duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(interval);
		var semitoneError = intervalSemitoneTarget - transposedSemitones;
		var initialSemitones = $duncanmalashock$elm_music_theory$Music$Internal$Pitch$semitones(p);
		var targetOctaveSemitones = initialSemitones + semitoneError;
		var numberOfOctaves = (targetOctaveSemitones / 12) | 0;
		return A2(
			$duncanmalashock$elm_music_theory$Music$Internal$Pitch$Pitch,
			transposedPitchClass,
			$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(numberOfOctaves));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$transposeUp = F2(
	function (theInterval, thePitch) {
		return A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$transpose, theInterval, thePitch);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$voicingClass = function (_v0) {
	var ch = _v0.a;
	var theOctave = _v0.b;
	var vc = _v0.c;
	return vc;
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$toPitches = function (theVoicing) {
	var vc = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$voicingClass(theVoicing);
	var theRoot = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$root(theVoicing);
	return {
		hI: A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$transposeUp, vc.hI, theRoot),
		eS: A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$transposeUp, vc.eS, theRoot),
		eT: A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$transposeUp, vc.eT, theRoot),
		eU: A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$transposeUp, vc.eU, theRoot)
	};
};
var $duncanmalashock$elm_music_theory$Music$Voicing$FourPart$toPitchList = function (theVoicing) {
	return function (v) {
		return _List_fromArray(
			[v.eS, v.eU, v.eT, v.hI]);
	}(
		$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$toPitches(theVoicing));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$FlatFive = 1;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$SharpFive = 0;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$alteredExtensionToSymbol = function (alteration) {
	switch (alteration) {
		case 0:
			return '♭9';
		case 1:
			return '♯9';
		case 2:
			return '♯11';
		default:
			return '♭13';
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$specialCaseAlterationsToSymbol = function (alteration) {
	switch (alteration) {
		case 1:
			return '♭5';
		case 0:
			return '♯5';
		default:
			return 'add9';
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols = F2(
	function (specialCaseAlterations, alterations) {
		return function (symbols) {
			return $elm$core$List$isEmpty(symbols) ? '' : ('(' + (A2($elm$core$String$join, ',', symbols) + ')'));
		}(
			_Utils_ap(
				A2($elm$core$List$map, $duncanmalashock$elm_music_theory$Music$Internal$ChordType$specialCaseAlterationsToSymbol, specialCaseAlterations),
				A2($elm$core$List$map, $duncanmalashock$elm_music_theory$Music$Internal$ChordType$alteredExtensionToSymbol, alterations)));
	});
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$AugmentedEleventh = 2;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$AugmentedNinth = 1;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$AugmentedTriad = 1;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$Classification = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$DiminishedSeventh = 3;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$DiminishedTriad = 3;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$Eleventh = 1;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$MajorSeventh = 1;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$MajorTriad = 0;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$MinorNinth = 0;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$MinorSeventh = 2;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$MinorThirteenth = 3;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$MinorTriad = 2;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$Ninth = 0;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$Sixth = 0;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$Sus2Triad = 4;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$Sus4Triad = 5;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$Thirteenth = 2;
var $duncanmalashock$elm_music_theory$Music$Chord$Classification$Unclassifiable = function (a) {
	return {$: 1, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$includesAll = F2(
	function (intervals, theChordType) {
		return A2(
			$elm$core$List$all,
			function (interval) {
				return A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, interval, theChordType);
			},
			intervals);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$classify = function (theChordType) {
	var unalteredExtension = A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThirteenth, theChordType) ? $elm$core$Maybe$Just(2) : (A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectEleventh, theChordType) ? $elm$core$Maybe$Just(1) : (A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorNinth, theChordType) ? $elm$core$Maybe$Just(0) : $elm$core$Maybe$Nothing));
	var triad = A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$includesAll,
		_List_fromArray(
			[$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth]),
		theChordType) ? $elm$core$Maybe$Just(0) : (A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$includesAll,
		_List_fromArray(
			[$duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth]),
		theChordType) ? $elm$core$Maybe$Just(2) : (A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$includesAll,
		_List_fromArray(
			[$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird, $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedFifth]),
		theChordType) ? $elm$core$Maybe$Just(1) : (A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$includesAll,
		_List_fromArray(
			[$duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird, $duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedFifth]),
		theChordType) ? $elm$core$Maybe$Just(3) : (A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$includesAll,
		_List_fromArray(
			[$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth]),
		theChordType) ? $elm$core$Maybe$Just(4) : (A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$includesAll,
		_List_fromArray(
			[$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth]),
		theChordType) ? $elm$core$Maybe$Just(5) : $elm$core$Maybe$Nothing)))));
	var sixthOrSeventh = A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth, theChordType) ? $elm$core$Maybe$Just(0) : (A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh, theChordType) ? $elm$core$Maybe$Just(1) : (A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSeventh, theChordType) ? $elm$core$Maybe$Just(2) : (A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, $duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedSeventh, theChordType) ? $elm$core$Maybe$Just(3) : $elm$core$Maybe$Nothing)));
	var alteredExtensions = A2(
		$elm$core$List$filterMap,
		function (_v1) {
			var interval = _v1.a;
			var alteration = _v1.b;
			return A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, interval, theChordType) ? $elm$core$Maybe$Just(alteration) : $elm$core$Maybe$Nothing;
		},
		_List_fromArray(
			[
				_Utils_Tuple2($duncanmalashock$elm_music_theory$Music$Internal$Interval$minorNinth, 0),
				_Utils_Tuple2($duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedNinth, 1),
				_Utils_Tuple2($duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedEleventh, 2),
				_Utils_Tuple2($duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThirteenth, 3)
			]));
	if (!triad.$) {
		var theTriad = triad.a;
		return A4($duncanmalashock$elm_music_theory$Music$Chord$Classification$Classification, theTriad, sixthOrSeventh, unalteredExtension, alteredExtensions);
	} else {
		return $duncanmalashock$elm_music_theory$Music$Chord$Classification$Unclassifiable(
			$duncanmalashock$elm_music_theory$Music$Internal$ChordType$toIntervals(theChordType));
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol = function (maybeUnalteredExtension) {
	if (!maybeUnalteredExtension.$) {
		switch (maybeUnalteredExtension.a) {
			case 2:
				var _v1 = maybeUnalteredExtension.a;
				return '13';
			case 1:
				var _v2 = maybeUnalteredExtension.a;
				return '11';
			default:
				var _v3 = maybeUnalteredExtension.a;
				return '9';
		}
	} else {
		return '7';
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$sixOrSixNineSymbol = function (theChordType) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$includesAll,
		_List_fromArray(
			[$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorNinth]),
		theChordType) ? '6/9' : (A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth, theChordType) ? '6' : (A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$includes, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorNinth, theChordType) ? '(add9)' : ''));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$unknownChordSymbol = '?';
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$toString = function (theChordType) {
	var _v0 = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$classify(theChordType);
	if (!_v0.$) {
		var triad = _v0.a;
		var maybeSixthOrSeventh = _v0.b;
		var maybeUnalteredExtension = _v0.c;
		var alteredExtensions = _v0.d;
		switch (triad) {
			case 0:
				if (!maybeSixthOrSeventh.$) {
					switch (maybeSixthOrSeventh.a) {
						case 0:
							var _v3 = maybeSixthOrSeventh.a;
							return _Utils_ap(
								$duncanmalashock$elm_music_theory$Music$Internal$ChordType$sixOrSixNineSymbol(theChordType),
								A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						case 1:
							var _v4 = maybeSixthOrSeventh.a;
							return 'M' + ($duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension) + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						case 2:
							var _v5 = maybeSixthOrSeventh.a;
							return _Utils_ap(
								$duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension),
								A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						default:
							var _v6 = maybeSixthOrSeventh.a;
							return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$unknownChordSymbol;
					}
				} else {
					return _Utils_ap(
						$duncanmalashock$elm_music_theory$Music$Internal$ChordType$sixOrSixNineSymbol(theChordType),
						A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
				}
			case 1:
				if (!maybeSixthOrSeventh.$) {
					switch (maybeSixthOrSeventh.a) {
						case 0:
							var _v8 = maybeSixthOrSeventh.a;
							return _Utils_ap(
								$duncanmalashock$elm_music_theory$Music$Internal$ChordType$sixOrSixNineSymbol(theChordType),
								A2(
									$duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols,
									_List_fromArray(
										[0]),
									alteredExtensions));
						case 1:
							var _v9 = maybeSixthOrSeventh.a;
							return 'M' + ($duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension) + A2(
								$duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols,
								_List_fromArray(
									[0]),
								alteredExtensions));
						case 2:
							var _v10 = maybeSixthOrSeventh.a;
							return _Utils_ap(
								$duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension),
								A2(
									$duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols,
									_List_fromArray(
										[0]),
									alteredExtensions));
						default:
							var _v11 = maybeSixthOrSeventh.a;
							return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$unknownChordSymbol;
					}
				} else {
					return 'aug';
				}
			case 2:
				if (!maybeSixthOrSeventh.$) {
					switch (maybeSixthOrSeventh.a) {
						case 0:
							var _v13 = maybeSixthOrSeventh.a;
							return 'm' + ($duncanmalashock$elm_music_theory$Music$Internal$ChordType$sixOrSixNineSymbol(theChordType) + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						case 1:
							var _v14 = maybeSixthOrSeventh.a;
							return 'm/M' + ($duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension) + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						case 2:
							var _v15 = maybeSixthOrSeventh.a;
							return 'm' + ($duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension) + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						default:
							var _v16 = maybeSixthOrSeventh.a;
							return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$unknownChordSymbol;
					}
				} else {
					return 'm' + ($duncanmalashock$elm_music_theory$Music$Internal$ChordType$sixOrSixNineSymbol(theChordType) + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
				}
			case 3:
				if (!maybeSixthOrSeventh.$) {
					switch (maybeSixthOrSeventh.a) {
						case 0:
							var _v18 = maybeSixthOrSeventh.a;
							return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$unknownChordSymbol;
						case 1:
							var _v19 = maybeSixthOrSeventh.a;
							return 'm/M' + ($duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension) + A2(
								$duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols,
								_List_fromArray(
									[1]),
								alteredExtensions));
						case 2:
							var _v20 = maybeSixthOrSeventh.a;
							return 'ø' + ($duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension) + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						default:
							var _v21 = maybeSixthOrSeventh.a;
							return 'o7';
					}
				} else {
					return 'dim' + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions);
				}
			case 4:
				if (!maybeSixthOrSeventh.$) {
					switch (maybeSixthOrSeventh.a) {
						case 0:
							var _v23 = maybeSixthOrSeventh.a;
							return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$sixOrSixNineSymbol(theChordType) + ('sus2' + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						case 1:
							var _v24 = maybeSixthOrSeventh.a;
							return 'M' + ($duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension) + ('sus2' + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions)));
						case 2:
							var _v25 = maybeSixthOrSeventh.a;
							return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension) + ('sus2' + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						default:
							var _v26 = maybeSixthOrSeventh.a;
							return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$unknownChordSymbol;
					}
				} else {
					return 'sus2' + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions);
				}
			default:
				if (!maybeSixthOrSeventh.$) {
					switch (maybeSixthOrSeventh.a) {
						case 0:
							var _v28 = maybeSixthOrSeventh.a;
							return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$sixOrSixNineSymbol(theChordType) + ('sus4' + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						case 1:
							var _v29 = maybeSixthOrSeventh.a;
							return 'M' + ($duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension) + ('sus4' + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions)));
						case 2:
							var _v30 = maybeSixthOrSeventh.a;
							return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$highestUnalteredExtensionSymbol(maybeUnalteredExtension) + ('sus4' + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions));
						default:
							var _v31 = maybeSixthOrSeventh.a;
							return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$unknownChordSymbol;
					}
				} else {
					return 'sus4' + A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$alterationsSymbols, _List_Nil, alteredExtensions);
				}
		}
	} else {
		var list = _v0.a;
		return $duncanmalashock$elm_music_theory$Music$Internal$ChordType$unknownChordSymbol;
	}
};
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$offsetToString = function (_v0) {
	var o = _v0;
	return (!o) ? '' : ((o < 0) ? A2(
		$elm$core$String$join,
		'',
		A2(
			$elm$core$List$repeat,
			$elm$core$Basics$abs(o),
			'♭')) : A2(
		$elm$core$String$join,
		'',
		A2(
			$elm$core$List$repeat,
			$elm$core$Basics$abs(o),
			'♯')));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Letter$toString = function (letter) {
	switch (letter) {
		case 0:
			return 'C';
		case 1:
			return 'D';
		case 2:
			return 'E';
		case 3:
			return 'F';
		case 4:
			return 'G';
		case 5:
			return 'A';
		default:
			return 'B';
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$PitchClass$toString = function (_v0) {
	var l = _v0.a;
	var o = _v0.b;
	return _Utils_ap(
		$duncanmalashock$elm_music_theory$Music$Internal$Letter$toString(l),
		$duncanmalashock$elm_music_theory$Music$Internal$PitchClass$offsetToString(o));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Chord$symbol = function (_v0) {
	var rootPitchClass = _v0.a;
	var theChordType = _v0.b;
	return _Utils_ap(
		$duncanmalashock$elm_music_theory$Music$Internal$PitchClass$toString(rootPitchClass),
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$toString(theChordType));
};
var $duncanmalashock$elm_music_theory$Music$Chord$toString = function (chord) {
	return $duncanmalashock$elm_music_theory$Music$Internal$Chord$symbol(chord);
};
var $duncanmalashock$elm_music_theory$Music$Pitch$transposeUp = F2(
	function (interval, pitch) {
		return A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$transposeUp, interval, pitch);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$allRanges = _List_fromArray(
	[
		function ($) {
		return $.eS;
	},
		function ($) {
		return $.eU;
	},
		function ($) {
		return $.eT;
	},
		function ($) {
		return $.hI;
	}
	]);
var $duncanmalashock$elm_music_theory$Music$Internal$Octave$allValid = _List_fromArray(
	[
		$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(0),
		$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(1),
		$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(2),
		$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(3),
		$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(4),
		$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(5),
		$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(6),
		$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(7),
		$duncanmalashock$elm_music_theory$Music$Internal$Octave$octave(8)
	]);
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$getVoiceFour = A2(
	$elm$core$Basics$composeR,
	$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$toPitches,
	function ($) {
		return $.hI;
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$getVoiceOne = A2(
	$elm$core$Basics$composeR,
	$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$toPitches,
	function ($) {
		return $.eS;
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$getVoiceThree = A2(
	$elm$core$Basics$composeR,
	$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$toPitches,
	function ($) {
		return $.eT;
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$getVoiceTwo = A2(
	$elm$core$Basics$composeR,
	$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$toPitches,
	function ($) {
		return $.eU;
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$allVoices = _List_fromArray(
	[$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$getVoiceOne, $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$getVoiceTwo, $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$getVoiceThree, $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$getVoiceFour]);
var $duncanmalashock$elm_music_theory$Music$Internal$Chord$chordType = function (_v0) {
	var theChordType = _v0.b;
	return theChordType;
};
var $duncanmalashock$elm_music_theory$Music$Chord$chordType = function (theChord) {
	return $duncanmalashock$elm_music_theory$Music$Internal$Chord$chordType(theChord);
};
var $duncanmalashock$elm_music_theory$Music$Internal$Octave$toString = function (_v0) {
	var o = _v0;
	return $elm$core$String$fromInt(o);
};
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$toString = function (_v0) {
	var l = _v0.a;
	var o = _v0.b;
	return _Utils_ap(
		$duncanmalashock$elm_music_theory$Music$Internal$PitchClass$toString(l),
		$duncanmalashock$elm_music_theory$Music$Internal$Octave$toString(o));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$toString = F2(
	function (allVoices, v) {
		return A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				function (fn) {
					return $duncanmalashock$elm_music_theory$Music$Internal$Pitch$toString(
						fn(v));
				},
				allVoices));
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$List$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$List$cons, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, _List_Nil, list, _List_Nil);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$Voicing = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$voicing = F3(
	function (ch, theOctave, vc) {
		return A3($duncanmalashock$elm_music_theory$Music$Internal$Voicing$Voicing, ch, theOctave, vc);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$voicingClassesFromMethod = F2(
	function (chordType, _v0) {
		var fn = _v0;
		return fn(chordType);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Pitch$isWithin = F2(
	function (_v0, thePitch) {
		var lower = _v0.c8;
		var upper = _v0.dp;
		return (_Utils_cmp(
			$duncanmalashock$elm_music_theory$Music$Internal$Pitch$semitones(lower),
			$duncanmalashock$elm_music_theory$Music$Internal$Pitch$semitones(thePitch)) < 1) && (_Utils_cmp(
			$duncanmalashock$elm_music_theory$Music$Internal$Pitch$semitones(upper),
			$duncanmalashock$elm_music_theory$Music$Internal$Pitch$semitones(thePitch)) > -1);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$withInstrumentRanges = F4(
	function (allVoices, allRanges, ranges, theVoicing) {
		return A2(
			$elm$core$List$all,
			$elm$core$Basics$identity,
			A3(
				$elm$core$List$map2,
				F2(
					function (voice, aRange) {
						return A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$isWithin, aRange, voice);
					}),
				A2(
					$elm$core$List$map,
					function (fn) {
						return fn(theVoicing);
					},
					allVoices),
				A2(
					$elm$core$List$map,
					function (fn) {
						return fn(ranges);
					},
					allRanges)));
	});
var $duncanmalashock$elm_music_theory$Music$Chord$voiceFourParts = F3(
	function (voiceRanges, methods, chord) {
		return A2(
			$elm_community$list_extra$List$Extra$uniqueBy,
			$duncanmalashock$elm_music_theory$Music$Internal$Voicing$toString($duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$allVoices),
			A2(
				$elm$core$List$filter,
				A3($duncanmalashock$elm_music_theory$Music$Internal$Voicing$withInstrumentRanges, $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$allVoices, $duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$allRanges, voiceRanges),
				A2(
					$elm$core$List$concatMap,
					function (oct) {
						return A2(
							$elm$core$List$map,
							function (_class) {
								return A3($duncanmalashock$elm_music_theory$Music$Internal$Voicing$voicing, chord, oct, _class);
							},
							A2(
								$elm$core$List$concatMap,
								$duncanmalashock$elm_music_theory$Music$Internal$Voicing$FourPart$voicingClassesFromMethod(
									$duncanmalashock$elm_music_theory$Music$Chord$chordType(chord)),
								methods));
					},
					$duncanmalashock$elm_music_theory$Music$Internal$Octave$allValid)));
	});
var $author$project$Main$makeJazzChordButtons = F5(
	function (channel, velocity, minPitch, inMaxPitch, chord) {
		var maxPitch = A2($duncanmalashock$elm_music_theory$Music$Pitch$transposeUp, $duncanmalashock$elm_music_theory$Music$Interval$majorSeventh, inMaxPitch);
		var voicings = A3(
			$duncanmalashock$elm_music_theory$Music$Chord$voiceFourParts,
			{
				hI: A2($duncanmalashock$elm_music_theory$Music$Range$range, minPitch, maxPitch),
				eS: A2($duncanmalashock$elm_music_theory$Music$Range$range, minPitch, maxPitch),
				eT: A2($duncanmalashock$elm_music_theory$Music$Range$range, minPitch, maxPitch),
				eU: A2($duncanmalashock$elm_music_theory$Music$Range$range, minPitch, maxPitch)
			},
			_List_fromArray(
				[$duncanmalashock$elm_music_theory$Music$Voicing$FourPart$basic]),
			chord);
		var midiNumberList = A2(
			$elm$core$List$map,
			$elm$core$List$map($duncanmalashock$elm_music_theory$Music$Pitch$toMIDINoteNumber),
			A2($elm$core$List$map, $duncanmalashock$elm_music_theory$Music$Voicing$FourPart$toPitchList, voicings));
		var chordHelper = function (midiNotes) {
			return A5(
				$author$project$Controller$newChord,
				$duncanmalashock$elm_music_theory$Music$Chord$toString(chord),
				0,
				1,
				velocity,
				A2(
					$elm$core$List$map,
					function (p) {
						return {f: channel, y: p};
					},
					midiNotes));
		};
		var chordButtons = A2($elm$core$List$map, chordHelper, midiNumberList);
		return A2(
			$elm$core$List$map,
			function (b) {
				return _Utils_Tuple2(1, b);
			},
			chordButtons);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$VoicingMethod = $elm$core$Basics$identity;
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$custom = F2(
	function (categorizeFn, buildFromCategorized) {
		return A2(
			$elm$core$Basics$composeR,
			categorizeFn,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(buildFromCategorized),
				$elm$core$Maybe$withDefault(_List_Nil)));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$adjustToBeAboveMinimum = function (_v0) {
	var minimum = _v0.bO;
	var lower = _v0.c8;
	var upper = _v0.dp;
	var lowerIsAboveUpper = F2(
		function (l, u) {
			return $duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(
				A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract, l, u)) < 0;
		});
	var differenceIsBelowMinimum = F2(
		function (l, u) {
			return _Utils_cmp(
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(
					A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract, l, u)),
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(minimum)) < 0;
		});
	return A3(
		$duncanmalashock$elm_music_theory$Util$Basic$while,
		function (currentUpper) {
			return A2(lowerIsAboveUpper, lower, currentUpper) || A2(differenceIsBelowMinimum, lower, currentUpper);
		},
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$addOctave,
		upper);
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$octaveShiftsAllowedBetween = function (_v0) {
	var maximum = _v0.bM;
	var lower = _v0.c8;
	var upper = _v0.dp;
	var difference = F2(
		function (l, u) {
			return $duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(
				A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract, l, u));
		});
	var differenceIsBelowMaximum = F2(
		function (l, u) {
			return _Utils_cmp(
				A2(difference, l, u),
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(maximum)) < 0;
		});
	var maximumIsAtLeastCurrentDifferencePlusAnOctave = F2(
		function (l, u) {
			return _Utils_cmp(
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(maximum),
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones($duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave) + A2(difference, l, u)) > -1;
		});
	return function (_v3) {
		var count = _v3.a;
		var shouldBeAbove = _v3.b;
		return count;
	}(
		A3(
			$duncanmalashock$elm_music_theory$Util$Basic$while,
			function (_v1) {
				var count = _v1.a;
				var currentUpper = _v1.b;
				return A2(differenceIsBelowMaximum, lower, currentUpper) && A2(maximumIsAtLeastCurrentDifferencePlusAnOctave, lower, currentUpper);
			},
			function (_v2) {
				var count = _v2.a;
				var shouldBeAbove = _v2.b;
				return _Utils_Tuple2(
					count + 1,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$addOctave(shouldBeAbove));
			},
			_Utils_Tuple2(0, upper)));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$shiftCountToIntervalList = function (count) {
	return A2(
		$elm$core$List$map,
		function (currentCount) {
			return A3($duncanmalashock$elm_music_theory$Util$Basic$applyNTimes, currentCount, $duncanmalashock$elm_music_theory$Music$Internal$Interval$addOctave, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison);
		},
		A2($elm$core$List$range, 0, count));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$shiftFromVoiceOne = F2(
	function (shiftCount, voicingClasses) {
		var doShifts = F2(
			function (intervalsToShift, vc) {
				return A2(
					$elm$core$List$map,
					function (shift) {
						return _Utils_update(
							vc,
							{
								eS: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, shift, vc.eS)
							});
					},
					intervalsToShift);
			});
		return A2(
			$elm$core$List$concatMap,
			doShifts(
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$shiftCountToIntervalList(shiftCount)),
			voicingClasses);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$shiftFromVoiceTwo = F2(
	function (shiftCount, voicingClasses) {
		var doShifts = F2(
			function (intervalsToShift, vc) {
				return A2(
					$elm$core$List$map,
					function (shift) {
						return _Utils_update(
							vc,
							{
								eS: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, shift, vc.eS),
								eU: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, shift, vc.eU)
							});
					},
					intervalsToShift);
			});
		return A2(
			$elm$core$List$concatMap,
			doShifts(
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$shiftCountToIntervalList(shiftCount)),
			voicingClasses);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$performAllowedOctaveShifts = F2(
	function (_v0, initialVoicingClass) {
		var shiftsForVoiceTwo = _v0.eH;
		var shiftsForVoiceOne = _v0.eF;
		return A2(
			$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$shiftFromVoiceOne,
			shiftsForVoiceOne,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$shiftFromVoiceTwo,
				shiftsForVoiceTwo,
				_List_fromArray(
					[initialVoicingClass])));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$validateInitialVoicingClass = F2(
	function (intervalLimits, voicingClass) {
		var differenceMeetsMinimum = F3(
			function (l, u, minimum) {
				return _Utils_cmp(
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(
						A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract, l, u)),
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(minimum)) > -1;
			});
		var differenceMeetsMaximum = F3(
			function (l, u, maximum) {
				return _Utils_cmp(
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(
						A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$subtract, l, u)),
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$semitones(maximum)) < 1;
			});
		return A2(
			$elm$core$List$all,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					differenceMeetsMinimum,
					voicingClass.eT,
					voicingClass.eU,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$min(intervalLimits.aC)),
					A3(
					differenceMeetsMaximum,
					voicingClass.eT,
					voicingClass.eU,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$max(intervalLimits.aC)),
					A3(
					differenceMeetsMinimum,
					voicingClass.eU,
					voicingClass.eS,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$min(intervalLimits.aD)),
					A3(
					differenceMeetsMaximum,
					voicingClass.eU,
					voicingClass.eS,
					$duncanmalashock$elm_music_theory$Music$Internal$Interval$max(intervalLimits.aD))
				])) ? $elm$core$Maybe$Just(voicingClass) : $elm$core$Maybe$Nothing;
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$placeFactors = F2(
	function (intervalLimits, voicingClass) {
		var voiceThreeInitial = $duncanmalashock$elm_music_theory$Music$Internal$Interval$toSimple(voicingClass.eT);
		var voiceTwoInitial = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$adjustToBeAboveMinimum(
			{
				c8: voiceThreeInitial,
				bO: $duncanmalashock$elm_music_theory$Music$Internal$Interval$min(intervalLimits.aC),
				dp: $duncanmalashock$elm_music_theory$Music$Internal$Interval$toSimple(voicingClass.eU)
			});
		var voiceOneInitial = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$adjustToBeAboveMinimum(
			{
				c8: voiceTwoInitial,
				bO: $duncanmalashock$elm_music_theory$Music$Internal$Interval$min(intervalLimits.aD),
				dp: $duncanmalashock$elm_music_theory$Music$Internal$Interval$toSimple(voicingClass.eS)
			});
		var twoToOneOctaveShifts = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$octaveShiftsAllowedBetween(
			{
				c8: voiceTwoInitial,
				bM: $duncanmalashock$elm_music_theory$Music$Internal$Interval$max(intervalLimits.aD),
				dp: voiceOneInitial
			});
		var threeToTwoOctaveShifts = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$octaveShiftsAllowedBetween(
			{
				c8: voiceThreeInitial,
				bM: $duncanmalashock$elm_music_theory$Music$Internal$Interval$max(intervalLimits.aC),
				dp: voiceTwoInitial
			});
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$performAllowedOctaveShifts(
					{eF: twoToOneOctaveShifts, eH: threeToTwoOctaveShifts}),
				A2(
					$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$validateInitialVoicingClass,
					intervalLimits,
					{eS: voiceOneInitial, eT: voiceThreeInitial, eU: voiceTwoInitial})));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$placeSelectedFactors = F2(
	function (voiceIntervalLimits, builder) {
		return A2(
			$duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$execute,
			{
				gN: $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$placeFactors(voiceIntervalLimits)
			},
			builder);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$VoicingClass = F3(
	function (voiceOne, voiceTwo, voiceThree) {
		return {eS: voiceOne, eT: voiceThree, eU: voiceTwo};
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$selectFactors = $duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$builder($duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$VoicingClass);
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$Basic$spacingLimits = {
	aC: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$range, $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedUnison, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave),
	aD: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$range, $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedUnison, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave)
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$withFactor = F2(
	function (factor, builder) {
		return A3(
			$duncanmalashock$elm_music_theory$Music$Internal$VoicingClass$withFactor,
			factor,
			{aQ: false},
			builder);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$Basic$basic = A2(
	$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$custom,
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$categorizeFactors,
	function (factors) {
		return A2(
			$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$placeSelectedFactors,
			$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$Basic$spacingLimits,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$withFactor,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison,
				A2(
					$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$withFactor,
					factors.eO,
					A2($duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$withFactor, factors.dP, $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$selectFactors))));
	});
var $duncanmalashock$elm_music_theory$Music$Voicing$ThreePart$basic = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$Basic$basic;
var $duncanmalashock$elm_music_theory$Music$Interval$perfectFourth = $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth;
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$toPitches = function (theVoicing) {
	var vc = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$voicingClass(theVoicing);
	var theRoot = $duncanmalashock$elm_music_theory$Music$Internal$Voicing$root(theVoicing);
	return {
		eS: A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$transposeUp, vc.eS, theRoot),
		eT: A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$transposeUp, vc.eT, theRoot),
		eU: A2($duncanmalashock$elm_music_theory$Music$Internal$Pitch$transposeUp, vc.eU, theRoot)
	};
};
var $duncanmalashock$elm_music_theory$Music$Voicing$ThreePart$toPitchList = function (theVoicing) {
	return function (v) {
		return _List_fromArray(
			[v.eS, v.eU, v.eT]);
	}(
		$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$toPitches(theVoicing));
};
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$allRanges = _List_fromArray(
	[
		function ($) {
		return $.eS;
	},
		function ($) {
		return $.eU;
	},
		function ($) {
		return $.eT;
	}
	]);
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$getVoiceOne = A2(
	$elm$core$Basics$composeR,
	$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$toPitches,
	function ($) {
		return $.eS;
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$getVoiceThree = A2(
	$elm$core$Basics$composeR,
	$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$toPitches,
	function ($) {
		return $.eT;
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$getVoiceTwo = A2(
	$elm$core$Basics$composeR,
	$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$toPitches,
	function ($) {
		return $.eU;
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$allVoices = _List_fromArray(
	[$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$getVoiceOne, $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$getVoiceTwo, $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$getVoiceThree]);
var $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$voicingClassesFromMethod = F2(
	function (chordType, _v0) {
		var fn = _v0;
		return fn(chordType);
	});
var $duncanmalashock$elm_music_theory$Music$Chord$voiceThreeParts = F3(
	function (voiceRanges, methods, chord) {
		return A2(
			$elm_community$list_extra$List$Extra$uniqueBy,
			$duncanmalashock$elm_music_theory$Music$Internal$Voicing$toString($duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$allVoices),
			A2(
				$elm$core$List$filter,
				A3($duncanmalashock$elm_music_theory$Music$Internal$Voicing$withInstrumentRanges, $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$allVoices, $duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$allRanges, voiceRanges),
				A2(
					$elm$core$List$concatMap,
					function (oct) {
						return A2(
							$elm$core$List$map,
							function (_class) {
								return A3($duncanmalashock$elm_music_theory$Music$Internal$Voicing$voicing, chord, oct, _class);
							},
							A2(
								$elm$core$List$concatMap,
								$duncanmalashock$elm_music_theory$Music$Internal$Voicing$ThreePart$voicingClassesFromMethod(
									$duncanmalashock$elm_music_theory$Music$Chord$chordType(chord)),
								methods));
					},
					$duncanmalashock$elm_music_theory$Music$Internal$Octave$allValid)));
	});
var $author$project$Main$makeTriadChordButtons = F5(
	function (channel, velocity, minPitch, inMaxPitch, chord) {
		var maxPitch = A2($duncanmalashock$elm_music_theory$Music$Pitch$transposeUp, $duncanmalashock$elm_music_theory$Music$Interval$perfectFourth, inMaxPitch);
		var voicings = A3(
			$duncanmalashock$elm_music_theory$Music$Chord$voiceThreeParts,
			{
				eS: A2($duncanmalashock$elm_music_theory$Music$Range$range, minPitch, maxPitch),
				eT: A2($duncanmalashock$elm_music_theory$Music$Range$range, minPitch, maxPitch),
				eU: A2($duncanmalashock$elm_music_theory$Music$Range$range, minPitch, maxPitch)
			},
			_List_fromArray(
				[$duncanmalashock$elm_music_theory$Music$Voicing$ThreePart$basic]),
			chord);
		var midiNumberList = A2(
			$elm$core$List$map,
			$elm$core$List$map($duncanmalashock$elm_music_theory$Music$Pitch$toMIDINoteNumber),
			A2($elm$core$List$map, $duncanmalashock$elm_music_theory$Music$Voicing$ThreePart$toPitchList, voicings));
		var chordHelper = function (midiNotes) {
			return A5(
				$author$project$Controller$newChord,
				$duncanmalashock$elm_music_theory$Music$Chord$toString(chord),
				0,
				1,
				velocity,
				A2(
					$elm$core$List$map,
					function (p) {
						return {f: channel, y: p};
					},
					midiNotes));
		};
		var chordButtons = A2($elm$core$List$map, chordHelper, midiNumberList);
		return A2(
			$elm$core$List$map,
			function (b) {
				return _Utils_Tuple2(1, b);
			},
			chordButtons);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Heptatonic = function (a) {
	return {$: 2, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$melodicMinor = $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Heptatonic(
	{cu: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth, cv: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth, cw: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond, ey: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh, dh: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth, cx: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird});
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Hexatonic = function (a) {
	return {$: 1, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Octatonic = function (a) {
	return {$: 3, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Pentatonic = function (a) {
	return {$: 0, a: a};
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$modeShift = function (scale) {
	switch (scale.$) {
		case 0:
			var pent = scale.a;
			var intervalToSubtract = $duncanmalashock$elm_music_theory$Music$Internal$Interval$reverse(pent.cw);
			return $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Pentatonic(
				{
					cu: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave, intervalToSubtract),
					cv: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, pent.cu, intervalToSubtract),
					cw: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, pent.cx, intervalToSubtract),
					cx: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, pent.cv, intervalToSubtract)
				});
		case 1:
			var hex = scale.a;
			var intervalToSubtract = $duncanmalashock$elm_music_theory$Music$Internal$Interval$reverse(hex.cw);
			return $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Hexatonic(
				{
					cu: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, hex.dh, intervalToSubtract),
					cv: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, hex.cu, intervalToSubtract),
					cw: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, hex.cx, intervalToSubtract),
					dh: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave, intervalToSubtract),
					cx: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, hex.cv, intervalToSubtract)
				});
		case 2:
			var hep = scale.a;
			var intervalToSubtract = $duncanmalashock$elm_music_theory$Music$Internal$Interval$reverse(hep.cw);
			return $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Heptatonic(
				{
					cu: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, hep.dh, intervalToSubtract),
					cv: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, hep.cu, intervalToSubtract),
					cw: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, hep.cx, intervalToSubtract),
					ey: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave, intervalToSubtract),
					dh: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, hep.ey, intervalToSubtract),
					cx: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, hep.cv, intervalToSubtract)
				});
		default:
			var oct = scale.a;
			var intervalToSubtract = $duncanmalashock$elm_music_theory$Music$Internal$Interval$reverse(oct.cw);
			return $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Octatonic(
				{
					gV: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectOctave, intervalToSubtract),
					cu: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, oct.dh, intervalToSubtract),
					cv: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, oct.cu, intervalToSubtract),
					cw: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, oct.cx, intervalToSubtract),
					ey: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, oct.gV, intervalToSubtract),
					dh: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, oct.ey, intervalToSubtract),
					cx: A2($duncanmalashock$elm_music_theory$Music$Internal$Interval$add, oct.cv, intervalToSubtract)
				});
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode = F2(
	function (modeShiftIndex, scale) {
		mode:
		while (true) {
			if (modeShiftIndex <= 1) {
				return scale;
			} else {
				var $temp$modeShiftIndex = modeShiftIndex - 1,
					$temp$scale = $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$modeShift(scale);
				modeShiftIndex = $temp$modeShiftIndex;
				scale = $temp$scale;
				continue mode;
			}
		}
	});
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$acoustic = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 4, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$melodicMinor);
var $duncanmalashock$elm_music_theory$Music$Internal$Scale$Scale = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Scale$scale = F2(
	function (scaleRoot, theScaleType) {
		return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$Scale, scaleRoot, theScaleType);
	});
var $duncanmalashock$elm_music_theory$Music$Scale$acoustic = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$acoustic);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ionian = $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Heptatonic(
	{cu: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth, cv: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth, cw: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond, ey: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh, dh: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth, cx: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird});
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$aeolian = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 6, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ionian);
var $duncanmalashock$elm_music_theory$Music$Scale$aeolian = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$aeolian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSixth = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minor, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Sixth);
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$harmonicMinor = $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Heptatonic(
	{cu: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth, cv: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth, cw: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond, ey: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh, dh: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSixth, cx: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird});
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$aeolianHarmonic = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 6, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$harmonicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$aeolianHarmonic = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$aeolianHarmonic);
};
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedFourth = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectAugmented, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Fourth);
var $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSecond = A3($duncanmalashock$elm_music_theory$Music$Internal$Interval$Interval, $duncanmalashock$elm_music_theory$Music$Internal$Interval$up, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minor, $duncanmalashock$elm_music_theory$Music$Internal$Interval$Second);
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$diminishedHalfToneWholeTone = $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Octatonic(
	{gV: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSeventh, cu: $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedFourth, cv: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird, cw: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSecond, ey: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth, dh: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth, cx: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird});
var $duncanmalashock$elm_music_theory$Music$Scale$diminishedHalfToneWholeTone = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$diminishedHalfToneWholeTone);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$diminishedWholeToneHalfTone = $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Octatonic(
	{gV: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh, cu: $duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedFifth, cv: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth, cw: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond, ey: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth, dh: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSixth, cx: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird});
var $duncanmalashock$elm_music_theory$Music$Scale$diminishedWholeToneHalfTone = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$diminishedWholeToneHalfTone);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$dorian = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 2, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ionian);
var $duncanmalashock$elm_music_theory$Music$Scale$dorian = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$dorian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$dorianFlat2 = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 2, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$melodicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$dorianFlat2 = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$dorianFlat2);
};
var $duncanmalashock$elm_music_theory$Music$Scale$harmonicMinor = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$harmonicMinor);
};
var $duncanmalashock$elm_music_theory$Music$Scale$ionian = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ionian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$locrian = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 7, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ionian);
var $duncanmalashock$elm_music_theory$Music$Scale$locrian = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$locrian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$locrianNatural6 = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 2, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$harmonicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$locrianNatural6 = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$locrianNatural6);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$lydian = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 4, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ionian);
var $duncanmalashock$elm_music_theory$Music$Scale$lydian = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$lydian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$lydianAugmented = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 3, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$melodicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$lydianAugmented = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$lydianAugmented);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$lydianDiminished = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 4, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$harmonicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$lydianDiminished = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$lydianDiminished);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$major = $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ionian;
var $duncanmalashock$elm_music_theory$Music$Scale$major = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$major);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$majorAugmented = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 3, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$harmonicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$majorAugmented = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$majorAugmented);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$majorMinor = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 5, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$melodicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$majorMinor = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$majorMinor);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$majorPentatonic = $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Pentatonic(
	{cu: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth, cv: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth, cw: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond, cx: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird});
var $duncanmalashock$elm_music_theory$Music$Scale$majorPentatonic = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$majorPentatonic);
};
var $duncanmalashock$elm_music_theory$Music$Scale$melodicMinor = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$melodicMinor);
};
var $duncanmalashock$elm_music_theory$Music$Scale$minor = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$aeolian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$minorLocrian = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 6, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$melodicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$minorLocrian = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$minorLocrian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$minorPentatonic = $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Pentatonic(
	{cu: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSeventh, cv: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth, cw: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird, cx: $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth});
var $duncanmalashock$elm_music_theory$Music$Scale$minorPentatonic = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$minorPentatonic);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mixolydian = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 5, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ionian);
var $duncanmalashock$elm_music_theory$Music$Scale$mixolydian = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mixolydian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$phrygian = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 3, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ionian);
var $duncanmalashock$elm_music_theory$Music$Scale$phrygian = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$phrygian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$phrygianDominant = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 5, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$harmonicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$phrygianDominant = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$phrygianDominant);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$superlocrian = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 7, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$melodicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$superlocrian = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$superlocrian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ultralocrian = A2($duncanmalashock$elm_music_theory$Music$Internal$ScaleType$mode, 7, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$harmonicMinor);
var $duncanmalashock$elm_music_theory$Music$Scale$ultralocrian = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$ultralocrian);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$wholeTone = $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$Hexatonic(
	{cu: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSixth, cv: $duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedFourth, cw: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond, dh: $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSeventh, cx: $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird});
var $duncanmalashock$elm_music_theory$Music$Scale$wholeTone = function (scaleRoot) {
	return A2($duncanmalashock$elm_music_theory$Music$Internal$Scale$scale, scaleRoot, $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$wholeTone);
};
var $author$project$Main$scaleToScaleConstructor = function (scaleId) {
	switch (scaleId) {
		case 0:
			return $duncanmalashock$elm_music_theory$Music$Scale$major;
		case 1:
			return $duncanmalashock$elm_music_theory$Music$Scale$minor;
		case 2:
			return $duncanmalashock$elm_music_theory$Music$Scale$ionian;
		case 3:
			return $duncanmalashock$elm_music_theory$Music$Scale$dorian;
		case 4:
			return $duncanmalashock$elm_music_theory$Music$Scale$phrygian;
		case 5:
			return $duncanmalashock$elm_music_theory$Music$Scale$lydian;
		case 6:
			return $duncanmalashock$elm_music_theory$Music$Scale$mixolydian;
		case 7:
			return $duncanmalashock$elm_music_theory$Music$Scale$aeolian;
		case 8:
			return $duncanmalashock$elm_music_theory$Music$Scale$locrian;
		case 9:
			return $duncanmalashock$elm_music_theory$Music$Scale$melodicMinor;
		case 10:
			return $duncanmalashock$elm_music_theory$Music$Scale$dorianFlat2;
		case 11:
			return $duncanmalashock$elm_music_theory$Music$Scale$lydianAugmented;
		case 12:
			return $duncanmalashock$elm_music_theory$Music$Scale$acoustic;
		case 13:
			return $duncanmalashock$elm_music_theory$Music$Scale$majorMinor;
		case 14:
			return $duncanmalashock$elm_music_theory$Music$Scale$minorLocrian;
		case 15:
			return $duncanmalashock$elm_music_theory$Music$Scale$superlocrian;
		case 16:
			return $duncanmalashock$elm_music_theory$Music$Scale$harmonicMinor;
		case 17:
			return $duncanmalashock$elm_music_theory$Music$Scale$locrianNatural6;
		case 18:
			return $duncanmalashock$elm_music_theory$Music$Scale$majorAugmented;
		case 19:
			return $duncanmalashock$elm_music_theory$Music$Scale$lydianDiminished;
		case 20:
			return $duncanmalashock$elm_music_theory$Music$Scale$phrygianDominant;
		case 21:
			return $duncanmalashock$elm_music_theory$Music$Scale$aeolianHarmonic;
		case 22:
			return $duncanmalashock$elm_music_theory$Music$Scale$ultralocrian;
		case 23:
			return $duncanmalashock$elm_music_theory$Music$Scale$diminishedWholeToneHalfTone;
		case 24:
			return $duncanmalashock$elm_music_theory$Music$Scale$diminishedHalfToneWholeTone;
		case 25:
			return $duncanmalashock$elm_music_theory$Music$Scale$wholeTone;
		case 26:
			return $duncanmalashock$elm_music_theory$Music$Scale$majorPentatonic;
		default:
			return $duncanmalashock$elm_music_theory$Music$Scale$minorPentatonic;
	}
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$ChordType = $elm$core$Basics$identity;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType = _List_fromArray(
	[$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison]);
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$add = F2(
	function (factorToAdd, _v0) {
		var factorList = _v0;
		return A2($elm$core$List$cons, factorToAdd, factorList);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove = F2(
	function (factorToRemove, _v0) {
		var factorList = _v0;
		return A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Basics$eq(factorToRemove),
				$elm$core$Basics$not),
			factorList);
	});
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMajorThird = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird,
		A2(
			$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond,
				A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird, factors))));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorSeventh = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSeventh,
		A2(
			$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedSeventh,
				A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh, factors))));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSharpFifth = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedFifth,
		A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth, factors));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$augmentedDominantSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorSeventh(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSharpFifth(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMajorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType)));
var $duncanmalashock$elm_music_theory$Music$ChordType$augmentedDominantSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$augmentedDominantSeventh;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withDiminishedSeventh = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedSeventh,
		A2(
			$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSeventh,
				A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh, factors))));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFlatFifth = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedFifth,
		A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth, factors));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorThird = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird,
		A2(
			$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond,
				A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird, factors))));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$diminishedSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withDiminishedSeventh(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFlatFifth(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType)));
var $duncanmalashock$elm_music_theory$Music$ChordType$diminishedSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$diminishedSeventh;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFifth,
		A2(
			$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$augmentedFifth,
			A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedFifth, factors)));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$dominantSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorSeventh(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMajorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType)));
var $duncanmalashock$elm_music_theory$Music$ChordType$dominantSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$dominantSeventh;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSuspendedFourth = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth,
		A2(
			$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird,
				A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird, factors))));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$dominantSeventhSus4 = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorSeventh(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSuspendedFourth($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType)));
var $duncanmalashock$elm_music_theory$Music$ChordType$dominantSeventhSus4 = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$dominantSeventhSus4;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$halfDiminished = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorSeventh(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFlatFifth(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType)));
var $duncanmalashock$elm_music_theory$Music$ChordType$halfDiminishedSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$halfDiminished;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMajorSeventh = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSeventh,
		A2(
			$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$diminishedSeventh,
				A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorSeventh, factors))));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$majorSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMajorSeventh(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMajorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType)));
var $duncanmalashock$elm_music_theory$Music$ChordType$majorSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$majorSeventh;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSixth = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSixth,
		A2(
			$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThirteenth,
			A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThirteenth, factors)));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$majorSix = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSixth(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMajorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType)));
var $duncanmalashock$elm_music_theory$Music$ChordType$majorSix = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$majorSix;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$minorMajorSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMajorSeventh(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType)));
var $duncanmalashock$elm_music_theory$Music$ChordType$minorMajorSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$minorMajorSeventh;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$minorSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorSeventh(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType)));
var $duncanmalashock$elm_music_theory$Music$ChordType$minorSeventh = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$minorSeventh;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$minorSix = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSixth(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType)));
var $duncanmalashock$elm_music_theory$Music$ChordType$minorSix = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$minorSix;
var $duncanmalashock$elm_music_theory$Music$ChordType$sixthAndSeventhChords = _List_fromArray(
	[$duncanmalashock$elm_music_theory$Music$ChordType$majorSix, $duncanmalashock$elm_music_theory$Music$ChordType$minorSix, $duncanmalashock$elm_music_theory$Music$ChordType$majorSeventh, $duncanmalashock$elm_music_theory$Music$ChordType$minorSeventh, $duncanmalashock$elm_music_theory$Music$ChordType$dominantSeventh, $duncanmalashock$elm_music_theory$Music$ChordType$diminishedSeventh, $duncanmalashock$elm_music_theory$Music$ChordType$halfDiminishedSeventh, $duncanmalashock$elm_music_theory$Music$ChordType$augmentedDominantSeventh, $duncanmalashock$elm_music_theory$Music$ChordType$dominantSeventhSus4, $duncanmalashock$elm_music_theory$Music$ChordType$minorMajorSeventh]);
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$augmented = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSharpFifth(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMajorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType));
var $duncanmalashock$elm_music_theory$Music$ChordType$augmented = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$augmented;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$diminished = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFlatFifth(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType));
var $duncanmalashock$elm_music_theory$Music$ChordType$diminished = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$diminished;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$major = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMajorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType));
var $duncanmalashock$elm_music_theory$Music$ChordType$major = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$major;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$minor = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withMinorThird($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType));
var $duncanmalashock$elm_music_theory$Music$ChordType$minor = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$minor;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSuspendedSecond = function (factors) {
	return A2(
		$duncanmalashock$elm_music_theory$Music$Internal$ChordType$add,
		$duncanmalashock$elm_music_theory$Music$Internal$Interval$majorSecond,
		A2(
			$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
			$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectFourth,
			A2(
				$duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove,
				$duncanmalashock$elm_music_theory$Music$Internal$Interval$minorThird,
				A2($duncanmalashock$elm_music_theory$Music$Internal$ChordType$remove, $duncanmalashock$elm_music_theory$Music$Internal$Interval$majorThird, factors))));
};
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$sus2 = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSuspendedSecond($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType));
var $duncanmalashock$elm_music_theory$Music$ChordType$sus2 = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$sus2;
var $duncanmalashock$elm_music_theory$Music$Internal$ChordType$sus4 = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$withFifth(
	$duncanmalashock$elm_music_theory$Music$Internal$ChordType$withSuspendedFourth($duncanmalashock$elm_music_theory$Music$Internal$ChordType$chordType));
var $duncanmalashock$elm_music_theory$Music$ChordType$sus4 = $duncanmalashock$elm_music_theory$Music$Internal$ChordType$sus4;
var $duncanmalashock$elm_music_theory$Music$ChordType$triads = _List_fromArray(
	[$duncanmalashock$elm_music_theory$Music$ChordType$major, $duncanmalashock$elm_music_theory$Music$ChordType$minor, $duncanmalashock$elm_music_theory$Music$ChordType$augmented, $duncanmalashock$elm_music_theory$Music$ChordType$diminished, $duncanmalashock$elm_music_theory$Music$ChordType$sus2, $duncanmalashock$elm_music_theory$Music$ChordType$sus4]);
var $author$project$Main$chordKeyboard = function (_v0) {
	var channel = _v0.f;
	var velocity = _v0.E;
	var key = _v0.aN;
	var scaleId = _v0.eC;
	var octave = _v0.aS;
	var range = _v0.aW;
	var chordType = _v0.b7;
	var scaleConstructor = $author$project$Main$scaleToScaleConstructor(scaleId);
	var pitchClass = $author$project$Main$keyToPitchClass(key);
	var minPitch = A2($duncanmalashock$elm_music_theory$Music$Pitch$fromPitchClassInOctave, octave, pitchClass);
	var maxPitch = A2($duncanmalashock$elm_music_theory$Music$Pitch$fromPitchClassInOctave, octave + range, pitchClass);
	var chords = function () {
		if (!chordType) {
			return A2(
				$duncanmalashock$elm_music_theory$Music$Scale$allChords,
				$duncanmalashock$elm_music_theory$Music$ChordType$triads,
				scaleConstructor(pitchClass));
		} else {
			return A2(
				$duncanmalashock$elm_music_theory$Music$Scale$allChords,
				$duncanmalashock$elm_music_theory$Music$ChordType$sixthAndSeventhChords,
				scaleConstructor(pitchClass));
		}
	}();
	var chordButtons = function () {
		if (!chordType) {
			return A2(
				$elm$core$List$map,
				A4($author$project$Main$makeTriadChordButtons, channel, velocity, minPitch, maxPitch),
				chords);
		} else {
			return A2(
				$elm$core$List$map,
				A4($author$project$Main$makeJazzChordButtons, channel, velocity, minPitch, maxPitch),
				chords);
		}
	}();
	var longest = A2(
		$elm$core$Maybe$withDefault,
		1,
		$elm$core$List$maximum(
			A2($elm$core$List$map, $elm$core$List$length, chordButtons)));
	var paddedChords = A2(
		$elm$core$List$map,
		$elm$core$List$reverse,
		A2(
			$elm$core$List$map,
			function (cs) {
				return (_Utils_cmp(
					$elm$core$List$length(cs),
					longest) < 0) ? _Utils_ap(
					cs,
					_List_fromArray(
						[
							_Utils_Tuple2(1, $author$project$Controller$Space)
						])) : cs;
			},
			chordButtons));
	return $author$project$Controller$SizedRow(
		A2(
			$elm$core$List$map,
			function (c) {
				return _Utils_Tuple2(
					1,
					$author$project$Controller$SizedColumn(c));
			},
			paddedChords));
};
var $author$project$EditableController$FourNote = 1;
var $mdgriffith$elm_ui$Element$Input$Row = 0;
var $mdgriffith$elm_ui$Element$Input$radioRow = $mdgriffith$elm_ui$Element$Input$radioHelper(0);
var $author$project$Main$chordTypeRadio = F2(
	function (chordType, msg) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$radioRow,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(4),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(36)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$scrollbarY,
					$mdgriffith$elm_ui$Element$Border$width(2),
					$mdgriffith$elm_ui$Element$Border$dashed
				]),
			{
				d: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{bG: 10, bK: 0, bV: 0, bZ: 0})
						]),
					$mdgriffith$elm_ui$Element$text('Chord Type')),
				ae: msg,
				aT: _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						0,
						$mdgriffith$elm_ui$Element$text('Triad')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						1,
						$mdgriffith$elm_ui$Element$text('Four Note'))
					]),
				aX: $elm$core$Maybe$Just(chordType)
			});
	});
var $author$project$EditableController$A = 12;
var $author$project$EditableController$Ab = 14;
var $author$project$EditableController$As = 13;
var $author$project$EditableController$B = 15;
var $author$project$EditableController$Bb = 16;
var $author$project$EditableController$Cs = 1;
var $author$project$EditableController$D = 2;
var $author$project$EditableController$Db = 4;
var $author$project$EditableController$Ds = 3;
var $author$project$EditableController$E = 5;
var $author$project$EditableController$Eb = 6;
var $author$project$EditableController$F = 7;
var $author$project$EditableController$Fs = 8;
var $author$project$EditableController$G = 9;
var $author$project$EditableController$Gb = 11;
var $author$project$EditableController$Gs = 10;
var $author$project$Main$noteRadio = F2(
	function (note, msg) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$radio,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(2),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(100)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$scrollbarY,
					$mdgriffith$elm_ui$Element$Border$width(2),
					$mdgriffith$elm_ui$Element$Border$dashed
				]),
			{
				d: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{bG: 10, bK: 0, bV: 0, bZ: 0})
						]),
					$mdgriffith$elm_ui$Element$text('Note')),
				ae: msg,
				aT: _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						0,
						$mdgriffith$elm_ui$Element$text('C')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						1,
						$mdgriffith$elm_ui$Element$text('C#')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						2,
						$mdgriffith$elm_ui$Element$text('D')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						3,
						$mdgriffith$elm_ui$Element$text('D#')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						4,
						$mdgriffith$elm_ui$Element$text('Db')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						5,
						$mdgriffith$elm_ui$Element$text('E')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						6,
						$mdgriffith$elm_ui$Element$text('Eb')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						7,
						$mdgriffith$elm_ui$Element$text('F')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						8,
						$mdgriffith$elm_ui$Element$text('F#')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						9,
						$mdgriffith$elm_ui$Element$text('G')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						10,
						$mdgriffith$elm_ui$Element$text('G#')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						11,
						$mdgriffith$elm_ui$Element$text('Gb')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						12,
						$mdgriffith$elm_ui$Element$text('A')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						13,
						$mdgriffith$elm_ui$Element$text('A#')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						14,
						$mdgriffith$elm_ui$Element$text('Ab')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						15,
						$mdgriffith$elm_ui$Element$text('B')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						16,
						$mdgriffith$elm_ui$Element$text('Bb'))
					]),
				aX: $elm$core$Maybe$Just(note)
			});
	});
var $author$project$EditableController$Acoustic = 12;
var $author$project$EditableController$Aeolian = 7;
var $author$project$EditableController$AeolianHarmonic = 21;
var $author$project$EditableController$DiminishedHalfToneWholeTone = 24;
var $author$project$EditableController$DiminishedWholeToneHalfTone = 23;
var $author$project$EditableController$Dorian = 3;
var $author$project$EditableController$DorianFlat2 = 10;
var $author$project$EditableController$HarmonicMinor = 16;
var $author$project$EditableController$Ionian = 2;
var $author$project$EditableController$Locrian = 8;
var $author$project$EditableController$LocrianNatural6 = 17;
var $author$project$EditableController$Lydian = 5;
var $author$project$EditableController$LydianAugmented = 11;
var $author$project$EditableController$LydianDiminished = 19;
var $author$project$EditableController$MajorAugmented = 18;
var $author$project$EditableController$MajorMinor = 13;
var $author$project$EditableController$MajorPentatonic = 26;
var $author$project$EditableController$MelodicMinor = 9;
var $author$project$EditableController$MinorLocrian = 14;
var $author$project$EditableController$MinorPentatonic = 27;
var $author$project$EditableController$Mixolydian = 6;
var $author$project$EditableController$NaturalMinor = 1;
var $author$project$EditableController$Phrygian = 4;
var $author$project$EditableController$PhrygianDominant = 20;
var $author$project$EditableController$SuperLocrian = 15;
var $author$project$EditableController$UltraLocrian = 22;
var $author$project$EditableController$WholeTone = 25;
var $author$project$Main$scaleRadio = F2(
	function (scale, msg) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$radio,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(2),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(100)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$scrollbarY,
					$mdgriffith$elm_ui$Element$Border$width(2),
					$mdgriffith$elm_ui$Element$Border$dashed
				]),
			{
				d: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{bG: 10, bK: 0, bV: 0, bZ: 0})
						]),
					$mdgriffith$elm_ui$Element$text('Scale')),
				ae: msg,
				aT: _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						0,
						$mdgriffith$elm_ui$Element$text('Major')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						1,
						$mdgriffith$elm_ui$Element$text('Natural Minor')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						2,
						$mdgriffith$elm_ui$Element$text('Ionian')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						3,
						$mdgriffith$elm_ui$Element$text('Dorian')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						4,
						$mdgriffith$elm_ui$Element$text('Phrygian')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						5,
						$mdgriffith$elm_ui$Element$text('Lydian')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						6,
						$mdgriffith$elm_ui$Element$text('Mixolydian')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						7,
						$mdgriffith$elm_ui$Element$text('Aeolian')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						8,
						$mdgriffith$elm_ui$Element$text('Locrian')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						9,
						$mdgriffith$elm_ui$Element$text('Melodic Minor')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						10,
						$mdgriffith$elm_ui$Element$text('Dorian Flat2')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						11,
						$mdgriffith$elm_ui$Element$text('Lydian Augmented')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						12,
						$mdgriffith$elm_ui$Element$text('Acoustic')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						13,
						$mdgriffith$elm_ui$Element$text('Major Minor')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						14,
						$mdgriffith$elm_ui$Element$text('Minor Locrian')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						15,
						$mdgriffith$elm_ui$Element$text('Superlocrian')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						16,
						$mdgriffith$elm_ui$Element$text('Harmonic Minor')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						17,
						$mdgriffith$elm_ui$Element$text('Locrian Natural6')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						18,
						$mdgriffith$elm_ui$Element$text('Major Augmented')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						19,
						$mdgriffith$elm_ui$Element$text('Lydian Diminished')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						20,
						$mdgriffith$elm_ui$Element$text('Phrygian Dominant')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						21,
						$mdgriffith$elm_ui$Element$text('Aeolian Harmonic')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						22,
						$mdgriffith$elm_ui$Element$text('Ultralocrian')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						23,
						$mdgriffith$elm_ui$Element$text('Diminished Whole Half Tone')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						24,
						$mdgriffith$elm_ui$Element$text('Diminished Half Whole Tone')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						25,
						$mdgriffith$elm_ui$Element$text('Whole Tone')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						26,
						$mdgriffith$elm_ui$Element$text('Major Pentatonic')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						27,
						$mdgriffith$elm_ui$Element$text('Minor Pentatonic'))
					]),
				aX: $elm$core$Maybe$Just(scale)
			});
	});
var $author$project$Utils$mmap6 = F7(
	function (func, ma, mb, mc, md, me, mf) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					if (md.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var d = md.a;
						if (me.$ === 1) {
							return $elm$core$Maybe$Nothing;
						} else {
							var e = me.a;
							if (mf.$ === 1) {
								return $elm$core$Maybe$Nothing;
							} else {
								var f = mf.a;
								return $elm$core$Maybe$Just(
									A6(func, a, b, c, d, e, f));
							}
						}
					}
				}
			}
		}
	});
var $author$project$EditableController$toChordBuildInput = function (state) {
	var mVelocity = $elm$core$String$toInt(state.E);
	var mScale = $elm$core$Maybe$Just(state.by);
	var mRange = $elm$core$String$toInt(state.aW);
	var mOctave = $elm$core$String$toInt(state.aS);
	var mKey = $elm$core$Maybe$Just(state.aN);
	var mChannel = $author$project$Midi$stringToChannel(state.f);
	return A7(
		$author$project$Utils$mmap6,
		F6(
			function (c, v, n, s, o, r) {
				return {f: c, b7: state.b7, aN: n, aS: o, aW: r, eC: s, E: v};
			}),
		mChannel,
		mVelocity,
		mKey,
		mScale,
		mOctave,
		mRange);
};
var $author$project$Main$editChordBuilderPane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.f, d: 'Channel', e: 'channel#'},
				'number',
				function (newChannel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChordBuilder(
							_Utils_update(
								state,
								{f: newChannel})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.E, d: 'Velocity', e: 'velocity'},
				'number',
				function (newVelocity) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChordBuilder(
							_Utils_update(
								state,
								{E: newVelocity})));
				}),
				A2(
				$author$project$Main$noteRadio,
				state.aN,
				function (newNote) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChordBuilder(
							_Utils_update(
								state,
								{aN: newNote})));
				}),
				A2(
				$author$project$Main$scaleRadio,
				state.by,
				function (newScale) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChordBuilder(
							_Utils_update(
								state,
								{by: newScale})));
				}),
				A2(
				$author$project$Main$chordTypeRadio,
				state.b7,
				function (newChordType) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChordBuilder(
							_Utils_update(
								state,
								{b7: newChordType})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.aS, d: 'Octave Number', e: 'octave#'},
				'number',
				function (newOctave) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChordBuilder(
							_Utils_update(
								state,
								{aS: newOctave})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.aW, d: 'Range', e: '# of octaves'},
				'number',
				function (newRange) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChordBuilder(
							_Utils_update(
								state,
								{aW: newRange})));
				}),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (i) {
						return $author$project$Main$FinishedEdit(
							$author$project$Main$chordKeyboard(i));
					},
					$author$project$EditableController$toChordBuildInput(state)))
			]));
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$EditableController$editStateToChord = function (_v0) {
	var label = _v0.d;
	var labelSize = _v0.n;
	var colour = _v0.i;
	var velocity = _v0.E;
	var notes = _v0.aR;
	return A2(
		$elm$core$Maybe$map,
		function (v) {
			return A5(
				$author$project$Controller$newChord,
				label,
				labelSize,
				colour,
				v,
				$elm$core$Dict$values(notes));
		},
		$elm$core$String$toInt(velocity));
};
var $author$project$Main$editChordPane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.d, d: 'Label', e: 'label'},
				'text',
				function (newLabel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChord(
							_Utils_update(
								state,
								{d: newLabel})));
				}),
				A2(
				$author$project$Style$labelSizeRadio,
				state.n,
				function (newLabelSize) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChord(
							_Utils_update(
								state,
								{n: newLabelSize})));
				}),
				A2(
				$author$project$Style$colourRadio,
				state.i,
				function (newColour) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChord(
							_Utils_update(
								state,
								{i: newColour})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.E, d: 'Velocity', e: 'velocity'},
				'number',
				function (newVelocity) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditChord(
							_Utils_update(
								state,
								{E: newVelocity})));
				}),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(4),
								$mdgriffith$elm_ui$Element$spacing(4),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$author$project$Style$backgroundColour(1)
							]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Notes'),
								A2(
								$mdgriffith$elm_ui$Element$Input$button,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$padding(5),
										$mdgriffith$elm_ui$Element$spacing(2),
										$mdgriffith$elm_ui$Element$Border$width(2),
										$mdgriffith$elm_ui$Element$Border$solid,
										$author$project$Style$borderColour(3),
										$mdgriffith$elm_ui$Element$Font$size(14)
									]),
								{
									d: $mdgriffith$elm_ui$Element$text('Clear'),
									af: $elm$core$Maybe$Just(
										$author$project$Main$UpdateControllerState(
											$author$project$EditableController$EditChord(
												_Utils_update(
													state,
													{aR: $elm$core$Dict$empty}))))
								})
							])),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(96)),
								$mdgriffith$elm_ui$Element$padding(4),
								$mdgriffith$elm_ui$Element$spacing(2),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$scrollbarY
							]),
						A2(
							$elm$core$List$map,
							function (_v0) {
								var channel = _v0.f;
								var pitch = _v0.y;
								return $mdgriffith$elm_ui$Element$text(
									'Ch: ' + ($author$project$Midi$channelToString(channel) + (', pitch: ' + $elm$core$String$fromInt(pitch))));
							},
							$elm$core$Dict$values(state.aR)))
					])),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (c) {
						return $author$project$Main$FinishedEdit(c);
					},
					$author$project$EditableController$editStateToChord(state)))
			]));
};
var $author$project$Controller$controllerToString = function (control) {
	switch (control.$) {
		case 0:
			var label = control.a;
			return 'Module: ' + label;
		case 1:
			var subcontrols = control.a;
			return 'Row: ' + ($elm$core$String$fromInt(
				$elm$core$List$length(subcontrols)) + ' items');
		case 2:
			var subcontrols = control.a;
			return 'Column: ' + ($elm$core$String$fromInt(
				$elm$core$List$length(subcontrols)) + ' items');
		case 3:
			var subcontrols = control.a;
			return 'Row: ' + ($elm$core$String$fromInt(
				$elm$core$List$length(subcontrols)) + ' items');
		case 4:
			var subcontrols = control.a;
			return 'Column: ' + ($elm$core$String$fromInt(
				$elm$core$List$length(subcontrols)) + ' items');
		case 5:
			var channel = control.a.f;
			var pitch = control.a.y;
			var velocity = control.a.E;
			return 'Button: ' + ($author$project$Midi$channelToString(channel) + (' ' + ($elm$core$String$fromInt(pitch) + (' ' + $elm$core$String$fromInt(velocity)))));
		case 6:
			var notes = control.a.aR;
			return 'Chord: ' + ($elm$core$String$fromInt(
				$elm$core$List$length(notes)) + ' notes');
		case 7:
			var channel = control.a.f;
			var controller = control.a.ar;
			var value = control.a.aE;
			return 'CC Value: ' + ($author$project$Midi$channelToString(channel) + (' ' + ($elm$core$String$fromInt(controller) + (' ' + $elm$core$String$fromInt(value)))));
		case 8:
			var onPressMsgs = control.a.a8;
			var onReleaseMsgs = control.a.a9;
			return 'Command: ' + ($elm$core$String$fromInt(
				$elm$core$List$length(onPressMsgs)) + (' msgs on press, ' + ($elm$core$String$fromInt(
				$elm$core$List$length(onReleaseMsgs)) + ' msgs on release ')));
		case 9:
			var midiMsgs = control.a.aP;
			return 'Sequence: ' + ($elm$core$String$fromInt(
				$elm$core$Array$length(midiMsgs)) + ' msgs');
		case 10:
			var channel = control.a.f;
			var ccNumber = control.a.b4;
			return 'Fader: ' + ($author$project$Midi$channelToString(channel) + (' ' + $elm$core$String$fromInt(ccNumber)));
		case 11:
			var channel1 = control.a.bH;
			var channel2 = control.a.bI;
			var ccNumber1 = control.a.b5;
			var ccNumber2 = control.a.b6;
			return 'Fader: ' + ($author$project$Midi$channelToString(channel1) + (' cc1 ' + ($elm$core$String$fromInt(ccNumber1) + (' ' + ($author$project$Midi$channelToString(channel2) + (' cc2 ' + $elm$core$String$fromInt(ccNumber2)))))));
		case 12:
			var channel = control.a.f;
			return 'PitchBend: Ch ' + $author$project$Midi$channelToString(channel);
		case 13:
			return 'MidiLog';
		default:
			return 'Space';
	}
};
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$editColumnPane = function (subControls) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$solid,
								$author$project$Style$borderColour(3)
							]),
						{
							d: $mdgriffith$elm_ui$Element$text('Add'),
							af: $elm$core$Maybe$Just(
								$author$project$Main$UpdateControllerState(
									$author$project$EditableController$EditColumn(
										A2(
											$elm$core$List$append,
											subControls,
											_List_fromArray(
												[$author$project$Controller$Space])))))
						}),
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$solid,
								$author$project$Style$borderColour(3)
							]),
						{
							d: $mdgriffith$elm_ui$Element$text('Remove'),
							af: $elm$core$Maybe$Just(
								$author$project$Main$UpdateControllerState(
									$author$project$EditableController$EditColumn(
										$elm$core$List$reverse(
											A2(
												$elm$core$Maybe$withDefault,
												_List_Nil,
												$elm$core$List$tail(
													$elm$core$List$reverse(subControls)))))))
						})
					])),
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(18)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Listening for MIDI...')
					])),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(300)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$padding(2),
						$mdgriffith$elm_ui$Element$spacing(4),
						$mdgriffith$elm_ui$Element$scrollbarY,
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed
					]),
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Element$text,
					A2($elm$core$List$map, $author$project$Controller$controllerToString, subControls))),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				$elm$core$Maybe$Just(
					$author$project$Main$FinishedEdit(
						$author$project$Controller$Column(subControls))))
			]));
};
var $author$project$EditableController$OnReleaseMsgs = 1;
var $author$project$Controller$newCommand = F5(
	function (label, labelSize, colour, onPressMsgs, onReleaseMsgs) {
		return $author$project$Controller$Command(
			{
				i: colour,
				d: label,
				n: $elm$core$Maybe$Just(labelSize),
				a8: onPressMsgs,
				a9: onReleaseMsgs,
				c: 1
			});
	});
var $author$project$EditableController$editStateToCommand = function (_v0) {
	var label = _v0.d;
	var labelSize = _v0.n;
	var colour = _v0.i;
	var onPressMsgs = _v0.a8;
	var onReleaseMsgs = _v0.a9;
	return $elm$core$Maybe$Just(
		A5($author$project$Controller$newCommand, label, labelSize, colour, onPressMsgs, onReleaseMsgs));
};
var $mdgriffith$elm_ui$Element$Input$HiddenLabel = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Element$Input$labelHidden = $mdgriffith$elm_ui$Element$Input$HiddenLabel;
var $author$project$Midi$editMidiButtonMsgView = F2(
	function (editMsg, midiButtonMsg) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(4),
					$mdgriffith$elm_ui$Element$spacing(4),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					function () {
					switch (midiButtonMsg.$) {
						case 0:
							var state = midiButtonMsg.a;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(4),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A3(
										$author$project$Style$editTextBox,
										{g: state.f, d: 'Channel', e: '1-16'},
										'number',
										function (newChannel) {
											return editMsg(
												$author$project$Midi$ENoteOn(
													_Utils_update(
														state,
														{f: newChannel})));
										}),
										A3(
										$author$project$Style$editTextBox,
										{g: state.y, d: 'Pitch', e: '0-127'},
										'number',
										function (newPitch) {
											return editMsg(
												$author$project$Midi$ENoteOn(
													_Utils_update(
														state,
														{y: newPitch})));
										}),
										A3(
										$author$project$Style$editTextBox,
										{g: state.E, d: 'Velocity', e: '0-127'},
										'number',
										function (newVelocity) {
											return editMsg(
												$author$project$Midi$ENoteOn(
													_Utils_update(
														state,
														{E: newVelocity})));
										})
									]));
						case 1:
							var state = midiButtonMsg.a;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(4),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A3(
										$author$project$Style$editTextBox,
										{g: state.f, d: 'Channel', e: '1-16'},
										'number',
										function (newChannel) {
											return editMsg(
												$author$project$Midi$ENoteOff(
													_Utils_update(
														state,
														{f: newChannel})));
										}),
										A3(
										$author$project$Style$editTextBox,
										{g: state.y, d: 'Pitch', e: '0-127'},
										'number',
										function (newPitch) {
											return editMsg(
												$author$project$Midi$ENoteOff(
													_Utils_update(
														state,
														{y: newPitch})));
										}),
										A3(
										$author$project$Style$editTextBox,
										{g: state.E, d: 'Velocity', e: '0-127'},
										'number',
										function (newVelocity) {
											return editMsg(
												$author$project$Midi$ENoteOff(
													_Utils_update(
														state,
														{E: newVelocity})));
										})
									]));
						case 2:
							var state = midiButtonMsg.a;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(4),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A3(
										$author$project$Style$editTextBox,
										{g: state.f, d: 'Channel', e: '1-16'},
										'number',
										function (newChannel) {
											return editMsg(
												$author$project$Midi$EControllerChange(
													_Utils_update(
														state,
														{f: newChannel})));
										}),
										A3(
										$author$project$Style$editTextBox,
										{g: state.ar, d: 'CC#', e: '0-127'},
										'number',
										function (newCC) {
											return editMsg(
												$author$project$Midi$EControllerChange(
													_Utils_update(
														state,
														{ar: newCC})));
										}),
										A3(
										$author$project$Style$editTextBox,
										{g: state.aE, d: 'Value', e: '0-127'},
										'number',
										function (newValue) {
											return editMsg(
												$author$project$Midi$EControllerChange(
													_Utils_update(
														state,
														{aE: newValue})));
										})
									]));
						case 3:
							var state = midiButtonMsg.a;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(4),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A3(
										$author$project$Style$editTextBox,
										{g: state.f, d: 'Channel', e: '1-16'},
										'number',
										function (newChannel) {
											return editMsg(
												$author$project$Midi$EProgramChange(
													_Utils_update(
														state,
														{f: newChannel})));
										}),
										A3(
										$author$project$Style$editTextBox,
										{g: state.W, d: 'Preset', e: '0-127'},
										'number',
										function (newPreset) {
											return editMsg(
												$author$project$Midi$EProgramChange(
													_Utils_update(
														state,
														{W: newPreset})));
										})
									]));
						case 4:
							var data = midiButtonMsg.a;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(4),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A3(
										$author$project$Style$editTextBox,
										{g: data, d: 'SysEx Data', e: 'Enter comma sep hex e.g. 2D, 46, 4A'},
										'text',
										function (newDataString) {
											return editMsg(
												$author$project$Midi$ESystemExclusive(newDataString));
										})
									]));
						case 5:
							var songNumber = midiButtonMsg.a;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(4),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A3(
										$author$project$Style$editTextBox,
										{g: songNumber, d: 'Song Number', e: '0-127'},
										'number',
										function (newSongNumber) {
											return editMsg(
												$author$project$Midi$ESongSelect(newSongNumber));
										})
									]));
						case 6:
							return $mdgriffith$elm_ui$Element$none;
						case 7:
							return $mdgriffith$elm_ui$Element$none;
						case 8:
							return $mdgriffith$elm_ui$Element$none;
						case 9:
							return $mdgriffith$elm_ui$Element$none;
						case 10:
							return $mdgriffith$elm_ui$Element$none;
						default:
							return $mdgriffith$elm_ui$Element$none;
					}
				}()
				]));
	});
var $author$project$Midi$editMidiButtonSelector = F2(
	function (selectMsg, selected) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$radio,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(4),
					$mdgriffith$elm_ui$Element$padding(4),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(100)),
					$mdgriffith$elm_ui$Element$scrollbarY,
					$mdgriffith$elm_ui$Element$Border$width(2)
				]),
			{
				d: $mdgriffith$elm_ui$Element$Input$labelHidden('Message Type'),
				ae: selectMsg,
				aT: _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$ENoteOn(
							{f: '', y: '', E: ''}),
						$mdgriffith$elm_ui$Element$text('Note On')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$ENoteOff(
							{f: '', y: '', E: ''}),
						$mdgriffith$elm_ui$Element$text('Note Off')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$EControllerChange(
							{f: '', ar: '', aE: ''}),
						$mdgriffith$elm_ui$Element$text('CC Value')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$EProgramChange(
							{f: '', W: ''}),
						$mdgriffith$elm_ui$Element$text('Program Change')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$ESystemExclusive(''),
						$mdgriffith$elm_ui$Element$text('SysEx')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$ESongSelect(''),
						$mdgriffith$elm_ui$Element$text('Song Select')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$ETuneRequest,
						$mdgriffith$elm_ui$Element$text('Tune Request')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$EStartSong,
						$mdgriffith$elm_ui$Element$text('Start Song')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$EContinueSong,
						$mdgriffith$elm_ui$Element$text('Continue Song')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$EStopSong,
						$mdgriffith$elm_ui$Element$text('Stop Song')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$EActiveSensing,
						$mdgriffith$elm_ui$Element$text('Active Sensing')),
						A2(
						$mdgriffith$elm_ui$Element$Input$option,
						$author$project$Midi$ESystemReset,
						$mdgriffith$elm_ui$Element$text('System Reset'))
					]),
				aX: $elm$core$Maybe$Just(selected)
			});
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$trim = _String_trim;
var $author$project$Midi$editMidiButtonToMidiMsg = function (eMidiButtonMsg) {
	var toNibbleInt = function (i) {
		return A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$clamp, 0, 15),
			A2(
				$elm$core$Maybe$map,
				function (ch) {
					return ch - 1;
				},
				$elm$core$String$toInt(i)));
	};
	var toByteInt = function (i) {
		return A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$clamp, 0, 127),
			$elm$core$String$toInt(i));
	};
	switch (eMidiButtonMsg.$) {
		case 0:
			var channel = eMidiButtonMsg.a.f;
			var pitch = eMidiButtonMsg.a.y;
			var velocity = eMidiButtonMsg.a.E;
			var _v1 = _Utils_Tuple3(
				toNibbleInt(channel),
				toByteInt(pitch),
				toByteInt(velocity));
			if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
				var ch = _v1.a.a;
				var p = _v1.b.a;
				var v = _v1.c.a;
				return $elm$core$Maybe$Just(
					$author$project$Midi$NoteOn(
						{f: ch, y: p, E: v}));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		case 1:
			var channel = eMidiButtonMsg.a.f;
			var pitch = eMidiButtonMsg.a.y;
			var velocity = eMidiButtonMsg.a.E;
			var _v2 = _Utils_Tuple3(
				toNibbleInt(channel),
				toByteInt(pitch),
				toByteInt(velocity));
			if (((!_v2.a.$) && (!_v2.b.$)) && (!_v2.c.$)) {
				var ch = _v2.a.a;
				var p = _v2.b.a;
				var v = _v2.c.a;
				return $elm$core$Maybe$Just(
					$author$project$Midi$NoteOff(
						{f: ch, y: p, E: v}));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		case 2:
			var channel = eMidiButtonMsg.a.f;
			var controller = eMidiButtonMsg.a.ar;
			var value = eMidiButtonMsg.a.aE;
			var _v3 = _Utils_Tuple3(
				toNibbleInt(channel),
				toByteInt(controller),
				toByteInt(value));
			if (((!_v3.a.$) && (!_v3.b.$)) && (!_v3.c.$)) {
				var ch = _v3.a.a;
				var c = _v3.b.a;
				var v = _v3.c.a;
				return $elm$core$Maybe$Just(
					$author$project$Midi$ControllerChange(
						{f: ch, ar: c, aE: v}));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		case 3:
			var channel = eMidiButtonMsg.a.f;
			var preset = eMidiButtonMsg.a.W;
			var _v4 = _Utils_Tuple2(
				toNibbleInt(channel),
				toByteInt(preset));
			if ((!_v4.a.$) && (!_v4.b.$)) {
				var ch = _v4.a.a;
				var p = _v4.b.a;
				return $elm$core$Maybe$Just(
					$author$project$Midi$ProgramChange(
						{f: ch, W: p}));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		case 5:
			var songNumber = eMidiButtonMsg.a;
			return A2(
				$elm$core$Maybe$map,
				$author$project$Midi$SongSelect,
				toByteInt(songNumber));
		case 4:
			var dataString = eMidiButtonMsg.a;
			var hex_values = A2(
				$elm$core$List$map,
				$rtfeldman$elm_hex$Hex$fromString,
				A2(
					$elm$core$List$map,
					$elm$core$String$trim,
					A2($elm$core$String$split, ',', dataString)));
			var all_okay = A2(
				$elm$core$List$all,
				function (h) {
					if (!h.$) {
						return true;
					} else {
						return false;
					}
				},
				hex_values);
			return all_okay ? $elm$core$Maybe$Just(
				$author$project$Midi$SystemExclusive(
					$elm$core$Array$fromList(
						A2($elm$core$List$filterMap, $elm$core$Result$toMaybe, hex_values)))) : $elm$core$Maybe$Nothing;
		case 6:
			return $elm$core$Maybe$Just($author$project$Midi$TuneRequest);
		case 7:
			return $elm$core$Maybe$Just($author$project$Midi$StartSong);
		case 8:
			return $elm$core$Maybe$Just($author$project$Midi$ContinueSong);
		case 9:
			return $elm$core$Maybe$Just($author$project$Midi$StopSong);
		case 10:
			return $elm$core$Maybe$Just($author$project$Midi$ActiveSensing);
		default:
			return $elm$core$Maybe$Just($author$project$Midi$SystemReset);
	}
};
var $author$project$Main$newCommandMidiMsgView = F2(
	function (newMsg, state) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(4),
					$mdgriffith$elm_ui$Element$spacing(4),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Border$dashed,
					$mdgriffith$elm_ui$Element$Border$width(2)
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Midi$editMidiButtonSelector,
					function (msgType) {
						return $author$project$Main$UpdateControllerState(
							$author$project$EditableController$EditCommand(
								_Utils_update(
									state,
									{
										bw: $elm$core$Maybe$Just(msgType)
									})));
					},
					newMsg),
					A2(
					$author$project$Midi$editMidiButtonMsgView,
					function (updatedMidi) {
						return $author$project$Main$UpdateControllerState(
							$author$project$EditableController$EditCommand(
								_Utils_update(
									state,
									{
										bw: $elm$core$Maybe$Just(updatedMidi)
									})));
					},
					newMsg),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					A3(
						$author$project$Style$acceptOrCloseButtons,
						'Add',
						$author$project$Main$UpdateControllerState(
							$author$project$EditableController$EditCommand(
								_Utils_update(
									state,
									{bw: $elm$core$Maybe$Nothing}))),
						A2(
							$elm$core$Maybe$map,
							function (completeMsg) {
								var _v0 = state.dM;
								if (!_v0) {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditCommand(
											_Utils_update(
												state,
												{
													bw: $elm$core$Maybe$Nothing,
													a8: A2(
														$elm$core$List$append,
														state.a8,
														_List_fromArray(
															[completeMsg]))
												})));
								} else {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditCommand(
											_Utils_update(
												state,
												{
													bw: $elm$core$Maybe$Nothing,
													a9: A2(
														$elm$core$List$append,
														state.a9,
														_List_fromArray(
															[completeMsg]))
												})));
								}
							},
							$author$project$Midi$editMidiButtonToMidiMsg(newMsg))))
				]));
	});
var $author$project$Main$editCommandPane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.d, d: 'Label', e: 'label'},
				'text',
				function (newLabel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditCommand(
							_Utils_update(
								state,
								{d: newLabel})));
				}),
				A2(
				$author$project$Style$labelSizeRadio,
				state.n,
				function (newLabelSize) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditCommand(
							_Utils_update(
								state,
								{n: newLabelSize})));
				}),
				A2(
				$author$project$Style$colourRadio,
				state.i,
				function (newColour) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditCommand(
							_Utils_update(
								state,
								{i: newColour})));
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$radio,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10)
					]),
				{
					d: $mdgriffith$elm_ui$Element$Input$labelHidden('On Event Type'),
					ae: function (newEditMode) {
						return $author$project$Main$UpdateControllerState(
							$author$project$EditableController$EditCommand(
								_Utils_update(
									state,
									{dM: newEditMode})));
					},
					aT: _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$Input$option,
							0,
							$mdgriffith$elm_ui$Element$text('Button Press')),
							A2(
							$mdgriffith$elm_ui$Element$Input$option,
							1,
							$mdgriffith$elm_ui$Element$text('Button Release'))
						]),
					aX: $elm$core$Maybe$Just(state.dM)
				}),
				function () {
				var _v0 = state.bw;
				if (!_v0.$) {
					var newMsg = _v0.a;
					return A2($author$project$Main$newCommandMidiMsgView, newMsg, state);
				} else {
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(4),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(300)),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(4),
										$mdgriffith$elm_ui$Element$scrollbarY,
										$mdgriffith$elm_ui$Element$Border$width(2),
										$mdgriffith$elm_ui$Element$Border$dashed
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(6),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										A2(
											$elm$core$List$cons,
											A2(
												$mdgriffith$elm_ui$Element$row,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$padding(4),
														$mdgriffith$elm_ui$Element$spacing(4),
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$author$project$Style$backgroundColour(1)
													]),
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('Messages'),
														A2(
														$mdgriffith$elm_ui$Element$Input$button,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$alignRight,
																$mdgriffith$elm_ui$Element$padding(5),
																$mdgriffith$elm_ui$Element$Border$width(2),
																$mdgriffith$elm_ui$Element$Border$solid,
																$author$project$Style$borderColour(3),
																$mdgriffith$elm_ui$Element$Font$size(14)
															]),
														{
															d: $mdgriffith$elm_ui$Element$text('Add Msg'),
															af: $elm$core$Maybe$Just(
																$author$project$Main$UpdateControllerState(
																	$author$project$EditableController$EditCommand(
																		_Utils_update(
																			state,
																			{
																				bw: $elm$core$Maybe$Just(
																					$author$project$Midi$ENoteOn(
																						{f: '', y: '', E: ''}))
																			}))))
														}),
														A2(
														$mdgriffith$elm_ui$Element$Input$button,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$alignRight,
																$mdgriffith$elm_ui$Element$padding(5),
																$mdgriffith$elm_ui$Element$spacing(2),
																$mdgriffith$elm_ui$Element$Border$width(2),
																$mdgriffith$elm_ui$Element$Border$solid,
																$author$project$Style$borderColour(3),
																$mdgriffith$elm_ui$Element$Font$size(14)
															]),
														{
															d: $mdgriffith$elm_ui$Element$text('Clear'),
															af: $elm$core$Maybe$Just(
																$author$project$Main$UpdateControllerState(
																	$author$project$EditableController$EditCommand(
																		function () {
																			var _v1 = state.dM;
																			if (!_v1) {
																				return _Utils_update(
																					state,
																					{a8: _List_Nil});
																			} else {
																				return _Utils_update(
																					state,
																					{a9: _List_Nil});
																			}
																		}())))
														})
													])),
											A2(
												$elm$core$List$map,
												function (midiMsg) {
													return A2(
														$mdgriffith$elm_ui$Element$paragraph,
														_List_Nil,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$text(
																$author$project$Midi$midiMsgToString(midiMsg))
															]));
												},
												function () {
													var _v2 = state.dM;
													if (!_v2) {
														return state.a8;
													} else {
														return state.a9;
													}
												}())))
									]))
							]));
				}
			}(),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (c) {
						return $author$project$Main$FinishedEdit(c);
					},
					$author$project$EditableController$editStateToCommand(state)))
			]));
};
var $author$project$EditableController$editStateToFader = function (_v0) {
	var label = _v0.d;
	var labelSize = _v0.n;
	var colour = _v0.i;
	var channel = _v0.f;
	var ccNumber = _v0.b4;
	var valueMin = _v0.cI;
	var valueMax = _v0.cF;
	var _v1 = $author$project$Midi$stringToChannel(channel);
	if (!_v1.$) {
		var ch = _v1.a;
		var _v2 = _Utils_Tuple3(
			$elm$core$String$toInt(ccNumber),
			$elm$core$String$toInt(valueMin),
			$elm$core$String$toInt(valueMax));
		if (((!_v2.a.$) && (!_v2.b.$)) && (!_v2.c.$)) {
			var cc = _v2.a.a;
			var vmin = _v2.b.a;
			var vmax = _v2.c.a;
			return $elm$core$Maybe$Just(
				$author$project$Controller$Fader(
					{
						b4: cc,
						f: ch,
						i: colour,
						d: label,
						n: $elm$core$Maybe$Just(labelSize),
						c: $author$project$Controller$Set,
						cF: vmax,
						cI: vmin,
						bh: 50
					}));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$editFaderPane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.d, d: 'Label', e: 'label'},
				'text',
				function (newLabel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditFader(
							_Utils_update(
								state,
								{d: newLabel})));
				}),
				A2(
				$author$project$Style$labelSizeRadio,
				state.n,
				function (newLabelSize) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditFader(
							_Utils_update(
								state,
								{n: newLabelSize})));
				}),
				A2(
				$author$project$Style$colourRadio,
				state.i,
				function (newColour) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditFader(
							_Utils_update(
								state,
								{i: newColour})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.f, d: 'Channel', e: 'channel#'},
				'number',
				function (newChannel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditFader(
							_Utils_update(
								state,
								{f: newChannel})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.b4, d: 'CC Number', e: 'cc#'},
				'number',
				function (newCCNumber) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditFader(
							_Utils_update(
								state,
								{b4: newCCNumber})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.cI, d: 'Min Value', e: 'min value'},
				'number',
				function (newMinValue) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditFader(
							_Utils_update(
								state,
								{cI: newMinValue})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.cF, d: 'Max Value', e: 'max value'},
				'number',
				function (newMaxValue) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditFader(
							_Utils_update(
								state,
								{cF: newMaxValue})));
				}),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (c) {
						return $author$project$Main$FinishedEdit(c);
					},
					$author$project$EditableController$editStateToFader(state)))
			]));
};
var $author$project$EditableController$toIsomorphicInput = function (state) {
	var mVelocity = $elm$core$String$toInt(state.E);
	var mRowLength = $elm$core$String$toInt(state.cy);
	var mOffset = $elm$core$String$toInt(state.cn);
	var mNumberOfRows = $elm$core$String$toInt(state.cm);
	var mFirstNote = $elm$core$String$toInt(state.ch);
	var mChannel = $author$project$Midi$stringToChannel(state.f);
	return A7(
		$author$project$Utils$mmap6,
		F6(
			function (c, v, f, n, o, r) {
				return {f: c, ch: f, cm: n, cn: o, cy: r, E: v};
			}),
		mChannel,
		mVelocity,
		mFirstNote,
		mNumberOfRows,
		mOffset,
		mRowLength);
};
var $author$project$Main$editIsomorphicPane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.f, d: 'Channel', e: 'channel#'},
				'number',
				function (newChannel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditIsomorphic(
							_Utils_update(
								state,
								{f: newChannel})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.E, d: 'Velocity', e: 'velocity'},
				'number',
				function (newVelocity) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditIsomorphic(
							_Utils_update(
								state,
								{E: newVelocity})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.ch, d: 'First Note', e: 'first note#'},
				'number',
				function (newFirstNote) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditIsomorphic(
							_Utils_update(
								state,
								{ch: newFirstNote})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.cm, d: 'Rows', e: 'num of rows'},
				'number',
				function (newNumRows) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditIsomorphic(
							_Utils_update(
								state,
								{cm: newNumRows})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.cn, d: 'Note Offset', e: 'offset'},
				'number',
				function (newOffset) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditIsomorphic(
							_Utils_update(
								state,
								{cn: newOffset})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.cy, d: 'Row Length', e: 'row length'},
				'number',
				function (newRowLength) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditIsomorphic(
							_Utils_update(
								state,
								{cy: newRowLength})));
				}),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (i) {
						return $author$project$Main$FinishedEdit(
							$author$project$Main$isomorphicKeyboard(i));
					},
					$author$project$EditableController$toIsomorphicInput(state)))
			]));
};
var $author$project$Main$DeleteSavedModule = function (a) {
	return {$: 28, a: a};
};
var $author$project$EditableController$Load = 2;
var $author$project$EditableController$Wrap = 1;
var $author$project$Style$selectableOption = F4(
	function (msg, mSelected, index, label) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$alignLeft,
					function () {
					var _v0 = A2(
						$elm$core$Maybe$map,
						function (s) {
							return _Utils_eq(s, index);
						},
						mSelected);
					if ((!_v0.$) && _v0.a) {
						return $author$project$Style$backgroundColour(5);
					} else {
						return $author$project$Style$backgroundColour(0);
					}
				}(),
					$mdgriffith$elm_ui$Element$Events$onClick(
					msg(index))
				]),
			$mdgriffith$elm_ui$Element$text(label));
	});
var $author$project$Main$editModulePane = F2(
	function (savedModules, state) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignTop,
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$spacing(10),
					$author$project$Main$editPanelWidth,
					$author$project$Style$backgroundColour(0),
					$mdgriffith$elm_ui$Element$Border$width(4)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$radioRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					{
						d: $mdgriffith$elm_ui$Element$Input$labelHidden('Create Mode'),
						ae: function (newMode) {
							return $author$project$Main$UpdateControllerState(
								$author$project$EditableController$EditModule(
									_Utils_update(
										state,
										{fC: newMode})));
						},
						aT: _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$Input$option,
								0,
								$mdgriffith$elm_ui$Element$text('New')),
								A2(
								$mdgriffith$elm_ui$Element$Input$option,
								1,
								$mdgriffith$elm_ui$Element$text('Wrap')),
								A2(
								$mdgriffith$elm_ui$Element$Input$option,
								2,
								$mdgriffith$elm_ui$Element$text('Load'))
							]),
						aX: $elm$core$Maybe$Just(state.fC)
					}),
					function () {
					var _v0 = state.fC;
					switch (_v0) {
						case 0:
							return A3(
								$author$project$Style$editTextBox,
								{g: state.d, d: 'Label', e: 'label'},
								'text',
								function (newLabel) {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditModule(
											_Utils_update(
												state,
												{d: newLabel})));
								});
						case 1:
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(10),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('Will wrap the current controller in a module.')
											])),
										A3(
										$author$project$Style$editTextBox,
										{g: state.d, d: 'Label', e: 'label'},
										'text',
										function (newLabel) {
											return $author$project$Main$UpdateControllerState(
												$author$project$EditableController$EditModule(
													_Utils_update(
														state,
														{d: newLabel})));
										})
									]));
						default:
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(10),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										function () {
										var _v1 = state.g0;
										if (!_v1.$) {
											var index = _v1.a;
											return A2(
												$mdgriffith$elm_ui$Element$Input$button,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$padding(5),
														$mdgriffith$elm_ui$Element$Border$width(2),
														$mdgriffith$elm_ui$Element$Border$solid,
														$author$project$Style$backgroundColour(7),
														$author$project$Style$fontColour(0),
														$author$project$Style$borderColour(3)
													]),
												{
													d: $mdgriffith$elm_ui$Element$text('Delete'),
													af: A2(
														$elm$core$Maybe$map,
														function (_v2) {
															var key = _v2.a;
															return $author$project$Main$DeleteSavedModule(key);
														},
														A2(
															$elm$core$Array$get,
															index,
															$elm$core$Array$fromList(
																$elm$core$Dict$toList(savedModules))))
												});
										} else {
											return $mdgriffith$elm_ui$Element$none;
										}
									}(),
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$height(
												$mdgriffith$elm_ui$Element$px(200)),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$scrollbarY,
												$mdgriffith$elm_ui$Element$Border$width(2),
												$mdgriffith$elm_ui$Element$Border$dashed
											]),
										A2(
											$elm$core$List$indexedMap,
											A2(
												$author$project$Style$selectableOption,
												function (newSelected) {
													return $author$project$Main$UpdateControllerState(
														$author$project$EditableController$EditModule(
															_Utils_update(
																state,
																{
																	g0: $elm$core$Maybe$Just(newSelected)
																})));
												},
												state.g0),
											$elm$core$Dict$keys(savedModules)))
									]));
					}
				}(),
					A3(
					$author$project$Style$acceptOrCloseButtons,
					'Ok',
					$author$project$Main$ClosePopUp,
					function () {
						var _v3 = state.fC;
						switch (_v3) {
							case 0:
								return $elm$core$Maybe$Just(
									$author$project$Main$FinishedEdit(
										A2($author$project$Controller$Module, state.d, state.ar)));
							case 1:
								return $elm$core$Maybe$Just(
									$author$project$Main$FinishedEdit(
										A2($author$project$Controller$Module, state.d, state.gD)));
							default:
								return A2(
									$elm$core$Maybe$andThen,
									function (i) {
										return A2(
											$elm$core$Maybe$map,
											function (m) {
												return $author$project$Main$FinishedEdit(m);
											},
											A2(
												$elm$core$Array$get,
												i,
												$elm$core$Array$fromList(
													$elm$core$Dict$values(savedModules))));
									},
									state.g0);
						}
					}())
				]));
	});
var $author$project$EditableController$editStateToNote = function (_v0) {
	var label = _v0.d;
	var labelSize = _v0.n;
	var colour = _v0.i;
	var pitch = _v0.y;
	var channel = _v0.f;
	var velocity = _v0.E;
	var _v1 = _Utils_Tuple3(
		$author$project$Midi$stringToChannel(channel),
		$elm$core$String$toInt(pitch),
		$elm$core$String$toInt(velocity));
	if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
		var ch = _v1.a.a;
		var nn = _v1.b.a;
		var vel = _v1.c.a;
		return $elm$core$Maybe$Just(
			A6($author$project$Controller$newNote, label, labelSize, colour, ch, nn, vel));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$editNotePane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.d, d: 'Label', e: 'label'},
				'text',
				function (newLabel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditNote(
							_Utils_update(
								state,
								{d: newLabel})));
				}),
				A2(
				$author$project$Style$labelSizeRadio,
				state.n,
				function (newLabelSize) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditNote(
							_Utils_update(
								state,
								{n: newLabelSize})));
				}),
				A2(
				$author$project$Style$colourRadio,
				state.i,
				function (newColour) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditNote(
							_Utils_update(
								state,
								{i: newColour})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.f, d: 'Channel', e: 'channel#'},
				'number',
				function (newChannel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditNote(
							_Utils_update(
								state,
								{f: newChannel})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.y, d: 'Note Number', e: 'note#'},
				'number',
				function (newNoteNumber) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditNote(
							_Utils_update(
								state,
								{y: newNoteNumber})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.E, d: 'Velocity', e: 'velocity'},
				'number',
				function (newVelocity) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditNote(
							_Utils_update(
								state,
								{E: newVelocity})));
				}),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (c) {
						return $author$project$Main$FinishedEdit(c);
					},
					$author$project$EditableController$editStateToNote(state)))
			]));
};
var $author$project$EditableController$editStateToPitchBend = function (state) {
	return A2(
		$elm$core$Maybe$map,
		function (ch) {
			return $author$project$Controller$PitchBend(
				{
					bn: 8192,
					f: ch,
					i: state.i,
					d: state.d,
					n: $elm$core$Maybe$Just(state.n),
					c: $author$project$Controller$Set
				});
		},
		$author$project$Midi$stringToChannel(state.f));
};
var $author$project$Main$editPitchBendPane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.d, d: 'Label', e: 'label'},
				'text',
				function (newLabel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditPitchBend(
							_Utils_update(
								state,
								{d: newLabel})));
				}),
				A2(
				$author$project$Style$labelSizeRadio,
				state.n,
				function (newLabelSize) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditPitchBend(
							_Utils_update(
								state,
								{n: newLabelSize})));
				}),
				A2(
				$author$project$Style$colourRadio,
				state.i,
				function (newColour) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditPitchBend(
							_Utils_update(
								state,
								{i: newColour})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.f, d: 'Channel', e: 'channel#'},
				'number',
				function (newChannel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditPitchBend(
							_Utils_update(
								state,
								{f: newChannel})));
				}),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (c) {
						return $author$project$Main$FinishedEdit(c);
					},
					$author$project$EditableController$editStateToPitchBend(state)))
			]));
};
var $author$project$Main$editRowPane = function (subControls) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$solid,
								$author$project$Style$borderColour(3)
							]),
						{
							d: $mdgriffith$elm_ui$Element$text('Add'),
							af: $elm$core$Maybe$Just(
								$author$project$Main$UpdateControllerState(
									$author$project$EditableController$EditRow(
										A2(
											$elm$core$List$append,
											subControls,
											_List_fromArray(
												[$author$project$Controller$Space])))))
						}),
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$solid,
								$author$project$Style$borderColour(3)
							]),
						{
							d: $mdgriffith$elm_ui$Element$text('Remove'),
							af: $elm$core$Maybe$Just(
								$author$project$Main$UpdateControllerState(
									$author$project$EditableController$EditRow(
										$elm$core$List$reverse(
											A2(
												$elm$core$Maybe$withDefault,
												_List_Nil,
												$elm$core$List$tail(
													$elm$core$List$reverse(subControls)))))))
						})
					])),
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(18)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Listening for MIDI...')
					])),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(300)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$padding(2),
						$mdgriffith$elm_ui$Element$spacing(4),
						$mdgriffith$elm_ui$Element$scrollbarY,
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed
					]),
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Element$text,
					A2($elm$core$List$map, $author$project$Controller$controllerToString, subControls))),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				$elm$core$Maybe$Just(
					$author$project$Main$FinishedEdit(
						$author$project$Controller$Row(subControls))))
			]));
};
var $duncanmalashock$elm_music_theory$Music$Pitch$toString = function (pitch) {
	return $duncanmalashock$elm_music_theory$Music$Internal$Pitch$toString(pitch);
};
var $author$project$Main$makeNotesRow = F5(
	function (channel, velocity, intervals, root, octave) {
		return function (n) {
			return _Utils_Tuple2(
				1,
				$author$project$Controller$SizedRow(n));
		}(
			A2(
				$elm$core$List$map,
				function (_v1) {
					var s = _v1.a;
					var n = _v1.b;
					return _Utils_Tuple2(
						1,
						A6($author$project$Controller$newNote, s, 1, 4, channel, n, velocity));
				},
				A2(
					$elm$core$List$filter,
					function (_v0) {
						var n = _v0.b;
						return A2(
							$elm$core$List$member,
							n,
							A2($elm$core$List$range, 1, 127));
					},
					A2(
						$elm$core$List$map,
						function (p) {
							return _Utils_Tuple2(
								$duncanmalashock$elm_music_theory$Music$Pitch$toString(p),
								$duncanmalashock$elm_music_theory$Music$Pitch$toMIDINoteNumber(p));
						},
						A2(
							$elm$core$List$map,
							function (i) {
								return A2(
									$duncanmalashock$elm_music_theory$Music$Pitch$transposeUp,
									i,
									A2($duncanmalashock$elm_music_theory$Music$Pitch$fromPitchClassInOctave, octave, root));
							},
							intervals)))));
	});
var $duncanmalashock$elm_music_theory$Music$Internal$Scale$scaleType = function (_v0) {
	var theScaleType = _v0.b;
	return theScaleType;
};
var $duncanmalashock$elm_music_theory$Music$Scale$scaleType = function (theScale) {
	return $duncanmalashock$elm_music_theory$Music$Internal$Scale$scaleType(theScale);
};
var $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$toList = function (theScaleType) {
	switch (theScaleType.$) {
		case 0:
			var intervals = theScaleType.a;
			return _List_fromArray(
				[$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison, intervals.cw, intervals.cx, intervals.cv, intervals.cu]);
		case 1:
			var intervals = theScaleType.a;
			return _List_fromArray(
				[$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison, intervals.cw, intervals.cx, intervals.cv, intervals.cu, intervals.dh]);
		case 2:
			var intervals = theScaleType.a;
			return _List_fromArray(
				[$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison, intervals.cw, intervals.cx, intervals.cv, intervals.cu, intervals.dh, intervals.ey]);
		default:
			var intervals = theScaleType.a;
			return _List_fromArray(
				[$duncanmalashock$elm_music_theory$Music$Internal$Interval$perfectUnison, intervals.cw, intervals.cx, intervals.cv, intervals.cu, intervals.dh, intervals.ey, intervals.gV]);
	}
};
var $duncanmalashock$elm_music_theory$Music$ScaleType$toList = function (scaleType) {
	return $duncanmalashock$elm_music_theory$Music$Internal$ScaleType$toList(scaleType);
};
var $author$project$Main$scaleKeyboard = function (_v0) {
	var channel = _v0.f;
	var velocity = _v0.E;
	var key = _v0.aN;
	var scaleId = _v0.eC;
	var octave = _v0.aS;
	var range = _v0.aW;
	var scaleConstructor = $author$project$Main$scaleToScaleConstructor(scaleId);
	var pitchClass = $author$project$Main$keyToPitchClass(key);
	var scale = scaleConstructor(pitchClass);
	var octaveNumbers = A2(
		$elm$core$List$map,
		$elm$core$Basics$add(octave),
		A2($elm$core$List$range, 0, range - 1));
	var intervals = $duncanmalashock$elm_music_theory$Music$ScaleType$toList(
		$duncanmalashock$elm_music_theory$Music$Scale$scaleType(scale));
	return $author$project$Controller$SizedColumn(
		$elm$core$List$reverse(
			A2(
				$elm$core$List$map,
				A4($author$project$Main$makeNotesRow, channel, velocity, intervals, pitchClass),
				octaveNumbers)));
};
var $author$project$EditableController$toScaleKeyboardInput = function (state) {
	var mVelocity = $elm$core$String$toInt(state.E);
	var mScale = $elm$core$Maybe$Just(state.by);
	var mRange = $elm$core$String$toInt(state.aW);
	var mOctave = $elm$core$String$toInt(state.aS);
	var mKey = $elm$core$Maybe$Just(state.aN);
	var mChannel = $author$project$Midi$stringToChannel(state.f);
	return A7(
		$author$project$Utils$mmap6,
		F6(
			function (c, v, n, s, o, r) {
				return {f: c, aN: n, aS: o, aW: r, eC: s, E: v};
			}),
		mChannel,
		mVelocity,
		mKey,
		mScale,
		mOctave,
		mRange);
};
var $author$project$Main$editScalePane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.f, d: 'Channel', e: 'channel#'},
				'number',
				function (newChannel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditScale(
							_Utils_update(
								state,
								{f: newChannel})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.E, d: 'Velocity', e: 'velocity'},
				'number',
				function (newVelocity) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditScale(
							_Utils_update(
								state,
								{E: newVelocity})));
				}),
				A2(
				$author$project$Main$noteRadio,
				state.aN,
				function (newNote) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditScale(
							_Utils_update(
								state,
								{aN: newNote})));
				}),
				A2(
				$author$project$Main$scaleRadio,
				state.by,
				function (newScale) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditScale(
							_Utils_update(
								state,
								{by: newScale})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.aS, d: 'Octave Number', e: 'octave#'},
				'number',
				function (newOctave) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditScale(
							_Utils_update(
								state,
								{aS: newOctave})));
				}),
				A3(
				$author$project$Style$editTextBox,
				{g: state.aW, d: 'Range', e: '# of octaves'},
				'number',
				function (newRange) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditScale(
							_Utils_update(
								state,
								{aW: newRange})));
				}),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (i) {
						return $author$project$Main$FinishedEdit(
							$author$project$Main$scaleKeyboard(i));
					},
					$author$project$EditableController$toScaleKeyboardInput(state)))
			]));
};
var $author$project$Controller$newSequence = F4(
	function (label, labelSize, colour, midiMsgs) {
		return $author$project$Controller$Sequence(
			{
				i: colour,
				a5: 0,
				d: label,
				n: $elm$core$Maybe$Just(labelSize),
				aP: midiMsgs,
				c: 1
			});
	});
var $author$project$EditableController$editStateToSequence = function (_v0) {
	var label = _v0.d;
	var labelSize = _v0.n;
	var colour = _v0.i;
	var midiMsgs = _v0.aP;
	return $elm$core$Maybe$Just(
		A4($author$project$Controller$newSequence, label, labelSize, colour, midiMsgs));
};
var $author$project$Main$newSequenceMidiMsgView = F2(
	function (newMsg, state) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(4),
					$mdgriffith$elm_ui$Element$spacing(4),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Border$dashed,
					$mdgriffith$elm_ui$Element$Border$width(2)
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Midi$editMidiButtonSelector,
					function (msgType) {
						return $author$project$Main$UpdateControllerState(
							$author$project$EditableController$EditSequence(
								_Utils_update(
									state,
									{
										bw: $elm$core$Maybe$Just(msgType)
									})));
					},
					newMsg),
					A2(
					$author$project$Midi$editMidiButtonMsgView,
					function (updatedMidi) {
						return $author$project$Main$UpdateControllerState(
							$author$project$EditableController$EditSequence(
								_Utils_update(
									state,
									{
										bw: $elm$core$Maybe$Just(updatedMidi)
									})));
					},
					newMsg),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					A3(
						$author$project$Style$acceptOrCloseButtons,
						'Add',
						$author$project$Main$UpdateControllerState(
							$author$project$EditableController$EditSequence(
								_Utils_update(
									state,
									{bw: $elm$core$Maybe$Nothing}))),
						A2(
							$elm$core$Maybe$map,
							function (completeMsg) {
								return $author$project$Main$UpdateControllerState(
									$author$project$EditableController$EditSequence(
										_Utils_update(
											state,
											{
												aP: A2(
													$elm$core$Array$append,
													state.aP,
													$elm$core$Array$fromList(
														_List_fromArray(
															[completeMsg]))),
												bw: $elm$core$Maybe$Nothing
											})));
							},
							$author$project$Midi$editMidiButtonToMidiMsg(newMsg))))
				]));
	});
var $author$project$Main$editSequencePane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.d, d: 'Label', e: 'label'},
				'text',
				function (newLabel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditSequence(
							_Utils_update(
								state,
								{d: newLabel})));
				}),
				A2(
				$author$project$Style$labelSizeRadio,
				state.n,
				function (newLabelSize) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditSequence(
							_Utils_update(
								state,
								{n: newLabelSize})));
				}),
				A2(
				$author$project$Style$colourRadio,
				state.i,
				function (newColour) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditSequence(
							_Utils_update(
								state,
								{i: newColour})));
				}),
				function () {
				var _v0 = state.bw;
				if (!_v0.$) {
					var newMsg = _v0.a;
					return A2($author$project$Main$newSequenceMidiMsgView, newMsg, state);
				} else {
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(4),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(300)),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(4),
										$mdgriffith$elm_ui$Element$scrollbarY,
										$mdgriffith$elm_ui$Element$Border$width(2),
										$mdgriffith$elm_ui$Element$Border$dashed
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(6),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										A2(
											$elm$core$List$cons,
											A2(
												$mdgriffith$elm_ui$Element$row,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$padding(4),
														$mdgriffith$elm_ui$Element$spacing(4),
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$author$project$Style$backgroundColour(1)
													]),
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('Messages'),
														A2(
														$mdgriffith$elm_ui$Element$Input$button,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$alignRight,
																$mdgriffith$elm_ui$Element$padding(5),
																$mdgriffith$elm_ui$Element$Border$width(2),
																$mdgriffith$elm_ui$Element$Border$solid,
																$author$project$Style$borderColour(3),
																$mdgriffith$elm_ui$Element$Font$size(14)
															]),
														{
															d: $mdgriffith$elm_ui$Element$text('Add Msg'),
															af: $elm$core$Maybe$Just(
																$author$project$Main$UpdateControllerState(
																	$author$project$EditableController$EditSequence(
																		_Utils_update(
																			state,
																			{
																				bw: $elm$core$Maybe$Just(
																					$author$project$Midi$ENoteOn(
																						{f: '', y: '', E: ''}))
																			}))))
														}),
														A2(
														$mdgriffith$elm_ui$Element$Input$button,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$alignRight,
																$mdgriffith$elm_ui$Element$padding(5),
																$mdgriffith$elm_ui$Element$spacing(2),
																$mdgriffith$elm_ui$Element$Border$width(2),
																$mdgriffith$elm_ui$Element$Border$solid,
																$author$project$Style$borderColour(3),
																$mdgriffith$elm_ui$Element$Font$size(14)
															]),
														{
															d: $mdgriffith$elm_ui$Element$text('Clear'),
															af: $elm$core$Maybe$Just(
																$author$project$Main$UpdateControllerState(
																	$author$project$EditableController$EditSequence(
																		_Utils_update(
																			state,
																			{aP: $elm$core$Array$empty}))))
														})
													])),
											$elm$core$Array$toList(
												A2(
													$elm$core$Array$map,
													function (midiMsg) {
														return A2(
															$mdgriffith$elm_ui$Element$paragraph,
															_List_Nil,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$text(
																	$author$project$Midi$midiMsgToString(midiMsg))
																]));
													},
													state.aP))))
									]))
							]));
				}
			}(),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (c) {
						return $author$project$Main$FinishedEdit(c);
					},
					$author$project$EditableController$editStateToSequence(state)))
			]));
};
var $elm$svg$Svg$Attributes$points = _VirtualDom_attribute('points');
var $elm$svg$Svg$polyline = $elm$svg$Svg$trustedNode('polyline');
var $feathericons$elm_feather$FeatherIcons$trash2 = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'trash-2',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('3 6 5 6 21 6')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('10'),
					$elm$svg$Svg$Attributes$y1('11'),
					$elm$svg$Svg$Attributes$x2('10'),
					$elm$svg$Svg$Attributes$y2('17')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('14'),
					$elm$svg$Svg$Attributes$y1('11'),
					$elm$svg$Svg$Attributes$x2('14'),
					$elm$svg$Svg$Attributes$y2('17')
				]),
			_List_Nil)
		]));
var $author$project$Main$editSizedItemView = F3(
	function (subControls, i, _v0) {
		var s = _v0.a;
		var c = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(5),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(5),
							$mdgriffith$elm_ui$Element$Border$width(2),
							$mdgriffith$elm_ui$Element$Border$solid,
							$author$project$Style$borderColour(3),
							$author$project$Style$backgroundColour(7),
							$mdgriffith$elm_ui$Element$Font$color(
							$author$project$Style$appColourToRGB(0))
						]),
					{
						d: $mdgriffith$elm_ui$Element$html(
							A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withSize, 10, $feathericons$elm_feather$FeatherIcons$trash2))),
						af: $elm$core$Maybe$Just(
							$author$project$Main$UpdateControllerState(
								$author$project$EditableController$EditSizedRow(
									A2(
										$elm$core$List$map,
										$elm$core$Tuple$second,
										A2(
											$elm$core$List$filter,
											function (_v1) {
												var ci = _v1.a;
												return !_Utils_eq(i, ci);
											},
											A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, subControls))))))
					}),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					$mdgriffith$elm_ui$Element$text(
						A2(
							$elm$core$String$left,
							16,
							$author$project$Controller$controllerToString(c)))),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$spacing(2)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(5),
									$mdgriffith$elm_ui$Element$Border$width(2),
									$mdgriffith$elm_ui$Element$Border$solid,
									$author$project$Style$borderColour(3)
								]),
							{
								d: $mdgriffith$elm_ui$Element$html(
									A2(
										$feathericons$elm_feather$FeatherIcons$toHtml,
										_List_Nil,
										A2($feathericons$elm_feather$FeatherIcons$withSize, 10, $feathericons$elm_feather$FeatherIcons$plus))),
								af: $elm$core$Maybe$Just(
									$author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditSizedRow(
											A2(
												$elm$core$List$indexedMap,
												F2(
													function (ci, _v2) {
														var cs = _v2.a;
														var cc = _v2.b;
														return _Utils_eq(ci, i) ? _Utils_Tuple2(
															A2($elm$core$Basics$max, cs - 1, 0),
															cc) : _Utils_Tuple2(cs, cc);
													}),
												subControls))))
							}),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$alignRight]),
							$mdgriffith$elm_ui$Element$text(
								$elm$core$String$fromInt(s))),
							A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(5),
									$mdgriffith$elm_ui$Element$Border$width(2),
									$mdgriffith$elm_ui$Element$Border$solid,
									$author$project$Style$borderColour(3)
								]),
							{
								d: $mdgriffith$elm_ui$Element$html(
									A2(
										$feathericons$elm_feather$FeatherIcons$toHtml,
										_List_Nil,
										A2($feathericons$elm_feather$FeatherIcons$withSize, 10, $feathericons$elm_feather$FeatherIcons$plus))),
								af: $elm$core$Maybe$Just(
									$author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditSizedRow(
											A2(
												$elm$core$List$indexedMap,
												F2(
													function (ci, _v3) {
														var cs = _v3.a;
														var cc = _v3.b;
														return _Utils_eq(ci, i) ? _Utils_Tuple2(cs + 1, cc) : _Utils_Tuple2(cs, cc);
													}),
												subControls))))
							})
						]))
				]));
	});
var $author$project$Main$editSizedColumnPane = function (subControls) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$solid,
								$author$project$Style$borderColour(3)
							]),
						{
							d: $mdgriffith$elm_ui$Element$text('Add'),
							af: $elm$core$Maybe$Just(
								$author$project$Main$UpdateControllerState(
									$author$project$EditableController$EditSizedColumn(
										A2(
											$elm$core$List$append,
											subControls,
											_List_fromArray(
												[
													_Utils_Tuple2(1, $author$project$Controller$Space)
												])))))
						}),
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$solid,
								$author$project$Style$borderColour(3)
							]),
						{
							d: $mdgriffith$elm_ui$Element$text('Remove'),
							af: $elm$core$Maybe$Just(
								$author$project$Main$UpdateControllerState(
									$author$project$EditableController$EditSizedColumn(
										$elm$core$List$reverse(
											A2(
												$elm$core$Maybe$withDefault,
												_List_Nil,
												$elm$core$List$tail(
													$elm$core$List$reverse(subControls)))))))
						})
					])),
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(18)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Listening for MIDI...')
					])),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(300)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$padding(2),
						$mdgriffith$elm_ui$Element$spacing(4),
						$mdgriffith$elm_ui$Element$scrollbarY,
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed
					]),
				A2(
					$elm$core$List$indexedMap,
					$author$project$Main$editSizedItemView(subControls),
					subControls)),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				$elm$core$Maybe$Just(
					$author$project$Main$FinishedEdit(
						$author$project$Controller$SizedColumn(subControls))))
			]));
};
var $author$project$Main$editSizedRowPane = function (subControls) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$solid,
								$author$project$Style$borderColour(3)
							]),
						{
							d: $mdgriffith$elm_ui$Element$text('Add'),
							af: $elm$core$Maybe$Just(
								$author$project$Main$UpdateControllerState(
									$author$project$EditableController$EditSizedRow(
										A2(
											$elm$core$List$append,
											subControls,
											_List_fromArray(
												[
													_Utils_Tuple2(1, $author$project$Controller$Space)
												])))))
						}),
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$solid,
								$author$project$Style$borderColour(3)
							]),
						{
							d: $mdgriffith$elm_ui$Element$text('Remove'),
							af: $elm$core$Maybe$Just(
								$author$project$Main$UpdateControllerState(
									$author$project$EditableController$EditSizedRow(
										$elm$core$List$reverse(
											A2(
												$elm$core$Maybe$withDefault,
												_List_Nil,
												$elm$core$List$tail(
													$elm$core$List$reverse(subControls)))))))
						})
					])),
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$size(18)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Listening for MIDI...')
					])),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(300)),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$padding(2),
						$mdgriffith$elm_ui$Element$spacing(4),
						$mdgriffith$elm_ui$Element$scrollbarY,
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$dashed
					]),
				A2(
					$elm$core$List$indexedMap,
					$author$project$Main$editSizedItemView(subControls),
					subControls)),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				$elm$core$Maybe$Just(
					$author$project$Main$FinishedEdit(
						$author$project$Controller$SizedRow(subControls))))
			]));
};
var $author$project$EditableController$Params2 = 1;
var $author$project$EditableController$editStateToXYFader = function (state) {
	var _v0 = _Utils_Tuple2(
		$author$project$Midi$stringToChannel(state.bH),
		$author$project$Midi$stringToChannel(state.bI));
	if ((!_v0.a.$) && (!_v0.b.$)) {
		var ch1 = _v0.a.a;
		var ch2 = _v0.b.a;
		var _v1 = _List_fromArray(
			[
				$elm$core$String$toInt(state.b5),
				$elm$core$String$toInt(state.cJ),
				$elm$core$String$toInt(state.cG),
				$elm$core$String$toInt(state.b6),
				$elm$core$String$toInt(state.cK),
				$elm$core$String$toInt(state.cH)
			]);
		if ((((((((((((_v1.b && (!_v1.a.$)) && _v1.b.b) && (!_v1.b.a.$)) && _v1.b.b.b) && (!_v1.b.b.a.$)) && _v1.b.b.b.b) && (!_v1.b.b.b.a.$)) && _v1.b.b.b.b.b) && (!_v1.b.b.b.b.a.$)) && _v1.b.b.b.b.b.b) && (!_v1.b.b.b.b.b.a.$)) && (!_v1.b.b.b.b.b.b.b)) {
			var cc1 = _v1.a.a;
			var _v2 = _v1.b;
			var vmin1 = _v2.a.a;
			var _v3 = _v2.b;
			var vmax1 = _v3.a.a;
			var _v4 = _v3.b;
			var cc2 = _v4.a.a;
			var _v5 = _v4.b;
			var vmin2 = _v5.a.a;
			var _v6 = _v5.b;
			var vmax2 = _v6.a.a;
			return $elm$core$Maybe$Just(
				$author$project$Controller$XYFader(
					{
						b5: cc1,
						b6: cc2,
						bH: ch1,
						bI: ch2,
						i: state.i,
						d: state.d,
						n: $elm$core$Maybe$Just(state.n),
						c: $author$project$Controller$Set,
						cG: vmax1,
						cH: vmax2,
						cJ: vmin1,
						cK: vmin2,
						bi: 50,
						bj: 50
					}));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$editXYFaderPane = function (state) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignTop,
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10),
				$author$project$Main$editPanelWidth,
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Style$editTextBox,
				{g: state.d, d: 'Label', e: 'label'},
				'text',
				function (newLabel) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditXYFader(
							_Utils_update(
								state,
								{d: newLabel})));
				}),
				A2(
				$author$project$Style$labelSizeRadio,
				state.n,
				function (newLabelSize) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditXYFader(
							_Utils_update(
								state,
								{n: newLabelSize})));
				}),
				A2(
				$author$project$Style$colourRadio,
				state.i,
				function (newColour) {
					return $author$project$Main$UpdateControllerState(
						$author$project$EditableController$EditXYFader(
							_Utils_update(
								state,
								{i: newColour})));
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$radio,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10)
					]),
				{
					d: $mdgriffith$elm_ui$Element$Input$labelHidden('Param Select'),
					ae: function (newActive) {
						return $author$project$Main$UpdateControllerState(
							$author$project$EditableController$EditXYFader(
								_Utils_update(
									state,
									{cO: newActive})));
					},
					aT: _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$Input$option,
							0,
							$mdgriffith$elm_ui$Element$text('Params X')),
							A2(
							$mdgriffith$elm_ui$Element$Input$option,
							1,
							$mdgriffith$elm_ui$Element$text('Params Y'))
						]),
					aX: $elm$core$Maybe$Just(state.cO)
				}),
				function () {
				var _v0 = state.cO;
				if (!_v0) {
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$dashed
							]),
						_List_fromArray(
							[
								A3(
								$author$project$Style$editTextBox,
								{g: state.bH, d: 'Channel X', e: 'channel x#'},
								'number',
								function (newChannel) {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditXYFader(
											_Utils_update(
												state,
												{bH: newChannel})));
								}),
								A3(
								$author$project$Style$editTextBox,
								{g: state.b5, d: 'CC X Number', e: 'cc x #'},
								'number',
								function (newCCNumber) {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditXYFader(
											_Utils_update(
												state,
												{b5: newCCNumber})));
								}),
								A3(
								$author$project$Style$editTextBox,
								{g: state.cJ, d: 'X Min Value', e: 'x min value'},
								'number',
								function (newMinValue) {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditXYFader(
											_Utils_update(
												state,
												{cJ: newMinValue})));
								}),
								A3(
								$author$project$Style$editTextBox,
								{g: state.cG, d: 'X Max Value', e: 'x max value'},
								'number',
								function (newMaxValue) {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditXYFader(
											_Utils_update(
												state,
												{cG: newMaxValue})));
								})
							]));
				} else {
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$dashed
							]),
						_List_fromArray(
							[
								A3(
								$author$project$Style$editTextBox,
								{g: state.bI, d: 'Channel Y', e: 'channel y#'},
								'number',
								function (newChannel) {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditXYFader(
											_Utils_update(
												state,
												{bI: newChannel})));
								}),
								A3(
								$author$project$Style$editTextBox,
								{g: state.b6, d: 'CC Y Number', e: 'cc y #'},
								'number',
								function (newCCNumber) {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditXYFader(
											_Utils_update(
												state,
												{b6: newCCNumber})));
								}),
								A3(
								$author$project$Style$editTextBox,
								{g: state.cK, d: 'Y Min Value', e: 'y min value'},
								'number',
								function (newMinValue) {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditXYFader(
											_Utils_update(
												state,
												{cK: newMinValue})));
								}),
								A3(
								$author$project$Style$editTextBox,
								{g: state.cH, d: 'Y Max Value', e: 'y max value'},
								'number',
								function (newMaxValue) {
									return $author$project$Main$UpdateControllerState(
										$author$project$EditableController$EditXYFader(
											_Utils_update(
												state,
												{cH: newMaxValue})));
								})
							]));
				}
			}(),
				A3(
				$author$project$Style$acceptOrCloseButtons,
				'Ok',
				$author$project$Main$ClosePopUp,
				A2(
					$elm$core$Maybe$map,
					function (c) {
						return $author$project$Main$FinishedEdit(c);
					},
					$author$project$EditableController$editStateToXYFader(state)))
			]));
};
var $author$project$Main$SetEditType = function (a) {
	return {$: 26, a: a};
};
var $author$project$EditableController$editableControllerToString = function (eController) {
	switch (eController.$) {
		case 0:
			return 'Module';
		case 1:
			return 'Isomorphic Keyboard';
		case 2:
			return 'Scale';
		case 3:
			return 'Chord Keyboard';
		case 4:
			return 'Column';
		case 5:
			return 'Row';
		case 6:
			return 'Column';
		case 7:
			return 'Row';
		case 8:
			return 'Note';
		case 9:
			return 'Chord';
		case 10:
			return 'CC Value';
		case 11:
			return 'Command';
		case 12:
			return 'Sequence';
		case 13:
			return 'Fader';
		case 14:
			return 'XY Fader';
		case 15:
			return 'Pitch Bend';
		case 16:
			return 'Midi Log';
		default:
			return 'Space';
	}
};
var $author$project$Main$selectorOption = F2(
	function (current, _new) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				$author$project$Style$fillSpace,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(5),
							$mdgriffith$elm_ui$Element$Events$onClick(
							$author$project$Main$SetEditType(_new))
						]),
					function () {
						var _v0 = _Utils_Tuple2(current, _new);
						_v0$16:
						while (true) {
							switch (_v0.a.$) {
								case 0:
									if (!_v0.b.$) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 1:
									if (_v0.b.$ === 1) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 2:
									if (_v0.b.$ === 2) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 3:
									if (_v0.b.$ === 3) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 6:
									if (_v0.b.$ === 6) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 7:
									if (_v0.b.$ === 7) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 8:
									if (_v0.b.$ === 8) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 9:
									if (_v0.b.$ === 9) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 10:
									if (_v0.b.$ === 10) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 11:
									if (_v0.b.$ === 11) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 12:
									if (_v0.b.$ === 12) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 13:
									if (_v0.b.$ === 13) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 14:
									if (_v0.b.$ === 14) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 15:
									if (_v0.b.$ === 15) {
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 16:
									if (_v0.b.$ === 16) {
										var _v1 = _v0.a;
										var _v2 = _v0.b;
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								case 17:
									if (_v0.b.$ === 17) {
										var _v3 = _v0.a;
										var _v4 = _v0.b;
										return _List_fromArray(
											[
												$author$project$Style$backgroundColour(5)
											]);
									} else {
										break _v0$16;
									}
								default:
									break _v0$16;
							}
						}
						return _List_Nil;
					}())),
			$mdgriffith$elm_ui$Element$text(
				$author$project$EditableController$editableControllerToString(_new)));
	});
var $mdgriffith$elm_ui$Internal$Model$Padding = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Model$Spaced = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, _v0) {
				var pad = _v0.a;
				var spacing = _v0.b;
				return _Utils_Tuple2(
					function () {
						if (!pad.$) {
							var x = pad.a;
							return pad;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 7)) {
								var _v3 = attr.b;
								var name = _v3.a;
								var t = _v3.b;
								var r = _v3.c;
								var b = _v3.d;
								var l = _v3.e;
								return $elm$core$Maybe$Just(
									A5($mdgriffith$elm_ui$Internal$Model$Padding, name, t, r, b, l));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}(),
					function () {
						if (!spacing.$) {
							var x = spacing.a;
							return spacing;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v6 = attr.b;
								var name = _v6.a;
								var x = _v6.b;
								var y = _v6.c;
								return $elm$core$Maybe$Just(
									A3($mdgriffith$elm_ui$Internal$Model$Spaced, name, x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}());
			}),
		_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
		attrs);
};
var $mdgriffith$elm_ui$Element$wrappedRow = F2(
	function (attrs, children) {
		var _v0 = $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding(attrs);
		var padded = _v0.a;
		var spaced = _v0.b;
		if (spaced.$ === 1) {
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asRow,
				$mdgriffith$elm_ui$Internal$Model$div,
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.bJ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.aI + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dt)))),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							attrs))),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
		} else {
			var _v2 = spaced.a;
			var spaceName = _v2.a;
			var x = _v2.b;
			var y = _v2.c;
			var newPadding = function () {
				if (!padded.$) {
					var _v5 = padded.a;
					var name = _v5.a;
					var t = _v5.b;
					var r = _v5.c;
					var b = _v5.d;
					var l = _v5.e;
					if ((_Utils_cmp(r, x / 2) > -1) && (_Utils_cmp(b, y / 2) > -1)) {
						var newTop = t - (y / 2);
						var newRight = r - (x / 2);
						var newLeft = l - (x / 2);
						var newBottom = b - (y / 2);
						return $elm$core$Maybe$Just(
							A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, newRight, newBottom, newLeft),
									newTop,
									newRight,
									newBottom,
									newLeft)));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}();
			if (!newPadding.$) {
				var pad = newPadding.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asRow,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.bJ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.aI + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dt)))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								_Utils_ap(
									attrs,
									_List_fromArray(
										[pad]))))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
			} else {
				var halfY = -(y / 2);
				var halfX = -(x / 2);
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					attrs,
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asRow,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.bJ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.aI + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dt)))),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											A2(
												$elm$html$Html$Attributes$style,
												'margin',
												$elm$core$String$fromFloat(halfY) + ('px' + (' ' + ($elm$core$String$fromFloat(halfX) + 'px'))))),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Internal$Model$Attr(
												A2(
													$elm$html$Html$Attributes$style,
													'width',
													'calc(100% + ' + ($elm$core$String$fromInt(x) + 'px)'))),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$Attr(
													A2(
														$elm$html$Html$Attributes$style,
														'height',
														'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))),
												A2(
													$elm$core$List$cons,
													A2(
														$mdgriffith$elm_ui$Internal$Model$StyleClass,
														$mdgriffith$elm_ui$Internal$Flag$spacing,
														A3($mdgriffith$elm_ui$Internal$Model$SpacingStyle, spaceName, x, y)),
													_List_Nil))))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(children))
							])));
			}
		}
	});
var $author$project$Main$editMenu = F3(
	function (oldController, savedModules, menuType) {
		return A2(
			$mdgriffith$elm_ui$Element$wrappedRow,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(4),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$padding(10),
							$mdgriffith$elm_ui$Element$spacing(10),
							$author$project$Main$editPanelWidth,
							$author$project$Style$backgroundColour(0),
							$mdgriffith$elm_ui$Element$Border$width(4)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditModule(
										{ar: $author$project$Controller$Space, fC: 0, d: '', gD: oldController, g0: $elm$core$Maybe$Nothing})),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditIsomorphic($author$project$EditableController$defaultEditIsomorphicState)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditScale($author$project$EditableController$defaultEditScaleState)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditChordBuilder($author$project$EditableController$defaultEditChordBuilderState)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditSizedColumn(_List_Nil)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditSizedRow(_List_Nil)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditNote($author$project$EditableController$defaultEditNoteState)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditChord($author$project$EditableController$defaultEditChordState)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditCCValue($author$project$EditableController$defaultEditCCValueState)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditCommand($author$project$EditableController$defaultEditCommandState)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditSequence($author$project$EditableController$defaultEditSequenceState)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditFader($author$project$EditableController$defaultEditFaderState)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditXYFader($author$project$EditableController$defaultEditXYFaderState)),
									A2(
									$author$project$Main$selectorOption,
									menuType,
									$author$project$EditableController$EditPitchBend($author$project$EditableController$defaultEditPitchBendState)),
									A2($author$project$Main$selectorOption, menuType, $author$project$EditableController$EditMidiLog),
									A2($author$project$Main$selectorOption, menuType, $author$project$EditableController$EditSpace)
								])),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$alignTop,
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$padding(10),
									$mdgriffith$elm_ui$Element$Border$width(2),
									$mdgriffith$elm_ui$Element$Border$dashed,
									$mdgriffith$elm_ui$Element$Font$size(14),
									$mdgriffith$elm_ui$Element$Font$alignLeft
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(
									$author$project$EditableController$description(menuType))
								]))
						])),
					function () {
					switch (menuType.$) {
						case 0:
							var state = menuType.a;
							return A2($author$project$Main$editModulePane, savedModules, state);
						case 1:
							var state = menuType.a;
							return $author$project$Main$editIsomorphicPane(state);
						case 2:
							var state = menuType.a;
							return $author$project$Main$editScalePane(state);
						case 3:
							var state = menuType.a;
							return $author$project$Main$editChordBuilderPane(state);
						case 5:
							var subControls = menuType.a;
							return $author$project$Main$editRowPane(subControls);
						case 4:
							var subControls = menuType.a;
							return $author$project$Main$editColumnPane(subControls);
						case 7:
							var subControls = menuType.a;
							return $author$project$Main$editSizedRowPane(subControls);
						case 6:
							var subControls = menuType.a;
							return $author$project$Main$editSizedColumnPane(subControls);
						case 8:
							var state = menuType.a;
							return $author$project$Main$editNotePane(state);
						case 9:
							var state = menuType.a;
							return $author$project$Main$editChordPane(state);
						case 10:
							var state = menuType.a;
							return $author$project$Main$editCCValuePane(state);
						case 11:
							var state = menuType.a;
							return $author$project$Main$editCommandPane(state);
						case 12:
							var state = menuType.a;
							return $author$project$Main$editSequencePane(state);
						case 13:
							var state = menuType.a;
							return $author$project$Main$editFaderPane(state);
						case 14:
							var state = menuType.a;
							return $author$project$Main$editXYFaderPane(state);
						case 15:
							var state = menuType.a;
							return $author$project$Main$editPitchBendPane(state);
						case 16:
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignTop,
										$mdgriffith$elm_ui$Element$padding(10),
										$author$project$Style$backgroundColour(0),
										$mdgriffith$elm_ui$Element$Border$width(4)
									]),
								A3(
									$author$project$Style$acceptOrCloseButtons,
									'Ok',
									$author$project$Main$ClosePopUp,
									$elm$core$Maybe$Just(
										$author$project$Main$FinishedEdit($author$project$Controller$MidiLog))));
						default:
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignTop,
										$mdgriffith$elm_ui$Element$padding(10),
										$author$project$Style$backgroundColour(0),
										$mdgriffith$elm_ui$Element$Border$width(4)
									]),
								A3(
									$author$project$Style$acceptOrCloseButtons,
									'Ok',
									$author$project$Main$ClosePopUp,
									$elm$core$Maybe$Just(
										$author$project$Main$FinishedEdit($author$project$Controller$Space))));
					}
				}()
				]));
	});
var $author$project$Main$DeletePage = function (a) {
	return {$: 14, a: a};
};
var $author$project$Main$UpdatePage = F2(
	function (a, b) {
		return {$: 22, a: a, b: b};
	});
var $author$project$Main$UpdatePageMenuState = function (a) {
	return {$: 16, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$fontWeight = $mdgriffith$elm_ui$Internal$Flag$flag(13);
var $mdgriffith$elm_ui$Element$Font$bold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.fi);
var $author$project$Main$editPageMenu = F2(
	function (index, state) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$spacing(10),
						$author$project$Style$backgroundColour(0),
						$mdgriffith$elm_ui$Element$Border$width(4)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$bold]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Edit Page')
							])),
						A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(5),
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$solid,
								$author$project$Style$backgroundColour(7),
								$author$project$Style$fontColour(0),
								$author$project$Style$borderColour(3)
							]),
						{
							d: $mdgriffith$elm_ui$Element$text('Delete'),
							af: $elm$core$Maybe$Just(
								$author$project$Main$DeletePage(index))
						}),
						A2(
						$mdgriffith$elm_ui$Element$Input$text,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Border$width(2),
								$mdgriffith$elm_ui$Element$Border$rounded(0),
								$author$project$Style$borderColour(3)
							]),
						{
							d: A2(
								$mdgriffith$elm_ui$Element$Input$labelAbove,
								_List_Nil,
								$mdgriffith$elm_ui$Element$text('Page Label')),
							ae: function (newLabel) {
								return $author$project$Main$UpdatePageMenuState(
									_Utils_update(
										state,
										{d: newLabel}));
							},
							e: $elm$core$Maybe$Just(
								A2(
									$mdgriffith$elm_ui$Element$Input$placeholder,
									_List_Nil,
									$mdgriffith$elm_ui$Element$text('page label'))),
							dn: state.d
						}),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(2)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$Input$button,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$padding(5),
										$mdgriffith$elm_ui$Element$Border$width(2),
										$mdgriffith$elm_ui$Element$Border$solid,
										$author$project$Style$borderColour(3)
									]),
								{
									d: $mdgriffith$elm_ui$Element$text('Update'),
									af: $elm$core$Maybe$Just(
										A2($author$project$Main$UpdatePage, index, state))
								}),
								A2(
								$mdgriffith$elm_ui$Element$Input$button,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$padding(5),
										$mdgriffith$elm_ui$Element$Border$width(2),
										$mdgriffith$elm_ui$Element$Border$solid,
										$author$project$Style$borderColour(3)
									]),
								{
									d: $mdgriffith$elm_ui$Element$text('Cancel'),
									af: $elm$core$Maybe$Just($author$project$Main$ClosePopUp)
								})
							]))
					])));
	});
var $author$project$Main$AddPage = function (a) {
	return {$: 21, a: a};
};
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$map2 = F3(
	function (func, _v0, _v1) {
		var decodeA = _v0;
		var decodeB = _v1;
		return F2(
			function (bites, offset) {
				var _v2 = A2(decodeA, bites, offset);
				var aOffset = _v2.a;
				var a = _v2.b;
				var _v3 = A2(decodeB, bites, aOffset);
				var bOffset = _v3.a;
				var b = _v3.b;
				return _Utils_Tuple2(
					bOffset,
					A2(func, a, b));
			});
	});
var $folkertdev$elm_flate$Inflate$GZip$andMap = F2(
	function (argument, _function) {
		return A3($elm$bytes$Bytes$Decode$map2, $elm$core$Basics$apL, _function, argument);
	});
var $elm$bytes$Bytes$Decode$andThen = F2(
	function (callback, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var newOffset = _v1.a;
				var a = _v1.b;
				var _v2 = callback(a);
				var decodeB = _v2;
				return A2(decodeB, bites, newOffset);
			});
	});
var $elm$bytes$Bytes$Decode$bytes = function (n) {
	return _Bytes_read_bytes(n);
};
var $elm$bytes$Bytes$BE = 1;
var $elm$bytes$Bytes$Decode$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$bytes$Bytes$Decode$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$bytes$Bytes$Decode$map = F2(
	function (func, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var aOffset = _v1.a;
				var a = _v1.b;
				return _Utils_Tuple2(
					aOffset,
					func(a));
			});
	});
var $folkertdev$elm_flate$Checksum$Crc32$tinf_crc32case = function (i) {
	switch (i) {
		case 0:
			return 0;
		case 1:
			return 498536548;
		case 2:
			return 997073096;
		case 3:
			return 651767980;
		case 4:
			return 1994146192;
		case 5:
			return 1802195444;
		case 6:
			return 1303535960;
		case 7:
			return 1342533948;
		case 8:
			return 3988292384;
		case 9:
			return 4027552580;
		case 10:
			return 3604390888;
		case 11:
			return 3412177804;
		case 12:
			return 2607071920;
		case 13:
			return 2262029012;
		case 14:
			return 2685067896;
		default:
			return 3183342108;
	}
};
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $folkertdev$elm_flate$Checksum$Crc32$step = F2(
	function (_byte, crc) {
		var a = (crc ^ _byte) >>> 0;
		var b = ((a >>> 4) ^ $folkertdev$elm_flate$Checksum$Crc32$tinf_crc32case(a & 15)) >>> 0;
		var c = (b >>> 4) ^ $folkertdev$elm_flate$Checksum$Crc32$tinf_crc32case(b & 15);
		return c;
	});
var $elm$bytes$Bytes$Decode$succeed = function (a) {
	return F2(
		function (_v0, offset) {
			return _Utils_Tuple2(offset, a);
		});
};
var $elm$bytes$Bytes$Decode$unsignedInt32 = function (endianness) {
	return _Bytes_read_u32(!endianness);
};
var $elm$bytes$Bytes$Decode$unsignedInt8 = _Bytes_read_u8;
var $folkertdev$elm_flate$Checksum$Crc32$crc32Help = function (_v0) {
	var remaining = _v0.ct;
	var crc = _v0.cf;
	return (remaining >= 8) ? A3(
		$elm$bytes$Bytes$Decode$map2,
		F2(
			function (word1, word2) {
				var byte8 = 255 & word2;
				var byte7 = 255 & (word2 >>> 8);
				var byte6 = 255 & (word2 >>> 16);
				var byte5 = 255 & (word2 >>> 24);
				var byte4 = 255 & word1;
				var byte3 = 255 & (word1 >>> 8);
				var byte2 = 255 & (word1 >>> 16);
				var byte1 = 255 & (word1 >>> 24);
				return $elm$bytes$Bytes$Decode$Loop(
					{
						cf: A2(
							$folkertdev$elm_flate$Checksum$Crc32$step,
							byte8,
							A2(
								$folkertdev$elm_flate$Checksum$Crc32$step,
								byte7,
								A2(
									$folkertdev$elm_flate$Checksum$Crc32$step,
									byte6,
									A2(
										$folkertdev$elm_flate$Checksum$Crc32$step,
										byte5,
										A2(
											$folkertdev$elm_flate$Checksum$Crc32$step,
											byte4,
											A2(
												$folkertdev$elm_flate$Checksum$Crc32$step,
												byte3,
												A2(
													$folkertdev$elm_flate$Checksum$Crc32$step,
													byte2,
													A2($folkertdev$elm_flate$Checksum$Crc32$step, byte1, crc)))))))),
						ct: remaining - 8
					});
			}),
		$elm$bytes$Bytes$Decode$unsignedInt32(1),
		$elm$bytes$Bytes$Decode$unsignedInt32(1)) : ((remaining > 0) ? A2(
		$elm$bytes$Bytes$Decode$map,
		function (_byte) {
			return $elm$bytes$Bytes$Decode$Loop(
				{
					cf: A2($folkertdev$elm_flate$Checksum$Crc32$step, _byte, crc),
					ct: remaining - 1
				});
		},
		$elm$bytes$Bytes$Decode$unsignedInt8) : $elm$bytes$Bytes$Decode$succeed(
		$elm$bytes$Bytes$Decode$Done((crc ^ 4294967295) >>> 0)));
};
var $elm$bytes$Bytes$Decode$loopHelp = F4(
	function (state, callback, bites, offset) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var decoder = _v0;
			var _v1 = A2(decoder, bites, offset);
			var newOffset = _v1.a;
			var step = _v1.b;
			if (!step.$) {
				var newState = step.a;
				var $temp$state = newState,
					$temp$callback = callback,
					$temp$bites = bites,
					$temp$offset = newOffset;
				state = $temp$state;
				callback = $temp$callback;
				bites = $temp$bites;
				offset = $temp$offset;
				continue loopHelp;
			} else {
				var result = step.a;
				return _Utils_Tuple2(newOffset, result);
			}
		}
	});
var $elm$bytes$Bytes$Decode$loop = F2(
	function (state, callback) {
		return A2($elm$bytes$Bytes$Decode$loopHelp, state, callback);
	});
var $elm$bytes$Bytes$width = _Bytes_width;
var $folkertdev$elm_flate$Checksum$Crc32$tinf_crc32 = function (buffer) {
	var length = $elm$bytes$Bytes$width(buffer);
	var initialCrc = 4294967295;
	return (!length) ? 0 : A2(
		$elm$core$Maybe$withDefault,
		0,
		A2(
			$elm$bytes$Bytes$Decode$decode,
			A2(
				$elm$bytes$Bytes$Decode$loop,
				{cf: initialCrc, ct: length},
				$folkertdev$elm_flate$Checksum$Crc32$crc32Help),
			buffer));
};
var $folkertdev$elm_flate$Checksum$Crc32$crc32 = $folkertdev$elm_flate$Checksum$Crc32$tinf_crc32;
var $elm$bytes$Bytes$Decode$fail = _Bytes_decodeFailure;
var $folkertdev$elm_flate$Inflate$GZip$flags = {dH: 16, cf: 2, fU: 4, ed: 8, dn: 1};
var $folkertdev$elm_flate$Inflate$GZip$skipUntilZero = function () {
	var go = function (n) {
		return A2(
			$elm$bytes$Bytes$Decode$map,
			function (_byte) {
				return (!_byte) ? $elm$bytes$Bytes$Decode$Done(n + 1) : $elm$bytes$Bytes$Decode$Loop(n + 1);
			},
			$elm$bytes$Bytes$Decode$unsignedInt8);
	};
	return A2($elm$bytes$Bytes$Decode$loop, 0, go);
}();
var $elm$bytes$Bytes$Decode$unsignedInt16 = function (endianness) {
	return _Bytes_read_u16(!endianness);
};
var $folkertdev$elm_flate$Inflate$GZip$gzipFindBuffer = function (sliced) {
	if ((sliced.d_ !== 31) || (sliced.d$ !== 139)) {
		return $elm$core$Maybe$Nothing;
	} else {
		if (sliced.d9 !== 8) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!(!(sliced.c_ & 224))) {
				return $elm$core$Maybe$Nothing;
			} else {
				var headerSize = 10;
				var flagSet = function (flag) {
					return !(!(sliced.c_ & flag));
				};
				var skipExtra = flagSet($folkertdev$elm_flate$Inflate$GZip$flags.fU) ? A2(
					$elm$bytes$Bytes$Decode$andThen,
					function (extraSize) {
						return A2(
							$elm$bytes$Bytes$Decode$map,
							function (_v1) {
								return extraSize + 2;
							},
							$elm$bytes$Bytes$Decode$bytes(extraSize));
					},
					$elm$bytes$Bytes$Decode$unsignedInt16(0)) : $elm$bytes$Bytes$Decode$succeed(0);
				var skipFileComment = flagSet($folkertdev$elm_flate$Inflate$GZip$flags.dH) ? $folkertdev$elm_flate$Inflate$GZip$skipUntilZero : $elm$bytes$Bytes$Decode$succeed(0);
				var skipFileName = flagSet($folkertdev$elm_flate$Inflate$GZip$flags.ed) ? $folkertdev$elm_flate$Inflate$GZip$skipUntilZero : $elm$bytes$Bytes$Decode$succeed(0);
				var skipAll = A2(
					$folkertdev$elm_flate$Inflate$GZip$andMap,
					skipFileComment,
					A2(
						$folkertdev$elm_flate$Inflate$GZip$andMap,
						skipFileName,
						A2(
							$folkertdev$elm_flate$Inflate$GZip$andMap,
							skipExtra,
							$elm$bytes$Bytes$Decode$succeed(
								F3(
									function (a, b, c) {
										return (a + b) + c;
									})))));
				var checkHeaderCrc = function (bytesRead) {
					return flagSet($folkertdev$elm_flate$Inflate$GZip$flags.cf) ? A2(
						$elm$bytes$Bytes$Decode$andThen,
						function (checksum) {
							var _v0 = A2(
								$elm$bytes$Bytes$Decode$decode,
								$elm$bytes$Bytes$Decode$bytes(bytesRead),
								sliced.em);
							if (!_v0.$) {
								var header = _v0.a;
								return (!_Utils_eq(
									checksum,
									$folkertdev$elm_flate$Checksum$Crc32$crc32(header) & 65535)) ? $elm$bytes$Bytes$Decode$fail : $elm$bytes$Bytes$Decode$succeed(2);
							} else {
								return $elm$bytes$Bytes$Decode$fail;
							}
						},
						$elm$bytes$Bytes$Decode$unsignedInt16(0)) : $elm$bytes$Bytes$Decode$succeed(0);
				};
				var decoder = A2(
					$elm$bytes$Bytes$Decode$andThen,
					function (skipped0) {
						return A2(
							$elm$bytes$Bytes$Decode$andThen,
							function (skipped1) {
								var skipped = skipped0 + skipped1;
								return $elm$bytes$Bytes$Decode$bytes(
									$elm$bytes$Bytes$width(sliced.K) - skipped);
							},
							checkHeaderCrc(skipped0 + headerSize));
					},
					skipAll);
				return A2($elm$bytes$Bytes$Decode$decode, decoder, sliced.K);
			}
		}
	}
};
var $folkertdev$elm_flate$Inflate$GZip$GzipSlice = F9(
	function (orig, id1, id2, method, flg, restOfHeader, buffer, crc32, decompressedLength) {
		return {K: buffer, fB: crc32, fJ: decompressedLength, c_: flg, d_: id1, d$: id2, d9: method, em: orig, gS: restOfHeader};
	});
var $folkertdev$elm_flate$Inflate$GZip$gzipSlice = function (buffer) {
	var decoder = A2(
		$folkertdev$elm_flate$Inflate$GZip$andMap,
		$elm$bytes$Bytes$Decode$unsignedInt32(0),
		A2(
			$folkertdev$elm_flate$Inflate$GZip$andMap,
			$elm$bytes$Bytes$Decode$unsignedInt32(0),
			A2(
				$folkertdev$elm_flate$Inflate$GZip$andMap,
				$elm$bytes$Bytes$Decode$bytes(
					(($elm$bytes$Bytes$width(buffer) - 10) - 4) - 4),
				A2(
					$folkertdev$elm_flate$Inflate$GZip$andMap,
					$elm$bytes$Bytes$Decode$bytes(6),
					A2(
						$folkertdev$elm_flate$Inflate$GZip$andMap,
						$elm$bytes$Bytes$Decode$unsignedInt8,
						A2(
							$folkertdev$elm_flate$Inflate$GZip$andMap,
							$elm$bytes$Bytes$Decode$unsignedInt8,
							A2(
								$folkertdev$elm_flate$Inflate$GZip$andMap,
								$elm$bytes$Bytes$Decode$unsignedInt8,
								A2(
									$folkertdev$elm_flate$Inflate$GZip$andMap,
									$elm$bytes$Bytes$Decode$unsignedInt8,
									$elm$bytes$Bytes$Decode$succeed(
										$folkertdev$elm_flate$Inflate$GZip$GzipSlice(buffer))))))))));
	return A2($elm$bytes$Bytes$Decode$decode, decoder, buffer);
};
var $elm$bytes$Bytes$Encode$Bytes = function (a) {
	return {$: 10, a: a};
};
var $elm$bytes$Bytes$Encode$bytes = $elm$bytes$Bytes$Encode$Bytes;
var $folkertdev$elm_flate$Inflate$BitReader$decode = F2(
	function (bytes, _v0) {
		var reader = _v0;
		var initialState = {v: 0, K: bytes, H: 0, z: 0, dm: 0};
		var _v1 = reader(initialState);
		if (!_v1.$) {
			var _v2 = _v1.a;
			var value = _v2.a;
			return $elm$core$Result$Ok(value);
		} else {
			var e = _v1.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$bytes$Bytes$Encode$encode = _Bytes_encode;
var $elm$bytes$Bytes$Encode$Seq = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$getWidths = F2(
	function (width, builders) {
		getWidths:
		while (true) {
			if (!builders.b) {
				return width;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$width = width + $elm$bytes$Bytes$Encode$getWidth(b),
					$temp$builders = bs;
				width = $temp$width;
				builders = $temp$builders;
				continue getWidths;
			}
		}
	});
var $elm$bytes$Bytes$Encode$sequence = function (builders) {
	return A2(
		$elm$bytes$Bytes$Encode$Seq,
		A2($elm$bytes$Bytes$Encode$getWidths, 0, builders),
		builders);
};
var $folkertdev$elm_flate$Experimental$ByteArray$ByteArray = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $folkertdev$elm_flate$Experimental$ByteArray$empty = A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, $elm$core$Array$empty, 0, 0);
var $folkertdev$elm_flate$Inflate$BitReader$BitReader = $elm$core$Basics$identity;
var $folkertdev$elm_flate$Inflate$BitReader$loopHelp = F3(
	function (accum, callback, state) {
		loopHelp:
		while (true) {
			var _v0 = callback(accum);
			var decoder = _v0;
			var _v1 = decoder(state);
			if (_v1.$ === 1) {
				var e = _v1.a;
				return $elm$core$Result$Err(e);
			} else {
				if (!_v1.a.a.$) {
					var _v2 = _v1.a;
					var newAccum = _v2.a.a;
					var newState = _v2.b;
					var $temp$accum = newAccum,
						$temp$callback = callback,
						$temp$state = newState;
					accum = $temp$accum;
					callback = $temp$callback;
					state = $temp$state;
					continue loopHelp;
				} else {
					var _v3 = _v1.a;
					var result = _v3.a.a;
					var newState = _v3.b;
					return $elm$core$Result$Ok(
						_Utils_Tuple2(result, newState));
				}
			}
		}
	});
var $folkertdev$elm_flate$Inflate$BitReader$loop = F2(
	function (state, callback) {
		return A2($folkertdev$elm_flate$Inflate$BitReader$loopHelp, state, callback);
	});
var $folkertdev$elm_flate$Inflate$BitReader$map = F2(
	function (f, _v0) {
		var g = _v0;
		return function (s) {
			var _v1 = g(s);
			if (!_v1.$) {
				var _v2 = _v1.a;
				var value = _v2.a;
				var newState = _v2.b;
				return $elm$core$Result$Ok(
					_Utils_Tuple2(
						f(value),
						newState));
			} else {
				var e = _v1.a;
				return $elm$core$Result$Err(e);
			}
		};
	});
var $elm$bytes$Bytes$Encode$U16 = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$unsignedInt16 = $elm$bytes$Bytes$Encode$U16;
var $elm$bytes$Bytes$Encode$U32 = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$unsignedInt32 = $elm$bytes$Bytes$Encode$U32;
var $elm$bytes$Bytes$Encode$U8 = function (a) {
	return {$: 3, a: a};
};
var $elm$bytes$Bytes$Encode$unsignedInt8 = $elm$bytes$Bytes$Encode$U8;
var $folkertdev$elm_flate$Experimental$ByteArray$toBytes = function (_v0) {
	var array = _v0.a;
	var finalSize = _v0.b;
	var finalBytes = _v0.c;
	var initial = function () {
		var finalInt32 = finalBytes >>> ((4 - finalSize) * 8);
		switch (finalSize) {
			case 4:
				return _List_fromArray(
					[
						A2($elm$bytes$Bytes$Encode$unsignedInt32, 1, finalBytes)
					]);
			case 1:
				return _List_fromArray(
					[
						$elm$bytes$Bytes$Encode$unsignedInt8(finalInt32)
					]);
			case 2:
				return _List_fromArray(
					[
						A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, finalInt32)
					]);
			case 3:
				return _List_fromArray(
					[
						A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, finalInt32 >> 8),
						$elm$bytes$Bytes$Encode$unsignedInt8(255 & finalInt32)
					]);
			default:
				return _List_Nil;
		}
	}();
	var folder = F2(
		function (element, accum) {
			return A2(
				$elm$core$List$cons,
				A2($elm$bytes$Bytes$Encode$unsignedInt32, 1, element),
				accum);
		});
	return $elm$bytes$Bytes$Encode$encode(
		$elm$bytes$Bytes$Encode$sequence(
			A3($elm$core$Array$foldr, folder, initial, array)));
};
var $folkertdev$elm_flate$Inflate$BitReader$andThen = F2(
	function (f, _v0) {
		var g = _v0;
		return function (s) {
			var _v1 = g(s);
			if (!_v1.$) {
				var _v2 = _v1.a;
				var value = _v2.a;
				var newState = _v2.b;
				var _v3 = f(value);
				var h = _v3;
				return h(newState);
			} else {
				var e = _v1.a;
				return $elm$core$Result$Err(e);
			}
		};
	});
var $folkertdev$elm_flate$Experimental$ByteArray$push = F2(
	function (value, input) {
		var array = input.a;
		var finalSize = input.b;
		var finalBytes = input.c;
		if (finalSize === 4) {
			return A3(
				$folkertdev$elm_flate$Experimental$ByteArray$ByteArray,
				A2($elm$core$Array$push, finalBytes, array),
				1,
				value << 24);
		} else {
			if (!finalSize) {
				return A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, 1, value << 24);
			} else {
				var offset = finalSize;
				var internalIndex = $elm$core$Array$length(array) - 1;
				var _new = ((255 & value) << ((3 - offset) * 8)) | finalBytes;
				var mask = 4278190080 >>> (offset * 8);
				return A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, finalSize + 1, _new);
			}
		}
	});
var $folkertdev$elm_flate$Experimental$ByteArray$pushMany = F3(
	function (nbytes, value_, input) {
		var array = input.a;
		var finalSize = input.b;
		var finalBytes = input.c;
		var value = (nbytes === 4) ? value_ : (((1 << (nbytes * 8)) - 1) & value_);
		if (!nbytes) {
			return input;
		} else {
			if (finalSize === 4) {
				return A3(
					$folkertdev$elm_flate$Experimental$ByteArray$ByteArray,
					A2($elm$core$Array$push, finalBytes, array),
					nbytes,
					value << ((4 - nbytes) * 8));
			} else {
				if (!finalSize) {
					return A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, nbytes, value << ((4 - nbytes) * 8));
				} else {
					var freeSpace = 4 - finalSize;
					if (_Utils_cmp(nbytes, freeSpace) > 0) {
						var bytesLeftOver = (finalSize + nbytes) - 4;
						var forFinal = value >>> (bytesLeftOver * 8);
						var newFinal = finalBytes | forFinal;
						var amount = ((8 - finalSize) - nbytes) * 8;
						var forNextFinal = (((1 << (bytesLeftOver * 8)) - 1) & value) << amount;
						return A3(
							$folkertdev$elm_flate$Experimental$ByteArray$ByteArray,
							A2($elm$core$Array$push, newFinal, array),
							nbytes - freeSpace,
							forNextFinal);
					} else {
						var amount = (4 - (finalSize + nbytes)) * 8;
						var forFinal = value << amount;
						var newFinal = finalBytes | forFinal;
						return A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, finalSize + nbytes, newFinal);
					}
				}
			}
		}
	});
var $folkertdev$elm_flate$Experimental$ByteArray$appendBytesHelp = function (_v0) {
	var remaining = _v0.a;
	var bytearray = _v0.b;
	var array = bytearray.a;
	var finalSize = bytearray.b;
	var finalBytes = bytearray.c;
	return (remaining >= 4) ? A2(
		$elm$bytes$Bytes$Decode$map,
		function (_new) {
			return $elm$bytes$Bytes$Decode$Loop(
				_Utils_Tuple2(
					remaining - 4,
					A3($folkertdev$elm_flate$Experimental$ByteArray$pushMany, 4, _new, bytearray)));
		},
		$elm$bytes$Bytes$Decode$unsignedInt32(1)) : ((remaining >= 1) ? A2(
		$elm$bytes$Bytes$Decode$map,
		function (_new) {
			return $elm$bytes$Bytes$Decode$Loop(
				_Utils_Tuple2(
					remaining - 1,
					A2($folkertdev$elm_flate$Experimental$ByteArray$push, _new, bytearray)));
		},
		$elm$bytes$Bytes$Decode$unsignedInt8) : $elm$bytes$Bytes$Decode$succeed(
		$elm$bytes$Bytes$Decode$Done(bytearray)));
};
var $folkertdev$elm_flate$Experimental$ByteArray$appendBytes = F2(
	function (bytes, barray) {
		var array = barray.a;
		var finalSize = barray.b;
		var finalBytes = barray.c;
		var decoder = A2(
			$elm$bytes$Bytes$Decode$loop,
			_Utils_Tuple2(
				$elm$bytes$Bytes$width(bytes),
				barray),
			$folkertdev$elm_flate$Experimental$ByteArray$appendBytesHelp);
		var _v0 = A2($elm$bytes$Bytes$Decode$decode, decoder, bytes);
		if (!_v0.$) {
			var v = _v0.a;
			return v;
		} else {
			return barray;
		}
	});
var $elm$core$Array$repeat = F2(
	function (n, e) {
		return A2(
			$elm$core$Array$initialize,
			n,
			function (_v0) {
				return e;
			});
	});
var $folkertdev$elm_flate$Inflate$Internal$buildTree = F3(
	function (lengths, offset, num) {
		var tableDict = function () {
			var updater = function (maybeValue) {
				if (maybeValue.$ === 1) {
					return $elm$core$Maybe$Just(1);
				} else {
					var v = maybeValue.a;
					return $elm$core$Maybe$Just(v + 1);
				}
			};
			var folder = F3(
				function (key, value, accum) {
					return ((_Utils_cmp(key, offset) > -1) && (_Utils_cmp(key, num + offset) < 0)) ? A3($elm$core$Dict$update, value, updater, accum) : accum;
				});
			return A3($elm$core$Dict$foldl, folder, $elm$core$Dict$empty, lengths);
		}();
		var offsetsDict = A3(
			$elm$core$Dict$foldl,
			F3(
				function (key, value, _v4) {
					var sum = _v4.a;
					var dict = _v4.b;
					return _Utils_Tuple2(
						sum + value,
						A3($elm$core$Dict$insert, key, sum, dict));
				}),
			_Utils_Tuple2(0, $elm$core$Dict$empty),
			tableDict);
		var newTable = function () {
			var helper = F4(
				function (key, value, i, array) {
					helper:
					while (true) {
						if (_Utils_cmp(i, key) > 0) {
							var $temp$key = key,
								$temp$value = value,
								$temp$i = i - 1,
								$temp$array = A2($elm$core$List$cons, 0, array);
							key = $temp$key;
							value = $temp$value;
							i = $temp$i;
							array = $temp$array;
							continue helper;
						} else {
							return A2($elm$core$List$cons, value, array);
						}
					}
				});
			var foldHelp = F3(
				function (key, value, _v3) {
					var i = _v3.a;
					var array = _v3.b;
					return _Utils_Tuple2(
						key - 1,
						A4(helper, key, value, i, array));
				});
			var anotherGo = F2(
				function (i, array) {
					anotherGo:
					while (true) {
						if (i >= 0) {
							var $temp$i = i - 1,
								$temp$array = A2($elm$core$List$cons, 0, array);
							i = $temp$i;
							array = $temp$array;
							continue anotherGo;
						} else {
							return array;
						}
					}
				});
			return function (_v2) {
				var a = _v2.a;
				var b = _v2.b;
				return A2(anotherGo, a, b);
			}(
				A3(
					$elm$core$Dict$foldr,
					foldHelp,
					_Utils_Tuple2(15, _List_Nil),
					tableDict));
		}();
		var go2 = F3(
			function (i, currentTranslation, currentOffsets) {
				go2:
				while (true) {
					if ((i - num) < 0) {
						var _v0 = A2($elm$core$Dict$get, offset + i, lengths);
						if (_v0.$ === 1) {
							var $temp$i = i + 1,
								$temp$currentTranslation = currentTranslation,
								$temp$currentOffsets = currentOffsets;
							i = $temp$i;
							currentTranslation = $temp$currentTranslation;
							currentOffsets = $temp$currentOffsets;
							continue go2;
						} else {
							var v = _v0.a;
							if (!(!v)) {
								var _v1 = A2($elm$core$Dict$get, v, currentOffsets);
								if (_v1.$ === 1) {
									return currentTranslation;
								} else {
									var w = _v1.a;
									var $temp$i = i + 1,
										$temp$currentTranslation = A3($elm$core$Array$set, w, i, currentTranslation),
										$temp$currentOffsets = A3($elm$core$Dict$insert, v, w + 1, currentOffsets);
									i = $temp$i;
									currentTranslation = $temp$currentTranslation;
									currentOffsets = $temp$currentOffsets;
									continue go2;
								}
							} else {
								var $temp$i = i + 1,
									$temp$currentTranslation = currentTranslation,
									$temp$currentOffsets = currentOffsets;
								i = $temp$i;
								currentTranslation = $temp$currentTranslation;
								currentOffsets = $temp$currentOffsets;
								continue go2;
							}
						}
					} else {
						return currentTranslation;
					}
				}
			});
		var translation2 = A3(
			go2,
			0,
			A2($elm$core$Array$repeat, num, 0),
			offsetsDict.b);
		return {bf: newTable, b$: translation2};
	});
var $folkertdev$elm_flate$Inflate$Internal$clcIndices = _List_fromArray(
	[16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]);
var $folkertdev$elm_flate$Inflate$BitSet$BitSet320 = function (a) {
	return function (b) {
		return function (c) {
			return function (d) {
				return function (e) {
					return function (f) {
						return function (g) {
							return function (h) {
								return function (i) {
									return function (j) {
										return {$: 0, a: a, b: b, c: c, d: d, e: e, f: f, g: g, h: h, i: i, j: j};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $folkertdev$elm_flate$Inflate$BitSet$insert = F2(
	function (n, input) {
		var b1 = input.a;
		var b2 = input.b;
		var b3 = input.c;
		var b4 = input.d;
		var b5 = input.e;
		var b6 = input.f;
		var b7 = input.g;
		var b8 = input.h;
		var b9 = input.i;
		var b10 = input.j;
		if (n >= 320) {
			return input;
		} else {
			var bit = 1 << (n % 32);
			var _v0 = (n / 32) | 0;
			switch (_v0) {
				case 0:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(bit | b1)(b2)(b3)(b4)(b5)(b6)(b7)(b8)(b9)(b10);
				case 1:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(bit | b2)(b3)(b4)(b5)(b6)(b7)(b8)(b9)(b10);
				case 2:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(bit | b3)(b4)(b5)(b6)(b7)(b8)(b9)(b10);
				case 3:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(bit | b4)(b5)(b6)(b7)(b8)(b9)(b10);
				case 4:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(bit | b5)(b6)(b7)(b8)(b9)(b10);
				case 5:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(b5)(bit | b6)(b7)(b8)(b9)(b10);
				case 6:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(b5)(b6)(bit | b7)(b8)(b9)(b10);
				case 7:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(b5)(b6)(b7)(bit | b8)(b9)(b10);
				case 8:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(b5)(b6)(b7)(b8)(bit | b9)(b10);
				case 9:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(b5)(b6)(b7)(b8)(b9)(bit | b10);
				default:
					return input;
			}
		}
	});
var $folkertdev$elm_flate$Inflate$BitSet$member = F2(
	function (n, input) {
		var b1 = input.a;
		var b2 = input.b;
		var b3 = input.c;
		var b4 = input.d;
		var b5 = input.e;
		var b6 = input.f;
		var b7 = input.g;
		var b8 = input.h;
		var b9 = input.i;
		var b10 = input.j;
		if (n >= 320) {
			return false;
		} else {
			var bit = 1 << (n % 32);
			var _v0 = (n / 32) | 0;
			switch (_v0) {
				case 0:
					return (bit & b1) > 0;
				case 1:
					return (bit & b2) > 0;
				case 2:
					return (bit & b3) > 0;
				case 3:
					return (bit & b4) > 0;
				case 4:
					return (bit & b5) > 0;
				case 5:
					return (bit & b6) > 0;
				case 6:
					return (bit & b7) > 0;
				case 7:
					return (bit & b8) > 0;
				case 8:
					return (bit & b9) > 0;
				case 9:
					return (bit & b10) > 0;
				default:
					return false;
			}
		}
	});
var $elm$core$Bitwise$complement = _Bitwise_complement;
var $folkertdev$elm_flate$Inflate$BitSet$remove = F2(
	function (n, input) {
		var b1 = input.a;
		var b2 = input.b;
		var b3 = input.c;
		var b4 = input.d;
		var b5 = input.e;
		var b6 = input.f;
		var b7 = input.g;
		var b8 = input.h;
		var b9 = input.i;
		var b10 = input.j;
		if (n >= 320) {
			return input;
		} else {
			var bit = ~(1 << (n % 32));
			var _v0 = (n / 32) | 0;
			switch (_v0) {
				case 0:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(bit & b1)(b2)(b3)(b4)(b5)(b6)(b7)(b8)(b9)(b10);
				case 1:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(bit & b2)(b3)(b4)(b5)(b6)(b7)(b8)(b9)(b10);
				case 2:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(bit & b3)(b4)(b5)(b6)(b7)(b8)(b9)(b10);
				case 3:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(bit & b4)(b5)(b6)(b7)(b8)(b9)(b10);
				case 4:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(bit & b5)(b6)(b7)(b8)(b9)(b10);
				case 5:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(b5)(bit & b6)(b7)(b8)(b9)(b10);
				case 6:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(b5)(b6)(bit & b7)(b8)(b9)(b10);
				case 7:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(b5)(b6)(b7)(bit & b8)(b9)(b10);
				case 8:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(b5)(b6)(b7)(b8)(bit & b9)(b10);
				case 9:
					return $folkertdev$elm_flate$Inflate$BitSet$BitSet320(b1)(b2)(b3)(b4)(b5)(b6)(b7)(b8)(b9)(bit & b10);
				default:
					return input;
			}
		}
	});
var $folkertdev$elm_flate$Inflate$Internal$copySegment = F5(
	function (i, value, bitset, lengths, length) {
		var end = i + length;
		var go = F3(
			function (j, currentBitSet, accum) {
				go:
				while (true) {
					if ((j - end) < 0) {
						if (!(!value)) {
							var $temp$j = j + 1,
								$temp$currentBitSet = A2($folkertdev$elm_flate$Inflate$BitSet$insert, j, currentBitSet),
								$temp$accum = A3($elm$core$Dict$insert, j, value, accum);
							j = $temp$j;
							currentBitSet = $temp$currentBitSet;
							accum = $temp$accum;
							continue go;
						} else {
							if (A2($folkertdev$elm_flate$Inflate$BitSet$member, j, currentBitSet)) {
								var $temp$j = j + 1,
									$temp$currentBitSet = A2($folkertdev$elm_flate$Inflate$BitSet$remove, j, currentBitSet),
									$temp$accum = A2($elm$core$Dict$remove, j, accum);
								j = $temp$j;
								currentBitSet = $temp$currentBitSet;
								accum = $temp$accum;
								continue go;
							} else {
								var $temp$j = j + 1,
									$temp$currentBitSet = currentBitSet,
									$temp$accum = accum;
								j = $temp$j;
								currentBitSet = $temp$currentBitSet;
								accum = $temp$accum;
								continue go;
							}
						}
					} else {
						return _Utils_Tuple2(currentBitSet, accum);
					}
				}
			});
		var _v0 = A3(go, i, bitset, lengths);
		var newBitSet = _v0.a;
		var newLengths = _v0.b;
		return _Utils_Tuple3(i + length, newBitSet, newLengths);
	});
var $folkertdev$elm_flate$Inflate$Internal$decodeSymbolInnerLoop = F5(
	function (table, cur, tag, bitsAvailable, sum) {
		decodeSymbolInnerLoop:
		while (true) {
			var newTag = tag >>> 1;
			if (!table.b) {
				return {v: bitsAvailable, cT: cur, dl: sum, dm: tag};
			} else {
				var value = table.a;
				var rest = table.b;
				var newerCur = ((cur << 1) + (tag & 1)) - value;
				var newSum = sum + value;
				if (newerCur >= 0) {
					var $temp$table = rest,
						$temp$cur = newerCur,
						$temp$tag = newTag,
						$temp$bitsAvailable = bitsAvailable - 1,
						$temp$sum = newSum;
					table = $temp$table;
					cur = $temp$cur;
					tag = $temp$tag;
					bitsAvailable = $temp$bitsAvailable;
					sum = $temp$sum;
					continue decodeSymbolInnerLoop;
				} else {
					return {v: bitsAvailable - 1, cT: newerCur, dl: newSum, dm: newTag};
				}
			}
		}
	});
var $folkertdev$elm_flate$Inflate$BitReader$moveFromReserve = F2(
	function (nbits, state) {
		var masked = (nbits === 32) ? (state.H << state.v) : ((((1 << nbits) - 1) & state.H) << state.v);
		return {v: state.v + nbits, K: state.K, H: state.H >>> nbits, z: state.z - nbits, dm: masked | state.dm};
	});
var $folkertdev$elm_flate$Inflate$BitReader$runDecoder = F3(
	function (width, valueDecoder, state) {
		var decoder = A3(
			$elm$bytes$Bytes$Decode$map2,
			$elm$core$Tuple$pair,
			valueDecoder,
			$elm$bytes$Bytes$Decode$bytes(
				$elm$bytes$Bytes$width(state.K) - width));
		var _v0 = A2($elm$bytes$Bytes$Decode$decode, decoder, state.K);
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$core$Result$Ok(value);
		} else {
			return $elm$core$Result$Err('BitReader.runDecoder: Unexpected end of Bytes');
		}
	});
var $folkertdev$elm_flate$Inflate$BitReader$unsignedInt24 = function (endianness) {
	if (!endianness) {
		return A3(
			$elm$bytes$Bytes$Decode$map2,
			F2(
				function (b2, b1) {
					return (b1 << 16) | b2;
				}),
			$elm$bytes$Bytes$Decode$unsignedInt16(endianness),
			$elm$bytes$Bytes$Decode$unsignedInt8);
	} else {
		return A3(
			$elm$bytes$Bytes$Decode$map2,
			F2(
				function (b1, b2) {
					return (b1 << 16) | b2;
				}),
			$elm$bytes$Bytes$Decode$unsignedInt16(endianness),
			$elm$bytes$Bytes$Decode$unsignedInt8);
	}
};
var $folkertdev$elm_flate$Inflate$BitReader$readMoreBits = function (state) {
	readMoreBits:
	while (true) {
		var freeSpaceOnTag = 32 - state.v;
		if ((_Utils_cmp(freeSpaceOnTag, state.z) < 1) && (state.z > 0)) {
			return $elm$core$Result$Ok(
				A2($folkertdev$elm_flate$Inflate$BitReader$moveFromReserve, freeSpaceOnTag, state));
		} else {
			if (!$elm$bytes$Bytes$width(state.K)) {
				return $elm$core$Result$Ok(
					A2($folkertdev$elm_flate$Inflate$BitReader$moveFromReserve, state.z, state));
			} else {
				var state1 = A2($folkertdev$elm_flate$Inflate$BitReader$moveFromReserve, state.z, state);
				var _v0 = function () {
					var _v1 = $elm$bytes$Bytes$width(state.K);
					switch (_v1) {
						case 0:
							return _Utils_Tuple3(
								0,
								0,
								$elm$bytes$Bytes$Decode$succeed(0));
						case 1:
							return _Utils_Tuple3(1, 8, $elm$bytes$Bytes$Decode$unsignedInt8);
						case 2:
							return _Utils_Tuple3(
								2,
								16,
								$elm$bytes$Bytes$Decode$unsignedInt16(0));
						case 3:
							return _Utils_Tuple3(
								3,
								24,
								$folkertdev$elm_flate$Inflate$BitReader$unsignedInt24(0));
						default:
							return _Utils_Tuple3(
								4,
								32,
								$elm$bytes$Bytes$Decode$unsignedInt32(0));
					}
				}();
				var width = _v0.a;
				var additionallyAvailable = _v0.b;
				var decoder = _v0.c;
				var _v2 = A3($folkertdev$elm_flate$Inflate$BitReader$runDecoder, width, decoder, state1);
				if (_v2.$ === 1) {
					var e = _v2.a;
					return $elm$core$Result$Err(e);
				} else {
					var _v3 = _v2.a;
					var newReserve = _v3.a;
					var newBuffer = _v3.b;
					var $temp$state = {v: state1.v, K: newBuffer, H: newReserve, z: additionallyAvailable, dm: state1.dm};
					state = $temp$state;
					continue readMoreBits;
				}
			}
		}
	}
};
var $folkertdev$elm_flate$Inflate$Internal$decodeSymbol = F2(
	function (table, tree) {
		return function (state) {
			var _v0 = (state.v < 16) ? $folkertdev$elm_flate$Inflate$BitReader$readMoreBits(state) : $elm$core$Result$Ok(state);
			if (_v0.$ === 1) {
				var e = _v0.a;
				return $elm$core$Result$Err(e);
			} else {
				var d = _v0.a;
				var _v1 = A5($folkertdev$elm_flate$Inflate$Internal$decodeSymbolInnerLoop, table, 0, d.dm, d.v, 0);
				var cur = _v1.cT;
				var tag = _v1.dm;
				var bitsAvailable = _v1.v;
				var sum = _v1.dl;
				var _v2 = A2($elm$core$Array$get, sum + cur, tree.b$);
				if (_v2.$ === 1) {
					return $elm$core$Result$Err('Index into trans tree out of bounds');
				} else {
					var result = _v2.a;
					return $elm$core$Result$Ok(
						_Utils_Tuple2(
							result,
							{v: bitsAvailable, K: d.K, H: d.H, z: d.z, dm: tag}));
				}
			}
		};
	});
var $folkertdev$elm_flate$Inflate$BitReader$readBits = F2(
	function (numberOfBits, base) {
		return function (state) {
			if (!numberOfBits) {
				return $elm$core$Result$Ok(
					_Utils_Tuple2(base, state));
			} else {
				var _v0 = (_Utils_cmp(state.v, numberOfBits) < 0) ? $folkertdev$elm_flate$Inflate$BitReader$readMoreBits(state) : $elm$core$Result$Ok(state);
				if (_v0.$ === 1) {
					var e = _v0.a;
					return $elm$core$Result$Err(e);
				} else {
					var d = _v0.a;
					var val = d.dm & (65535 >>> (16 - numberOfBits));
					var newTag = d.dm >>> numberOfBits;
					return $elm$core$Result$Ok(
						_Utils_Tuple2(
							val + base,
							{v: d.v - numberOfBits, K: d.K, H: d.H, z: d.z, dm: newTag}));
				}
			}
		};
	});
var $folkertdev$elm_flate$Inflate$BitReader$succeed = function (x) {
	return function (s) {
		return $elm$core$Result$Ok(
			_Utils_Tuple2(x, s));
	};
};
var $folkertdev$elm_flate$Inflate$Internal$decodeDynamicTreeLength = F4(
	function (codeTree, hlit, hdist, _v0) {
		var i = _v0.a;
		var bitset = _v0.b;
		var lengths = _v0.c;
		if (_Utils_cmp(i, hlit + hdist) < 0) {
			var table = A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(codeTree.bf));
			return A2(
				$folkertdev$elm_flate$Inflate$BitReader$andThen,
				function (sym) {
					switch (sym) {
						case 16:
							var prev = A2(
								$elm$core$Maybe$withDefault,
								0,
								A2($elm$core$Dict$get, i - 1, lengths));
							return A2(
								$folkertdev$elm_flate$Inflate$BitReader$map,
								A2(
									$elm$core$Basics$composeR,
									A4($folkertdev$elm_flate$Inflate$Internal$copySegment, i, prev, bitset, lengths),
									$elm$bytes$Bytes$Decode$Loop),
								A2($folkertdev$elm_flate$Inflate$BitReader$readBits, 2, 3));
						case 17:
							return A2(
								$folkertdev$elm_flate$Inflate$BitReader$map,
								A2(
									$elm$core$Basics$composeR,
									A4($folkertdev$elm_flate$Inflate$Internal$copySegment, i, 0, bitset, lengths),
									$elm$bytes$Bytes$Decode$Loop),
								A2($folkertdev$elm_flate$Inflate$BitReader$readBits, 3, 3));
						case 18:
							return A2(
								$folkertdev$elm_flate$Inflate$BitReader$map,
								A2(
									$elm$core$Basics$composeR,
									A4($folkertdev$elm_flate$Inflate$Internal$copySegment, i, 0, bitset, lengths),
									$elm$bytes$Bytes$Decode$Loop),
								A2($folkertdev$elm_flate$Inflate$BitReader$readBits, 7, 11));
						case 0:
							return A2($folkertdev$elm_flate$Inflate$BitSet$member, i, bitset) ? $folkertdev$elm_flate$Inflate$BitReader$succeed(
								$elm$bytes$Bytes$Decode$Loop(
									_Utils_Tuple3(
										i + 1,
										bitset,
										A2($elm$core$Dict$remove, i, lengths)))) : $folkertdev$elm_flate$Inflate$BitReader$succeed(
								$elm$bytes$Bytes$Decode$Loop(
									_Utils_Tuple3(i + 1, bitset, lengths)));
						default:
							return $folkertdev$elm_flate$Inflate$BitReader$succeed(
								$elm$bytes$Bytes$Decode$Loop(
									_Utils_Tuple3(
										i + 1,
										A2($folkertdev$elm_flate$Inflate$BitSet$insert, i, bitset),
										A3($elm$core$Dict$insert, i, sym, lengths))));
					}
				},
				A2($folkertdev$elm_flate$Inflate$Internal$decodeSymbol, table, codeTree));
		} else {
			return $folkertdev$elm_flate$Inflate$BitReader$succeed(
				$elm$bytes$Bytes$Decode$Done(lengths));
		}
	});
var $folkertdev$elm_flate$Inflate$BitSet$empty = $folkertdev$elm_flate$Inflate$BitSet$BitSet320(0)(0)(0)(0)(0)(0)(0)(0)(0)(0);
var $folkertdev$elm_flate$Inflate$Internal$decodeTreeLengths = F4(
	function (hlit, hdist, hclen, codeLengths) {
		var clcs = A2($elm$core$List$take, hclen, $folkertdev$elm_flate$Inflate$Internal$clcIndices);
		var initialLengths = function () {
			var go = F3(
				function (xs, ys, accum) {
					go:
					while (true) {
						if (!xs.b) {
							return accum;
						} else {
							var index = xs.a;
							var restIndex = xs.b;
							if (!ys.b) {
								return accum;
							} else {
								var codeLength = ys.a;
								var restCodeLength = ys.b;
								if (!(!codeLength)) {
									var $temp$xs = restIndex,
										$temp$ys = restCodeLength,
										$temp$accum = A3($elm$core$Dict$insert, index, codeLength, accum);
									xs = $temp$xs;
									ys = $temp$ys;
									accum = $temp$accum;
									continue go;
								} else {
									var $temp$xs = restIndex,
										$temp$ys = restCodeLength,
										$temp$accum = accum;
									xs = $temp$xs;
									ys = $temp$ys;
									accum = $temp$accum;
									continue go;
								}
							}
						}
					}
				});
			return A3(go, clcs, codeLengths, $elm$core$Dict$empty);
		}();
		var codeTree = A3($folkertdev$elm_flate$Inflate$Internal$buildTree, initialLengths, 0, 19);
		var initialBitSet = A3(
			$elm$core$Dict$foldl,
			F2(
				function (i, _v0) {
					return $folkertdev$elm_flate$Inflate$BitSet$insert(i);
				}),
			$folkertdev$elm_flate$Inflate$BitSet$empty,
			initialLengths);
		return A2(
			$folkertdev$elm_flate$Inflate$BitReader$loop,
			_Utils_Tuple3(0, initialBitSet, initialLengths),
			A3($folkertdev$elm_flate$Inflate$Internal$decodeDynamicTreeLength, codeTree, hlit, hdist));
	});
var $folkertdev$elm_flate$Inflate$BitReader$exactly = F2(
	function (tableCount, decoder) {
		var helper = function (_v0) {
			var n = _v0.a;
			var xs = _v0.b;
			return (n <= 0) ? $folkertdev$elm_flate$Inflate$BitReader$succeed(
				$elm$bytes$Bytes$Decode$Done(
					$elm$core$List$reverse(xs))) : A2(
				$folkertdev$elm_flate$Inflate$BitReader$map,
				function (x) {
					return $elm$bytes$Bytes$Decode$Loop(
						_Utils_Tuple2(
							n - 1,
							A2($elm$core$List$cons, x, xs)));
				},
				decoder);
		};
		return A2(
			$folkertdev$elm_flate$Inflate$BitReader$loop,
			_Utils_Tuple2(tableCount, _List_Nil),
			helper);
	});
var $folkertdev$elm_flate$Inflate$Internal$cont = F3(
	function (hlit, hdist, hclen) {
		var buildTrees = function (lengths) {
			return _Utils_Tuple2(
				A3($folkertdev$elm_flate$Inflate$Internal$buildTree, lengths, 0, hlit),
				A3($folkertdev$elm_flate$Inflate$Internal$buildTree, lengths, hlit, hdist));
		};
		return A2(
			$folkertdev$elm_flate$Inflate$BitReader$map,
			buildTrees,
			A2(
				$folkertdev$elm_flate$Inflate$BitReader$andThen,
				A3($folkertdev$elm_flate$Inflate$Internal$decodeTreeLengths, hlit, hdist, hclen),
				A2(
					$folkertdev$elm_flate$Inflate$BitReader$exactly,
					hclen,
					A2($folkertdev$elm_flate$Inflate$BitReader$readBits, 3, 0))));
	});
var $folkertdev$elm_flate$Inflate$BitReader$map2 = F3(
	function (f, _v0, _v1) {
		var fa = _v0;
		var fb = _v1;
		return function (state) {
			var _v2 = fa(state);
			if (_v2.$ === 1) {
				var e = _v2.a;
				return $elm$core$Result$Err(e);
			} else {
				var _v3 = _v2.a;
				var a = _v3.a;
				var newState = _v3.b;
				var _v4 = fb(newState);
				if (_v4.$ === 1) {
					var e = _v4.a;
					return $elm$core$Result$Err(e);
				} else {
					var _v5 = _v4.a;
					var b = _v5.a;
					var newerState = _v5.b;
					return $elm$core$Result$Ok(
						_Utils_Tuple2(
							A2(f, a, b),
							newerState));
				}
			}
		};
	});
var $folkertdev$elm_flate$Inflate$BitReader$andMap = F2(
	function (a, f) {
		return A3($folkertdev$elm_flate$Inflate$BitReader$map2, $elm$core$Basics$apL, f, a);
	});
var $folkertdev$elm_flate$Inflate$BitReader$map3 = F4(
	function (f, a, b, c) {
		return A2(
			$folkertdev$elm_flate$Inflate$BitReader$andMap,
			c,
			A2(
				$folkertdev$elm_flate$Inflate$BitReader$andMap,
				b,
				A2(
					$folkertdev$elm_flate$Inflate$BitReader$andMap,
					a,
					$folkertdev$elm_flate$Inflate$BitReader$succeed(f))));
	});
var $folkertdev$elm_flate$Inflate$Internal$decodeTrees = A2(
	$folkertdev$elm_flate$Inflate$BitReader$andThen,
	$elm$core$Basics$identity,
	A4(
		$folkertdev$elm_flate$Inflate$BitReader$map3,
		$folkertdev$elm_flate$Inflate$Internal$cont,
		A2($folkertdev$elm_flate$Inflate$BitReader$readBits, 5, 257),
		A2($folkertdev$elm_flate$Inflate$BitReader$readBits, 5, 1),
		A2($folkertdev$elm_flate$Inflate$BitReader$readBits, 4, 4)));
var $folkertdev$elm_flate$Inflate$BitReader$error = function (e) {
	return function (s) {
		return $elm$core$Result$Err(e);
	};
};
var $folkertdev$elm_flate$Inflate$BitReader$getBit = A2($folkertdev$elm_flate$Inflate$BitReader$readBits, 1, 0);
var $folkertdev$elm_flate$Experimental$ByteArray$get = F2(
	function (index, _v0) {
		var array = _v0.a;
		var finalSize = _v0.b;
		var finalBytes = _v0.c;
		var offset = index % 4;
		if (_Utils_cmp(
			index,
			($elm$core$Array$length(array) * 4) + finalSize) > -1) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (_Utils_cmp(
				index,
				$elm$core$Array$length(array) * 4) > -1) {
				return $elm$core$Maybe$Just(255 & (finalBytes >>> (8 * (3 - offset))));
			} else {
				var internalIndex = (index / 4) | 0;
				var _v1 = A2($elm$core$Array$get, internalIndex, array);
				if (_v1.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var int32 = _v1.a;
					return $elm$core$Maybe$Just(255 & (int32 >>> (8 * (3 - offset))));
				}
			}
		}
	});
var $folkertdev$elm_flate$Experimental$ByteArray$copyToBackInternal = F5(
	function (startIndex, size, array, finalSize, finalBytes) {
		copyToBackInternal:
		while (true) {
			var offset = startIndex % 4;
			var internalIndex = (startIndex / 4) | 0;
			if (size <= 0) {
				return A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, finalSize, finalBytes);
			} else {
				if (_Utils_cmp(
					startIndex + 4,
					(($elm$core$Array$length(array) - 1) * 4) + finalSize) > -1) {
					var _v0 = A2(
						$folkertdev$elm_flate$Experimental$ByteArray$get,
						startIndex,
						A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, finalSize, finalBytes));
					if (_v0.$ === 1) {
						return A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, finalSize, finalBytes);
					} else {
						var value = _v0.a;
						var _v1 = A2(
							$folkertdev$elm_flate$Experimental$ByteArray$push,
							value,
							A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, finalSize, finalBytes));
						var newArray = _v1.a;
						var newFinalSize = _v1.b;
						var newFinalBytes = _v1.c;
						var $temp$startIndex = startIndex + 1,
							$temp$size = size - 1,
							$temp$array = newArray,
							$temp$finalSize = newFinalSize,
							$temp$finalBytes = newFinalBytes;
						startIndex = $temp$startIndex;
						size = $temp$size;
						array = $temp$array;
						finalSize = $temp$finalSize;
						finalBytes = $temp$finalBytes;
						continue copyToBackInternal;
					}
				} else {
					var _v2 = A2($elm$core$Array$get, internalIndex, array);
					if (_v2.$ === 1) {
						return A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, finalSize, finalBytes);
					} else {
						var value = _v2.a;
						var written = A2($elm$core$Basics$min, 4 - offset, size);
						var maskedFront = value << (8 * offset);
						var maskedBack = function () {
							if (_Utils_cmp(4 - offset, size) > 0) {
								var bytesWeNeedToRemove = 4 - size;
								var bytesWeHave = (3 - offset) + 1;
								return maskedFront >> (bytesWeNeedToRemove * 8);
							} else {
								return maskedFront >> (offset * 8);
							}
						}();
						var _v3 = A3(
							$folkertdev$elm_flate$Experimental$ByteArray$pushMany,
							written,
							maskedBack,
							A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, finalSize, finalBytes));
						var x = _v3.a;
						var y = _v3.b;
						var z = _v3.c;
						var $temp$startIndex = startIndex + written,
							$temp$size = size - written,
							$temp$array = x,
							$temp$finalSize = y,
							$temp$finalBytes = z;
						startIndex = $temp$startIndex;
						size = $temp$size;
						array = $temp$array;
						finalSize = $temp$finalSize;
						finalBytes = $temp$finalBytes;
						continue copyToBackInternal;
					}
				}
			}
		}
	});
var $folkertdev$elm_flate$Experimental$ByteArray$copyToBack = F3(
	function (startIndex, size, _v0) {
		var array = _v0.a;
		var finalSize = _v0.b;
		var finalBytes = _v0.c;
		return A5($folkertdev$elm_flate$Experimental$ByteArray$copyToBackInternal, startIndex, size, array, finalSize, finalBytes);
	});
var $folkertdev$elm_flate$Inflate$Internal$HuffmanTable = $elm$core$Basics$identity;
var $folkertdev$elm_flate$Inflate$Internal$buildBitsBase = F2(
	function (delta, first) {
		var initializer = function (i) {
			return (_Utils_cmp(i, delta) < 0) ? 0 : (((i - delta) / delta) | 0);
		};
		var folder = F2(
			function (bit, _v0) {
				var sum = _v0.a;
				var accum = _v0.b;
				return _Utils_Tuple2(
					sum + (1 << bit),
					A2(
						$elm$core$Array$push,
						{cR: sum, a: bit},
						accum));
			});
		var bits = A2($elm$core$Array$initialize, 30, initializer);
		var base = A3(
			$elm$core$Array$foldl,
			folder,
			_Utils_Tuple2(first, $elm$core$Array$empty),
			bits).b;
		return base;
	});
var $folkertdev$elm_flate$Inflate$Internal$hardcodedDistanceTable = A2($folkertdev$elm_flate$Inflate$Internal$buildBitsBase, 2, 1);
var $folkertdev$elm_flate$Inflate$Internal$hardcodedLengthTable = function (_v0) {
	var array = _v0;
	return A3(
		$elm$core$Array$set,
		28,
		{cR: 258, a: 0},
		array);
}(
	A2($folkertdev$elm_flate$Inflate$Internal$buildBitsBase, 4, 3));
var $folkertdev$elm_flate$Inflate$Internal$readHuffmanTable = F2(
	function (index, _v0) {
		var table = _v0;
		return A2($elm$core$Array$get, index, table);
	});
var $folkertdev$elm_flate$Inflate$Internal$decodeLength = function (symbol) {
	var _v0 = A2($folkertdev$elm_flate$Inflate$Internal$readHuffmanTable, symbol - 257, $folkertdev$elm_flate$Inflate$Internal$hardcodedLengthTable);
	if (_v0.$ === 1) {
		return $folkertdev$elm_flate$Inflate$BitReader$error(
			function () {
				var _v1 = $folkertdev$elm_flate$Inflate$Internal$hardcodedDistanceTable;
				var internal = _v1;
				return 'index out of bounds in hardcodedLengthTable: requested index ' + ($elm$core$String$fromInt(symbol - 257) + ('but hardcodedLengthTable has length ' + $elm$core$String$fromInt(
					$elm$core$Array$length(internal))));
			}());
	} else {
		var entry = _v0.a;
		return A2($folkertdev$elm_flate$Inflate$BitReader$readBits, entry.a, entry.cR);
	}
};
var $folkertdev$elm_flate$Inflate$Internal$decodeOffset = F2(
	function (outputLength, dt) {
		var table_ = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$tail(dt.bf));
		return A2(
			$folkertdev$elm_flate$Inflate$BitReader$andThen,
			function (distance) {
				var _v0 = A2($folkertdev$elm_flate$Inflate$Internal$readHuffmanTable, distance, $folkertdev$elm_flate$Inflate$Internal$hardcodedDistanceTable);
				if (_v0.$ === 1) {
					return $folkertdev$elm_flate$Inflate$BitReader$error(
						function () {
							var _v1 = $folkertdev$elm_flate$Inflate$Internal$hardcodedDistanceTable;
							var internal = _v1;
							return 'index out of bounds in hardcodedDistanceTable: requested index ' + ($elm$core$String$fromInt(distance) + ('but hardcodedLengthTable has length ' + $elm$core$String$fromInt(
								$elm$core$Array$length(internal))));
						}());
				} else {
					var entry = _v0.a;
					return A2(
						$folkertdev$elm_flate$Inflate$BitReader$map,
						function (v) {
							return outputLength - v;
						},
						A2($folkertdev$elm_flate$Inflate$BitReader$readBits, entry.a, entry.cR));
				}
			},
			A2($folkertdev$elm_flate$Inflate$Internal$decodeSymbol, table_, dt));
	});
var $folkertdev$elm_flate$Inflate$Internal$inflateBlockDataHelp = F2(
	function (trees, _v0) {
		var outputLength = _v0.a;
		var output = _v0.b;
		var table = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$tail(trees.a6.bf));
		return A2(
			$folkertdev$elm_flate$Inflate$BitReader$andThen,
			function (symbol) {
				return (symbol === 256) ? $folkertdev$elm_flate$Inflate$BitReader$succeed(
					$elm$bytes$Bytes$Decode$Done(output)) : ((symbol < 256) ? $folkertdev$elm_flate$Inflate$BitReader$succeed(
					$elm$bytes$Bytes$Decode$Loop(
						_Utils_Tuple2(
							outputLength + 1,
							A2($folkertdev$elm_flate$Experimental$ByteArray$push, symbol, output)))) : A3(
					$folkertdev$elm_flate$Inflate$BitReader$map2,
					F2(
						function (length, offset) {
							return $elm$bytes$Bytes$Decode$Loop(
								_Utils_Tuple2(
									outputLength + length,
									A3($folkertdev$elm_flate$Experimental$ByteArray$copyToBack, offset, length, output)));
						}),
					$folkertdev$elm_flate$Inflate$Internal$decodeLength(symbol),
					A2($folkertdev$elm_flate$Inflate$Internal$decodeOffset, outputLength, trees.a2)));
			},
			A2($folkertdev$elm_flate$Inflate$Internal$decodeSymbol, table, trees.a6));
	});
var $folkertdev$elm_flate$Inflate$Internal$inflateBlockData = F3(
	function (trees, outputLength, output) {
		return A2(
			$folkertdev$elm_flate$Inflate$BitReader$loop,
			_Utils_Tuple2(outputLength, output),
			$folkertdev$elm_flate$Inflate$Internal$inflateBlockDataHelp(trees));
	});
var $folkertdev$elm_flate$Inflate$Internal$uncompressedBlockDecoder = function (bufferWidth) {
	var decodeLengths = A3(
		$elm$bytes$Bytes$Decode$map2,
		$elm$core$Tuple$pair,
		$elm$bytes$Bytes$Decode$unsignedInt16(0),
		$elm$bytes$Bytes$Decode$unsignedInt16(0));
	return A2(
		$elm$bytes$Bytes$Decode$andThen,
		function (_v0) {
			var length = _v0.a;
			var invlength = _v0.b;
			if (!_Utils_eq(length, (~invlength) & 65535)) {
				return $elm$bytes$Bytes$Decode$fail;
			} else {
				var remainingSize = (bufferWidth - 4) - length;
				return A3(
					$elm$bytes$Bytes$Decode$map2,
					$elm$core$Tuple$pair,
					$elm$bytes$Bytes$Decode$bytes(length),
					$elm$bytes$Bytes$Decode$bytes(remainingSize));
			}
		},
		decodeLengths);
};
var $folkertdev$elm_flate$Inflate$Internal$inflateUncompressedBlock = function (state) {
	var _v0 = A2(
		$elm$bytes$Bytes$Decode$decode,
		$folkertdev$elm_flate$Inflate$Internal$uncompressedBlockDecoder(
			$elm$bytes$Bytes$width(state.K)),
		state.K);
	if (_v0.$ === 1) {
		return $elm$core$Result$Err('inflateUncompressedBlock: ran out of bounds');
	} else {
		var _v1 = _v0.a;
		var block = _v1.a;
		var newBuffer = _v1.b;
		return $elm$core$Result$Ok(
			_Utils_Tuple2(
				block,
				_Utils_update(
					state,
					{K: newBuffer})));
	}
};
var $folkertdev$elm_flate$Experimental$ByteArray$length = function (_v0) {
	var array = _v0.a;
	var finalSize = _v0.b;
	var finalBytes = _v0.c;
	var _v1 = $elm$core$Array$length(array) * 4;
	if (!_v1) {
		return finalSize;
	} else {
		var l = _v1;
		return l + finalSize;
	}
};
var $folkertdev$elm_flate$Inflate$Internal$sdtree = {
	bf: _List_fromArray(
		[0, 0, 0, 0, 0, 32, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]),
	b$: A2(
		$elm$core$Array$append,
		$elm$core$Array$fromList(
			_List_fromArray(
				[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31])),
		A2($elm$core$Array$repeat, 288 - 32, 0))
};
var $folkertdev$elm_flate$Inflate$BitReader$flushHelp = function (state0) {
	var availableSpace = 32 - state0.v;
	var state = A2(
		$folkertdev$elm_flate$Inflate$BitReader$moveFromReserve,
		A2($elm$core$Basics$min, availableSpace, state0.z),
		state0);
	var reserveEncoder = (state.z > 24) ? _List_fromArray(
		[
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, state.H)
		]) : ((state.z > 16) ? _List_fromArray(
		[
			A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, state.H),
			$elm$bytes$Bytes$Encode$unsignedInt8(state.H >> 16)
		]) : ((state.z > 8) ? _List_fromArray(
		[
			A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, state.H)
		]) : ((state.z > 1) ? _List_fromArray(
		[
			$elm$bytes$Bytes$Encode$unsignedInt8(state.H)
		]) : _List_Nil)));
	var tagEncoder = (state.v > 24) ? _List_fromArray(
		[
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, state.dm)
		]) : ((state.v > 16) ? _List_fromArray(
		[
			A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, state.dm),
			$elm$bytes$Bytes$Encode$unsignedInt8(state.dm >> 16)
		]) : ((state.v > 8) ? _List_fromArray(
		[
			A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, state.dm)
		]) : ((state.v > 1) ? _List_fromArray(
		[
			$elm$bytes$Bytes$Encode$unsignedInt8(state.dm)
		]) : _List_Nil)));
	return $elm$bytes$Bytes$Encode$encode(
		$elm$bytes$Bytes$Encode$sequence(
			_Utils_ap(
				tagEncoder,
				_Utils_ap(
					reserveEncoder,
					_List_fromArray(
						[
							$elm$bytes$Bytes$Encode$bytes(state.K)
						])))));
};
var $folkertdev$elm_flate$Inflate$BitReader$flush = function (state) {
	return {
		v: 0,
		K: $folkertdev$elm_flate$Inflate$BitReader$flushHelp(state),
		H: 0,
		z: 0,
		dm: 0
	};
};
var $folkertdev$elm_flate$Inflate$BitReader$skipToByteBoundary = function (s) {
	var available = s.v + s.z;
	var untilBoundary = A2($elm$core$Basics$modBy, 8, available);
	var _v0 = A2($folkertdev$elm_flate$Inflate$BitReader$readBits, untilBoundary, 0);
	var step = _v0;
	var _v1 = step(s);
	if (_v1.$ === 1) {
		var e = _v1.a;
		return $elm$core$Result$Err(e);
	} else {
		var _v2 = _v1.a;
		var newState = _v2.b;
		var _v3 = $folkertdev$elm_flate$Inflate$BitReader$readMoreBits(newState);
		if (_v3.$ === 1) {
			var e = _v3.a;
			return $elm$core$Result$Err(e);
		} else {
			var newerState = _v3.a;
			return $elm$core$Result$Ok(
				_Utils_Tuple2(
					0,
					$folkertdev$elm_flate$Inflate$BitReader$flush(newerState)));
		}
	}
};
var $folkertdev$elm_flate$Inflate$Internal$sltree = {
	bf: _List_fromArray(
		[0, 0, 0, 0, 0, 0, 0, 24, 152, 112, 0, 0, 0, 0, 0, 0]),
	b$: $elm$core$Array$fromList(
		_List_fromArray(
			[256, 257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 280, 281, 282, 283, 284, 285, 286, 287, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255]))
};
var $folkertdev$elm_flate$Inflate$Internal$uncompressHelp = function (output) {
	var uncompressBlock = function (btype) {
		switch (btype) {
			case 0:
				return A2(
					$folkertdev$elm_flate$Inflate$BitReader$map,
					function (bytes) {
						return A2($folkertdev$elm_flate$Experimental$ByteArray$appendBytes, bytes, output);
					},
					A2(
						$folkertdev$elm_flate$Inflate$BitReader$andThen,
						function (_v1) {
							return $folkertdev$elm_flate$Inflate$Internal$inflateUncompressedBlock;
						},
						$folkertdev$elm_flate$Inflate$BitReader$skipToByteBoundary));
			case 1:
				return A3(
					$folkertdev$elm_flate$Inflate$Internal$inflateBlockData,
					{a2: $folkertdev$elm_flate$Inflate$Internal$sdtree, a6: $folkertdev$elm_flate$Inflate$Internal$sltree},
					$folkertdev$elm_flate$Experimental$ByteArray$length(output),
					output);
			case 2:
				return A2(
					$folkertdev$elm_flate$Inflate$BitReader$andThen,
					function (_v2) {
						var ltree = _v2.a;
						var dtree = _v2.b;
						return A3(
							$folkertdev$elm_flate$Inflate$Internal$inflateBlockData,
							{a2: dtree, a6: ltree},
							$folkertdev$elm_flate$Experimental$ByteArray$length(output),
							output);
					},
					$folkertdev$elm_flate$Inflate$Internal$decodeTrees);
			default:
				return $folkertdev$elm_flate$Inflate$BitReader$error(
					'invalid block type: ' + ($elm$core$String$fromInt(btype) + ' (only 0, 1 and 2 are valid block types)'));
		}
	};
	var readTwoBits = A3(
		$folkertdev$elm_flate$Inflate$BitReader$map2,
		F2(
			function (b1, b2) {
				return b1 + (2 * b2);
			}),
		$folkertdev$elm_flate$Inflate$BitReader$getBit,
		$folkertdev$elm_flate$Inflate$BitReader$getBit);
	var go = F2(
		function (isFinal, blockType) {
			return (!(!isFinal)) ? A2(
				$folkertdev$elm_flate$Inflate$BitReader$map,
				$elm$bytes$Bytes$Decode$Done,
				uncompressBlock(blockType)) : A2(
				$folkertdev$elm_flate$Inflate$BitReader$map,
				$elm$bytes$Bytes$Decode$Loop,
				uncompressBlock(blockType));
		});
	return A2(
		$folkertdev$elm_flate$Inflate$BitReader$andThen,
		$elm$core$Basics$identity,
		A3($folkertdev$elm_flate$Inflate$BitReader$map2, go, $folkertdev$elm_flate$Inflate$BitReader$getBit, readTwoBits));
};
var $folkertdev$elm_flate$Inflate$Internal$uncompress = A2(
	$folkertdev$elm_flate$Inflate$BitReader$map,
	A2($elm$core$Basics$composeR, $folkertdev$elm_flate$Experimental$ByteArray$toBytes, $elm$core$List$singleton),
	A2($folkertdev$elm_flate$Inflate$BitReader$loop, $folkertdev$elm_flate$Experimental$ByteArray$empty, $folkertdev$elm_flate$Inflate$Internal$uncompressHelp));
var $folkertdev$elm_flate$Inflate$Internal$inflate = function (buffer) {
	var _v0 = A2($folkertdev$elm_flate$Inflate$BitReader$decode, buffer, $folkertdev$elm_flate$Inflate$Internal$uncompress);
	if (_v0.$ === 1) {
		var e = _v0.a;
		return $elm$core$Result$Err(e);
	} else {
		var values = _v0.a;
		return $elm$core$Result$Ok(
			$elm$bytes$Bytes$Encode$encode(
				$elm$bytes$Bytes$Encode$sequence(
					A2($elm$core$List$map, $elm$bytes$Bytes$Encode$bytes, values))));
	}
};
var $folkertdev$elm_flate$Inflate$GZip$inflate = function (buffer) {
	return A2(
		$elm$core$Maybe$andThen,
		A2($elm$core$Basics$composeR, $folkertdev$elm_flate$Inflate$Internal$inflate, $elm$core$Result$toMaybe),
		A2(
			$elm$core$Maybe$andThen,
			$folkertdev$elm_flate$Inflate$GZip$gzipFindBuffer,
			$folkertdev$elm_flate$Inflate$GZip$gzipSlice(buffer)));
};
var $folkertdev$elm_flate$Inflate$Inflate$inflateGZip = $folkertdev$elm_flate$Inflate$GZip$inflate;
var $folkertdev$elm_flate$Flate$inflateGZip = $folkertdev$elm_flate$Inflate$Inflate$inflateGZip;
var $elm$bytes$Bytes$Decode$string = function (n) {
	return _Bytes_read_string(n);
};
var $danfishgold$base64_bytes$Encode$isValidChar = function (c) {
	if ($elm$core$Char$isAlphaNum(c)) {
		return true;
	} else {
		switch (c) {
			case '+':
				return true;
			case '/':
				return true;
			default:
				return false;
		}
	}
};
var $danfishgold$base64_bytes$Encode$unsafeConvertChar = function (_char) {
	var key = $elm$core$Char$toCode(_char);
	if ((key >= 65) && (key <= 90)) {
		return key - 65;
	} else {
		if ((key >= 97) && (key <= 122)) {
			return (key - 97) + 26;
		} else {
			if ((key >= 48) && (key <= 57)) {
				return ((key - 48) + 26) + 26;
			} else {
				switch (_char) {
					case '+':
						return 62;
					case '/':
						return 63;
					default:
						return -1;
				}
			}
		}
	}
};
var $danfishgold$base64_bytes$Encode$encodeCharacters = F4(
	function (a, b, c, d) {
		if ($danfishgold$base64_bytes$Encode$isValidChar(a) && $danfishgold$base64_bytes$Encode$isValidChar(b)) {
			var n2 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(b);
			var n1 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(a);
			if ('=' === d) {
				if ('=' === c) {
					var n = (n1 << 18) | (n2 << 12);
					var b1 = n >> 16;
					return $elm$core$Maybe$Just(
						$elm$bytes$Bytes$Encode$unsignedInt8(b1));
				} else {
					if ($danfishgold$base64_bytes$Encode$isValidChar(c)) {
						var n3 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(c);
						var n = ((n1 << 18) | (n2 << 12)) | (n3 << 6);
						var combined = n >> 8;
						return $elm$core$Maybe$Just(
							A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, combined));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}
			} else {
				if ($danfishgold$base64_bytes$Encode$isValidChar(c) && $danfishgold$base64_bytes$Encode$isValidChar(d)) {
					var n4 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(d);
					var n3 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(c);
					var n = ((n1 << 18) | (n2 << 12)) | ((n3 << 6) | n4);
					var combined = n >> 8;
					var b3 = n;
					return $elm$core$Maybe$Just(
						$elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, combined),
									$elm$bytes$Bytes$Encode$unsignedInt8(b3)
								])));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $danfishgold$base64_bytes$Encode$encodeChunks = F2(
	function (input, accum) {
		encodeChunks:
		while (true) {
			var _v0 = $elm$core$String$toList(
				A2($elm$core$String$left, 4, input));
			_v0$4:
			while (true) {
				if (!_v0.b) {
					return $elm$core$Maybe$Just(accum);
				} else {
					if (_v0.b.b) {
						if (_v0.b.b.b) {
							if (_v0.b.b.b.b) {
								if (!_v0.b.b.b.b.b) {
									var a = _v0.a;
									var _v1 = _v0.b;
									var b = _v1.a;
									var _v2 = _v1.b;
									var c = _v2.a;
									var _v3 = _v2.b;
									var d = _v3.a;
									var _v4 = A4($danfishgold$base64_bytes$Encode$encodeCharacters, a, b, c, d);
									if (!_v4.$) {
										var enc = _v4.a;
										var $temp$input = A2($elm$core$String$dropLeft, 4, input),
											$temp$accum = A2($elm$core$List$cons, enc, accum);
										input = $temp$input;
										accum = $temp$accum;
										continue encodeChunks;
									} else {
										return $elm$core$Maybe$Nothing;
									}
								} else {
									break _v0$4;
								}
							} else {
								var a = _v0.a;
								var _v5 = _v0.b;
								var b = _v5.a;
								var _v6 = _v5.b;
								var c = _v6.a;
								var _v7 = A4($danfishgold$base64_bytes$Encode$encodeCharacters, a, b, c, '=');
								if (_v7.$ === 1) {
									return $elm$core$Maybe$Nothing;
								} else {
									var enc = _v7.a;
									return $elm$core$Maybe$Just(
										A2($elm$core$List$cons, enc, accum));
								}
							}
						} else {
							var a = _v0.a;
							var _v8 = _v0.b;
							var b = _v8.a;
							var _v9 = A4($danfishgold$base64_bytes$Encode$encodeCharacters, a, b, '=', '=');
							if (_v9.$ === 1) {
								return $elm$core$Maybe$Nothing;
							} else {
								var enc = _v9.a;
								return $elm$core$Maybe$Just(
									A2($elm$core$List$cons, enc, accum));
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
			return $elm$core$Maybe$Nothing;
		}
	});
var $danfishgold$base64_bytes$Encode$encoder = function (string) {
	return A2(
		$elm$core$Maybe$map,
		A2($elm$core$Basics$composeR, $elm$core$List$reverse, $elm$bytes$Bytes$Encode$sequence),
		A2($danfishgold$base64_bytes$Encode$encodeChunks, string, _List_Nil));
};
var $danfishgold$base64_bytes$Encode$toBytes = function (string) {
	return A2(
		$elm$core$Maybe$map,
		$elm$bytes$Bytes$Encode$encode,
		$danfishgold$base64_bytes$Encode$encoder(string));
};
var $danfishgold$base64_bytes$Base64$toBytes = $danfishgold$base64_bytes$Encode$toBytes;
var $author$project$Main$importPageFromUrlMenu = function (pageString) {
	var mDecompressedPage = $elm$core$Result$toMaybe(
		A2(
			$miniBill$elm_codec$Codec$decodeString,
			$author$project$Main$pageCodec,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$andThen,
					function (bytes) {
						return A2(
							$elm$bytes$Bytes$Decode$decode,
							$elm$bytes$Bytes$Decode$string(
								$elm$bytes$Bytes$width(bytes)),
							bytes);
					},
					A2(
						$elm$core$Maybe$andThen,
						$folkertdev$elm_flate$Flate$inflateGZip,
						$danfishgold$base64_bytes$Base64$toBytes(pageString))))));
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(300)),
					$author$project$Style$backgroundColour(0),
					$mdgriffith$elm_ui$Element$Border$width(4)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$Font$bold]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('Import Page From URL')
						])),
					function () {
					if (!mDecompressedPage.$) {
						var page = mDecompressedPage.a;
						return A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Do you want to import \"' + (page.d + '\" to your pages?'))
								]));
					} else {
						return A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Could not decompress page in URL, \r\n                          make sure you have copied it correctly.\r\n                          ')
								]));
					}
				}(),
					A3(
					$author$project$Style$acceptOrCloseButtons,
					'Add Page',
					$author$project$Main$ClosePopUp,
					A2($elm$core$Maybe$map, $author$project$Main$AddPage, mDecompressedPage))
				])));
};
var $author$project$Main$date = '2024-02-13';
var $mdgriffith$elm_ui$Internal$Model$Heading = function (a) {
	return {$: 4, a: a};
};
var $mdgriffith$elm_ui$Element$Region$heading = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Describe, $mdgriffith$elm_ui$Internal$Model$Heading);
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $mdgriffith$elm_ui$Element$Font$italic = $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gk);
var $mdgriffith$elm_ui$Element$Font$underline = $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.hF);
var $author$project$Style$linkStyle = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Font$underline,
		$author$project$Style$fontColour(5)
	]);
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $mdgriffith$elm_ui$Element$newTabLink = F2(
	function (attrs, _v0) {
		var url = _v0.ap;
		var label = _v0.d;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$Attr(
							$elm$html$Html$Attributes$target('_blank')),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cd + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.aI + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.d5)))),
									attrs)))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Main$version = '0.5.3';
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $author$project$Main$infoPanel = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$centerX,
			$mdgriffith$elm_ui$Element$centerY,
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
		]),
	A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$centerX,
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$padding(20),
				$mdgriffith$elm_ui$Element$spacing(20),
				$author$project$Style$backgroundColour(0),
				$mdgriffith$elm_ui$Element$Border$width(4)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(20),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$scrollbarY,
						$mdgriffith$elm_ui$Element$Font$size(16)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								A2($mdgriffith$elm_ui$Element$paddingXY, 8, 18),
								$author$project$Style$backgroundColour(3),
								$mdgriffith$elm_ui$Element$Font$bold,
								$mdgriffith$elm_ui$Element$Font$size(48),
								$author$project$Style$fontColour(0),
								$mdgriffith$elm_ui$Element$Region$heading(2)
							]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('MIDI'),
								$mdgriffith$elm_ui$Element$text('Surf')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Ver. '),
								$mdgriffith$elm_ui$Element$text($author$project$Main$version),
								$mdgriffith$elm_ui$Element$text(' (' + ($author$project$Main$date + ', ')),
								A2(
								$mdgriffith$elm_ui$Element$newTabLink,
								$author$project$Style$linkStyle,
								{
									d: $mdgriffith$elm_ui$Element$text('source'),
									ap: 'https://github.com/mochreach/midi-surf'
								}),
								$mdgriffith$elm_ui$Element$text(')')
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$html(
								A2(
									$elm$html$Html$iframe,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$height(300),
											$elm$html$Html$Attributes$width(360),
											$elm$html$Html$Attributes$src('https://www.youtube.com/embed/a2TxqJtEovM'),
											$elm$html$Html$Attributes$title('YouTube video player'),
											A2($elm$html$Html$Attributes$attribute, 'frameborder', '0'),
											A2($elm$html$Html$Attributes$attribute, 'allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'),
											A2($elm$html$Html$Attributes$attribute, 'allowfullscreen', '')
										]),
									_List_Nil)))),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$bold]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('iOS')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('If you\'re having trouble running this on iOS, please check out this '),
								A2(
								$mdgriffith$elm_ui$Element$newTabLink,
								$author$project$Style$linkStyle,
								{
									d: $mdgriffith$elm_ui$Element$text('post'),
									ap: 'https://op-forums.com/t/midi-surf-a-free-customisable-web-based-midi-controller/23708/17'
								}),
								$mdgriffith$elm_ui$Element$text(' for instructions from a user on how to get it working.')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$bold]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Supporting Development')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Please consider supporting the development of this\r\n                    app if you enjoy using it, I\'d really appreciate it if you\r\n                    did! You can join our community on '),
								A2(
								$mdgriffith$elm_ui$Element$newTabLink,
								$author$project$Style$linkStyle,
								{
									d: $mdgriffith$elm_ui$Element$text('Patreon'),
									ap: 'https://patreon.com/mochreach'
								}),
								$mdgriffith$elm_ui$Element$text(' and help guide the direction the app (and future software\r\n                    from Mo Chreach!), or buy me a coffee/beer/cup of tea/sausage roll on\r\n                    '),
								A2(
								$mdgriffith$elm_ui$Element$newTabLink,
								$author$project$Style$linkStyle,
								{
									d: $mdgriffith$elm_ui$Element$text('Ko-Fi'),
									ap: 'https://ko-fi.com/mochreach'
								}),
								$mdgriffith$elm_ui$Element$text('. Check out my channel on '),
								A2(
								$mdgriffith$elm_ui$Element$newTabLink,
								$author$project$Style$linkStyle,
								{
									d: $mdgriffith$elm_ui$Element$text('YouTube'),
									ap: 'https://www.youtube.com/@mochreach'
								}),
								$mdgriffith$elm_ui$Element$text(' for more videos about MIDI Surf and other music\r\n                      technology topics.\r\n                      ')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$bold]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Version History')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$italic]),
								$mdgriffith$elm_ui$Element$text('The Theory Craft Update (2024-02-03): ')),
								$mdgriffith$elm_ui$Element$text('Quickly add customisable keyboard with either a scale or even\r\n                        a banks of chords!\r\n                        ')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$italic]),
								$mdgriffith$elm_ui$Element$text('The No Wasted Effort Update (2023-01-30): ')),
								$mdgriffith$elm_ui$Element$text(' Export your presets as a link and share them with anyone!\r\n                        Alternatively, you can export them to a file to keep them safe.\r\n                        ')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$italic]),
								$mdgriffith$elm_ui$Element$text('The Hands On Update (2023-01-22): ')),
								$mdgriffith$elm_ui$Element$text('More controller types such as XY faders and pitch bend.\r\n                        ')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$italic]),
								$mdgriffith$elm_ui$Element$text('The Initial Release (2023-01-06): ')),
								$mdgriffith$elm_ui$Element$text('Hurray, I\'ve launched the first version of the app! We have\r\n                        basic control types like notes, chords, CC values and faders.\r\n                        ')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$bold]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Analytics')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('By using this application, you agree to data on your usage being\r\n                        collected by Google Analytics. I collect the minimum amount of data\r\n                        possible so that I can improve the app, but if you\'re not keen on\r\n                        this, feel free to block it.\r\n                        ')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Copyright: '),
								A2(
								$mdgriffith$elm_ui$Element$newTabLink,
								$author$project$Style$linkStyle,
								{
									d: $mdgriffith$elm_ui$Element$text('Mo Chreach! Music Technology Ltd'),
									ap: 'https://mochreach.dev'
								})
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(5),
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$Border$width(2),
						$mdgriffith$elm_ui$Element$Border$solid,
						$author$project$Style$borderColour(3)
					]),
				{
					d: $mdgriffith$elm_ui$Element$text('Close'),
					af: $elm$core$Maybe$Just($author$project$Main$ClosePopUp)
				})
			])));
var $mdgriffith$elm_ui$Element$Font$center = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.hj);
var $elm$core$Basics$pi = _Basics_pi;
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $mdgriffith$elm_ui$Internal$Model$Rotate = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$rotate = $mdgriffith$elm_ui$Internal$Flag$flag(24);
var $mdgriffith$elm_ui$Element$rotate = function (angle) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$rotate,
		A2(
			$mdgriffith$elm_ui$Internal$Model$Rotate,
			_Utils_Tuple3(0, 0, 1),
			angle));
};
var $mdgriffith$elm_ui$Internal$Model$boxShadowClass = function (shadow) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				shadow.d0 ? 'box-inset' : 'box-',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.cn.a) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.cn.b) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.bo) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.cB) + 'px',
				$mdgriffith$elm_ui$Internal$Model$formatColorClass(shadow.bq)
			]));
};
var $mdgriffith$elm_ui$Internal$Flag$shadows = $mdgriffith$elm_ui$Internal$Flag$flag(19);
var $mdgriffith$elm_ui$Element$Border$shadow = function (almostShade) {
	var shade = {bo: almostShade.bo, bq: almostShade.bq, d0: false, cn: almostShade.cn, cB: almostShade.cB};
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$shadows,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			$mdgriffith$elm_ui$Internal$Model$boxShadowClass(shade),
			'box-shadow',
			$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(shade)));
};
var $mdgriffith$elm_ui$Element$transparent = function (on) {
	return on ? A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2($mdgriffith$elm_ui$Internal$Model$Transparency, 'transparent', 1.0)) : A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2($mdgriffith$elm_ui$Internal$Model$Transparency, 'visible', 0.0));
};
var $mdgriffith$elm_ui$Element$Input$defaultCheckbox = function (checked) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Internal$Model$htmlClass('focusable'),
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(14)),
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(14)),
				$mdgriffith$elm_ui$Element$Font$color($mdgriffith$elm_ui$Element$Input$white),
				$mdgriffith$elm_ui$Element$centerY,
				$mdgriffith$elm_ui$Element$Font$size(9),
				$mdgriffith$elm_ui$Element$Font$center,
				$mdgriffith$elm_ui$Element$Border$rounded(3),
				$mdgriffith$elm_ui$Element$Border$color(
				checked ? A3($mdgriffith$elm_ui$Element$rgb, 59 / 255, 153 / 255, 252 / 255) : A3($mdgriffith$elm_ui$Element$rgb, 211 / 255, 211 / 255, 211 / 255)),
				$mdgriffith$elm_ui$Element$Border$shadow(
				{
					bo: 1,
					bq: checked ? A4($mdgriffith$elm_ui$Element$rgba, 238 / 255, 238 / 255, 238 / 255, 0) : A3($mdgriffith$elm_ui$Element$rgb, 238 / 255, 238 / 255, 238 / 255),
					cn: _Utils_Tuple2(0, 0),
					cB: 1
				}),
				$mdgriffith$elm_ui$Element$Background$color(
				checked ? A3($mdgriffith$elm_ui$Element$rgb, 59 / 255, 153 / 255, 252 / 255) : $mdgriffith$elm_ui$Element$Input$white),
				$mdgriffith$elm_ui$Element$Border$width(
				checked ? 0 : 1),
				$mdgriffith$elm_ui$Element$inFront(
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$color($mdgriffith$elm_ui$Element$Input$white),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(6)),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(9)),
							$mdgriffith$elm_ui$Element$rotate(
							$elm$core$Basics$degrees(-45)),
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$moveUp(1),
							$mdgriffith$elm_ui$Element$transparent(!checked),
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{bG: 2, bK: 2, bV: 0, bZ: 0})
						]),
					$mdgriffith$elm_ui$Element$none))
			]),
		$mdgriffith$elm_ui$Element$none);
};
var $mdgriffith$elm_ui$Element$InternalColumn = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridPosition = function (a) {
	return {$: 9, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridTemplateStyle = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$asGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$getSpacing = F2(
	function (attrs, _default) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A3(
				$elm$core$List$foldr,
				F2(
					function (attr, acc) {
						if (!acc.$) {
							var x = acc.a;
							return $elm$core$Maybe$Just(x);
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v2 = attr.b;
								var x = _v2.b;
								var y = _v2.c;
								return $elm$core$Maybe$Just(
									_Utils_Tuple2(x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}),
				$elm$core$Maybe$Nothing,
				attrs));
	});
var $mdgriffith$elm_ui$Internal$Flag$gridPosition = $mdgriffith$elm_ui$Internal$Flag$flag(35);
var $mdgriffith$elm_ui$Internal$Flag$gridTemplate = $mdgriffith$elm_ui$Internal$Flag$flag(34);
var $mdgriffith$elm_ui$Element$tableHelper = F2(
	function (attrs, config) {
		var onGrid = F3(
			function (rowLevel, columnLevel, elem) {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$StyleClass,
							$mdgriffith$elm_ui$Internal$Flag$gridPosition,
							$mdgriffith$elm_ui$Internal$Model$GridPosition(
								{fx: columnLevel, av: 1, am: rowLevel, ab: 1}))
						]),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[elem])));
			});
		var columnWidth = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.ab;
			} else {
				var colConfig = col.a;
				return colConfig.ab;
			}
		};
		var columnHeader = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.c4;
			} else {
				var colConfig = col.a;
				return colConfig.c4;
			}
		};
		var maybeHeaders = function (headers) {
			return A2(
				$elm$core$List$all,
				$elm$core$Basics$eq($mdgriffith$elm_ui$Internal$Model$Empty),
				headers) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (col, header) {
							return A3(onGrid, 1, col + 1, header);
						}),
					headers));
		}(
			A2($elm$core$List$map, columnHeader, config.fy));
		var add = F3(
			function (cell, columnConfig, cursor) {
				if (!columnConfig.$) {
					var col = columnConfig.a;
					return _Utils_update(
						cursor,
						{
							aG: cursor.aG + 1,
							as: A2(
								$elm$core$List$cons,
								A3(
									onGrid,
									cursor.am,
									cursor.aG,
									A2(
										col.cL,
										_Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? (cursor.am - 1) : (cursor.am - 2),
										cell)),
								cursor.as)
						});
				} else {
					var col = columnConfig.a;
					return {
						aG: cursor.aG + 1,
						as: A2(
							$elm$core$List$cons,
							A3(
								onGrid,
								cursor.am,
								cursor.aG,
								col.cL(cell)),
							cursor.as),
						am: cursor.am
					};
				}
			});
		var build = F3(
			function (columns, rowData, cursor) {
				var newCursor = A3(
					$elm$core$List$foldl,
					add(rowData),
					cursor,
					columns);
				return {aG: 1, as: newCursor.as, am: cursor.am + 1};
			});
		var children = A3(
			$elm$core$List$foldl,
			build(config.fy),
			{
				aG: 1,
				as: _List_Nil,
				am: _Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? 1 : 2
			},
			config.fG);
		var _v0 = A2(
			$mdgriffith$elm_ui$Internal$Model$getSpacing,
			attrs,
			_Utils_Tuple2(0, 0));
		var sX = _v0.a;
		var sY = _v0.b;
		var template = A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$gridTemplate,
			$mdgriffith$elm_ui$Internal$Model$GridTemplateStyle(
				{
					fy: A2($elm$core$List$map, columnWidth, config.fy),
					gW: A2(
						$elm$core$List$repeat,
						$elm$core$List$length(config.fG),
						$mdgriffith$elm_ui$Internal$Model$Content),
					g9: _Utils_Tuple2(
						$mdgriffith$elm_ui$Element$px(sX),
						$mdgriffith$elm_ui$Element$px(sY))
				}));
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asGrid,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($elm$core$List$cons, template, attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				function () {
					if (maybeHeaders.$ === 1) {
						return children.as;
					} else {
						var renderedHeaders = maybeHeaders.a;
						return _Utils_ap(
							renderedHeaders,
							$elm$core$List$reverse(children.as));
					}
				}()));
	});
var $mdgriffith$elm_ui$Element$table = F2(
	function (attrs, config) {
		return A2(
			$mdgriffith$elm_ui$Element$tableHelper,
			attrs,
			{
				fy: A2($elm$core$List$map, $mdgriffith$elm_ui$Element$InternalColumn, config.fy),
				fG: config.fG
			});
	});
var $author$project$Main$deviceTable = function (devices) {
	return A2(
		$mdgriffith$elm_ui$Element$table,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(10),
				$mdgriffith$elm_ui$Element$spacing(10)
			]),
		{
			fy: _List_fromArray(
				[
					{
					c4: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$underline]),
						$mdgriffith$elm_ui$Element$text('Name')),
					cL: function (_v0) {
						var name = _v0.ed;
						return $mdgriffith$elm_ui$Element$text(name);
					},
					ab: $mdgriffith$elm_ui$Element$fill
				},
					{
					c4: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$underline]),
						$mdgriffith$elm_ui$Element$text('Input')),
					cL: function (_v1) {
						var input = _v1.gd;
						return A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							function () {
								if ((!input.$) && input.a) {
									return A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX]),
										$mdgriffith$elm_ui$Element$Input$defaultCheckbox(true));
								} else {
									return A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX]),
										$mdgriffith$elm_ui$Element$Input$defaultCheckbox(false));
								}
							}());
					},
					ab: $mdgriffith$elm_ui$Element$fill
				},
					{
					c4: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$underline]),
						$mdgriffith$elm_ui$Element$text('Output')),
					cL: function (_v3) {
						var output = _v3.gJ;
						return A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							function () {
								if ((!output.$) && output.a) {
									return A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX]),
										$mdgriffith$elm_ui$Element$Input$defaultCheckbox(true));
								} else {
									return A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX]),
										$mdgriffith$elm_ui$Element$Input$defaultCheckbox(false));
								}
							}());
					},
					ab: $mdgriffith$elm_ui$Element$fill
				}
				]),
			fG: devices
		});
};
var $author$project$Main$midiMenu = function (devices) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$spacing(10),
					$author$project$Style$backgroundColour(0),
					$mdgriffith$elm_ui$Element$Border$width(4)
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$Font$bold]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('MIDI Devices')
						])),
				_Utils_ap(
					function () {
						if (!devices.b) {
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('No MIDI devices connected.')
										]))
								]);
						} else {
							return _List_fromArray(
								[
									$author$project$Main$deviceTable(devices)
								]);
						}
					}(),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(5),
									$mdgriffith$elm_ui$Element$Border$width(2),
									$mdgriffith$elm_ui$Element$Border$solid,
									$author$project$Style$borderColour(3)
								]),
							{
								d: $mdgriffith$elm_ui$Element$text('Cancel'),
								af: $elm$core$Maybe$Just($author$project$Main$ClosePopUp)
							})
						])))));
};
var $author$project$Main$DeleteSavedPage = function (a) {
	return {$: 17, a: a};
};
var $author$project$Main$ImportPage = 2;
var $author$project$Main$ImportPageRequested = {$: 18};
var $author$project$Main$LoadPage = 1;
var $author$project$Midi$changeChannel = F2(
	function (newChannel, midiMsg) {
		switch (midiMsg.$) {
			case 0:
				var params = midiMsg.a;
				return $author$project$Midi$NoteOn(
					_Utils_update(
						params,
						{f: newChannel}));
			case 1:
				var params = midiMsg.a;
				return $author$project$Midi$NoteOff(
					_Utils_update(
						params,
						{f: newChannel}));
			case 2:
				var params = midiMsg.a;
				return $author$project$Midi$KeyPressure(
					_Utils_update(
						params,
						{f: newChannel}));
			case 3:
				var params = midiMsg.a;
				return $author$project$Midi$ControllerChange(
					_Utils_update(
						params,
						{f: newChannel}));
			case 4:
				var params = midiMsg.a;
				return $author$project$Midi$ProgramChange(
					_Utils_update(
						params,
						{f: newChannel}));
			case 5:
				var params = midiMsg.a;
				return $author$project$Midi$ChannelPressure(
					_Utils_update(
						params,
						{f: newChannel}));
			case 6:
				var params = midiMsg.a;
				return $author$project$Midi$PitchBend(
					_Utils_update(
						params,
						{f: newChannel}));
			case 7:
				var params = midiMsg.a;
				return $author$project$Midi$SystemExclusive(params);
			case 8:
				var params = midiMsg.a;
				return $author$project$Midi$SongPosition(params);
			case 9:
				var params = midiMsg.a;
				return $author$project$Midi$SongSelect(params);
			case 10:
				var params = midiMsg.a;
				return $author$project$Midi$UnofficialBusSelect(params);
			case 11:
				return $author$project$Midi$TuneRequest;
			case 12:
				return $author$project$Midi$EndOfSysEx;
			case 13:
				return $author$project$Midi$TimingTick;
			case 14:
				return $author$project$Midi$StartSong;
			case 15:
				return $author$project$Midi$ContinueSong;
			case 16:
				return $author$project$Midi$StopSong;
			case 17:
				return $author$project$Midi$ActiveSensing;
			case 18:
				return $author$project$Midi$SystemReset;
			default:
				var data = midiMsg.a;
				return $author$project$Midi$Unknown(data);
		}
	});
var $author$project$Controller$setChannel = F2(
	function (channel, controller) {
		switch (controller.$) {
			case 0:
				var label = controller.a;
				var subControl = controller.b;
				return A2(
					$author$project$Controller$Module,
					label,
					A2($author$project$Controller$setChannel, channel, subControl));
			case 1:
				var subcontrols = controller.a;
				return $author$project$Controller$Row(
					A2(
						$elm$core$List$map,
						$author$project$Controller$setChannel(channel),
						subcontrols));
			case 2:
				var subcontrols = controller.a;
				return $author$project$Controller$Column(
					A2(
						$elm$core$List$map,
						$author$project$Controller$setChannel(channel),
						subcontrols));
			case 3:
				var subcontrols = controller.a;
				return $author$project$Controller$SizedRow(
					A2(
						$elm$core$List$map,
						function (_v1) {
							var s = _v1.a;
							var c = _v1.b;
							return _Utils_Tuple2(
								s,
								A2($author$project$Controller$setChannel, channel, c));
						},
						subcontrols));
			case 4:
				var subcontrols = controller.a;
				return $author$project$Controller$SizedColumn(
					A2(
						$elm$core$List$map,
						function (_v2) {
							var s = _v2.a;
							var c = _v2.b;
							return _Utils_Tuple2(
								s,
								A2($author$project$Controller$setChannel, channel, c));
						},
						subcontrols));
			case 5:
				var state = controller.a;
				return $author$project$Controller$Note(
					_Utils_update(
						state,
						{f: channel}));
			case 6:
				var state = controller.a;
				return $author$project$Controller$Chord(
					_Utils_update(
						state,
						{
							aR: A2(
								$elm$core$List$map,
								function (n) {
									return {f: channel, y: n.y};
								},
								state.aR)
						}));
			case 7:
				var state = controller.a;
				return $author$project$Controller$CCValue(
					_Utils_update(
						state,
						{f: channel}));
			case 8:
				var state = controller.a;
				return $author$project$Controller$Command(
					_Utils_update(
						state,
						{
							a8: A2(
								$elm$core$List$map,
								$author$project$Midi$changeChannel(
									$author$project$Midi$channelToMidiNumber(channel)),
								state.a8),
							a9: A2(
								$elm$core$List$map,
								$author$project$Midi$changeChannel(
									$author$project$Midi$channelToMidiNumber(channel)),
								state.a9)
						}));
			case 9:
				var state = controller.a;
				return $author$project$Controller$Sequence(
					_Utils_update(
						state,
						{
							aP: A2(
								$elm$core$Array$map,
								$author$project$Midi$changeChannel(
									$author$project$Midi$channelToMidiNumber(channel)),
								state.aP)
						}));
			case 10:
				var state = controller.a;
				return $author$project$Controller$Fader(
					_Utils_update(
						state,
						{f: channel}));
			case 11:
				var state = controller.a;
				return $author$project$Controller$XYFader(
					_Utils_update(
						state,
						{bH: channel, bI: channel}));
			case 12:
				var state = controller.a;
				return $author$project$Controller$PitchBend(
					_Utils_update(
						state,
						{f: channel}));
			case 13:
				return $author$project$Controller$MidiLog;
			default:
				return $author$project$Controller$Space;
		}
	});
var $author$project$Main$newPageMenu = F2(
	function (savedPages, state) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$spacing(10),
						$author$project$Style$backgroundColour(0),
						$mdgriffith$elm_ui$Element$Border$width(4)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$bold]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Pages')
							])),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						A2(
							$mdgriffith$elm_ui$Element$Input$radioRow,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(10)
								]),
							{
								d: $mdgriffith$elm_ui$Element$Input$labelHidden('Mode'),
								ae: function (newMode) {
									return $author$project$Main$UpdatePageMenuState(
										_Utils_update(
											state,
											{o: newMode}));
								},
								aT: _List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$Input$option,
										0,
										$mdgriffith$elm_ui$Element$text('New')),
										A2(
										$mdgriffith$elm_ui$Element$Input$option,
										1,
										$mdgriffith$elm_ui$Element$text('Load')),
										A2(
										$mdgriffith$elm_ui$Element$Input$option,
										2,
										$mdgriffith$elm_ui$Element$text('Import'))
									]),
								aX: $elm$core$Maybe$Just(state.o)
							})),
						function () {
						var _v0 = state.o;
						switch (_v0) {
							case 0:
								return A2(
									$mdgriffith$elm_ui$Element$Input$text,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Border$width(2),
											$mdgriffith$elm_ui$Element$Border$rounded(0),
											$author$project$Style$borderColour(3)
										]),
									{
										d: A2(
											$mdgriffith$elm_ui$Element$Input$labelAbove,
											_List_Nil,
											$mdgriffith$elm_ui$Element$text('Page Label')),
										ae: function (newLabel) {
											return $author$project$Main$UpdatePageMenuState(
												_Utils_update(
													state,
													{d: newLabel}));
										},
										e: $elm$core$Maybe$Just(
											A2(
												$mdgriffith$elm_ui$Element$Input$placeholder,
												_List_Nil,
												$mdgriffith$elm_ui$Element$text('page label'))),
										dn: state.d
									});
							case 1:
								return A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(10)
										]),
									_List_fromArray(
										[
											function () {
											var _v1 = state.ad;
											if (!_v1.$) {
												var index = _v1.a;
												return A2(
													$mdgriffith$elm_ui$Element$Input$button,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$padding(5),
															$mdgriffith$elm_ui$Element$Border$width(2),
															$mdgriffith$elm_ui$Element$Border$solid,
															$author$project$Style$backgroundColour(7),
															$author$project$Style$fontColour(0),
															$author$project$Style$borderColour(3)
														]),
													{
														d: $mdgriffith$elm_ui$Element$text('Delete'),
														af: A2(
															$elm$core$Maybe$map,
															function (_v2) {
																var key = _v2.a;
																return $author$project$Main$DeleteSavedPage(key);
															},
															A2(
																$elm$core$Array$get,
																index,
																$elm$core$Array$fromList(
																	$elm$core$Dict$toList(savedPages))))
													});
											} else {
												return $mdgriffith$elm_ui$Element$none;
											}
										}(),
											A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$height(
													$mdgriffith$elm_ui$Element$px(200)),
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$scrollbarY,
													$mdgriffith$elm_ui$Element$Border$width(2),
													$mdgriffith$elm_ui$Element$Border$dashed
												]),
											A2(
												$elm$core$List$indexedMap,
												A2(
													$author$project$Style$selectableOption,
													function (newSelected) {
														return $author$project$Main$UpdatePageMenuState(
															_Utils_update(
																state,
																{
																	ad: $elm$core$Maybe$Just(newSelected)
																}));
													},
													state.ad),
												$elm$core$Dict$keys(savedPages))),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											A2(
												$mdgriffith$elm_ui$Element$Input$text,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Border$width(2),
														$mdgriffith$elm_ui$Element$Border$rounded(0),
														$author$project$Style$borderColour(3),
														$mdgriffith$elm_ui$Element$htmlAttribute(
														$elm$html$Html$Attributes$type_('number'))
													]),
												{
													d: A2(
														$mdgriffith$elm_ui$Element$Input$labelAbove,
														_List_Nil,
														$mdgriffith$elm_ui$Element$text('Set Channel (Optional)')),
													ae: function (newChannel) {
														return $author$project$Main$UpdatePageMenuState(
															_Utils_update(
																state,
																{
																	bp: $elm$core$String$isEmpty(newChannel) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(newChannel)
																}));
													},
													e: $elm$core$Maybe$Just(
														A2(
															$mdgriffith$elm_ui$Element$Input$placeholder,
															_List_Nil,
															$mdgriffith$elm_ui$Element$text('set channel'))),
													dn: A2($elm$core$Maybe$withDefault, '', state.bp)
												}))
										]));
							default:
								return A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(10)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$Input$button,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$padding(5),
													$mdgriffith$elm_ui$Element$Border$width(2),
													$mdgriffith$elm_ui$Element$Border$solid
												]),
											{
												d: $mdgriffith$elm_ui$Element$text('Import Page'),
												af: $elm$core$Maybe$Just($author$project$Main$ImportPageRequested)
											}),
											function () {
											var _v3 = _Utils_Tuple2(state.a7, state.bs);
											_v3$2:
											while (true) {
												if (!_v3.a.$) {
													if (_v3.b.$ === 1) {
														var _v4 = _v3.b;
														return A2(
															$mdgriffith$elm_ui$Element$paragraph,
															_List_Nil,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$text('Import Sucessful')
																]));
													} else {
														break _v3$2;
													}
												} else {
													if (!_v3.b.$) {
														var _v5 = _v3.a;
														var error = _v3.b.a;
														return A2(
															$mdgriffith$elm_ui$Element$paragraph,
															_List_Nil,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$text('Failed to import: ' + error)
																]));
													} else {
														break _v3$2;
													}
												}
											}
											return $mdgriffith$elm_ui$Element$none;
										}()
										]));
						}
					}(),
						A3(
						$author$project$Style$acceptOrCloseButtons,
						'Add Page',
						$author$project$Main$ClosePopUp,
						function () {
							var _v6 = state.o;
							switch (_v6) {
								case 0:
									return $elm$core$String$isEmpty(state.d) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
										$author$project$Main$AddPage(
											{
												aH: {dK: false, m: 2},
												ar: $author$project$Controller$Space,
												d: state.d
											}));
								case 1:
									return A2(
										$elm$core$Maybe$andThen,
										function (i) {
											return A2(
												$elm$core$Maybe$map,
												function (p) {
													var _v7 = A2($elm$core$Maybe$andThen, $author$project$Midi$stringToChannel, state.bp);
													if (!_v7.$) {
														var ch = _v7.a;
														return $author$project$Main$AddPage(
															_Utils_update(
																p,
																{
																	ar: A2($author$project$Controller$setChannel, ch, p.ar)
																}));
													} else {
														return $author$project$Main$AddPage(p);
													}
												},
												A2(
													$elm$core$Array$get,
													i,
													$elm$core$Array$fromList(
														$elm$core$Dict$values(savedPages))));
										},
										state.ad);
								default:
									return A2(
										$elm$core$Maybe$map,
										function (p) {
											return $author$project$Main$AddPage(p);
										},
										state.a7);
							}
						}())
					])));
	});
var $mdgriffith$elm_ui$Internal$Model$AsTextColumn = 5;
var $mdgriffith$elm_ui$Internal$Model$asTextColumn = 5;
var $mdgriffith$elm_ui$Element$textColumn = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asTextColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width(
					A2(
						$mdgriffith$elm_ui$Element$maximum,
						750,
						A2($mdgriffith$elm_ui$Element$minimum, 500, $mdgriffith$elm_ui$Element$fill))),
				attrs),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $author$project$Main$noWebMidiPanel = function (_v0) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$padding(20),
					$mdgriffith$elm_ui$Element$spacing(20),
					$author$project$Style$backgroundColour(0),
					$mdgriffith$elm_ui$Element$Border$width(4)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$textColumn,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(20),
							$mdgriffith$elm_ui$Element$Font$size(16),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(300))
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(24),
									$mdgriffith$elm_ui$Element$Font$bold
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Web MIDI API is not available!\r\n                        ')
								])),
							A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('I can\'t access the Web MIDI API on your browser. You\r\n                        could try refreshing your page, but this is probably\r\n                        happening because either you need to allow access (there\r\n                        should have had a popup) or your browser does not\r\n                        support the Web MIDI API. Please check the following\r\n                        website to determine if your browser is compatible:\r\n                        '),
									A2(
									$mdgriffith$elm_ui$Element$newTabLink,
									$author$project$Style$linkStyle,
									{
										d: $mdgriffith$elm_ui$Element$text('https://caniuse.com/midi'),
										ap: 'https://caniuse.com/midi'
									})
								])),
							A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(5),
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$Border$width(2),
									$mdgriffith$elm_ui$Element$Border$solid,
									$author$project$Style$borderColour(3)
								]),
							{
								d: $mdgriffith$elm_ui$Element$text('Close'),
								af: $elm$core$Maybe$Just($author$project$Main$ClosePopUp)
							})
						]))
				])));
};
var $author$project$Main$ExportSelectedPage = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$SaveModule = 1;
var $author$project$Main$SaveSelectedModule = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$SaveSelectedPage = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$UpdateSaveMenuState = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$saveMenu = function (state) {
	var pages = state.b;
	var mSelectedPage = state.ad;
	var modules = state.db;
	var mSelectedModule = state.cj;
	var mode = state.o;
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$spacing(10),
					$author$project$Style$backgroundColour(0),
					$mdgriffith$elm_ui$Element$Border$width(4)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$Font$bold]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('Save')
						])),
					A2(
					$mdgriffith$elm_ui$Element$Input$radioRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					{
						d: $mdgriffith$elm_ui$Element$Input$labelHidden('Save Mode'),
						ae: function (newMode) {
							return $author$project$Main$UpdateSaveMenuState(
								_Utils_update(
									state,
									{o: newMode}));
						},
						aT: _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$Input$option,
								0,
								$mdgriffith$elm_ui$Element$text('Page')),
								A2(
								$mdgriffith$elm_ui$Element$Input$option,
								1,
								$mdgriffith$elm_ui$Element$text('Module'))
							]),
						aX: $elm$core$Maybe$Just(mode)
					}),
					function () {
					if (!mode) {
						return A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(5),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$height(
											$mdgriffith$elm_ui$Element$px(200)),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$scrollbarY,
											$mdgriffith$elm_ui$Element$Border$width(2),
											$mdgriffith$elm_ui$Element$Border$dashed
										]),
									A2(
										$elm$core$List$indexedMap,
										A2(
											$author$project$Style$selectableOption,
											function (newSelected) {
												return $author$project$Main$UpdateSaveMenuState(
													_Utils_update(
														state,
														{
															ad: $elm$core$Maybe$Just(newSelected)
														}));
											},
											mSelectedPage),
										A2(
											$elm$core$List$indexedMap,
											F2(
												function (i, l) {
													return $elm$core$String$fromInt(i) + (': ' + l);
												}),
											$elm$core$Array$toList(
												A2(
													$elm$core$Array$map,
													function ($) {
														return $.d;
													},
													pages))))),
									function () {
									if (!mSelectedPage.$) {
										var index = mSelectedPage.a;
										return A2(
											$mdgriffith$elm_ui$Element$Input$button,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$padding(5),
													$mdgriffith$elm_ui$Element$Border$width(2),
													$mdgriffith$elm_ui$Element$Border$solid
												]),
											{
												d: $mdgriffith$elm_ui$Element$text('Export to File'),
												af: A2(
													$elm$core$Maybe$map,
													function (p) {
														return $author$project$Main$ExportSelectedPage(p);
													},
													A2($elm$core$Array$get, index, pages))
											});
									} else {
										return $mdgriffith$elm_ui$Element$none;
									}
								}()
								]));
					} else {
						return A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(5),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$height(
											$mdgriffith$elm_ui$Element$px(200)),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$scrollbarY,
											$mdgriffith$elm_ui$Element$Border$width(2),
											$mdgriffith$elm_ui$Element$Border$dashed
										]),
									A2(
										$elm$core$List$indexedMap,
										A2(
											$author$project$Style$selectableOption,
											function (newSelected) {
												return $author$project$Main$UpdateSaveMenuState(
													_Utils_update(
														state,
														{
															cj: $elm$core$Maybe$Just(newSelected)
														}));
											},
											mSelectedModule),
										A2($elm$core$List$map, $author$project$Controller$controllerToString, modules)))
								]));
					}
				}(),
					A3(
					$author$project$Style$acceptOrCloseButtons,
					'Save',
					$author$project$Main$ClosePopUp,
					function () {
						var _v2 = _Utils_Tuple3(mode, mSelectedPage, mSelectedModule);
						_v2$2:
						while (true) {
							if (!_v2.a) {
								if (!_v2.b.$) {
									var _v3 = _v2.a;
									var index = _v2.b.a;
									return A2(
										$elm$core$Maybe$map,
										function (p) {
											return $author$project$Main$SaveSelectedPage(p);
										},
										A2($elm$core$Array$get, index, pages));
								} else {
									break _v2$2;
								}
							} else {
								if (!_v2.c.$) {
									var _v4 = _v2.a;
									var index = _v2.c.a;
									return A2(
										$elm$core$Maybe$map,
										function (p) {
											return $author$project$Main$SaveSelectedModule(p);
										},
										A2(
											$elm$core$Array$get,
											index,
											$elm$core$Array$fromList(modules)));
								} else {
									break _v2$2;
								}
							}
						}
						return $elm$core$Maybe$Nothing;
					}())
				])));
};
var $mdgriffith$elm_ui$Element$scrollbars = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.gY);
var $author$project$Main$CopyToClipboard = function (a) {
	return {$: 38, a: a};
};
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $elm$svg$Svg$Attributes$rx = _VirtualDom_attribute('rx');
var $elm$svg$Svg$Attributes$ry = _VirtualDom_attribute('ry');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $feathericons$elm_feather$FeatherIcons$copy = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'copy',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$rect,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x('9'),
					$elm$svg$Svg$Attributes$y('9'),
					$elm$svg$Svg$Attributes$width('13'),
					$elm$svg$Svg$Attributes$height('13'),
					$elm$svg$Svg$Attributes$rx('2'),
					$elm$svg$Svg$Attributes$ry('2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1')
				]),
			_List_Nil)
		]));
var $folkertdev$elm_flate$Flate$Dynamic = function (a) {
	return {$: 1, a: a};
};
var $folkertdev$elm_flate$Flate$WithWindowSize = function (a) {
	return {$: 1, a: a};
};
var $folkertdev$elm_flate$Deflate$Internal$chunksHelp = F2(
	function (chunkSize, _v0) {
		var sizeRemaining = _v0.a;
		var accum = _v0.b;
		return (!sizeRemaining) ? $elm$bytes$Bytes$Decode$succeed(
			$elm$bytes$Bytes$Decode$Done(_List_Nil)) : ((_Utils_cmp(chunkSize, sizeRemaining) > -1) ? A2(
			$elm$bytes$Bytes$Decode$map,
			function (_new) {
				return $elm$bytes$Bytes$Decode$Done(
					$elm$core$List$reverse(
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(true, _new),
							accum)));
			},
			$elm$bytes$Bytes$Decode$bytes(sizeRemaining)) : A2(
			$elm$bytes$Bytes$Decode$map,
			function (_new) {
				return $elm$bytes$Bytes$Decode$Loop(
					_Utils_Tuple2(
						sizeRemaining - chunkSize,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(false, _new),
							accum)));
			},
			$elm$bytes$Bytes$Decode$bytes(chunkSize)));
	});
var $folkertdev$elm_flate$Deflate$Internal$chunks = F2(
	function (chunkSize, buffer) {
		var _v0 = A2(
			$elm$bytes$Bytes$Decode$decode,
			A2(
				$elm$bytes$Bytes$Decode$loop,
				_Utils_Tuple2(
					$elm$bytes$Bytes$width(buffer),
					_List_Nil),
				$folkertdev$elm_flate$Deflate$Internal$chunksHelp(chunkSize)),
			buffer);
		if (_v0.$ === 1) {
			return _List_fromArray(
				[
					_Utils_Tuple2(
					true,
					$elm$bytes$Bytes$Encode$encode(
						$elm$bytes$Bytes$Encode$sequence(_List_Nil)))
				]);
		} else {
			if (!_v0.a.b) {
				return _List_fromArray(
					[
						_Utils_Tuple2(
						true,
						$elm$bytes$Bytes$Encode$encode(
							$elm$bytes$Bytes$Encode$sequence(_List_Nil)))
					]);
			} else {
				var value = _v0.a;
				return value;
			}
		}
	});
var $folkertdev$elm_flate$Deflate$Internal$default_block_size = 1024 * 1024;
var $folkertdev$elm_flate$Deflate$BitWriter$empty = {ah: 0, ai: _List_Nil, dm: 0};
var $folkertdev$elm_flate$Deflate$Symbol$code = function (symbol) {
	switch (symbol.$) {
		case 1:
			var _byte = symbol.a;
			return _byte;
		case 0:
			return 256;
		default:
			var length = symbol.a;
			return ((length >= 3) && (length <= 10)) ? ((257 + length) - 3) : (((length >= 11) && (length <= 18)) ? (265 + (((length - 11) / 2) | 0)) : (((length >= 19) && (length <= 34)) ? (269 + (((length - 19) / 4) | 0)) : (((length >= 35) && (length <= 66)) ? (273 + (((length - 35) / 8) | 0)) : (((length >= 67) && (length <= 130)) ? (277 + (((length - 67) / 16) | 0)) : (((length >= 131) && (length <= 257)) ? (281 + (((length - 131) / 32) | 0)) : ((length === 258) ? 285 : (-1)))))));
	}
};
var $folkertdev$elm_flate$Deflate$Symbol$distance = function (symbol) {
	if (symbol.$ === 2) {
		var distance_ = symbol.b;
		if (distance_ <= 4) {
			return $elm$core$Maybe$Just(
				_Utils_Tuple3(distance_ - 1, 0, 0));
		} else {
			var go = F3(
				function (extraBits, code_, base) {
					go:
					while (true) {
						if (_Utils_cmp(base * 2, distance_) < 0) {
							var $temp$extraBits = extraBits + 1,
								$temp$code_ = code_ + 2,
								$temp$base = base * 2;
							extraBits = $temp$extraBits;
							code_ = $temp$code_;
							base = $temp$base;
							continue go;
						} else {
							return _Utils_Tuple3(extraBits, code_, base);
						}
					}
				});
			var _v1 = A3(go, 1, 4, 4);
			var extraBits = _v1.a;
			var code_ = _v1.b;
			var base = _v1.c;
			var delta = (distance_ - base) - 1;
			var half = (base / 2) | 0;
			return (_Utils_cmp(distance_, base + half) < 1) ? $elm$core$Maybe$Just(
				_Utils_Tuple3(
					code_,
					extraBits,
					A2($elm$core$Basics$modBy, half, delta))) : $elm$core$Maybe$Just(
				_Utils_Tuple3(
					code_ + 1,
					extraBits,
					A2($elm$core$Basics$modBy, half, delta)));
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $folkertdev$elm_flate$Deflate$Symbol$update = F3(
	function (index, tagger, array) {
		var _v0 = A2($elm$core$Array$get, index, array);
		if (_v0.$ === 1) {
			return array;
		} else {
			var value = _v0.a;
			return A3(
				$elm$core$Array$set,
				index,
				tagger(value),
				array);
		}
	});
var $folkertdev$elm_flate$Deflate$Symbol$dynamicFindFrequencies = F2(
	function (symbol, _v0) {
		var literalCounts = _v0.a;
		var distanceCounts = _v0.b;
		var emptyDistanceCount = _v0.c;
		var _v1 = $folkertdev$elm_flate$Deflate$Symbol$distance(symbol);
		if (_v1.$ === 1) {
			return _Utils_Tuple3(
				A3(
					$folkertdev$elm_flate$Deflate$Symbol$update,
					$folkertdev$elm_flate$Deflate$Symbol$code(symbol),
					function (v) {
						return v + 1;
					},
					literalCounts),
				distanceCounts,
				emptyDistanceCount);
		} else {
			var _v2 = _v1.a;
			var d = _v2.a;
			return _Utils_Tuple3(
				A3(
					$folkertdev$elm_flate$Deflate$Symbol$update,
					$folkertdev$elm_flate$Deflate$Symbol$code(symbol),
					function (v) {
						return v + 1;
					},
					literalCounts),
				A3(
					$folkertdev$elm_flate$Deflate$Symbol$update,
					d,
					function (v) {
						return v + 1;
					},
					distanceCounts),
				false);
		}
	});
var $elm$core$List$sortWith = _List_sortWith;
var $folkertdev$elm_flate$Huffman$calcOptimalMaxBitWidth = function (frequencies) {
	var heapModificationLoop = function (heap) {
		heapModificationLoop:
		while (true) {
			if (!heap.b) {
				return 0;
			} else {
				if (!heap.b.b) {
					var _v1 = heap.a;
					var value = _v1.b;
					return A2($elm$core$Basics$max, 1, value);
				} else {
					var _v2 = heap.a;
					var weight1 = _v2.a;
					var width1 = _v2.b;
					var _v3 = heap.b;
					var _v4 = _v3.a;
					var weight2 = _v4.a;
					var width2 = _v4.b;
					var rest = _v3.b;
					var $temp$heap = A2(
						$elm$core$List$sortWith,
						F2(
							function (a, b) {
								return A2($elm$core$Basics$compare, b, a);
							}),
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								weight1 + weight2,
								1 + A2($elm$core$Basics$max, width1, width2)),
							rest));
					heap = $temp$heap;
					continue heapModificationLoop;
				}
			}
		}
	};
	var createHeapFolder = F2(
		function (freq, heap) {
			return (freq > 0) ? A2(
				$elm$core$List$cons,
				_Utils_Tuple2(-freq, 0),
				heap) : heap;
		});
	var createHeap = A3($elm$core$Array$foldl, createHeapFolder, _List_Nil, frequencies);
	return heapModificationLoop(createHeap);
};
var $folkertdev$elm_flate$LengthLimitedHuffmanCodes$mergeLoop = F3(
	function (xarr, yarr, accum) {
		mergeLoop:
		while (true) {
			var _v0 = _Utils_Tuple2(xarr, yarr);
			if (!_v0.a.b) {
				return A2(
					$elm$core$Array$append,
					accum,
					$elm$core$Array$fromList(yarr));
			} else {
				if (!_v0.b.b) {
					return A2(
						$elm$core$Array$append,
						accum,
						$elm$core$Array$fromList(xarr));
				} else {
					var _v1 = _v0.a;
					var x = _v1.a;
					var xrest = _v1.b;
					var _v2 = _v0.b;
					var y = _v2.a;
					var yrest = _v2.b;
					if (_Utils_cmp(x.aq, y.aq) < 0) {
						var $temp$xarr = xrest,
							$temp$yarr = yarr,
							$temp$accum = A2($elm$core$Array$push, x, accum);
						xarr = $temp$xarr;
						yarr = $temp$yarr;
						accum = $temp$accum;
						continue mergeLoop;
					} else {
						var $temp$xarr = xarr,
							$temp$yarr = yrest,
							$temp$accum = A2($elm$core$Array$push, y, accum);
						xarr = $temp$xarr;
						yarr = $temp$yarr;
						accum = $temp$accum;
						continue mergeLoop;
					}
				}
			}
		}
	});
var $folkertdev$elm_flate$LengthLimitedHuffmanCodes$merge = F2(
	function (x, y) {
		return A3(
			$folkertdev$elm_flate$LengthLimitedHuffmanCodes$mergeLoop,
			$elm$core$Array$toList(x),
			$elm$core$Array$toList(y),
			$elm$core$Array$empty);
	});
var $folkertdev$elm_flate$LengthLimitedHuffmanCodes$mergeNodes = F2(
	function (node1, node2) {
		return {
			be: A2($elm$core$Array$append, node1.be, node2.be),
			aq: node1.aq + node2.aq
		};
	});
var $folkertdev$elm_flate$LengthLimitedHuffmanCodes$package = function (nodes) {
	if ($elm$core$Array$length(nodes) >= 2) {
		var newLen = ($elm$core$Array$length(nodes) / 2) | 0;
		var loop = F2(
			function (currentNodes, accum) {
				loop:
				while (true) {
					if (currentNodes.b && currentNodes.b.b) {
						var self = currentNodes.a;
						var _v1 = currentNodes.b;
						var other = _v1.a;
						var rest = _v1.b;
						var $temp$currentNodes = rest,
							$temp$accum = A2(
							$elm$core$List$cons,
							A2($folkertdev$elm_flate$LengthLimitedHuffmanCodes$mergeNodes, self, other),
							accum);
						currentNodes = $temp$currentNodes;
						accum = $temp$accum;
						continue loop;
					} else {
						return $elm$core$Array$fromList(
							$elm$core$List$reverse(accum));
					}
				}
			});
		return A2(
			loop,
			$elm$core$Array$toList(nodes),
			_List_Nil);
	} else {
		return nodes;
	}
};
var $folkertdev$elm_flate$LengthLimitedHuffmanCodes$singletonNode = F2(
	function (symbol, weight) {
		return {
			be: A2($elm$core$Array$repeat, 1, symbol),
			aq: weight
		};
	});
var $elm_community$list_extra$List$Extra$stableSortWith = F2(
	function (pred, list) {
		var predWithIndex = F2(
			function (_v1, _v2) {
				var a1 = _v1.a;
				var i1 = _v1.b;
				var a2 = _v2.a;
				var i2 = _v2.b;
				var result = A2(pred, a1, a2);
				if (result === 1) {
					return A2($elm$core$Basics$compare, i1, i2);
				} else {
					return result;
				}
			});
		var listWithIndex = A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, a) {
					return _Utils_Tuple2(a, i);
				}),
			list);
		return A2(
			$elm$core$List$map,
			$elm$core$Tuple$first,
			A2($elm$core$List$sortWith, predWithIndex, listWithIndex));
	});
var $folkertdev$elm_flate$LengthLimitedHuffmanCodes$update = F3(
	function (index, tagger, array) {
		var _v0 = A2($elm$core$Array$get, index, array);
		if (_v0.$ === 1) {
			return array;
		} else {
			var value = _v0.a;
			return A3(
				$elm$core$Array$set,
				index,
				tagger(value),
				array);
		}
	});
var $folkertdev$elm_flate$LengthLimitedHuffmanCodes$calculate = F2(
	function (maxBitWidth, frequencies) {
		var source = $elm$core$Array$fromList(
			A2(
				$elm_community$list_extra$List$Extra$stableSortWith,
				F2(
					function (a, b) {
						return A2($elm$core$Basics$compare, a.aq, b.aq);
					}),
				$elm$core$Array$toList(
					A2(
						$elm$core$Array$map,
						function (_v3) {
							var symbol = _v3.a;
							var weight = _v3.b;
							return A2($folkertdev$elm_flate$LengthLimitedHuffmanCodes$singletonNode, symbol, weight);
						},
						A2(
							$elm$core$Array$filter,
							function (_v2) {
								var f = _v2.b;
								return f > 0;
							},
							A2($elm$core$Array$indexedMap, $elm$core$Tuple$pair, frequencies))))));
		var weighted = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, w) {
					return A2(
						$folkertdev$elm_flate$LengthLimitedHuffmanCodes$merge,
						$folkertdev$elm_flate$LengthLimitedHuffmanCodes$package(w),
						source);
				}),
			source,
			A2($elm$core$List$range, 0, maxBitWidth - 2));
		var loop = F2(
			function (symbols, accum) {
				loop:
				while (true) {
					if (!symbols.b) {
						return accum;
					} else {
						var symbol = symbols.a;
						var rest = symbols.b;
						var $temp$symbols = rest,
							$temp$accum = A3(
							$folkertdev$elm_flate$LengthLimitedHuffmanCodes$update,
							symbol,
							function (v) {
								return v + 1;
							},
							accum);
						symbols = $temp$symbols;
						accum = $temp$accum;
						continue loop;
					}
				}
			});
		var allSymbols = A2(
			$elm$core$List$concatMap,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.be;
				},
				$elm$core$Array$toList),
			$elm$core$Array$toList(
				$folkertdev$elm_flate$LengthLimitedHuffmanCodes$package(weighted)));
		return A2(
			loop,
			allSymbols,
			A2(
				$elm$core$Array$repeat,
				$elm$core$Array$length(frequencies),
				0));
	});
var $folkertdev$elm_flate$Huffman$Tree = $elm$core$Basics$identity;
var $folkertdev$elm_flate$Huffman$Code = $elm$core$Basics$identity;
var $folkertdev$elm_flate$Huffman$codeFromRecord = $elm$core$Basics$identity;
var $folkertdev$elm_flate$Huffman$new = function (n) {
	return A2(
		$elm$core$Array$repeat,
		n,
		$folkertdev$elm_flate$Huffman$codeFromRecord(
			{a: 0, ab: 0}));
};
var $folkertdev$elm_flate$Huffman$inverseEndianLoop = F4(
	function (i, limit, f, t) {
		inverseEndianLoop:
		while (true) {
			if (_Utils_cmp(i, limit) < 0) {
				var $temp$i = i + 1,
					$temp$limit = limit,
					$temp$f = f >> 1,
					$temp$t = (f & 1) | (t << 1);
				i = $temp$i;
				limit = $temp$limit;
				f = $temp$f;
				t = $temp$t;
				continue inverseEndianLoop;
			} else {
				return t;
			}
		}
	});
var $folkertdev$elm_flate$Huffman$inverseEndian = function (_v0) {
	var width = _v0.ab;
	var bits = _v0.a;
	var inverseBits = A4($folkertdev$elm_flate$Huffman$inverseEndianLoop, 0, width, bits, 0);
	return {a: inverseBits, ab: width};
};
var $folkertdev$elm_flate$Huffman$setMapping = F3(
	function (symbol, code, _v0) {
		var array = _v0;
		return A3(
			$elm$core$Array$set,
			symbol,
			$folkertdev$elm_flate$Huffman$inverseEndian(code),
			array);
	});
var $folkertdev$elm_flate$Huffman$restoreCanonicalHuffmanCodes = F2(
	function (bitWidths, tree) {
		var symbols = A2(
			$elm_community$list_extra$List$Extra$stableSortWith,
			F2(
				function (_v4, _v5) {
					var a = _v4.b;
					var b = _v5.b;
					return A2($elm$core$Basics$compare, a, b);
				}),
			$elm$core$Array$toList(
				A2(
					$elm$core$Array$filter,
					function (_v3) {
						var codeBitWidth = _v3.b;
						return codeBitWidth > 0;
					},
					A2($elm$core$Array$indexedMap, $elm$core$Tuple$pair, bitWidths))));
		var loop = F2(
			function (_v1, _v2) {
				var symbol = _v1.a;
				var bitWidth = _v1.b;
				var code = _v2.a;
				var prevWidth = _v2.b;
				var currentTree = _v2.c;
				var newBits = code << (bitWidth - prevWidth);
				var nextCode = {a: newBits, ab: bitWidth};
				return _Utils_Tuple3(
					newBits + 1,
					bitWidth,
					A3($folkertdev$elm_flate$Huffman$setMapping, symbol, nextCode, currentTree));
			});
		return function (_v0) {
			var x = _v0.c;
			return x;
		}(
			A3(
				$elm$core$List$foldl,
				loop,
				_Utils_Tuple3(0, 0, tree),
				symbols));
	});
var $folkertdev$elm_flate$Huffman$fromBitWidths = function (bitWidths) {
	var symbolCount = function (v) {
		return v + 1;
	}(
		A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Tuple$first,
				function (a) {
					return A2(
						$elm$core$Array$get,
						$elm$core$Array$length(a) - 1,
						a);
				}(
					A2(
						$elm$core$Array$filter,
						function (e) {
							return e.b > 0;
						},
						A2($elm$core$Array$indexedMap, $elm$core$Tuple$pair, bitWidths))))));
	return A2(
		$folkertdev$elm_flate$Huffman$restoreCanonicalHuffmanCodes,
		bitWidths,
		$folkertdev$elm_flate$Huffman$new(symbolCount));
};
var $folkertdev$elm_flate$Huffman$fromFrequencies = F2(
	function (symbolFrequencies, maxBitWidth_) {
		var maxBitWidth = A2(
			$elm$core$Basics$min,
			maxBitWidth_,
			$folkertdev$elm_flate$Huffman$calcOptimalMaxBitWidth(symbolFrequencies));
		var codeBitWidhts = A2($folkertdev$elm_flate$LengthLimitedHuffmanCodes$calculate, maxBitWidth, symbolFrequencies);
		return $folkertdev$elm_flate$Huffman$fromBitWidths(codeBitWidhts);
	});
var $folkertdev$elm_flate$Deflate$Symbol$buildDynamicHuffmanCodec = function (symbols) {
	var _v0 = A3(
		$elm$core$Array$foldl,
		$folkertdev$elm_flate$Deflate$Symbol$dynamicFindFrequencies,
		_Utils_Tuple3(
			A2($elm$core$Array$repeat, 286, 0),
			A2($elm$core$Array$repeat, 30, 0),
			true),
		symbols);
	var literalCounts = _v0.a;
	var distanceCounts = _v0.b;
	var emptyDistanceCount = _v0.c;
	return {
		a2: emptyDistanceCount ? A2(
			$folkertdev$elm_flate$Huffman$fromFrequencies,
			A3($elm$core$Array$set, 0, 1, distanceCounts),
			15) : A2($folkertdev$elm_flate$Huffman$fromFrequencies, distanceCounts, 15),
		a6: A2($folkertdev$elm_flate$Huffman$fromFrequencies, literalCounts, 15)
	};
};
var $folkertdev$elm_flate$Deflate$Symbol$EndOfBlock = {$: 0};
var $folkertdev$elm_flate$Deflate$Symbol$Literal = function (a) {
	return {$: 1, a: a};
};
var $folkertdev$elm_flate$Deflate$Symbol$Share = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $folkertdev$elm_flate$Deflate$Internal$codeToSymbol = function (code) {
	if (!code.$) {
		var v = code.a;
		return $folkertdev$elm_flate$Deflate$Symbol$Literal(v);
	} else {
		var length = code.a;
		var backwardDistance = code.b;
		return A2($folkertdev$elm_flate$Deflate$Symbol$Share, length, backwardDistance);
	}
};
var $folkertdev$elm_flate$LZ77$Literal = function (a) {
	return {$: 0, a: a};
};
var $folkertdev$elm_flate$LZ77$Pointer = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $folkertdev$elm_flate$PrefixTable$Small = function (a) {
	return {$: 0, a: a};
};
var $folkertdev$elm_flate$PrefixTable$Large = function (a) {
	return {$: 1, a: a};
};
var $folkertdev$elm_flate$PrefixTable$LargePrefixTable = $elm$core$Basics$identity;
var $folkertdev$elm_flate$PrefixTable$insertInList = F6(
	function (i, array, p2, position, remaining, accum) {
		insertInList:
		while (true) {
			if (!remaining.b) {
				var newPositions = A2(
					$elm$core$List$cons,
					_Utils_Tuple2(p2, position),
					accum);
				return _Utils_Tuple2(
					$folkertdev$elm_flate$PrefixTable$Large(
						A3($elm$core$Array$set, i, newPositions, array)),
					$elm$core$Maybe$Nothing);
			} else {
				var current = remaining.a;
				var key = current.a;
				var oldValue = current.b;
				var rest = remaining.b;
				if (!(key - p2)) {
					var newPositions = _Utils_ap(
						accum,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(key, position),
							rest));
					return _Utils_Tuple2(
						$folkertdev$elm_flate$PrefixTable$Large(
							A3($elm$core$Array$set, i, newPositions, array)),
						$elm$core$Maybe$Just(oldValue));
				} else {
					var $temp$i = i,
						$temp$array = array,
						$temp$p2 = p2,
						$temp$position = position,
						$temp$remaining = rest,
						$temp$accum = A2($elm$core$List$cons, current, accum);
					i = $temp$i;
					array = $temp$array;
					p2 = $temp$p2;
					position = $temp$position;
					remaining = $temp$remaining;
					accum = $temp$accum;
					continue insertInList;
				}
			}
		}
	});
var $folkertdev$elm_flate$PrefixTable$insert = F3(
	function (_v0, position, ptable) {
		var prefix_ = _v0;
		var prefix = 16777215 & (prefix_ >>> 0);
		if (!ptable.$) {
			var dict = ptable.a;
			var _v2 = A2($elm$core$Dict$get, prefix, dict);
			if (_v2.$ === 1) {
				return _Utils_Tuple2(
					$folkertdev$elm_flate$PrefixTable$Small(
						A3($elm$core$Dict$insert, prefix, position, dict)),
					$elm$core$Maybe$Nothing);
			} else {
				var oldValue = _v2.a;
				return _Utils_Tuple2(
					$folkertdev$elm_flate$PrefixTable$Small(
						A3($elm$core$Dict$insert, prefix, position, dict)),
					$elm$core$Maybe$Just(oldValue));
			}
		} else {
			var array = ptable.a;
			var index = prefix >> 8;
			var _v3 = A2($elm$core$Array$get, index, array);
			if (_v3.$ === 1) {
				return _Utils_Tuple2(ptable, $elm$core$Maybe$Nothing);
			} else {
				var positions = _v3.a;
				return A6($folkertdev$elm_flate$PrefixTable$insertInList, index, array, 255 & prefix, position, positions, _List_Nil);
			}
		}
	});
var $folkertdev$elm_flate$LZ77$longestCommonPrefixLoop = F5(
	function (i, j, limit, accum, array) {
		longestCommonPrefixLoop:
		while (true) {
			if (_Utils_cmp(i, limit) < 0) {
				var _v0 = A2($folkertdev$elm_flate$Experimental$ByteArray$get, i, array);
				if (_v0.$ === 1) {
					return accum;
				} else {
					var value1 = _v0.a;
					var _v1 = A2($folkertdev$elm_flate$Experimental$ByteArray$get, j, array);
					if (_v1.$ === 1) {
						return accum;
					} else {
						var value2 = _v1.a;
						if (!(value1 - value2)) {
							var $temp$i = i + 1,
								$temp$j = j + 1,
								$temp$limit = limit,
								$temp$accum = accum + 1,
								$temp$array = array;
							i = $temp$i;
							j = $temp$j;
							limit = $temp$limit;
							accum = $temp$accum;
							array = $temp$array;
							continue longestCommonPrefixLoop;
						} else {
							return accum;
						}
					}
				}
			} else {
				return accum;
			}
		}
	});
var $folkertdev$elm_flate$LZ77$max_length = 258;
var $folkertdev$elm_flate$LZ77$longestCommonPrefix = F3(
	function (i, j, array) {
		var remaining = A2(
			$elm$core$Basics$min,
			$folkertdev$elm_flate$LZ77$max_length - 3,
			$folkertdev$elm_flate$Experimental$ByteArray$length(array) - j);
		return A5($folkertdev$elm_flate$LZ77$longestCommonPrefixLoop, i, j, i + remaining, 0, array);
	});
var $folkertdev$elm_flate$PrefixTable$OutOfBounds = {$: 3};
var $folkertdev$elm_flate$PrefixTable$Prefix = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $folkertdev$elm_flate$PrefixTable$PrefixCode = $elm$core$Basics$identity;
var $folkertdev$elm_flate$PrefixTable$Trailing1 = function (a) {
	return {$: 1, a: a};
};
var $folkertdev$elm_flate$PrefixTable$Trailing2 = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $folkertdev$elm_flate$Experimental$ByteArray$getInt32 = F2(
	function (index, _v0) {
		var array = _v0.a;
		var finalBytes = _v0.c;
		var size = $elm$core$Array$length(array);
		return (!(index - size)) ? $elm$core$Maybe$Just(finalBytes) : A2($elm$core$Array$get, index, array);
	});
var $folkertdev$elm_flate$PrefixTable$prefixAt = F2(
	function (k, input) {
		var size = $folkertdev$elm_flate$Experimental$ByteArray$length(input);
		if (_Utils_cmp(k + 2, size) > -1) {
			if (_Utils_cmp(k, size) > -1) {
				return $folkertdev$elm_flate$PrefixTable$OutOfBounds;
			} else {
				if (_Utils_cmp(k + 1, size) > -1) {
					var _v0 = A2($folkertdev$elm_flate$Experimental$ByteArray$get, k, input);
					if (_v0.$ === 1) {
						return $folkertdev$elm_flate$PrefixTable$OutOfBounds;
					} else {
						var value = _v0.a;
						return $folkertdev$elm_flate$PrefixTable$Trailing1(value);
					}
				} else {
					var _v1 = A2($folkertdev$elm_flate$Experimental$ByteArray$get, k, input);
					if (_v1.$ === 1) {
						return $folkertdev$elm_flate$PrefixTable$OutOfBounds;
					} else {
						var v1 = _v1.a;
						var _v2 = A2($folkertdev$elm_flate$Experimental$ByteArray$get, k + 1, input);
						if (_v2.$ === 1) {
							return $folkertdev$elm_flate$PrefixTable$OutOfBounds;
						} else {
							var v2 = _v2.a;
							return A2($folkertdev$elm_flate$PrefixTable$Trailing2, v1, v2);
						}
					}
				}
			}
		} else {
			var offset = k % 4;
			var internalIndex = (k / 4) | 0;
			switch (offset) {
				case 0:
					var _v4 = A2($folkertdev$elm_flate$Experimental$ByteArray$getInt32, internalIndex, input);
					if (_v4.$ === 1) {
						return $folkertdev$elm_flate$PrefixTable$OutOfBounds;
					} else {
						var int32 = _v4.a;
						var first = 255 & ((int32 >> 24) >>> 0);
						var code = int32 >> 8;
						return A2($folkertdev$elm_flate$PrefixTable$Prefix, first, code);
					}
				case 1:
					var _v5 = A2($folkertdev$elm_flate$Experimental$ByteArray$getInt32, internalIndex, input);
					if (_v5.$ === 1) {
						return $folkertdev$elm_flate$PrefixTable$OutOfBounds;
					} else {
						var int32 = _v5.a;
						var first = 255 & ((255 & (int32 >> 16)) >>> 0);
						var code = 16777215 & int32;
						return A2($folkertdev$elm_flate$PrefixTable$Prefix, first, code);
					}
				case 2:
					var _v6 = A2($folkertdev$elm_flate$Experimental$ByteArray$getInt32, internalIndex, input);
					if (_v6.$ === 1) {
						return $folkertdev$elm_flate$PrefixTable$OutOfBounds;
					} else {
						var int32 = _v6.a;
						var _v7 = A2($folkertdev$elm_flate$Experimental$ByteArray$getInt32, internalIndex + 1, input);
						if (_v7.$ === 1) {
							return $folkertdev$elm_flate$PrefixTable$OutOfBounds;
						} else {
							var nextInt32 = _v7.a;
							var first = 255 & ((255 & (int32 >> 8)) >>> 0);
							var code = 16777215 & (((255 & (nextInt32 >> 24)) | ((65535 & int32) << 8)) >>> 0);
							return A2($folkertdev$elm_flate$PrefixTable$Prefix, first, code);
						}
					}
				default:
					var _v8 = A2($folkertdev$elm_flate$Experimental$ByteArray$getInt32, internalIndex, input);
					if (_v8.$ === 1) {
						return $folkertdev$elm_flate$PrefixTable$OutOfBounds;
					} else {
						var int32 = _v8.a;
						var _v9 = A2($folkertdev$elm_flate$Experimental$ByteArray$getInt32, internalIndex + 1, input);
						if (_v9.$ === 1) {
							return $folkertdev$elm_flate$PrefixTable$OutOfBounds;
						} else {
							var nextInt32 = _v9.a;
							var first = 255 & ((255 & int32) >>> 0);
							var code = (65535 & (nextInt32 >> 16)) | ((255 & int32) << 16);
							return A2($folkertdev$elm_flate$PrefixTable$Prefix, first, code);
						}
					}
			}
		}
	});
var $folkertdev$elm_flate$LZ77$updatePrefixTableLoop = F4(
	function (k, limit, buffer, prefixTable) {
		updatePrefixTableLoop:
		while (true) {
			if (_Utils_cmp(k, limit) < 0) {
				var _v0 = A2($folkertdev$elm_flate$PrefixTable$prefixAt, k, buffer);
				if (!_v0.$) {
					var code = _v0.b;
					var _v1 = A3($folkertdev$elm_flate$PrefixTable$insert, code, k, prefixTable);
					var newPrefixTable = _v1.a;
					var $temp$k = k + 1,
						$temp$limit = limit,
						$temp$buffer = buffer,
						$temp$prefixTable = newPrefixTable;
					k = $temp$k;
					limit = $temp$limit;
					buffer = $temp$buffer;
					prefixTable = $temp$prefixTable;
					continue updatePrefixTableLoop;
				} else {
					return prefixTable;
				}
			} else {
				return prefixTable;
			}
		}
	});
var $folkertdev$elm_flate$LZ77$flushLoop = F5(
	function (i, windowSize, buffer, prefixTable, encoders) {
		flushLoop:
		while (true) {
			var _v0 = A2($folkertdev$elm_flate$PrefixTable$prefixAt, i, buffer);
			switch (_v0.$) {
				case 3:
					return encoders;
				case 1:
					var p1 = _v0.a;
					return A2(
						$elm$core$Array$push,
						$folkertdev$elm_flate$LZ77$Literal(p1),
						encoders);
				case 2:
					var p1 = _v0.a;
					var p2 = _v0.b;
					return A2(
						$elm$core$Array$push,
						$folkertdev$elm_flate$LZ77$Literal(p2),
						A2(
							$elm$core$Array$push,
							$folkertdev$elm_flate$LZ77$Literal(p1),
							encoders));
				default:
					var p1 = _v0.a;
					var key = _v0.b;
					var _v1 = A3($folkertdev$elm_flate$PrefixTable$insert, key, i, prefixTable);
					var newPrefixTable = _v1.a;
					var matched = _v1.b;
					if (!matched.$) {
						var j = matched.a;
						var distance = i - j;
						if ((distance - windowSize) <= 0) {
							var length = 3 + A3($folkertdev$elm_flate$LZ77$longestCommonPrefix, i + 3, j + 3, buffer);
							var newEncoders = A2(
								$elm$core$Array$push,
								A2($folkertdev$elm_flate$LZ77$Pointer, length, distance),
								encoders);
							var newerPrefixTable = A4($folkertdev$elm_flate$LZ77$updatePrefixTableLoop, i + 1, i + length, buffer, newPrefixTable);
							var $temp$i = i + length,
								$temp$windowSize = windowSize,
								$temp$buffer = buffer,
								$temp$prefixTable = newerPrefixTable,
								$temp$encoders = newEncoders;
							i = $temp$i;
							windowSize = $temp$windowSize;
							buffer = $temp$buffer;
							prefixTable = $temp$prefixTable;
							encoders = $temp$encoders;
							continue flushLoop;
						} else {
							var $temp$i = i + 1,
								$temp$windowSize = windowSize,
								$temp$buffer = buffer,
								$temp$prefixTable = newPrefixTable,
								$temp$encoders = A2(
								$elm$core$Array$push,
								$folkertdev$elm_flate$LZ77$Literal(p1),
								encoders);
							i = $temp$i;
							windowSize = $temp$windowSize;
							buffer = $temp$buffer;
							prefixTable = $temp$prefixTable;
							encoders = $temp$encoders;
							continue flushLoop;
						}
					} else {
						var $temp$i = i + 1,
							$temp$windowSize = windowSize,
							$temp$buffer = buffer,
							$temp$prefixTable = newPrefixTable,
							$temp$encoders = A2(
							$elm$core$Array$push,
							$folkertdev$elm_flate$LZ77$Literal(p1),
							encoders);
						i = $temp$i;
						windowSize = $temp$windowSize;
						buffer = $temp$buffer;
						prefixTable = $temp$prefixTable;
						encoders = $temp$encoders;
						continue flushLoop;
					}
			}
		}
	});
var $folkertdev$elm_flate$PrefixTable$max_distance = 32768;
var $folkertdev$elm_flate$PrefixTable$max_window_size = $folkertdev$elm_flate$PrefixTable$max_distance;
var $folkertdev$elm_flate$PrefixTable$newLargePrefixTable = A2($elm$core$Array$repeat, 65535, _List_Nil);
var $folkertdev$elm_flate$PrefixTable$new = function (nbytes) {
	return (_Utils_cmp(nbytes, $folkertdev$elm_flate$PrefixTable$max_window_size) < 0) ? $folkertdev$elm_flate$PrefixTable$Small($elm$core$Dict$empty) : $folkertdev$elm_flate$PrefixTable$Large($folkertdev$elm_flate$PrefixTable$newLargePrefixTable);
};
var $folkertdev$elm_flate$LZ77$flush = F2(
	function (windowSize, buffer) {
		var codes = A5(
			$folkertdev$elm_flate$LZ77$flushLoop,
			0,
			windowSize,
			buffer,
			$folkertdev$elm_flate$PrefixTable$new(
				$folkertdev$elm_flate$Experimental$ByteArray$length(buffer)),
			$elm$core$Array$empty);
		return codes;
	});
var $elm$bytes$Bytes$Decode$map5 = F6(
	function (func, _v0, _v1, _v2, _v3, _v4) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		var decodeD = _v3;
		var decodeE = _v4;
		return F2(
			function (bites, offset) {
				var _v5 = A2(decodeA, bites, offset);
				var aOffset = _v5.a;
				var a = _v5.b;
				var _v6 = A2(decodeB, bites, aOffset);
				var bOffset = _v6.a;
				var b = _v6.b;
				var _v7 = A2(decodeC, bites, bOffset);
				var cOffset = _v7.a;
				var c = _v7.b;
				var _v8 = A2(decodeD, bites, cOffset);
				var dOffset = _v8.a;
				var d = _v8.b;
				var _v9 = A2(decodeE, bites, dOffset);
				var eOffset = _v9.a;
				var e = _v9.b;
				return _Utils_Tuple2(
					eOffset,
					A5(func, a, b, c, d, e));
			});
	});
var $folkertdev$elm_flate$Experimental$ByteArray$fromBytesHelp = function (_v0) {
	var remaining = _v0.a;
	var array = _v0.b;
	if (remaining >= 40) {
		return A2(
			$elm$bytes$Bytes$Decode$andThen,
			$elm$core$Basics$identity,
			A6(
				$elm$bytes$Bytes$Decode$map5,
				F5(
					function (a, b, c, d, e) {
						return A6(
							$elm$bytes$Bytes$Decode$map5,
							F5(
								function (f, g, h, i, j) {
									return $elm$bytes$Bytes$Decode$Loop(
										_Utils_Tuple2(
											remaining - 40,
											A2(
												$elm$core$Array$append,
												array,
												$elm$core$Array$fromList(
													_List_fromArray(
														[a, b, c, d, e, f, g, h, i, j])))));
								}),
							$elm$bytes$Bytes$Decode$unsignedInt32(1),
							$elm$bytes$Bytes$Decode$unsignedInt32(1),
							$elm$bytes$Bytes$Decode$unsignedInt32(1),
							$elm$bytes$Bytes$Decode$unsignedInt32(1),
							$elm$bytes$Bytes$Decode$unsignedInt32(1));
					}),
				$elm$bytes$Bytes$Decode$unsignedInt32(1),
				$elm$bytes$Bytes$Decode$unsignedInt32(1),
				$elm$bytes$Bytes$Decode$unsignedInt32(1),
				$elm$bytes$Bytes$Decode$unsignedInt32(1),
				$elm$bytes$Bytes$Decode$unsignedInt32(1)));
	} else {
		if (remaining >= 20) {
			return A6(
				$elm$bytes$Bytes$Decode$map5,
				F5(
					function (a, b, c, d, e) {
						return $elm$bytes$Bytes$Decode$Loop(
							_Utils_Tuple2(
								remaining - 20,
								A2(
									$elm$core$Array$push,
									e,
									A2(
										$elm$core$Array$push,
										d,
										A2(
											$elm$core$Array$push,
											c,
											A2(
												$elm$core$Array$push,
												b,
												A2($elm$core$Array$push, a, array)))))));
					}),
				$elm$bytes$Bytes$Decode$unsignedInt32(1),
				$elm$bytes$Bytes$Decode$unsignedInt32(1),
				$elm$bytes$Bytes$Decode$unsignedInt32(1),
				$elm$bytes$Bytes$Decode$unsignedInt32(1),
				$elm$bytes$Bytes$Decode$unsignedInt32(1));
		} else {
			if (remaining >= 4) {
				return A2(
					$elm$bytes$Bytes$Decode$map,
					function (a) {
						return $elm$bytes$Bytes$Decode$Loop(
							_Utils_Tuple2(
								remaining - 4,
								A2($elm$core$Array$push, a, array)));
					},
					$elm$bytes$Bytes$Decode$unsignedInt32(1));
			} else {
				switch (remaining) {
					case 0:
						return $elm$bytes$Bytes$Decode$succeed(
							$elm$bytes$Bytes$Decode$Done(
								_Utils_Tuple3(0, 0, array)));
					case 1:
						return A2(
							$elm$bytes$Bytes$Decode$map,
							function (_byte) {
								return $elm$bytes$Bytes$Decode$Done(
									_Utils_Tuple3(1, _byte << 24, array));
							},
							$elm$bytes$Bytes$Decode$unsignedInt8);
					case 2:
						return A2(
							$elm$bytes$Bytes$Decode$map,
							function (_byte) {
								return $elm$bytes$Bytes$Decode$Done(
									_Utils_Tuple3(2, _byte << 16, array));
							},
							$elm$bytes$Bytes$Decode$unsignedInt16(1));
					default:
						return A3(
							$elm$bytes$Bytes$Decode$map2,
							F2(
								function (bytes, _byte) {
									return $elm$bytes$Bytes$Decode$Done(
										_Utils_Tuple3(3, (bytes << 16) | (_byte << 8), array));
								}),
							$elm$bytes$Bytes$Decode$unsignedInt16(1),
							$elm$bytes$Bytes$Decode$unsignedInt8);
				}
			}
		}
	}
};
var $folkertdev$elm_flate$Experimental$ByteArray$fromBytes = function (buffer) {
	var _v0 = A2(
		$elm$bytes$Bytes$Decode$decode,
		A2(
			$elm$bytes$Bytes$Decode$loop,
			_Utils_Tuple2(
				$elm$bytes$Bytes$width(buffer),
				$elm$core$Array$empty),
			$folkertdev$elm_flate$Experimental$ByteArray$fromBytesHelp),
		buffer);
	if (_v0.$ === 1) {
		return $folkertdev$elm_flate$Experimental$ByteArray$empty;
	} else {
		var _v1 = _v0.a;
		var finalSize = _v1.a;
		var finalBytes = _v1.b;
		var array = _v1.c;
		return A3($folkertdev$elm_flate$Experimental$ByteArray$ByteArray, array, finalSize, finalBytes);
	}
};
var $folkertdev$elm_flate$LZ77$encodeWithOptions = F2(
	function (_v0, buffer) {
		var windowSize = _v0.hJ;
		return A2(
			$folkertdev$elm_flate$LZ77$flush,
			windowSize,
			$folkertdev$elm_flate$Experimental$ByteArray$fromBytes(buffer));
	});
var $folkertdev$elm_flate$ByteArray$decodeByteArrayHelp = function (_v0) {
	var remaining = _v0.a;
	var accum = _v0.b;
	return (remaining >= 4) ? A2(
		$elm$bytes$Bytes$Decode$map,
		function (_new) {
			var byte4 = 255 & (_new >>> 0);
			var byte3 = 255 & ((_new >> 8) >>> 0);
			var byte2 = 255 & ((_new >> 16) >>> 0);
			var byte1 = 255 & ((_new >> 24) >>> 0);
			var newAccum = A2(
				$elm$core$Array$push,
				byte4,
				A2(
					$elm$core$Array$push,
					byte3,
					A2(
						$elm$core$Array$push,
						byte2,
						A2($elm$core$Array$push, byte1, accum))));
			return $elm$bytes$Bytes$Decode$Loop(
				_Utils_Tuple2(remaining - 4, newAccum));
		},
		$elm$bytes$Bytes$Decode$unsignedInt32(1)) : ((remaining > 0) ? A2(
		$elm$bytes$Bytes$Decode$map,
		function (_new) {
			return $elm$bytes$Bytes$Decode$Loop(
				_Utils_Tuple2(
					remaining - 1,
					A2($elm$core$Array$push, _new, accum)));
		},
		$elm$bytes$Bytes$Decode$unsignedInt8) : $elm$bytes$Bytes$Decode$succeed(
		$elm$bytes$Bytes$Decode$Done(accum)));
};
var $folkertdev$elm_flate$ByteArray$decoder = function (n) {
	return A2(
		$elm$bytes$Bytes$Decode$loop,
		_Utils_Tuple2(n, $elm$core$Array$empty),
		$folkertdev$elm_flate$ByteArray$decodeByteArrayHelp);
};
var $folkertdev$elm_flate$ByteArray$fromBytes = function (buffer) {
	var _v0 = A2(
		$elm$bytes$Bytes$Decode$decode,
		$folkertdev$elm_flate$ByteArray$decoder(
			$elm$bytes$Bytes$width(buffer)),
		buffer);
	if (_v0.$ === 1) {
		return $elm$core$Array$empty;
	} else {
		var value = _v0.a;
		return value;
	}
};
var $folkertdev$elm_flate$Deflate$Internal$compress = F2(
	function (maybeWindowSize, buf) {
		if (maybeWindowSize.$ === 1) {
			return A2(
				$elm$core$Array$push,
				$folkertdev$elm_flate$Deflate$Symbol$EndOfBlock,
				A2(
					$elm$core$Array$map,
					$folkertdev$elm_flate$Deflate$Symbol$Literal,
					$folkertdev$elm_flate$ByteArray$fromBytes(buf)));
		} else {
			var windowSize = maybeWindowSize.a;
			return A2(
				$elm$core$Array$push,
				$folkertdev$elm_flate$Deflate$Symbol$EndOfBlock,
				A2(
					$elm$core$Array$map,
					$folkertdev$elm_flate$Deflate$Internal$codeToSymbol,
					A2(
						$folkertdev$elm_flate$LZ77$encodeWithOptions,
						{hJ: windowSize},
						buf)));
		}
	});
var $folkertdev$elm_flate$Deflate$BitWriter$flushIfNeeded = F3(
	function (tag, bitsWritten, encoders) {
		return (bitsWritten >= 16) ? {
			ah: bitsWritten - 16,
			ai: A2(
				$elm$core$List$cons,
				A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, tag),
				encoders),
			dm: tag >> 16
		} : {ah: bitsWritten, ai: encoders, dm: tag};
	});
var $folkertdev$elm_flate$Deflate$BitWriter$writeBits = F3(
	function (bitwidth, bits, state) {
		return A3($folkertdev$elm_flate$Deflate$BitWriter$flushIfNeeded, state.dm | (bits << state.ah), state.ah + bitwidth, state.ai);
	});
var $folkertdev$elm_flate$Huffman$encode = F2(
	function (symbol, _v0) {
		var table = _v0;
		var _v1 = A2($elm$core$Array$get, symbol, table);
		if (_v1.$ === 1) {
			return A2($folkertdev$elm_flate$Deflate$BitWriter$writeBits, 0, 0);
		} else {
			var width = _v1.a.ab;
			var bits = _v1.a.a;
			return A2($folkertdev$elm_flate$Deflate$BitWriter$writeBits, width, bits);
		}
	});
var $folkertdev$elm_flate$Deflate$Symbol$extraLength = function (symbol) {
	if (symbol.$ === 2) {
		var length = symbol.a;
		return (((length >= 3) && (length <= 10)) || (length === 258)) ? $elm$core$Maybe$Nothing : (((length >= 11) && (length <= 18)) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(
				1,
				A2($elm$core$Basics$modBy, 2, length - 11))) : (((length >= 19) && (length <= 34)) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(
				2,
				A2($elm$core$Basics$modBy, 4, length - 19))) : (((length >= 35) && (length <= 66)) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(
				3,
				A2($elm$core$Basics$modBy, 8, length - 35))) : (((length >= 67) && (length <= 130)) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(
				4,
				A2($elm$core$Basics$modBy, 16, length - 67))) : (((length >= 131) && (length <= 257)) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(
				5,
				A2($elm$core$Basics$modBy, 32, length - 131))) : $elm$core$Maybe$Nothing)))));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $folkertdev$elm_flate$Deflate$Symbol$encode = F3(
	function (symbol, htrees, bitWriter) {
		var maybeExtra = function () {
			var _v2 = $folkertdev$elm_flate$Deflate$Symbol$extraLength(symbol);
			if (_v2.$ === 1) {
				return $elm$core$Basics$identity;
			} else {
				var _v3 = _v2.a;
				var bits = _v3.a;
				var extra = _v3.b;
				return A2($folkertdev$elm_flate$Deflate$BitWriter$writeBits, bits, extra);
			}
		}();
		var maybeDistance = function () {
			var _v0 = $folkertdev$elm_flate$Deflate$Symbol$distance(symbol);
			if (_v0.$ === 1) {
				return $elm$core$Basics$identity;
			} else {
				var _v1 = _v0.a;
				var code_ = _v1.a;
				var bits = _v1.b;
				var extra = _v1.c;
				return A2(
					$elm$core$Basics$composeR,
					A2($folkertdev$elm_flate$Huffman$encode, code_, htrees.a2),
					(bits > 0) ? A2($folkertdev$elm_flate$Deflate$BitWriter$writeBits, bits, extra) : $elm$core$Basics$identity);
			}
		}();
		return maybeDistance(
			maybeExtra(
				A3(
					$folkertdev$elm_flate$Huffman$encode,
					$folkertdev$elm_flate$Deflate$Symbol$code(symbol),
					htrees.a6,
					bitWriter)));
	});
var $folkertdev$elm_flate$Deflate$Symbol$bitwidth_code_order = _List_fromArray(
	[16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]);
var $folkertdev$elm_flate$Deflate$Symbol$calculateCodes = function (runLengths) {
	var loop2 = F3(
		function (r, c, codes) {
			loop2:
			while (true) {
				if (c >= 3) {
					var n = A2($elm$core$Basics$min, 6, c);
					var $temp$r = r,
						$temp$c = c - n,
						$temp$codes = A2(
						$elm$core$Array$push,
						_Utils_Tuple3(16, 2, n - 3),
						codes);
					r = $temp$r;
					c = $temp$c;
					codes = $temp$codes;
					continue loop2;
				} else {
					return A2(
						$elm$core$Array$append,
						codes,
						A2(
							$elm$core$Array$repeat,
							c,
							_Utils_Tuple3(r.aE, 0, 0)));
				}
			}
		});
	var loop1 = F2(
		function (c, codes) {
			loop1:
			while (true) {
				if (c >= 11) {
					var n = A2($elm$core$Basics$min, 138, c);
					var $temp$c = c - n,
						$temp$codes = A2(
						$elm$core$Array$push,
						_Utils_Tuple3(18, 7, n - 11),
						codes);
					c = $temp$c;
					codes = $temp$codes;
					continue loop1;
				} else {
					if (c >= 3) {
						return A2(
							$elm$core$Array$push,
							_Utils_Tuple3(17, 3, c - 3),
							codes);
					} else {
						return A2(
							$elm$core$Array$append,
							codes,
							A2(
								$elm$core$Array$repeat,
								c,
								_Utils_Tuple3(0, 0, 0)));
					}
				}
			}
		});
	var folder = F2(
		function (r, codes) {
			return (!r.aE) ? A2(loop1, r.a1, codes) : A3(
				loop2,
				r,
				r.a1 - 1,
				A2(
					$elm$core$Array$push,
					_Utils_Tuple3(r.aE, 0, 0),
					codes));
		});
	return A3($elm$core$Array$foldl, folder, $elm$core$Array$empty, runLengths);
};
var $folkertdev$elm_flate$Huffman$getWidth = function (_v0) {
	var width = _v0.ab;
	return width;
};
var $folkertdev$elm_flate$Huffman$lookup = F2(
	function (symbol, _v0) {
		var array = _v0;
		return A2($elm$core$Array$get, symbol, array);
	});
var $folkertdev$elm_flate$Deflate$Symbol$calculateRunLengths = F2(
	function (lengths, accum) {
		calculateRunLengths:
		while (true) {
			if (!lengths.b) {
				return A3($elm$core$List$foldr, $elm$core$Array$push, $elm$core$Array$empty, accum);
			} else {
				var _v1 = lengths.a;
				var e = _v1.a;
				var size = _v1.b;
				var rest = lengths.b;
				var list = A2(
					$elm$core$List$indexedMap,
					$elm$core$Tuple$pair,
					A2(
						$elm$core$List$map,
						function (x) {
							return A2(
								$elm$core$Maybe$withDefault,
								0,
								A2(
									$elm$core$Maybe$map,
									$folkertdev$elm_flate$Huffman$getWidth,
									A2($folkertdev$elm_flate$Huffman$lookup, x, e)));
						},
						A2($elm$core$List$range, 0, size - 1)));
				var folder = F2(
					function (_v3, runLengths) {
						var i = _v3.a;
						var c = _v3.b;
						if (!runLengths.b) {
							return A2(
								$elm$core$List$cons,
								{a1: 1, aE: c},
								runLengths);
						} else {
							var last = runLengths.a;
							var remaining = runLengths.b;
							return _Utils_eq(last.aE, c) ? A2(
								$elm$core$List$cons,
								{a1: last.a1 + 1, aE: last.aE},
								remaining) : A2(
								$elm$core$List$cons,
								{a1: 1, aE: c},
								runLengths);
						}
					});
				var $temp$lengths = rest,
					$temp$accum = A3($elm$core$List$foldl, folder, accum, list);
				lengths = $temp$lengths;
				accum = $temp$accum;
				continue calculateRunLengths;
			}
		}
	});
var $folkertdev$elm_flate$Deflate$Symbol$buildBitWidthCodes = F3(
	function (literalCodeCount, distanceCodeCount, trees) {
		var runLengths = A2(
			$folkertdev$elm_flate$Deflate$Symbol$calculateRunLengths,
			_List_fromArray(
				[
					_Utils_Tuple2(trees.a6, literalCodeCount),
					_Utils_Tuple2(trees.a2, distanceCodeCount)
				]),
			_List_Nil);
		return $folkertdev$elm_flate$Deflate$Symbol$calculateCodes(runLengths);
	});
var $folkertdev$elm_flate$Deflate$Symbol$positionLoop = F3(
	function (predicate, i, elements) {
		positionLoop:
		while (true) {
			if (!elements.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var x = elements.a;
				var xs = elements.b;
				if (predicate(x)) {
					return $elm$core$Maybe$Just(i);
				} else {
					var $temp$predicate = predicate,
						$temp$i = i + 1,
						$temp$elements = xs;
					predicate = $temp$predicate;
					i = $temp$i;
					elements = $temp$elements;
					continue positionLoop;
				}
			}
		}
	});
var $folkertdev$elm_flate$Deflate$Symbol$position = F2(
	function (predicate, elements) {
		return A3($folkertdev$elm_flate$Deflate$Symbol$positionLoop, predicate, 0, elements);
	});
var $folkertdev$elm_flate$Huffman$positionFromTheEnd = F2(
	function (predicated, array) {
		var folder = F2(
			function (element, _v1) {
				var index = _v1.a;
				var accum = _v1.b;
				if (!accum.$) {
					return _Utils_Tuple2(index, accum);
				} else {
					return predicated(element) ? _Utils_Tuple2(
						index,
						$elm$core$Maybe$Just(index)) : _Utils_Tuple2(index - 1, $elm$core$Maybe$Nothing);
				}
			});
		var finalIndex = $elm$core$Array$length(array) - 1;
		return A2(
			$elm$core$Maybe$map,
			function (v) {
				return finalIndex - v;
			},
			A3(
				$elm$core$Array$foldr,
				folder,
				_Utils_Tuple2(finalIndex, $elm$core$Maybe$Nothing),
				array).b);
	});
var $folkertdev$elm_flate$Huffman$usedMaxSymbol = function (_v0) {
	var array = _v0;
	return A2(
		$elm$core$Maybe$map,
		function (trailingZeros) {
			return ($elm$core$Array$length(array) - 1) - trailingZeros;
		},
		A2(
			$folkertdev$elm_flate$Huffman$positionFromTheEnd,
			function (_v1) {
				var value = _v1;
				return value.ab > 0;
			},
			array));
};
var $folkertdev$elm_flate$Deflate$Symbol$writeDynamicHuffmanCodec = F2(
	function (trees, bitWriter) {
		var literal_code_count = A2(
			$elm$core$Basics$max,
			257,
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$folkertdev$elm_flate$Huffman$usedMaxSymbol(trees.a6)) + 1);
		var distance_code_count = A2(
			$elm$core$Basics$max,
			1,
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$folkertdev$elm_flate$Huffman$usedMaxSymbol(trees.a2)) + 1);
		var codes = A3(
			$folkertdev$elm_flate$Deflate$Symbol$buildBitWidthCodes,
			literal_code_count,
			distance_code_count,
			{a2: trees.a2, a6: trees.a6});
		var codeCounts = A3(
			$elm$core$Array$foldl,
			function (_v2) {
				var i = _v2.a;
				return A2(
					$folkertdev$elm_flate$Deflate$Symbol$update,
					i,
					function (v) {
						return v + 1;
					});
			},
			A2($elm$core$Array$repeat, 19, 0),
			codes);
		var bitWidthEncoder = A2($folkertdev$elm_flate$Huffman$fromFrequencies, codeCounts, 7);
		var bitwidthCodeCount = A2(
			$elm$core$Basics$max,
			4,
			A2(
				$elm$core$Maybe$withDefault,
				0,
				A2(
					$elm$core$Maybe$map,
					function (trailingZeros) {
						return 19 - trailingZeros;
					},
					A2(
						$folkertdev$elm_flate$Deflate$Symbol$position,
						function (i) {
							var _v1 = A2($folkertdev$elm_flate$Huffman$lookup, i, bitWidthEncoder);
							if (_v1.$ === 1) {
								return false;
							} else {
								var value = _v1.a;
								return $folkertdev$elm_flate$Huffman$getWidth(value) > 0;
							}
						},
						$elm$core$List$reverse($folkertdev$elm_flate$Deflate$Symbol$bitwidth_code_order)))));
		var v1 = function (writer) {
			return A3(
				$elm$core$List$foldl,
				F2(
					function (i, current) {
						var width = _Utils_eq(
							A2($elm$core$Array$get, i, codeCounts),
							$elm$core$Maybe$Just(0)) ? 0 : A2(
							$elm$core$Maybe$withDefault,
							0,
							A2(
								$elm$core$Maybe$map,
								$folkertdev$elm_flate$Huffman$getWidth,
								A2($folkertdev$elm_flate$Huffman$lookup, i, bitWidthEncoder)));
						return A3($folkertdev$elm_flate$Deflate$BitWriter$writeBits, 3, width, current);
					}),
				writer,
				A2($elm$core$List$take, bitwidthCodeCount, $folkertdev$elm_flate$Deflate$Symbol$bitwidth_code_order));
		};
		var v2 = function (writer) {
			return A3(
				$elm$core$Array$foldl,
				F2(
					function (_v0, current) {
						var code_ = _v0.a;
						var bits = _v0.b;
						var extra = _v0.c;
						return (bits > 0) ? A3(
							$folkertdev$elm_flate$Deflate$BitWriter$writeBits,
							bits,
							extra,
							A3($folkertdev$elm_flate$Huffman$encode, code_, bitWidthEncoder, current)) : A3($folkertdev$elm_flate$Huffman$encode, code_, bitWidthEncoder, current);
					}),
				writer,
				codes);
		};
		return v2(
			v1(
				A3(
					$folkertdev$elm_flate$Deflate$BitWriter$writeBits,
					4,
					bitwidthCodeCount - 4,
					A3(
						$folkertdev$elm_flate$Deflate$BitWriter$writeBits,
						5,
						distance_code_count - 1,
						A3($folkertdev$elm_flate$Deflate$BitWriter$writeBits, 5, literal_code_count - 257, bitWriter)))));
	});
var $folkertdev$elm_flate$Deflate$Internal$encodeCompressDynamic = F3(
	function (maybeWindowSize, buf, bitWriter) {
		var compressed = A2($folkertdev$elm_flate$Deflate$Internal$compress, maybeWindowSize, buf);
		var huffmanTree = $folkertdev$elm_flate$Deflate$Symbol$buildDynamicHuffmanCodec(compressed);
		var huffmanTreeWriter = A2($folkertdev$elm_flate$Deflate$Symbol$writeDynamicHuffmanCodec, huffmanTree, bitWriter);
		return A3(
			$elm$core$Array$foldl,
			F2(
				function (symbol, first) {
					return A3($folkertdev$elm_flate$Deflate$Symbol$encode, symbol, huffmanTree, first);
				}),
			huffmanTreeWriter,
			compressed);
	});
var $folkertdev$elm_flate$Deflate$BitWriter$writeBit = function (b) {
	if (!b) {
		return A2($folkertdev$elm_flate$Deflate$BitWriter$writeBits, 1, 0);
	} else {
		return A2($folkertdev$elm_flate$Deflate$BitWriter$writeBits, 1, 1);
	}
};
var $folkertdev$elm_flate$Deflate$Internal$encodeDynamicBlock = F3(
	function (windowSize, _v0, bitWriter) {
		var isLastBlock = _v0.a;
		var buffer = _v0.b;
		return A3(
			$folkertdev$elm_flate$Deflate$Internal$encodeCompressDynamic,
			windowSize,
			buffer,
			A3(
				$folkertdev$elm_flate$Deflate$BitWriter$writeBits,
				2,
				2,
				A2($folkertdev$elm_flate$Deflate$BitWriter$writeBit, isLastBlock, bitWriter)));
	});
var $folkertdev$elm_flate$Deflate$BitWriter$flushLoop = F3(
	function (tag, bitsWritten, encoders) {
		flushLoop:
		while (true) {
			if (bitsWritten > 0) {
				var $temp$tag = tag >> 8,
					$temp$bitsWritten = A2($elm$core$Basics$max, 0, bitsWritten - 8),
					$temp$encoders = A2(
					$elm$core$List$cons,
					$elm$bytes$Bytes$Encode$unsignedInt8(tag),
					encoders);
				tag = $temp$tag;
				bitsWritten = $temp$bitsWritten;
				encoders = $temp$encoders;
				continue flushLoop;
			} else {
				return {ah: bitsWritten, ai: encoders, dm: tag};
			}
		}
	});
var $folkertdev$elm_flate$Deflate$BitWriter$flush = function (state) {
	return A3($folkertdev$elm_flate$Deflate$BitWriter$flushLoop, state.dm, state.ah, state.ai);
};
var $folkertdev$elm_flate$Deflate$BitWriter$run = function (state) {
	return $elm$core$List$reverse(state.ai);
};
var $folkertdev$elm_flate$Deflate$Internal$encodeDynamic = F2(
	function (windowSize, buffer) {
		var encodedChunks = A2(
			$elm$core$List$map,
			$folkertdev$elm_flate$Deflate$Internal$encodeDynamicBlock(windowSize),
			A2($folkertdev$elm_flate$Deflate$Internal$chunks, $folkertdev$elm_flate$Deflate$Internal$default_block_size, buffer));
		return $elm$bytes$Bytes$Encode$encode(
			$elm$bytes$Bytes$Encode$sequence(
				$folkertdev$elm_flate$Deflate$BitWriter$run(
					$folkertdev$elm_flate$Deflate$BitWriter$flush(
						A3(
							$elm$core$List$foldl,
							F2(
								function (chunk, first) {
									return chunk(first);
								}),
							$folkertdev$elm_flate$Deflate$BitWriter$empty,
							encodedChunks)))));
	});
var $folkertdev$elm_flate$Deflate$Internal$max_non_compressed_block_size = 65535;
var $elm$core$Array$sliceLeft = F2(
	function (from, array) {
		var len = array.a;
		var tree = array.c;
		var tail = array.d;
		if (!from) {
			return array;
		} else {
			if (_Utils_cmp(
				from,
				$elm$core$Array$tailIndex(len)) > -1) {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					len - from,
					$elm$core$Array$shiftStep,
					$elm$core$Elm$JsArray$empty,
					A3(
						$elm$core$Elm$JsArray$slice,
						from - $elm$core$Array$tailIndex(len),
						$elm$core$Elm$JsArray$length(tail),
						tail));
			} else {
				var skipNodes = (from / $elm$core$Array$branchFactor) | 0;
				var helper = F2(
					function (node, acc) {
						if (!node.$) {
							var subTree = node.a;
							return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
						} else {
							var leaf = node.a;
							return A2($elm$core$List$cons, leaf, acc);
						}
					});
				var leafNodes = A3(
					$elm$core$Elm$JsArray$foldr,
					helper,
					_List_fromArray(
						[tail]),
					tree);
				var nodesToInsert = A2($elm$core$List$drop, skipNodes, leafNodes);
				if (!nodesToInsert.b) {
					return $elm$core$Array$empty;
				} else {
					var head = nodesToInsert.a;
					var rest = nodesToInsert.b;
					var firstSlice = from - (skipNodes * $elm$core$Array$branchFactor);
					var initialBuilder = {
						B: _List_Nil,
						w: 0,
						A: A3(
							$elm$core$Elm$JsArray$slice,
							firstSlice,
							$elm$core$Elm$JsArray$length(head),
							head)
					};
					return A2(
						$elm$core$Array$builderToArray,
						true,
						A3($elm$core$List$foldl, $elm$core$Array$appendHelpBuilder, initialBuilder, rest));
				}
			}
		}
	});
var $elm$core$Array$fetchNewTail = F4(
	function (shift, end, treeEnd, tree) {
		fetchNewTail:
		while (true) {
			var pos = $elm$core$Array$bitMask & (treeEnd >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var sub = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$end = end,
					$temp$treeEnd = treeEnd,
					$temp$tree = sub;
				shift = $temp$shift;
				end = $temp$end;
				treeEnd = $temp$treeEnd;
				tree = $temp$tree;
				continue fetchNewTail;
			} else {
				var values = _v0.a;
				return A3($elm$core$Elm$JsArray$slice, 0, $elm$core$Array$bitMask & end, values);
			}
		}
	});
var $elm$core$Array$hoistTree = F3(
	function (oldShift, newShift, tree) {
		hoistTree:
		while (true) {
			if ((_Utils_cmp(oldShift, newShift) < 1) || (!$elm$core$Elm$JsArray$length(tree))) {
				return tree;
			} else {
				var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, 0, tree);
				if (!_v0.$) {
					var sub = _v0.a;
					var $temp$oldShift = oldShift - $elm$core$Array$shiftStep,
						$temp$newShift = newShift,
						$temp$tree = sub;
					oldShift = $temp$oldShift;
					newShift = $temp$newShift;
					tree = $temp$tree;
					continue hoistTree;
				} else {
					return tree;
				}
			}
		}
	});
var $elm$core$Array$sliceTree = F3(
	function (shift, endIdx, tree) {
		var lastPos = $elm$core$Array$bitMask & (endIdx >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, lastPos, tree);
		if (!_v0.$) {
			var sub = _v0.a;
			var newSub = A3($elm$core$Array$sliceTree, shift - $elm$core$Array$shiftStep, endIdx, sub);
			return (!$elm$core$Elm$JsArray$length(newSub)) ? A3($elm$core$Elm$JsArray$slice, 0, lastPos, tree) : A3(
				$elm$core$Elm$JsArray$unsafeSet,
				lastPos,
				$elm$core$Array$SubTree(newSub),
				A3($elm$core$Elm$JsArray$slice, 0, lastPos + 1, tree));
		} else {
			return A3($elm$core$Elm$JsArray$slice, 0, lastPos, tree);
		}
	});
var $elm$core$Array$sliceRight = F2(
	function (end, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		if (_Utils_eq(end, len)) {
			return array;
		} else {
			if (_Utils_cmp(
				end,
				$elm$core$Array$tailIndex(len)) > -1) {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					end,
					startShift,
					tree,
					A3($elm$core$Elm$JsArray$slice, 0, $elm$core$Array$bitMask & end, tail));
			} else {
				var endIdx = $elm$core$Array$tailIndex(end);
				var depth = $elm$core$Basics$floor(
					A2(
						$elm$core$Basics$logBase,
						$elm$core$Array$branchFactor,
						A2($elm$core$Basics$max, 1, endIdx - 1)));
				var newShift = A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep);
				return A4(
					$elm$core$Array$Array_elm_builtin,
					end,
					newShift,
					A3(
						$elm$core$Array$hoistTree,
						startShift,
						newShift,
						A3($elm$core$Array$sliceTree, startShift, endIdx, tree)),
					A4($elm$core$Array$fetchNewTail, startShift, end, endIdx, tree));
			}
		}
	});
var $elm$core$Array$translateIndex = F2(
	function (index, _v0) {
		var len = _v0.a;
		var posIndex = (index < 0) ? (len + index) : index;
		return (posIndex < 0) ? 0 : ((_Utils_cmp(posIndex, len) > 0) ? len : posIndex);
	});
var $elm$core$Array$slice = F3(
	function (from, to, array) {
		var correctTo = A2($elm$core$Array$translateIndex, to, array);
		var correctFrom = A2($elm$core$Array$translateIndex, from, array);
		return (_Utils_cmp(correctFrom, correctTo) > 0) ? $elm$core$Array$empty : A2(
			$elm$core$Array$sliceLeft,
			correctFrom,
			A2($elm$core$Array$sliceRight, correctTo, array));
	});
var $folkertdev$elm_flate$ByteArray$fasterEncodeFolderR = F2(
	function (_byte, _v0) {
		var bytesOnAccum = _v0.a;
		var accum = _v0.b;
		var encoders = _v0.c;
		switch (bytesOnAccum) {
			case 0:
				var value = 255 & _byte;
				return _Utils_Tuple3(1, value, encoders);
			case 1:
				var value = accum | ((255 & _byte) << 8);
				return _Utils_Tuple3(2, value, encoders);
			case 2:
				var value = accum | ((255 & _byte) << 16);
				return _Utils_Tuple3(3, value, encoders);
			default:
				var value = accum | ((255 & _byte) << 24);
				return _Utils_Tuple3(
					0,
					0,
					A2(
						$elm$core$List$cons,
						A2($elm$bytes$Bytes$Encode$unsignedInt32, 1, value),
						encoders));
		}
	});
var $folkertdev$elm_flate$ByteArray$fasterEncodeR = function (_v0) {
	var bytesOnAccum = _v0.a;
	var accum = _v0.b;
	var otherEncoders = _v0.c;
	var encoders = function () {
		switch (bytesOnAccum) {
			case 0:
				return otherEncoders;
			case 1:
				return A2(
					$elm$core$List$cons,
					$elm$bytes$Bytes$Encode$unsignedInt8(accum),
					otherEncoders);
			case 2:
				return A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, accum),
					otherEncoders);
			default:
				var otherBytes = accum >> 8;
				var firstByte = 255 & accum;
				return A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, otherBytes),
					A2(
						$elm$core$List$cons,
						$elm$bytes$Bytes$Encode$unsignedInt8(firstByte),
						otherEncoders));
		}
	}();
	return encoders;
};
var $folkertdev$elm_flate$ByteArray$toBytes = function (array) {
	return $elm$bytes$Bytes$Encode$encode(
		$elm$bytes$Bytes$Encode$sequence(
			$folkertdev$elm_flate$ByteArray$fasterEncodeR(
				A3(
					$elm$core$Array$foldr,
					$folkertdev$elm_flate$ByteArray$fasterEncodeFolderR,
					_Utils_Tuple3(0, 0, _List_Nil),
					array))));
};
var $folkertdev$elm_flate$Deflate$BitWriter$writeEncoder = F2(
	function (encoder, state) {
		return {
			ah: state.ah,
			ai: A2($elm$core$List$cons, encoder, state.ai),
			dm: state.dm
		};
	});
var $folkertdev$elm_flate$Deflate$Internal$encodeRawBlock = F2(
	function (_v0, bitWriter) {
		var isLastBlock = _v0.a;
		var buffer = _v0.b;
		var byteArray = $folkertdev$elm_flate$ByteArray$fromBytes(buffer);
		var size = A2(
			$elm$core$Basics$min,
			$elm$core$Array$length(byteArray),
			$folkertdev$elm_flate$Deflate$Internal$max_non_compressed_block_size);
		var sliced = A3($elm$core$Array$slice, 0, size, byteArray);
		return A2(
			$folkertdev$elm_flate$Deflate$BitWriter$writeEncoder,
			$elm$bytes$Bytes$Encode$bytes(
				$folkertdev$elm_flate$ByteArray$toBytes(sliced)),
			A2(
				$folkertdev$elm_flate$Deflate$BitWriter$writeEncoder,
				A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, ~size),
				A2(
					$folkertdev$elm_flate$Deflate$BitWriter$writeEncoder,
					A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, size),
					$folkertdev$elm_flate$Deflate$BitWriter$flush(
						A3(
							$folkertdev$elm_flate$Deflate$BitWriter$writeBits,
							2,
							0,
							A2($folkertdev$elm_flate$Deflate$BitWriter$writeBit, isLastBlock, bitWriter))))));
	});
var $folkertdev$elm_flate$Deflate$Internal$encodeRaw = function (buffer) {
	return $elm$bytes$Bytes$Encode$encode(
		$elm$bytes$Bytes$Encode$sequence(
			$folkertdev$elm_flate$Deflate$BitWriter$run(
				A3(
					$elm$core$List$foldl,
					F2(
						function (chunk, first) {
							return A2($folkertdev$elm_flate$Deflate$Internal$encodeRawBlock, chunk, first);
						}),
					$folkertdev$elm_flate$Deflate$BitWriter$empty,
					A2(
						$folkertdev$elm_flate$Deflate$Internal$chunks,
						A2($elm$core$Basics$min, $folkertdev$elm_flate$Deflate$Internal$max_non_compressed_block_size, $folkertdev$elm_flate$Deflate$Internal$default_block_size),
						buffer)))));
};
var $folkertdev$elm_flate$Huffman$fromList = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$Basics$composeL, $elm$core$Basics$identity, $elm$core$Array$fromList),
	$elm$core$List$map($folkertdev$elm_flate$Huffman$codeFromRecord));
var $folkertdev$elm_flate$Huffman$hardcodedStaticHuffmanTree = {
	a2: $folkertdev$elm_flate$Huffman$fromList(
		_List_fromArray(
			[
				{a: 0, ab: 5},
				{a: 16, ab: 5},
				{a: 8, ab: 5},
				{a: 24, ab: 5},
				{a: 4, ab: 5},
				{a: 20, ab: 5},
				{a: 12, ab: 5},
				{a: 28, ab: 5},
				{a: 2, ab: 5},
				{a: 18, ab: 5},
				{a: 10, ab: 5},
				{a: 26, ab: 5},
				{a: 6, ab: 5},
				{a: 22, ab: 5},
				{a: 14, ab: 5},
				{a: 30, ab: 5},
				{a: 1, ab: 5},
				{a: 17, ab: 5},
				{a: 9, ab: 5},
				{a: 25, ab: 5},
				{a: 5, ab: 5},
				{a: 21, ab: 5},
				{a: 13, ab: 5},
				{a: 29, ab: 5},
				{a: 3, ab: 5},
				{a: 19, ab: 5},
				{a: 11, ab: 5},
				{a: 27, ab: 5},
				{a: 7, ab: 5},
				{a: 23, ab: 5}
			])),
	a6: $folkertdev$elm_flate$Huffman$fromList(
		_List_fromArray(
			[
				{a: 12, ab: 8},
				{a: 140, ab: 8},
				{a: 76, ab: 8},
				{a: 204, ab: 8},
				{a: 44, ab: 8},
				{a: 172, ab: 8},
				{a: 108, ab: 8},
				{a: 236, ab: 8},
				{a: 28, ab: 8},
				{a: 156, ab: 8},
				{a: 92, ab: 8},
				{a: 220, ab: 8},
				{a: 60, ab: 8},
				{a: 188, ab: 8},
				{a: 124, ab: 8},
				{a: 252, ab: 8},
				{a: 2, ab: 8},
				{a: 130, ab: 8},
				{a: 66, ab: 8},
				{a: 194, ab: 8},
				{a: 34, ab: 8},
				{a: 162, ab: 8},
				{a: 98, ab: 8},
				{a: 226, ab: 8},
				{a: 18, ab: 8},
				{a: 146, ab: 8},
				{a: 82, ab: 8},
				{a: 210, ab: 8},
				{a: 50, ab: 8},
				{a: 178, ab: 8},
				{a: 114, ab: 8},
				{a: 242, ab: 8},
				{a: 10, ab: 8},
				{a: 138, ab: 8},
				{a: 74, ab: 8},
				{a: 202, ab: 8},
				{a: 42, ab: 8},
				{a: 170, ab: 8},
				{a: 106, ab: 8},
				{a: 234, ab: 8},
				{a: 26, ab: 8},
				{a: 154, ab: 8},
				{a: 90, ab: 8},
				{a: 218, ab: 8},
				{a: 58, ab: 8},
				{a: 186, ab: 8},
				{a: 122, ab: 8},
				{a: 250, ab: 8},
				{a: 6, ab: 8},
				{a: 134, ab: 8},
				{a: 70, ab: 8},
				{a: 198, ab: 8},
				{a: 38, ab: 8},
				{a: 166, ab: 8},
				{a: 102, ab: 8},
				{a: 230, ab: 8},
				{a: 22, ab: 8},
				{a: 150, ab: 8},
				{a: 86, ab: 8},
				{a: 214, ab: 8},
				{a: 54, ab: 8},
				{a: 182, ab: 8},
				{a: 118, ab: 8},
				{a: 246, ab: 8},
				{a: 14, ab: 8},
				{a: 142, ab: 8},
				{a: 78, ab: 8},
				{a: 206, ab: 8},
				{a: 46, ab: 8},
				{a: 174, ab: 8},
				{a: 110, ab: 8},
				{a: 238, ab: 8},
				{a: 30, ab: 8},
				{a: 158, ab: 8},
				{a: 94, ab: 8},
				{a: 222, ab: 8},
				{a: 62, ab: 8},
				{a: 190, ab: 8},
				{a: 126, ab: 8},
				{a: 254, ab: 8},
				{a: 1, ab: 8},
				{a: 129, ab: 8},
				{a: 65, ab: 8},
				{a: 193, ab: 8},
				{a: 33, ab: 8},
				{a: 161, ab: 8},
				{a: 97, ab: 8},
				{a: 225, ab: 8},
				{a: 17, ab: 8},
				{a: 145, ab: 8},
				{a: 81, ab: 8},
				{a: 209, ab: 8},
				{a: 49, ab: 8},
				{a: 177, ab: 8},
				{a: 113, ab: 8},
				{a: 241, ab: 8},
				{a: 9, ab: 8},
				{a: 137, ab: 8},
				{a: 73, ab: 8},
				{a: 201, ab: 8},
				{a: 41, ab: 8},
				{a: 169, ab: 8},
				{a: 105, ab: 8},
				{a: 233, ab: 8},
				{a: 25, ab: 8},
				{a: 153, ab: 8},
				{a: 89, ab: 8},
				{a: 217, ab: 8},
				{a: 57, ab: 8},
				{a: 185, ab: 8},
				{a: 121, ab: 8},
				{a: 249, ab: 8},
				{a: 5, ab: 8},
				{a: 133, ab: 8},
				{a: 69, ab: 8},
				{a: 197, ab: 8},
				{a: 37, ab: 8},
				{a: 165, ab: 8},
				{a: 101, ab: 8},
				{a: 229, ab: 8},
				{a: 21, ab: 8},
				{a: 149, ab: 8},
				{a: 85, ab: 8},
				{a: 213, ab: 8},
				{a: 53, ab: 8},
				{a: 181, ab: 8},
				{a: 117, ab: 8},
				{a: 245, ab: 8},
				{a: 13, ab: 8},
				{a: 141, ab: 8},
				{a: 77, ab: 8},
				{a: 205, ab: 8},
				{a: 45, ab: 8},
				{a: 173, ab: 8},
				{a: 109, ab: 8},
				{a: 237, ab: 8},
				{a: 29, ab: 8},
				{a: 157, ab: 8},
				{a: 93, ab: 8},
				{a: 221, ab: 8},
				{a: 61, ab: 8},
				{a: 189, ab: 8},
				{a: 125, ab: 8},
				{a: 253, ab: 8},
				{a: 19, ab: 9},
				{a: 275, ab: 9},
				{a: 147, ab: 9},
				{a: 403, ab: 9},
				{a: 83, ab: 9},
				{a: 339, ab: 9},
				{a: 211, ab: 9},
				{a: 467, ab: 9},
				{a: 51, ab: 9},
				{a: 307, ab: 9},
				{a: 179, ab: 9},
				{a: 435, ab: 9},
				{a: 115, ab: 9},
				{a: 371, ab: 9},
				{a: 243, ab: 9},
				{a: 499, ab: 9},
				{a: 11, ab: 9},
				{a: 267, ab: 9},
				{a: 139, ab: 9},
				{a: 395, ab: 9},
				{a: 75, ab: 9},
				{a: 331, ab: 9},
				{a: 203, ab: 9},
				{a: 459, ab: 9},
				{a: 43, ab: 9},
				{a: 299, ab: 9},
				{a: 171, ab: 9},
				{a: 427, ab: 9},
				{a: 107, ab: 9},
				{a: 363, ab: 9},
				{a: 235, ab: 9},
				{a: 491, ab: 9},
				{a: 27, ab: 9},
				{a: 283, ab: 9},
				{a: 155, ab: 9},
				{a: 411, ab: 9},
				{a: 91, ab: 9},
				{a: 347, ab: 9},
				{a: 219, ab: 9},
				{a: 475, ab: 9},
				{a: 59, ab: 9},
				{a: 315, ab: 9},
				{a: 187, ab: 9},
				{a: 443, ab: 9},
				{a: 123, ab: 9},
				{a: 379, ab: 9},
				{a: 251, ab: 9},
				{a: 507, ab: 9},
				{a: 7, ab: 9},
				{a: 263, ab: 9},
				{a: 135, ab: 9},
				{a: 391, ab: 9},
				{a: 71, ab: 9},
				{a: 327, ab: 9},
				{a: 199, ab: 9},
				{a: 455, ab: 9},
				{a: 39, ab: 9},
				{a: 295, ab: 9},
				{a: 167, ab: 9},
				{a: 423, ab: 9},
				{a: 103, ab: 9},
				{a: 359, ab: 9},
				{a: 231, ab: 9},
				{a: 487, ab: 9},
				{a: 23, ab: 9},
				{a: 279, ab: 9},
				{a: 151, ab: 9},
				{a: 407, ab: 9},
				{a: 87, ab: 9},
				{a: 343, ab: 9},
				{a: 215, ab: 9},
				{a: 471, ab: 9},
				{a: 55, ab: 9},
				{a: 311, ab: 9},
				{a: 183, ab: 9},
				{a: 439, ab: 9},
				{a: 119, ab: 9},
				{a: 375, ab: 9},
				{a: 247, ab: 9},
				{a: 503, ab: 9},
				{a: 15, ab: 9},
				{a: 271, ab: 9},
				{a: 143, ab: 9},
				{a: 399, ab: 9},
				{a: 79, ab: 9},
				{a: 335, ab: 9},
				{a: 207, ab: 9},
				{a: 463, ab: 9},
				{a: 47, ab: 9},
				{a: 303, ab: 9},
				{a: 175, ab: 9},
				{a: 431, ab: 9},
				{a: 111, ab: 9},
				{a: 367, ab: 9},
				{a: 239, ab: 9},
				{a: 495, ab: 9},
				{a: 31, ab: 9},
				{a: 287, ab: 9},
				{a: 159, ab: 9},
				{a: 415, ab: 9},
				{a: 95, ab: 9},
				{a: 351, ab: 9},
				{a: 223, ab: 9},
				{a: 479, ab: 9},
				{a: 63, ab: 9},
				{a: 319, ab: 9},
				{a: 191, ab: 9},
				{a: 447, ab: 9},
				{a: 127, ab: 9},
				{a: 383, ab: 9},
				{a: 255, ab: 9},
				{a: 511, ab: 9},
				{a: 0, ab: 7},
				{a: 64, ab: 7},
				{a: 32, ab: 7},
				{a: 96, ab: 7},
				{a: 16, ab: 7},
				{a: 80, ab: 7},
				{a: 48, ab: 7},
				{a: 112, ab: 7},
				{a: 8, ab: 7},
				{a: 72, ab: 7},
				{a: 40, ab: 7},
				{a: 104, ab: 7},
				{a: 24, ab: 7},
				{a: 88, ab: 7},
				{a: 56, ab: 7},
				{a: 120, ab: 7},
				{a: 4, ab: 7},
				{a: 68, ab: 7},
				{a: 36, ab: 7},
				{a: 100, ab: 7},
				{a: 20, ab: 7},
				{a: 84, ab: 7},
				{a: 52, ab: 7},
				{a: 116, ab: 7},
				{a: 3, ab: 8},
				{a: 131, ab: 8},
				{a: 67, ab: 8},
				{a: 195, ab: 8},
				{a: 35, ab: 8},
				{a: 163, ab: 8},
				{a: 99, ab: 8},
				{a: 227, ab: 8}
			]))
};
var $folkertdev$elm_flate$Deflate$Internal$encodeCompressStatic = F3(
	function (maybeWindowSize, buf, bitWriter) {
		var huffmanTrees = $folkertdev$elm_flate$Huffman$hardcodedStaticHuffmanTree;
		var compressed = A2($folkertdev$elm_flate$Deflate$Internal$compress, maybeWindowSize, buf);
		return A3(
			$elm$core$Array$foldl,
			F2(
				function (symbol, first) {
					return A3($folkertdev$elm_flate$Deflate$Symbol$encode, symbol, huffmanTrees, first);
				}),
			bitWriter,
			compressed);
	});
var $folkertdev$elm_flate$Deflate$Internal$encodeStaticBlock = F3(
	function (windowSize, _v0, bitWriter) {
		var isLastBlock = _v0.a;
		var buffer = _v0.b;
		return A3(
			$folkertdev$elm_flate$Deflate$Internal$encodeCompressStatic,
			windowSize,
			buffer,
			A3(
				$folkertdev$elm_flate$Deflate$BitWriter$writeBits,
				2,
				1,
				A2($folkertdev$elm_flate$Deflate$BitWriter$writeBit, isLastBlock, bitWriter)));
	});
var $folkertdev$elm_flate$Deflate$Internal$encodeStatic = F2(
	function (windowSize, buffer) {
		return $elm$bytes$Bytes$Encode$encode(
			$elm$bytes$Bytes$Encode$sequence(
				$folkertdev$elm_flate$Deflate$BitWriter$run(
					$folkertdev$elm_flate$Deflate$BitWriter$flush(
						A3(
							$elm$core$List$foldl,
							F2(
								function (chunk, first) {
									return A3($folkertdev$elm_flate$Deflate$Internal$encodeStaticBlock, windowSize, chunk, first);
								}),
							$folkertdev$elm_flate$Deflate$BitWriter$empty,
							A2($folkertdev$elm_flate$Deflate$Internal$chunks, $folkertdev$elm_flate$Deflate$Internal$default_block_size, buffer))))));
	});
var $folkertdev$elm_flate$Flate$deflateWithOptions = F2(
	function (encoding, buffer) {
		switch (encoding.$) {
			case 0:
				return $folkertdev$elm_flate$Deflate$Internal$encodeRaw(buffer);
			case 2:
				if (!encoding.a.$) {
					var _v1 = encoding.a;
					return A2($folkertdev$elm_flate$Deflate$Internal$encodeStatic, $elm$core$Maybe$Nothing, buffer);
				} else {
					var w = encoding.a.a;
					return A2(
						$folkertdev$elm_flate$Deflate$Internal$encodeStatic,
						$elm$core$Maybe$Just(w),
						buffer);
				}
			default:
				if (!encoding.a.$) {
					var _v2 = encoding.a;
					return A2($folkertdev$elm_flate$Deflate$Internal$encodeDynamic, $elm$core$Maybe$Nothing, buffer);
				} else {
					var w = encoding.a.a;
					return A2(
						$folkertdev$elm_flate$Deflate$Internal$encodeDynamic,
						$elm$core$Maybe$Just(w),
						buffer);
				}
		}
	});
var $folkertdev$elm_flate$Flate$deflateGZipWithOptions = F2(
	function (encoding, buffer) {
		var encodedTrailer = _List_fromArray(
			[
				A2(
				$elm$bytes$Bytes$Encode$unsignedInt32,
				0,
				$folkertdev$elm_flate$Checksum$Crc32$crc32(buffer)),
				A2(
				$elm$bytes$Bytes$Encode$unsignedInt32,
				0,
				A2(
					$elm$core$Basics$modBy,
					4294967296,
					$elm$bytes$Bytes$width(buffer)))
			]);
		var encodedHeader = _List_fromArray(
			[
				$elm$bytes$Bytes$Encode$unsignedInt8(31),
				$elm$bytes$Bytes$Encode$unsignedInt8(139),
				$elm$bytes$Bytes$Encode$unsignedInt8(8),
				$elm$bytes$Bytes$Encode$unsignedInt8(0),
				A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
				$elm$bytes$Bytes$Encode$unsignedInt8(0),
				$elm$bytes$Bytes$Encode$unsignedInt8(255)
			]);
		var data = A2($folkertdev$elm_flate$Flate$deflateWithOptions, encoding, buffer);
		return $elm$bytes$Bytes$Encode$encode(
			$elm$bytes$Bytes$Encode$sequence(
				_Utils_ap(
					encodedHeader,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$bytes$Bytes$Encode$bytes(data)
							]),
						encodedTrailer))));
	});
var $folkertdev$elm_flate$LZ77$max_distance = 32768;
var $folkertdev$elm_flate$LZ77$maxWindowSize = $folkertdev$elm_flate$LZ77$max_distance;
var $folkertdev$elm_flate$Flate$deflateGZip = $folkertdev$elm_flate$Flate$deflateGZipWithOptions(
	$folkertdev$elm_flate$Flate$Dynamic(
		$folkertdev$elm_flate$Flate$WithWindowSize($folkertdev$elm_flate$LZ77$maxWindowSize)));
var $danfishgold$base64_bytes$Decode$lowest6BitsMask = 63;
var $elm$core$Char$fromCode = _Char_fromCode;
var $danfishgold$base64_bytes$Decode$unsafeToChar = function (n) {
	if (n <= 25) {
		return $elm$core$Char$fromCode(65 + n);
	} else {
		if (n <= 51) {
			return $elm$core$Char$fromCode(97 + (n - 26));
		} else {
			if (n <= 61) {
				return $elm$core$Char$fromCode(48 + (n - 52));
			} else {
				switch (n) {
					case 62:
						return '+';
					case 63:
						return '/';
					default:
						return '\u0000';
				}
			}
		}
	}
};
var $danfishgold$base64_bytes$Decode$bitsToChars = F2(
	function (bits, missing) {
		var s = $danfishgold$base64_bytes$Decode$unsafeToChar(bits & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var r = $danfishgold$base64_bytes$Decode$unsafeToChar((bits >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var q = $danfishgold$base64_bytes$Decode$unsafeToChar((bits >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var p = $danfishgold$base64_bytes$Decode$unsafeToChar(bits >>> 18);
		switch (missing) {
			case 0:
				return A2(
					$elm$core$String$cons,
					p,
					A2(
						$elm$core$String$cons,
						q,
						A2(
							$elm$core$String$cons,
							r,
							$elm$core$String$fromChar(s))));
			case 1:
				return A2(
					$elm$core$String$cons,
					p,
					A2(
						$elm$core$String$cons,
						q,
						A2($elm$core$String$cons, r, '=')));
			case 2:
				return A2(
					$elm$core$String$cons,
					p,
					A2($elm$core$String$cons, q, '=='));
			default:
				return '';
		}
	});
var $danfishgold$base64_bytes$Decode$bitsToCharSpecialized = F4(
	function (bits1, bits2, bits3, accum) {
		var z = $danfishgold$base64_bytes$Decode$unsafeToChar((bits3 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var y = $danfishgold$base64_bytes$Decode$unsafeToChar((bits3 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var x = $danfishgold$base64_bytes$Decode$unsafeToChar(bits3 >>> 18);
		var w = $danfishgold$base64_bytes$Decode$unsafeToChar(bits3 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var s = $danfishgold$base64_bytes$Decode$unsafeToChar(bits1 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var r = $danfishgold$base64_bytes$Decode$unsafeToChar((bits1 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var q = $danfishgold$base64_bytes$Decode$unsafeToChar((bits1 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var p = $danfishgold$base64_bytes$Decode$unsafeToChar(bits1 >>> 18);
		var d = $danfishgold$base64_bytes$Decode$unsafeToChar(bits2 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var c = $danfishgold$base64_bytes$Decode$unsafeToChar((bits2 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var b = $danfishgold$base64_bytes$Decode$unsafeToChar((bits2 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var a = $danfishgold$base64_bytes$Decode$unsafeToChar(bits2 >>> 18);
		return A2(
			$elm$core$String$cons,
			x,
			A2(
				$elm$core$String$cons,
				y,
				A2(
					$elm$core$String$cons,
					z,
					A2(
						$elm$core$String$cons,
						w,
						A2(
							$elm$core$String$cons,
							a,
							A2(
								$elm$core$String$cons,
								b,
								A2(
									$elm$core$String$cons,
									c,
									A2(
										$elm$core$String$cons,
										d,
										A2(
											$elm$core$String$cons,
											p,
											A2(
												$elm$core$String$cons,
												q,
												A2(
													$elm$core$String$cons,
													r,
													A2($elm$core$String$cons, s, accum))))))))))));
	});
var $danfishgold$base64_bytes$Decode$decode18Help = F5(
	function (a, b, c, d, e) {
		var combined6 = ((255 & d) << 16) | e;
		var combined5 = d >>> 8;
		var combined4 = 16777215 & c;
		var combined3 = ((65535 & b) << 8) | (c >>> 24);
		var combined2 = ((255 & a) << 16) | (b >>> 16);
		var combined1 = a >>> 8;
		return A4(
			$danfishgold$base64_bytes$Decode$bitsToCharSpecialized,
			combined3,
			combined2,
			combined1,
			A4($danfishgold$base64_bytes$Decode$bitsToCharSpecialized, combined6, combined5, combined4, ''));
	});
var $danfishgold$base64_bytes$Decode$u16BE = $elm$bytes$Bytes$Decode$unsignedInt16(1);
var $danfishgold$base64_bytes$Decode$u32BE = $elm$bytes$Bytes$Decode$unsignedInt32(1);
var $danfishgold$base64_bytes$Decode$decode18Bytes = A6($elm$bytes$Bytes$Decode$map5, $danfishgold$base64_bytes$Decode$decode18Help, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u16BE);
var $elm$bytes$Bytes$Decode$map3 = F4(
	function (func, _v0, _v1, _v2) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		return F2(
			function (bites, offset) {
				var _v3 = A2(decodeA, bites, offset);
				var aOffset = _v3.a;
				var a = _v3.b;
				var _v4 = A2(decodeB, bites, aOffset);
				var bOffset = _v4.a;
				var b = _v4.b;
				var _v5 = A2(decodeC, bites, bOffset);
				var cOffset = _v5.a;
				var c = _v5.b;
				return _Utils_Tuple2(
					cOffset,
					A3(func, a, b, c));
			});
	});
var $danfishgold$base64_bytes$Decode$loopHelp = function (_v0) {
	var remaining = _v0.ct;
	var string = _v0.cC;
	if (remaining >= 18) {
		return A2(
			$elm$bytes$Bytes$Decode$map,
			function (result) {
				return $elm$bytes$Bytes$Decode$Loop(
					{
						ct: remaining - 18,
						cC: _Utils_ap(string, result)
					});
			},
			$danfishgold$base64_bytes$Decode$decode18Bytes);
	} else {
		if (remaining >= 3) {
			var helper = F3(
				function (a, b, c) {
					var combined = ((a << 16) | (b << 8)) | c;
					return $elm$bytes$Bytes$Decode$Loop(
						{
							ct: remaining - 3,
							cC: _Utils_ap(
								string,
								A2($danfishgold$base64_bytes$Decode$bitsToChars, combined, 0))
						});
				});
			return A4($elm$bytes$Bytes$Decode$map3, helper, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8);
		} else {
			if (!remaining) {
				return $elm$bytes$Bytes$Decode$succeed(
					$elm$bytes$Bytes$Decode$Done(string));
			} else {
				if (remaining === 2) {
					var helper = F2(
						function (a, b) {
							var combined = (a << 16) | (b << 8);
							return $elm$bytes$Bytes$Decode$Done(
								_Utils_ap(
									string,
									A2($danfishgold$base64_bytes$Decode$bitsToChars, combined, 1)));
						});
					return A3($elm$bytes$Bytes$Decode$map2, helper, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8);
				} else {
					return A2(
						$elm$bytes$Bytes$Decode$map,
						function (a) {
							return $elm$bytes$Bytes$Decode$Done(
								_Utils_ap(
									string,
									A2($danfishgold$base64_bytes$Decode$bitsToChars, a << 16, 2)));
						},
						$elm$bytes$Bytes$Decode$unsignedInt8);
				}
			}
		}
	}
};
var $danfishgold$base64_bytes$Decode$decoder = function (width) {
	return A2(
		$elm$bytes$Bytes$Decode$loop,
		{ct: width, cC: ''},
		$danfishgold$base64_bytes$Decode$loopHelp);
};
var $danfishgold$base64_bytes$Decode$fromBytes = function (bytes) {
	return A2(
		$elm$bytes$Bytes$Decode$decode,
		$danfishgold$base64_bytes$Decode$decoder(
			$elm$bytes$Bytes$width(bytes)),
		bytes);
};
var $danfishgold$base64_bytes$Base64$fromBytes = $danfishgold$base64_bytes$Decode$fromBytes;
var $mdgriffith$elm_ui$Element$scrollbarX = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.gZ);
var $elm$bytes$Bytes$Encode$Utf8 = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$string = function (str) {
	return A2(
		$elm$bytes$Bytes$Encode$Utf8,
		_Bytes_getStringWidth(str),
		str);
};
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Main$shareMenu = function (mPage) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(10),
					$mdgriffith$elm_ui$Element$spacing(10),
					$author$project$Style$backgroundColour(0),
					$mdgriffith$elm_ui$Element$Border$width(4)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$Font$bold]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('Share Current Page')
						])),
					function () {
					if (!mPage.$) {
						var page = mPage.a;
						var encodedPage = A3($miniBill$elm_codec$Codec$encodeToString, 0, $author$project$Main$pageCodec, page);
						var compressedPage = A2(
							$elm$core$Maybe$withDefault,
							'',
							$danfishgold$base64_bytes$Base64$fromBytes(
								$folkertdev$elm_flate$Flate$deflateGZip(
									$elm$bytes$Bytes$Encode$encode(
										$elm$bytes$Bytes$Encode$string(encodedPage)))));
						var pageUrl = A3(
							$elm$url$Url$Builder$crossOrigin,
							'https://midisurf.app',
							_List_Nil,
							_List_fromArray(
								[
									A2($elm$url$Url$Builder$string, 'page', compressedPage)
								]));
						return A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(10)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$height(
											$mdgriffith$elm_ui$Element$px(40)),
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$px(280)),
											$mdgriffith$elm_ui$Element$scrollbarX
										]),
									$mdgriffith$elm_ui$Element$text(pageUrl)),
									A2(
									$mdgriffith$elm_ui$Element$Input$button,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$padding(4),
											$mdgriffith$elm_ui$Element$Border$width(2)
										]),
									{
										d: $mdgriffith$elm_ui$Element$html(
											A2(
												$feathericons$elm_feather$FeatherIcons$toHtml,
												_List_Nil,
												A2($feathericons$elm_feather$FeatherIcons$withSize, 20, $feathericons$elm_feather$FeatherIcons$copy))),
										af: $elm$core$Maybe$Just(
											$author$project$Main$CopyToClipboard(pageUrl))
									})
								]));
					} else {
						return A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(300))
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('No page selected.')
								]));
					}
				}(),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(5),
							$mdgriffith$elm_ui$Element$Border$width(2),
							$mdgriffith$elm_ui$Element$Border$solid
						]),
					{
						d: $mdgriffith$elm_ui$Element$text('Close'),
						af: $elm$core$Maybe$Just($author$project$Main$ClosePopUp)
					})
				])));
};
var $author$project$Main$renderPopup = F5(
	function (screen, midiStatus, savedPages, savedModules, popup) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(5),
						$mdgriffith$elm_ui$Element$scrollbars,
						$mdgriffith$elm_ui$Element$Background$color(
						A4($mdgriffith$elm_ui$Element$rgba, 0.5, 0.5, 0.5, 0.8))
					]),
				function () {
					if (!screen.$) {
						var s = screen.a;
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$px(s.ab)),
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(s.av))
							]);
					} else {
						return $author$project$Style$fillSpace;
					}
				}()),
			function () {
				switch (popup.$) {
					case 0:
						var reason = popup.a;
						return $author$project$Main$noWebMidiPanel(reason);
					case 1:
						return $author$project$Main$infoPanel;
					case 2:
						if (midiStatus.$ === 2) {
							var devices = midiStatus.a;
							return $author$project$Main$midiMenu(devices);
						} else {
							return $author$project$Main$midiMenu(_List_Nil);
						}
					case 3:
						var state = popup.a;
						return $author$project$Main$saveMenu(state);
					case 4:
						var mPage = popup.a;
						return $author$project$Main$shareMenu(mPage);
					case 5:
						var oldController = popup.b;
						var state = popup.c;
						return A3($author$project$Main$editMenu, oldController, savedModules, state);
					case 6:
						var state = popup.a;
						return A2($author$project$Main$newPageMenu, savedPages, state);
					case 7:
						var pageString = popup.a;
						return $author$project$Main$importPageFromUrlMenu(pageString);
					default:
						var index = popup.a;
						var state = popup.b;
						return A2($author$project$Main$editPageMenu, index, state);
				}
			}());
	});
var $author$project$Main$ToggleMenu = {$: 2};
var $author$project$Main$OpenInfoPanel = {$: 3};
var $author$project$Main$OpenMidiMenu = {$: 4};
var $author$project$Main$OpenSaveLoadMenu = {$: 5};
var $author$project$Main$OpenShareMenu = {$: 10};
var $author$project$Main$ToggleNormalEdit = {$: 11};
var $feathericons$elm_feather$FeatherIcons$coffee = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'coffee',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M18 8h1a4 4 0 0 1 0 8h-1')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('6'),
					$elm$svg$Svg$Attributes$y1('1'),
					$elm$svg$Svg$Attributes$x2('6'),
					$elm$svg$Svg$Attributes$y2('4')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('10'),
					$elm$svg$Svg$Attributes$y1('1'),
					$elm$svg$Svg$Attributes$x2('10'),
					$elm$svg$Svg$Attributes$y2('4')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('14'),
					$elm$svg$Svg$Attributes$y1('1'),
					$elm$svg$Svg$Attributes$x2('14'),
					$elm$svg$Svg$Attributes$y2('4')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$edit = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'edit',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z')
				]),
			_List_Nil)
		]));
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $feathericons$elm_feather$FeatherIcons$gitPullRequest = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'git-pull-request',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('18'),
					$elm$svg$Svg$Attributes$cy('18'),
					$elm$svg$Svg$Attributes$r('3')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('6'),
					$elm$svg$Svg$Attributes$cy('6'),
					$elm$svg$Svg$Attributes$r('3')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M13 6h3a2 2 0 0 1 2 2v7')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('6'),
					$elm$svg$Svg$Attributes$y1('9'),
					$elm$svg$Svg$Attributes$x2('6'),
					$elm$svg$Svg$Attributes$y2('21')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$info = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'info',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('12'),
					$elm$svg$Svg$Attributes$cy('12'),
					$elm$svg$Svg$Attributes$r('10')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('16'),
					$elm$svg$Svg$Attributes$x2('12'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('8'),
					$elm$svg$Svg$Attributes$x2('12.01'),
					$elm$svg$Svg$Attributes$y2('8')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$package = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'package',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('16.5'),
					$elm$svg$Svg$Attributes$y1('9.4'),
					$elm$svg$Svg$Attributes$x2('7.5'),
					$elm$svg$Svg$Attributes$y2('4.21')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('3.27 6.96 12 12.01 20.73 6.96')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('22.08'),
					$elm$svg$Svg$Attributes$x2('12'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$save = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'save',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('17 21 17 13 7 13 7 21')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('7 3 7 8 15 8')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$share2 = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'share-2',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('18'),
					$elm$svg$Svg$Attributes$cy('5'),
					$elm$svg$Svg$Attributes$r('3')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('6'),
					$elm$svg$Svg$Attributes$cy('12'),
					$elm$svg$Svg$Attributes$r('3')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('18'),
					$elm$svg$Svg$Attributes$cy('19'),
					$elm$svg$Svg$Attributes$r('3')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('8.59'),
					$elm$svg$Svg$Attributes$y1('13.51'),
					$elm$svg$Svg$Attributes$x2('15.42'),
					$elm$svg$Svg$Attributes$y2('17.49')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('15.41'),
					$elm$svg$Svg$Attributes$y1('6.51'),
					$elm$svg$Svg$Attributes$x2('8.59'),
					$elm$svg$Svg$Attributes$y2('10.49')
				]),
			_List_Nil)
		]));
var $author$project$Main$defaultButtons = _List_fromArray(
	[
		A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignLeft,
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(4),
				A2($mdgriffith$elm_ui$Element$paddingXY, 4, 0),
				$author$project$Style$backgroundColour(1)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$width(4),
						$author$project$Style$backgroundColour(0)
					]),
				{
					d: $mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 28, $feathericons$elm_feather$FeatherIcons$info))),
					af: $elm$core$Maybe$Just($author$project$Main$OpenInfoPanel)
				}),
				A2(
				$mdgriffith$elm_ui$Element$newTabLink,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$width(4),
						$author$project$Style$backgroundColour(0)
					]),
				{
					d: $mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 28, $feathericons$elm_feather$FeatherIcons$coffee))),
					ap: 'https://ko-fi.com/mochreach'
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$width(4),
						$author$project$Style$backgroundColour(0)
					]),
				{
					d: $mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 28, $feathericons$elm_feather$FeatherIcons$gitPullRequest))),
					af: $elm$core$Maybe$Just($author$project$Main$OpenMidiMenu)
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$width(4),
						$author$project$Style$backgroundColour(0)
					]),
				{
					d: $mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 28, $feathericons$elm_feather$FeatherIcons$save))),
					af: $elm$core$Maybe$Just($author$project$Main$OpenSaveLoadMenu)
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$width(4),
						$author$project$Style$backgroundColour(0)
					]),
				{
					d: $mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 28, $feathericons$elm_feather$FeatherIcons$share2))),
					af: $elm$core$Maybe$Just($author$project$Main$OpenShareMenu)
				}),
				A2(
				$mdgriffith$elm_ui$Element$newTabLink,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$width(4),
						$author$project$Style$backgroundColour(0)
					]),
				{
					d: $mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 28, $feathericons$elm_feather$FeatherIcons$package))),
					ap: 'https://github.com/mochreach/midi-surf/blob/main/presets.md'
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$width(4),
						$author$project$Style$backgroundColour(0)
					]),
				{
					d: $mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 28, $feathericons$elm_feather$FeatherIcons$edit))),
					af: $elm$core$Maybe$Just($author$project$Main$ToggleNormalEdit)
				})
			]))
	]);
var $author$project$Main$UndoEdit = {$: 12};
var $feathericons$elm_feather$FeatherIcons$rotateCcw = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'rotate-ccw',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('1 4 1 10 7 10')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M3.51 15a9 9 0 1 0 2.13-9.36L1 10')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$x = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'x',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('18'),
					$elm$svg$Svg$Attributes$y1('6'),
					$elm$svg$Svg$Attributes$x2('6'),
					$elm$svg$Svg$Attributes$y2('18')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('6'),
					$elm$svg$Svg$Attributes$y1('6'),
					$elm$svg$Svg$Attributes$x2('18'),
					$elm$svg$Svg$Attributes$y2('18')
				]),
			_List_Nil)
		]));
var $author$project$Main$editButtons = _List_fromArray(
	[
		A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignLeft,
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(4),
				A2($mdgriffith$elm_ui$Element$paddingXY, 4, 0),
				$author$project$Style$backgroundColour(1)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$width(4),
						$author$project$Style$backgroundColour(0)
					]),
				{
					d: $mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 28, $feathericons$elm_feather$FeatherIcons$x))),
					af: $elm$core$Maybe$Just($author$project$Main$ToggleNormalEdit)
				}),
				A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$Border$width(4),
						$author$project$Style$backgroundColour(0)
					]),
				{
					d: $mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 28, $feathericons$elm_feather$FeatherIcons$rotateCcw))),
					af: $elm$core$Maybe$Just($author$project$Main$UndoEdit)
				})
			]))
	]);
var $feathericons$elm_feather$FeatherIcons$menu = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'menu',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('3'),
					$elm$svg$Svg$Attributes$y1('12'),
					$elm$svg$Svg$Attributes$x2('21'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('3'),
					$elm$svg$Svg$Attributes$y1('6'),
					$elm$svg$Svg$Attributes$x2('21'),
					$elm$svg$Svg$Attributes$y2('6')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('3'),
					$elm$svg$Svg$Attributes$y1('18'),
					$elm$svg$Svg$Attributes$x2('21'),
					$elm$svg$Svg$Attributes$y2('18')
				]),
			_List_Nil)
		]));
var $author$project$Main$menuRow = F2(
	function (mode, menuOpen) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(10),
							menuOpen ? $author$project$Style$borderColour(1) : $author$project$Style$borderColour(3),
							$mdgriffith$elm_ui$Element$Border$width(4),
							menuOpen ? $author$project$Style$backgroundColour(1) : $author$project$Style$backgroundColour(0)
						]),
					{
						d: $mdgriffith$elm_ui$Element$html(
							A2(
								$feathericons$elm_feather$FeatherIcons$toHtml,
								_List_Nil,
								A2($feathericons$elm_feather$FeatherIcons$withSize, 36, $feathericons$elm_feather$FeatherIcons$menu))),
						af: $elm$core$Maybe$Just($author$project$Main$ToggleMenu)
					}),
				function () {
					if (menuOpen) {
						if (!mode.$) {
							return $author$project$Main$defaultButtons;
						} else {
							return $author$project$Main$editButtons;
						}
					} else {
						return _List_Nil;
					}
				}()));
	});
var $author$project$Main$OpenNewPageMenu = {$: 15};
var $author$project$Main$newPageButton = A2(
	$mdgriffith$elm_ui$Element$Input$button,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$padding(10),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$Border$width(4)
		]),
	{
		d: $mdgriffith$elm_ui$Element$html(
			A2(
				$feathericons$elm_feather$FeatherIcons$toHtml,
				_List_Nil,
				A2($feathericons$elm_feather$FeatherIcons$withSize, 36, $feathericons$elm_feather$FeatherIcons$plus))),
		af: $elm$core$Maybe$Just($author$project$Main$OpenNewPageMenu)
	});
var $author$project$Main$OpenEditPageMenu = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$SelectActivePage = function (a) {
	return {$: 30, a: a};
};
var $author$project$Main$pageButton = F4(
	function (mode, activePage, pageNumber, _v0) {
		var label = _v0.d;
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
					]),
				_Utils_ap(
					function () {
						if (!mode.$) {
							return _List_Nil;
						} else {
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Border$width(4),
									$author$project$Style$borderColour(3),
									$mdgriffith$elm_ui$Element$Border$dashed
								]);
						}
					}(),
					_Utils_eq(pageNumber, activePage) ? _List_fromArray(
						[
							$author$project$Style$backgroundColour(3),
							$author$project$Style$fontColour(0)
						]) : _List_fromArray(
						[
							$author$project$Style$backgroundColour(1),
							$author$project$Style$fontColour(3)
						]))),
			{
				d: $mdgriffith$elm_ui$Element$text(label),
				af: function () {
					if (!mode.$) {
						return $elm$core$Maybe$Just(
							$author$project$Main$SelectActivePage(pageNumber));
					} else {
						return $elm$core$Maybe$Just(
							$author$project$Main$OpenEditPageMenu(pageNumber));
					}
				}()
			});
	});
var $author$project$Main$titleBar = F4(
	function (mode, menuOpen, activePage, pages) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(76)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(4),
					$mdgriffith$elm_ui$Element$spacing(4),
					$mdgriffith$elm_ui$Element$scrollbarX,
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{bG: 4, bK: 0, bV: 0, bZ: 0})
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 8, 12),
							$author$project$Style$backgroundColour(3),
							$mdgriffith$elm_ui$Element$Font$bold,
							$author$project$Style$fontColour(0),
							$mdgriffith$elm_ui$Element$Region$heading(1)
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('MIDI'),
							$mdgriffith$elm_ui$Element$text('Surf')
						])),
					A2($author$project$Main$menuRow, mode, menuOpen),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(4)
						]),
					_Utils_ap(
						$elm$core$Array$toList(
							A2(
								$elm$core$Array$indexedMap,
								A2($author$project$Main$pageButton, mode, activePage),
								pages)),
						function () {
							if (!mode.$) {
								return _List_Nil;
							} else {
								return _List_fromArray(
									[$author$project$Main$newPageButton]);
							}
						}()))
				]));
	});
var $author$project$Main$view = function (model) {
	return {
		fh: $elm$core$List$singleton(
			A2(
				$mdgriffith$elm_ui$Element$layout,
				_Utils_ap(
					function () {
						var _v0 = model.h;
						if (!_v0.$) {
							var popup = _v0.a;
							return A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$inFront(
									A5($author$project$Main$renderPopup, model.bd, model.bN, model.aA, model.az, popup)),
								function () {
									var _v1 = model.bd;
									if (!_v1.$) {
										var screen = _v1.a;
										return _List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$px(screen.ab)),
												$mdgriffith$elm_ui$Element$height(
												$mdgriffith$elm_ui$Element$px(screen.av))
											]);
									} else {
										return $author$project$Style$fillSpace;
									}
								}());
						} else {
							return $author$project$Style$fillSpace;
						}
					}(),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$family(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$external(
									{ed: 'Space Mono', ap: 'https://fonts.googleapis.com/css?family=Space+Mono'}),
									$mdgriffith$elm_ui$Element$Font$monospace
								]))
						])),
				A2(
					$mdgriffith$elm_ui$Element$column,
					function () {
						var _v2 = model.bd;
						if (!_v2.$) {
							var screen = _v2.a;
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(screen.ab)),
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(screen.av))
								]);
						} else {
							return $author$project$Style$fillSpace;
						}
					}(),
					_List_fromArray(
						[
							A4($author$project$Main$titleBar, model.o, model.bt, model.k, model.b),
							function () {
							var _v3 = A2($elm$core$Array$get, model.k, model.b);
							if (!_v3.$) {
								var page = _v3.a;
								return A3(
									$mdgriffith$elm_ui$Element$Lazy$lazy2,
									$mdgriffith$elm_ui$Element$el,
									_Utils_ap(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$padding(2),
												$mdgriffith$elm_ui$Element$scrollbars
											]),
										$author$project$Style$fillSpace),
									A3($author$project$Main$renderPage, model.o, model.bu, page));
							} else {
								return A2(
									$mdgriffith$elm_ui$Element$el,
									$author$project$Style$fillSpace,
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
										$mdgriffith$elm_ui$Element$text('No page selected.')));
							}
						}()
						])))),
		hA: 'MIDI Surf'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		gc: $author$project$Main$init,
		gG: function (_v0) {
			return $author$project$Main$NoOp;
		},
		gH: function (_v1) {
			return $author$project$Main$NoOp;
		},
		he: $author$project$Main$subscriptions,
		hG: $author$project$Main$update,
		cL: $author$project$Main$view
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (mInitialState) {
			return $elm$json$Json$Decode$succeed(
				{c9: mInitialState});
		},
		A2($elm$json$Json$Decode$field, 'mInitialState', $elm$json$Json$Decode$value)))(0)}});}(this));